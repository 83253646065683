import {
    LeaseType, LocationType, ProductType, OrderTemperatureRange,
} from 'app/types/enums'
import {
    DeliveryServiceType, TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'

import {
    CustomerTransportCreateData, Selection,
} from './newCustomerTransport.types'

type Args = {
    value: CustomerTransportCreateData,
    selectedLane: Lane,
    isDeliveryRoad: boolean,
    defaultTimeZone: string,
    allowedProductTypes: ProductType[],
    getAllowedValues: (enumType: any) => Record<string, string>,
}

const getFields = ({
    value,
    selectedLane,
    isDeliveryRoad,
    defaultTimeZone,
    allowedProductTypes,
    getAllowedValues,
}: Args): DTOCardFormField<keyof CustomerTransportCreateData>[] => {
    const {
        selectedLaneId,
        transportModeForwarder,
    } = value

    const customerExecutionContactsOpsField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'customerExecutionContactsOps',
        componentName: 'MultipleContactObjectSelector',
        disabled: Boolean(selectedLaneId),
        labelKey: 'Lane.customerExecutionContactsOps',
    }]

    const AvailableLanesCard: DTOCardFormField<keyof CustomerTransportCreateData>[] = [{
        title: 'Available Lanes',
        className: 'availableLanes',
        id: 'availableLanes',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'selectedLaneId',
            componentName: 'AvailableLanes',
            value,
            labelKey: 'Order.leaseType',
        }],
    }]

    const transportModeRoadField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        disabled: selectedLane?.transportModeRoad === TransportModeRoad.LTL
            || selectedLane?.transportModeRoad === TransportModeRoad.FTL,
        labelKey: 'Order.transportModeRoad',
    }]

    const transportModeForwarderField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'transportModeForwarder',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        required: true,
        labelKey: 'Order.transportModeForwarder',
    }]

    const numberOfVansTracksFields: NestedField<keyof CustomerTransportCreateData>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'CustomerTransportCreateDto.numberOfTrucks',
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'CustomerTransportCreateDto.numberOfVans',
        },
    ]

    const originScSelectionField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'originScSelection',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(Selection),
        noSort: true,
        disabled: !selectedLane?.originScBackup,
        labelKey: 'CustomerTransportCreateDto.originScSelection',
    }]

    const isDeliveryRoadFirstSetFields: NestedField<keyof CustomerTransportCreateData>[] = [
        ...selectedLane?.originScBackup ? originScSelectionField : [],
        {
            name: 'originServiceCenter',
            componentName: 'LocationObjectSelector',
            disabled: true,
            labelKey: 'Lane.originSc',
        },
        {
            name: 'estimatedPickup',
            componentName: 'DateTimeZonedSelect',
            labelKey: 'CustomerTransportCreateDto.estimatedPickup',
        },
    ]

    const requestedPickupField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'requestedPickup',
        componentName: 'DateTimeZonedSelect',
        defaultTimeZone,
        required: true,
        labelKey: 'CustomerTransportCreateDto.requestedPickup',
    }]

    const leaseStartTimestampField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'leaseStartTimestamp',
        componentName: 'DateTimeZonedSelect',
        required: true,
        labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
    }]

    const freightForwarderField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'freightForwarder',
        componentName: 'CompanyObjectSelector',
        required: true,
        labelKey: 'OrderByNumberView.freightForwarder',
    }]

    const fwdPosSelectionField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'fwdPosSelection',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(Selection),
        noSort: true,
        labelKey: 'CustomerTransportCreateDto.fwdPosSelection',
    }]

    const isDeliveryRoadSecondSetFields: NestedField<keyof CustomerTransportCreateData>[] = [
        ...selectedLane?.fwdPosBackup ? fwdPosSelectionField : [],
        {
            name: 'forwarder',
            componentName: 'LocationObjectSelector',
            disabled: true,
            labelKey: 'Order.forwarderPositioning',
        },
        {
            name: 'requestedDelivery',
            componentName: 'DateTimeZonedSelect',
            required: true,
            defaultTimeZone,
            labelKey: 'Order.requestedDelivery',
        },
        {
            name: 'leaseStartTimestamp',
            componentName: 'DateTimeZonedSelect',
            required: true,
            labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
        },
    ]

    const originServiceCenterField: NestedField<keyof CustomerTransportCreateData>[] = [{
        name: 'originServiceCenter',
        componentName: 'LocationObjectSelector',
        disabled: true,
        labelKey: 'Lane.originSc',
    }]

    const PositioningSetupCard: DTOCardFormField<keyof CustomerTransportCreateData>[] = [
        {
            title: 'Positioning Setup',
            className: 'positioningSetup',
            id: 'positioningSetup',
            fields: [
                {
                    id: 'column6',
                    fields: [
                        {
                            name: 'leaseType',
                            componentName: 'EnumSelector',
                            value: selectedLane?.leaseType,
                            allowedValues: getAllowedValues(LeaseType),
                            disabled: true,
                            labelKey: 'Order.leaseType',
                        },
                        {
                            name: 'deliveryServiceType',
                            componentName: 'EnumSelector',
                            value: selectedLane?.deliveryServiceType,
                            allowedValues: getAllowedValues(DeliveryServiceType),
                            disabled: true,
                            labelKey: 'Order.deliveryServiceType',
                        },
                        {
                            name: 'laneCommentOps',
                            componentName: 'LongText',
                            disabled: true,
                            labelKey: 'Lane.laneCommentOps',
                        },
                        ...isDeliveryRoad ? transportModeRoadField : [],
                        ...isDeliveryRoad ? transportModeForwarderField : [],
                        ...transportModeForwarder === TransportModeRoad.FTL && isDeliveryRoad
                            ? numberOfVansTracksFields
                            : [],
                        {
                            name: 'orderReceived',
                            componentName: 'DateTimeZonedSelect',
                            required: true,
                            labelKey: 'Order.orderReceived',
                        },
                        {
                            name: 'orderEntered',
                            componentName: 'DateTimeZonedSelect',
                            disabled: true,
                            labelKey: 'Order.orderEntered',
                        },
                        {
                            name: 'mawbNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'Order.mawbNumber',
                        },
                    ],
                },
                {
                    id: 'column7',
                    fields: [
                        ...isDeliveryRoad ? isDeliveryRoadFirstSetFields : requestedPickupField,
                        {
                            name: 'customerReference',
                            componentName: 'InputSingleline',
                            required: true,
                            labelKey: 'Order.customerReference',
                        },
                        ...isDeliveryRoad ? [] : leaseStartTimestampField,
                        {
                            name: 'hawbNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'CustomerTransportCreateDto.hawbNumber',
                        },
                        ...selectedLane?.leaseType === LeaseType.AIRLINE_LEASE
                            ? freightForwarderField
                            : [],
                    ],
                },
                {
                    id: 'column8',
                    fields: [
                        ...isDeliveryRoad
                            ? isDeliveryRoadSecondSetFields
                            : originServiceCenterField,
                        {
                            name: 'commentForBillingOps',
                            componentName: 'LongText',
                            labelKey: 'OrderByNumberView.commentForBillingOps',
                        },
                        {
                            name: 'personInCharge',
                            componentName: 'InputSingleline',
                            labelKey: 'Order.personInCharge',
                        },
                        {
                            name: 'isPerformanceQualification',
                            componentName: 'Checkbox',
                            labelKey: 'Order.isPerformanceQualification',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Required Containers',
            className: 'requiredContainers',
            id: 'requiredContainers',
            fields: [{
                id: 'column5',
                fields: [
                    {
                        name: 'minOrder',
                        value: selectedLane?.minOrder,
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'LaneOpsInformationDto.minOrder',
                    },
                    {
                        name: 'requiredCargoComment',
                        componentName: 'LongText',
                        labelKey: 'Order.requiredCargoComment',
                    },
                    {
                        name: 'requiredContainers',
                        componentName: 'RequiredContainersByProductType',
                        allowedProductTypes,
                        disabled: !allowedProductTypes,
                        required: true,
                        labelKey: 'Order.requiredContainers',
                    },
                ],
            }],
        },
    ]

    return [
        {
            title: selectedLane ? `Selection: ${selectedLane.laneNumber}` : 'Selection',
            className: 'selection',
            id: 'selection',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'customerExecution',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'Lane.customerExecution',
                        },
                        {
                            name: 'account',
                            componentName: 'AccountSelector',
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'RegionalGroups.accountName',
                        },
                        ...selectedLaneId ? customerExecutionContactsOpsField : [],
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'originAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'Order.originAirport',
                        },
                        {
                            name: 'destinationAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'Order.destinationAirport',
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'handoverPoint',
                            componentName: 'LocationExpandedViewObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'Order.handoverPoint',
                        },
                        {
                            name: 'collectionDropoffPoint',
                            componentName: 'LocationExpandedViewObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'Order.collectionDropoffPoint',
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [
                        {
                            name: 'consignee',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'Order.consignee',
                        },
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OrderTemperatureRange),
                            useDropdown: true,
                            disabled: Boolean(selectedLaneId),
                            labelKey: 'Order.temperatureRange',
                        },
                    ],
                },
            ],
        },
        ...!selectedLaneId ? AvailableLanesCard : [],
        ...selectedLane ? PositioningSetupCard : [],
    ]
}

export default getFields
