import {
    LoggerStatus,
} from '../Loggers.types'

export const availableLoggerStatuses = {
    [LoggerStatus.ACTIVE]: 'Active',
    [LoggerStatus.DECOMMISSIONED]: 'Decomissioned',
    [LoggerStatus.SERVICE]: 'Service',
    [LoggerStatus.OUT_OF_SERVICE]: 'Out of Service',
    [LoggerStatus.READY_TO_SHIP]: 'Ready to Ship',
}

export default availableLoggerStatuses
