import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    Button,
    Dialog,
    TextField,
    DialogActions,
} from '@mui/material'
import {
    StaticDateTimePicker,
} from '@mui/x-date-pickers/StaticDateTimePicker'

import Select from 'app/shared-components/Select'
import {
    dateToStr,
    strToDate,
    dateTimeMask,
    getTimezones,
} from 'app/utils/date'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Timezone,
} from 'app/types/enums'

import styles from './Zone.module.css'

type Props = {
    state : {
        newZone: string,
        newDatetime: string,
    },
    setState: (state: any) => void,
    handleChangeZone: (zone: string) => void,
    handleChange: (datetime: string) => void,
    handleClose: () => void,
    disableFuture?: boolean,
}

const defaultProps: Partial<Props> = {
    disableFuture: false,
}

const Zone = ({
    state,
    setState,
    handleChange,
    handleChangeZone,
    handleClose,
    disableFuture,
}: Props) => {
    const timezonesAllowedValues = getTimezones(useEnumValues()(Timezone))

    const setDateAndZone = useCallback((datetime) => {
        const newDatetime = datetime ? dateToStr(datetime) : null

        setState({
            ...state,
            newDatetime,
        })
    }, [
        setState,
        state,
    ])

    const handleRemove = () => {
        handleChange(null)
        handleClose()
    }

    const handleSetTime = useCallback(() => {
        const dateWithoutZone = state.newDatetime.split(' ').slice(0, -1).join(' ')

        handleChange(`${dateWithoutZone} ${state.newZone}`)
        handleClose()
    }, [
        handleChange,
        handleClose,
        state.newDatetime,
        state.newZone,
    ])

    const datetime = useMemo(() => {
        return strToDate(state.newDatetime, dateTimeMask)
    }, [state.newDatetime])

    return (
        <Dialog
            open
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <>
                <StaticDateTimePicker
                    ampm={false}
                    value={strToDate(datetime)}
                    onChange={setDateAndZone}
                    renderInput={(params) => {
                        return <TextField {...params} /> // eslint-disable-line react/jsx-props-no-spreading, max-len
                    }}
                    disableFuture={disableFuture}
                    components={{
                        ActionBar: () => {
                            return null
                        },
                    }}
                />
                <div className={styles.zones}>
                    {state.newZone !== '' && (
                        <Select
                            title="Time Zone"
                            data={timezonesAllowedValues}
                            value={state.newZone}
                            onChange={handleChangeZone}
                        />
                    )}
                </div>
                <DialogActions>
                    <Button
                        onClick={handleRemove}
                        color="primary"
                    >
                        Remove
                    </Button>
                    <Button
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!state.newDatetime}
                        onClick={handleSetTime}
                        color="primary"
                    >
                        Ok
                    </Button>
                </DialogActions>

            </>
        </Dialog>
    )
}

Zone.defaultProps = defaultProps

export default Zone
