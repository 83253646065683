import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            height: 88,
            alignItems: 'center',
            padding: theme.spacing(2.5, 1.5),
            boxSizing: 'border-box',
            borderRadius: 16,
            border: `1px solid ${theme.palette.primary[100]}`,
            color: theme.palette.secondary[600],
            width: 335,
            justifyContent: 'space-between',
            marginRight: theme.spacing(3),
        },
        selected: {
            background: theme.palette.primary[30],
            pointerEvents: 'none',
        },
        unselected: {
            background: theme.palette.common.white,
            cursor: 'pointer',
        },
        disabled: {
            opacity: '50%',
            pointerEvents: 'none',
        },
        wrapper: {
            display: 'flex',
            gap: theme.spacing(3),
            alignItems: 'center',
        },
        img: {
            height: 24,
        },
        icon: {
            color: theme.palette.primary.main,
        },
    }
})

export default useStyles
