import React from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'

import useLoadDtoForSkyNetTable from 'app/shared-components/SkyNetTable/hooks/useLoadDtoForSkyNetTable'
import columns from './ContactChangeSummary.columns'
import ContactRequests from '../Contacts.request'

import useStyles from './ContactChangeSummary.style'
import {
    Modification,
} from './ContactChangeSummary.types'

type Props = {
    id: number
}

const ContactChangeSummary = ({
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const load: (params: any) => Promise<{
        items: Modification,
        matches: number
    }> = useLoadDtoForSkyNetTable(
        ContactRequests.getContactsChangeHistory.request(id),
    )

    return (
        <RichTable
            configName="ContactChangeSummaryTable"
            name={ContactRequests.name}
            load={load}
            columns={columns}
            uniqField="id"
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
        />
    )
}

export default ContactChangeSummary
