import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'

import {
    Contact,
} from '../Contacts.types'

const contactFormFields = (
    {
        disabled,
        allowedMainTypes,
        hasRelatedUser,
        isProspectDisabled,
        isProspect,
    }: {
        disabled: boolean,
        allowedMainTypes: Record<string, string>,
        hasRelatedUser: boolean,
        isProspectDisabled: boolean,
        isProspect: boolean,
    },
): DTOCardFormField<keyof Contact>[] => {
    return [
        {
            id: 'contact',
            title: 'Contact',
            className: 'contactCard',
            fields: [{
                id: 'column1',
                fields: [
                    {
                        name: 'title',
                        labelKey: 'Contact.title',
                        componentName: 'InputSingleline',
                    },
                    {
                        name: 'contactName',
                        labelKey: 'Contact.contactName',
                        componentName: 'InputSingleline',
                        required: true,
                        disabled: disabled || hasRelatedUser,
                    },
                    {
                        componentName: 'ContactNameComment',
                        disabled,
                    },
                    {
                        name: 'email',
                        componentName: 'InputSingleline',
                        labelKey: 'Contact.email',
                        required: true,
                        disabled: disabled || hasRelatedUser,
                    },
                ],
            }],
        },
        {
            id: 'phoneNumbers',
            title: 'Phone Numbers',
            fields: [{
                id: 'column2',
                fields: [
                    {
                        name: 'phone',
                        componentName: 'InputSingleline',
                        labelKey: 'Contact.phone',
                    },
                    {
                        name: 'mobilePhone',
                        componentName: 'InputSingleline',
                        labelKey: 'Contact.mobilePhone',
                    },
                    {
                        name: 'fax',
                        componentName: 'InputSingleline',
                        labelKey: 'Contact.fax',
                    },
                ],
            }],
        },
        {
            id: 'location',
            title: 'Location',
            fields: [{
                id: 'column3',
                fields: [
                    {
                        name: 'isProspect',
                        labelKey: 'LocationRegular.isProspect',
                        componentName: 'Switch',
                        disabled: disabled || isProspectDisabled,
                    },
                    isProspect ? {
                        name: 'company',
                        componentName: 'CompanyObjectSelector',
                        required: true,
                        labelKey: 'Accounts.accountCompany',
                    } as NestedField<keyof Contact> : {
                        name: 'location',
                        labelKey: 'Contact.location',
                        componentName: 'LocationObjectSelector',
                        title: 'Location',
                        required: true,
                        disabled: disabled || hasRelatedUser,
                    } as NestedField<keyof Contact>,
                    {
                        name: 'country',
                        componentName: 'CountryDataSelector',
                        labelKey: 'Country.basedInCountry',
                        disabled: disabled || !isProspect,
                    },
                    {
                        name: 'isMainContact',
                        labelKey: 'Contact.isMainContact',
                        title: 'Is Main Contact',
                        componentName: 'Switch',
                        disabled,
                    },
                ],
            }],
        },
        {
            id: 'otherInformation',
            title: 'Other Information',
            fields: [{
                id: 'column4',
                fields: [
                    {
                        name: 'position',
                        componentName: 'InputSingleline',
                        labelKey: 'Contact.position',
                    },
                    {
                        name: 'type',
                        componentName: 'MultiEnumSelector',
                        allowedValues: allowedMainTypes,
                        labelKey: 'Contact.type',
                        disabled: disabled || isProspect,
                    },
                    {
                        name: 'createdBy',
                        disabled: true,
                        componentName: 'InputSingleline',
                        labelKey: 'Contact.createdBy',
                    },
                    {
                        name: 'changedBy',
                        disabled: true,
                        componentName: 'InputSingleline',
                        labelKey: 'Contact.changedBy',
                    },
                    {
                        name: 'mainType',
                        disabled: true,
                        componentName: 'ChipSelect',
                        labelKey: 'Contact.mainType',
                    },
                ],
            }],
        },
    ]
}

export default contactFormFields
