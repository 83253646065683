import {
    LocationType,
} from 'app/types/enums'
import {
    FieldInCard,
} from 'app/types/form.types'
import {
    OrderStepFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getInternalCustomsFormFields = ({
    locationId,
    exists,
    forwarderId,
}: {
    locationId: number,
    forwarderId: number,
    exists: boolean,
}): FieldInCard<OrderStepFields>[] => {
    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'forwarder',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.ADDRESS,
                    isApprovedSubContractor: true,
                    labelKey: 'Location.forwarder',
                },
                {
                    name: 'forwarderContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    labelKey: 'Location.forwarderContacts',
                    existedForm: exists,
                    selectedLocation: forwarderId,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'location',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderStep.location',
                },
                {
                    name: 'locationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    labelKey: 'OrderStep.locationContacts',
                    existedForm: exists,
                    selectedLocation: locationId,
                },
            ],
        },
        {
            id: 'column3',
            fields: [{
                name: 'comment',
                componentName: 'LongText',
                labelKey: 'OrderStep.comment',
            }],
        },
    ]
}

export default getInternalCustomsFormFields
