import React from 'react'

import SimpleTable from 'app/shared-components/SimpleTable'
import {
    Column,
} from 'app/types/simpletable.types'

type Props = {
    name: string,
    value?: Record<string, any>[],
    onChange: (...args: any[]) => void,
    columns: Column<any>[],
    addEntry: (...args: any[]) => void,
    deleteEntry: (...args: any[]) => void,
    inputs: (...args: any) => JSX.Element,
}

const defaultProps: Partial<Props> = {
    value: [],
}
const Table = (props: Props) => {
    const {
        name,
        columns,
        value,
        onChange,
        addEntry,
        deleteEntry,
        inputs,
    } = props

    return (
        <SimpleTable
            name={name}
            columns={columns}
            data={value}
            component={inputs}
            addEntry={addEntry}
            onChange={onChange}
            onDelete={deleteEntry}
        />
    )
}

Table.defaultProps = defaultProps

export default Table
