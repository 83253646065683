export enum RegionZeroLevel {
    AMERICAS = 'AMERICAS',
    EMEA = 'EMEA',
    APAC = 'APAC',
}
export enum RegionFirstLevel {
    AMERICA_NORTH = 'AMERICA_NORTH',
    AMERICA_SOUTH = 'AMERICA_SOUTH',
    EUROPE = 'EUROPE',
    AFRICA = 'AFRICA',
    MIDDLE_EAST = 'MIDDLE_EAST',
    ASIA_SOUTH = 'ASIA_SOUTH',
    ASIA_SOUTHEAST = 'ASIA_SOUTHEAST',
    ASIA_EAST = 'ASIA_EAST',
    OCEANIA = 'OCEANIA',
    REST_OF_THE_WORLD = 'REST_OF_THE_WORLD',
}
export enum RegionSecondLevel {
    MEXICO = 'MEXICO',
    PUERTO_RICO = 'PUERTO_RICO',
    AMERICA_CENTRAL_REST = 'AMERICA_CENTRAL_REST',
    CANADA_WEST = 'CANADA_WEST',
    CANADA_MID = 'CANADA_MID',
    CANADA_EAST = 'CANADA_EAST',
    US_MIDWEST = 'US_MIDWEST',
    US_NORTHEAST = 'US_NORTHEAST',
    US_SOUTHWEST = 'US_SOUTHWEST',
    US_SOUTHEAST = 'US_SOUTHEAST',
    US_WEST = 'US_WEST',
    BRAZIL = 'BRAZIL',
    ARGENTINA = 'ARGENTINA',
    AMERICA_SOUTH_REST = 'AMERICA_SOUTH_REST',
    EUROPE_CENTRAL = 'EUROPE_CENTRAL',
    EUROPE_EAST = 'EUROPE_EAST',
    EUROPE_SOUTH = 'EUROPE_SOUTH',
    SCANDINAVIA = 'SCANDINAVIA',
    UNITED_KINGDOM = 'UNITED_KINGDOM',
    IRELAND = 'IRELAND',
    EGYPT = 'EGYPT',
    KENIA = 'KENIA',
    SOUTH_AFRICA = 'SOUTH_AFRICA',
    AFRICA_REST = 'AFRICA_REST',
    ISRAEL = 'ISRAEL',
    MIDDLE_EAST_REST = 'MIDDLE_EAST_REST',
    INDIA = 'INDIA',
    ASIA_SOUTH_REST = 'ASIA_SOUTH_REST',
    SINGAPORE = 'SINGAPORE',
    ASIA_SOUTHEAST_REST = 'ASIA_SOUTHEAST_REST',
    CHINA_NORTH = 'CHINA_NORTH',
    CHINA_SOUTH = 'CHINA_SOUTH',
    JAPAN = 'JAPAN',
    SOUTH_KOREA = 'SOUTH_KOREA',
    AUSTRALIA_WEST = 'AUSTRALIA_WEST',
    AUSTRALIA_CENTRAL = 'AUSTRALIA_CENTRAL',
    AUSTRALIA_EAST = 'AUSTRALIA_EAST',
    NEW_ZEALAND = 'NEW_ZEALAND',
    REST_OF_THE_WORLD = 'REST_OF_THE_WORLD',
}

export type State = {
    changedBy?: string,
    changedOn?: string,
    createdBy?: string,
    createdOn?: string,
    deleted?: boolean,
    id: number,
    stateIsoAlpha2Code?: string,
    stateName?: string,
    regionSecondLevel?: string,
}

export type Country = {
    changedBy?: string,
    changedOn?: string,
    countryIsoAlpha2Code?: string,
    countryName?: string,
    createdBy?: string,
    createdOn?: string,
    deleted?: boolean,
    id: number,
    zipCodePattern?: string,
    zipCodePatternDescription?: string,
    states: State[],
    regionZeroLevel?: string,
    regionFirstLevel?: string,
    regionSecondLevel?: string,
}
