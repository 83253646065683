import React, {
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const propTypes = {
    value: PropTypes.number,
    className: PropTypes.string,
}

const defaultProps = {
    value: undefined,
    className: '',
}

const AmountOfTime = (props) => {
    const {
        value,
        className,
    } = props

    const duration = useMemo(() => {
        return value && moment.duration(value, 'minutes')
    }, [value])

    const years = useMemo(() => {
        return duration && duration.years()
    }, [duration])
    const months = useMemo(() => {
        return duration && duration.months()
    }, [duration])
    const days = useMemo(() => {
        return duration && duration.days()
    }, [duration])
    const hrs = useMemo(() => {
        return duration && duration.hours()
    }, [duration])
    const minutes = useMemo(() => {
        return duration && duration.minutes()
    }, [duration])

    if (!value) {
        return null
    }

    return (
        <div className={className}>
            {
                years !== 0 && `${years}y `
            }
            {
                months !== 0 && `${years !== 0 ? Math.abs(months) : months}m `
            }
            {
                days !== 0 && `${months !== 0 ? Math.abs(days) : days} day${Math.abs(days) > 1 ? 's' : ''}`
            }
            {
                hrs !== 0 && `${days !== 0 ? ` ${Math.abs(hrs)}` : `${hrs}`}h`
            }
            {
                minutes !== 0 && `${days !== 0 || hrs !== 0 ? ` ${Math.abs(minutes)}` : `${minutes}`}min`
            }
        </div>
    )
}

AmountOfTime.propTypes = propTypes
AmountOfTime.defaultProps = defaultProps

export default AmountOfTime
