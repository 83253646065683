import React, {
    useCallback,
} from 'react'

import useGetAccountLane from 'app/Apps/AccountManagement/AccountLanes/hooks/useGetAccountLane'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    LaneStatus,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'
import Button from 'app/shared-components/Button'
import AwardRejectLaneForm from './AwardRejectLaneForm'
import useAwardLane from './hooks/useAwardLane'
import useRejectLane from './hooks/useRejectLane'

const AwardRejectLane = ({
    id, refetch,
}: {
    id: number,
    refetch: (...args: any[]) => void
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAccountLane(id)

    const onSuccess = useCallback(() => {
        refetch()
        invalidate()
    }, [
        refetch,
        invalidate,
    ])

    const award = useAwardLane({
        onSuccess,
    })

    const reject = useRejectLane({
        onSuccess,
    })

    const getCustomButtons = useCallback(({
        edited,
        isDisabledOnError,
    }) => {
        return [
            <Button
                onClick={() => { return award(id) }}
                key="award"
                name="award"
                label="Award Lane"
                disabled={edited || isDisabledOnError}
            />,
            <Button
                onClick={() => { return reject(id) }}
                key="reject"
                name="reject"
                label="Reject Lane"
                disabled={edited}
            />,
        ]
    }, [
        award,
        reject,
        id,
    ])

    return (
        <StatusHandler
            isError={isError}
            error={error}
            isFetching={isFetching}
            isSuccess={isDataReady}
        >
            <AwardRejectLaneForm
                data={data}
                disabled={data?.laneStatus === LaneStatus.AWARDED}
                onSuccess={onSuccess}
                onCancel={invalidate}
                getCustomButtons={getCustomButtons}
                requestParam={requests.updateAwardRejectLaneData({
                    id,
                })}
            />

        </StatusHandler>
    )
}

export default AwardRejectLane
