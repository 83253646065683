/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import {
    dateToStr,
    dateTimeMask,
} from 'app/utils/date'

import DateTimeSelect from './DateTimeSelect'

const propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
}

const defaultProps = {
    name: '',
    onChange: noop,
}

const DateTimeSelectContainer = (props) => {
    const {
        name,
        onChange,
    } = props

    const handleOnChange = useCallback((datetime) => {
        const newDate = dateToStr(datetime, dateTimeMask)

        onChange(newDate, {
            name,
            value: newDate,
        })
    }, [
        name,
        onChange,
    ])

    return (
        <DateTimeSelect
            {...props}
            onChange={handleOnChange}
        />
    )
}

DateTimeSelectContainer.propTypes = propTypes
DateTimeSelectContainer.defaultProps = defaultProps

export default DateTimeSelectContainer
