import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ModificationType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import request from '../positioningSetup.request'
import {
    PositioningSetupType,
} from '../PositioningSetup.types'

type RequestParams = {
    modificationType: ModificationType,
    orderNumber: string,
    data: PositioningSetupType,
}

const useUpdatePositioningSetup = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessUpdate,
        onError,
    } = useHandleStatusRequest({
        id: 'ModifyOrderStep',
        onSuccess,
    })

    return useMutation(({
        modificationType,
        orderNumber,
        data,
    }: RequestParams) => {
        return request.Update.mutationFn({
            modificationType,
            orderNumber,
            data,
            token,
        })
    }, {
        onSuccess: onSuccessUpdate,
        onError,
    })
}

export default useUpdatePositioningSetup
