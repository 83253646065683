import authRoles from 'app/authRoles'

export type rolesTypes = keyof typeof authRoles

const hasPermission = (roles: rolesTypes[], role: string[]) => {
    let hasPerm = false

    for (let i = 0; i < roles.length; i += 1) {
        if (role.includes(authRoles[roles[i]][0])) {
            hasPerm = true
            break
        }
    }

    return hasPerm
}

export default hasPermission
