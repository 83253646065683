import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'inline-flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
            paddingTop: theme.spacing(1),
        },
        icon: {
            height: '0.6em',
            width: '0.7em',
        },
    }
})

export default useStyles
