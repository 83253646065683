import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {
    FormHelperText,
    TextField,
} from '@mui/material'
import {
    MobileDatePicker,
} from '@mui/x-date-pickers/MobileDatePicker'
import {
    useTheme,
} from '@mui/material/styles'
import clsx from 'clsx'

import Label from 'app/shared-components/Label'

import {
    dateToDateStr,
    strToDate,
} from 'app/utils/date'

import useStyles from './DateSelect.styles'

const propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    hasError: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    className: PropTypes.string,
}

const defaultProps = {
    title: '',
    name: '',
    hasError: '',
    disabled: false,
    required: false,
    value: null,
    className: '',
}

const DateSelect = (props) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)
    const {
        title,
        name,
        hasError,
        onChange,
        value,
        disabled,
        required,
        className,
    } = props

    const handleOnChange = useCallback((date) => {
        const newValue = dateToDateStr(date)

        onChange(newValue, {
            target: {
                name,
                value: newValue,
            },
        })
    }, [
        name,
        onChange,
    ])

    return (
        <div
            className={className}
            data-testid={`dateSelect-${name}`}
        >
            <Label
                title={title}
                hasError={hasError}
            />
            <div
                className={clsx(
                    classes.dateField,
                    {
                        [classes.disabled]: disabled,
                        [classes.isRequired]: required,
                    },
                )}
            >
                {required && <div className={classes.requiredMark} />}
                <MobileDatePicker
                    className={classes.input}
                    value={strToDate(value)}
                    inputFormat="DD.MM.YYYY"
                    onChange={handleOnChange}
                    animateYearScrolling
                    error={Boolean(hasError)}
                    renderInput={(params) => {
                        return (
                            <TextField
                                fullWidth
                                variant="standard"
                                {...params} // eslint-disable-line react/jsx-props-no-spreading, max-len
                            />
                        )
                    }}
                    disabled={disabled}
                    fullWidth
                    disableOpenPicker
                />
                <FormHelperText
                    error={Boolean(hasError)}
                    className={classes.errorMessage}
                >
                    {hasError}
                </FormHelperText>
            </div>
        </div>
    )
}

DateSelect.propTypes = propTypes
DateSelect.defaultProps = defaultProps

export default DateSelect
