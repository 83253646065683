import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import config from '../BookedContainers.config'
import {
    RequestParams,
} from '../bookedContainers.types'

const useRebookContainers = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'SelectedContainer',
    })

    return useMutation(({
        orderNumber,
        orderVersion,
        selectedContainers,
    }: RequestParams) => {
        return config.RebookContainers.mutationFn({
            token,
            orderNumber,
            orderVersion,
            selectedContainers,
        })
    }, {
        onSuccess,
        onError,
    })
}

export default useRebookContainers
