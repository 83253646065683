import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import requests from 'app/Apps/Forecast/Forecast.requests'
import {
    ForecastType,
} from '../../Forecast.types'

export default ({
    onSuccess,
}: { onSuccess: (...args: any[]) => Promise<any> }) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdateForecast,
        onError,
    } = useHandleStatusRequest({
        title: 'Forecasts were updated',
        id: 'ForecastsBulkUpdate',
        onSuccess,
    })

    const {
        mutate: updateForecasts,
    } = useMutation((forecasts: ForecastType[]) => {
        return simpleApi({
            ...requests.bulkUpdate({
                data: forecasts,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessUpdateForecast,
    })

    return useCallback((forecasts: ForecastType[]) => {
        if (!forecasts.length) {
            onError({
                response: {
                    data: ['Nothing was changed'],
                    statusCode: 500,
                },
            })
            return
        }

        updateForecasts(forecasts)
    }, [
        updateForecasts,
        onError,
    ])
}
