import {
    useCallback,
} from 'react'
import {
    ExtendedCellTypes,
} from '../SkyNetSpreadSheet.types'

export default () => {
    return useCallback(async ({
        newData, cell, newValue, currentRowId,
    }: {
        newData: Record<string, any>[],
        cell: ExtendedCellTypes,
        newValue: any,
        currentRowId: number
    }): Promise<Record<string, any>[]> => {
        const result = [...newData]

        if ('onChangeAutopopulate' in cell && cell.onChangeAutopopulate) {
            const item = await cell.onChangeAutopopulate(newValue)

            Object.entries(item || {}).forEach(([
                key,
                elValue,
            ]) => {
                result[currentRowId][key] = result[currentRowId][key] || elValue
            })
        }

        if ('onChangeOverride' in cell && cell.onChangeOverride) {
            const item = await cell.onChangeOverride(newValue)

            Object.entries(item || {}).forEach(([
                key,
                elValue,
            ]) => {
                result[currentRowId][key] = elValue
            })
        }

        return result
    }, [])
}
