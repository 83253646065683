import React from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'

import LoggerOverview from './LoggerOverview'
import LoggerCreate from './LoggerCreate'
import LoggerUpdate from './LoggerUpdate'
import Documents from './Documents'
import tableConfig from './Logger.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const overviewTab: SidebarTab = {
    url: 'overview',
    label: 'Overview',
    Component: LoggerOverview,
}

const DocumentsTab: SidebarTab = {
    url: 'documents',
    label: 'Documents',
    Component: Documents,
}

const Loggers = () => {
    const loggerCreate = useHasPermission(['logger_create'])
    const loggerUpdate = useHasPermission(['logger_update'])
    const canLoggerRead = useHasPermission(['logger_read'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: {
                url: 'data-update',
                label: 'Data Update',
                Component: LoggerUpdate,
            },
            permission: loggerUpdate,
        },
        {
            tab: DocumentsTab,
            permission: canLoggerRead,
        },
    ])

    return (
        <SkyNetTable
            name="LoggerView"
            moduleName="Logger"
            createForm={loggerCreate ? LoggerCreate : undefined}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            uniqField="loggerNumber"
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default Loggers
