import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import loggerPairingRequests from '../LoggerExchange.requests'

export default (onSuccessAction) => {
    const token = useJWTToken()

    const {
        onError: onFailedRequest,
        onSuccess: onSuccessRequest,
    } = useHandleStatusRequest({
        id: 'LocationDuplicationCheck',
    })

    const onSuccess = useCallback((data) => {
        onSuccessRequest(data)
        onSuccessAction(data)
    }, [
        onSuccessRequest,
        onSuccessAction,
    ])

    const onError = useCallback((err) => {
        onFailedRequest(err)
    }, [onFailedRequest])

    return useMutation(({
        id, serialNumber,
    }: {id: number, serialNumber: string}) => {
        return simpleApi({
            ...loggerPairingRequests.deleteLoggerPairing({
                serialNumber, id,
            }),
            token,
        })
    }, {
        onSuccess,
        onError,
    })
}
