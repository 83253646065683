import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from 'react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    LocationAreas,
} from '../LocationAreas.types'

import requests from '../LocationAreas.requests'

export default (id) => {
    const queryClient = useQueryClient()
    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getLocationArea])
    }, [queryClient])
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<LocationAreas>({
        key: RequestKeys.getLocationArea,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.getLocationArea),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}
