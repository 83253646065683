import React, {
    useState,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RegionalGroup,
} from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.types'
import request from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.request'

import getFields from './regionalGroupCreateForm.fields'
import useStyles from './RegionalGroupCreateForm.style'

const RegionalGroupCreateForm = ({
    onSuccess,
    accountId,
}: {
    onSuccess: () => void,
    accountId: number,
}) => {
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState<Partial<RegionalGroup>>({
        account: {
            id: accountId,
        },
    })

    const getAllowedValues = useEnumValues()

    const fields = getFields({
        getAllowedValues,
    })

    const onSuccessCreate = () => {
        onSuccess()
        setValue({
            account: {
                id: accountId,
            },
        })
    }

    return (
        <FormWithControls
            onSuccess={onSuccessCreate}
            value={value}
            setExternalValue={setValue}
            name={request.domainName}
            fields={fields}
            requestParams={request.CreateRegionalGroup()}
            className={classes.root}
        />
    )
}

export default RegionalGroupCreateForm
