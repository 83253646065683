/* eslint-disable import/prefer-default-export */
import omit from 'lodash/omit'

export const idRemover = (array) => {
    return array.map((currentItem) => {
        return {
            ...omit(currentItem, 'id'),
        }
    })
}
