import React, {
    useMemo,
} from 'react'

import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    DeliveryServiceType, OrderTypes,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import validateForm from 'app/utils/validateForm'
import useOnSuccessCreateOrder from 'app/Apps/OrderManagement/Orders/hooks/useOnSuccessCreateOrder'

import useCreateCustomerTransportOrder from './hooks/useCreateCustomerTransportOrder'
import getFields from './finalizeBookingStep.fields'
import useStyles from './FinalizeBookingStep.style'
import {
    BookingDetailsStepType,
    FinalizeBookingStepType,
} from '../../createCustomerTransportForm.types'

const FinalizeBookingStep = ({
    setActiveStep,
    onChange,
    value,
    index,
    laneData,
    bookingDetails,
    onReset,
    setIsEdited,
}: {
    value: FinalizeBookingStepType,
    onChange: (val: Partial<FinalizeBookingStepType>) => void,
    setActiveStep: (val: number) => void,
    onReset: () => void,
    index: number,
    laneData: Lane,
    bookingDetails: BookingDetailsStepType,
    setIsEdited: (isEdited: boolean) => void
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        originScSelection,
        originScBackup,
        fwdPosBackup,
    } = value

    const onSuccessCreate = useOnSuccessCreateOrder(OrderTypes.CUSTOMER_TRANSPORT)

    const {
        isLoading,
        mutate,
    } = useCreateCustomerTransportOrder(onSuccessCreate)

    const isDeliveryRoad = laneData.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD

    const fields = useFieldsWithClassName(getFields({
        originScSelection,
        isDeliveryRoad,
        laneData,
        getAllowedValues,
        originScBackup,
        fwdPosBackup,
    }), classes)

    const onClickComplete = () => {
        setIsEdited(false)
        mutate({
            ...bookingDetails,
            lane: {
                id: laneData.id,
            },
            freightForwarder: value.freightForwarder,
            orderEntered: value.orderEntered,
            commentForBillingOps: value.commentForBillingOps,
            requiredCargoComment: value.requiredCargoComment,
            estimatedPickup: value.estimatedPickup,
            transportModeRoad: value.transportModeRoad,
            transportModeForwarder: value.transportModeForwarder,
            numberOfTrucks: value.numberOfTrucks,
            numberOfVans: value.numberOfVans,
            originScSelection: value.originScSelection,
            fwdPosSelection: value.fwdPosSelection,
            personInCharge: value.personInCharge,
            mawbNumber: value.mawbNumber,
            hawbNumber: value.hawbNumber,
        })
    }

    const onClickBack = () => {
        setActiveStep(index - 1)
        onReset()
    }

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value,
        })
    }, [
        fields,
        value,
    ])

    return (
        <div>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                name="OrderByNumberView"
            />
            <div className={classes.actions}>
                <Button
                    label="Complete"
                    onClick={onClickComplete}
                    className={classes.button}
                    disabled={!isFormValid}
                    saving={isLoading}
                    data-testid="btn-complete"
                />
                <Button
                    label="Back"
                    secondary
                    onClick={onClickBack}
                    className={classes.button}
                    data-testid="btn-back"
                />
            </div>
        </div>
    )
}

export default FinalizeBookingStep
