import React, {
    useCallback,
    useState,
} from 'react'

import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import noop from 'lodash/noop'

import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useCollapsablePanel from 'app/shared-components/CollapsibleTable/hooks/useCollapsablePanel'

import Card from 'app/shared-components/Card'

import useStyles from './StorageTable.style'
import columns from './Storage.columns'
import {
    Storage,
} from './Storage.types'
import StorageForm from './StorageForm'

type Props = {
    data?: Storage[],
    onChange?: (...args: any[]) => void,
}
const defaultProps: Partial<Props> = {
    data: [],
    onChange: noop,
}

const domainName = 'Storages'

const StorageTable = ({
    data,
    onChange,
}: Props) => {
    const {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    } = useCollapsablePanel('storages')

    const {
        classes,
    } = useStyles()
    const loadData = useCallback(() => {
        return Promise.resolve({
            data,
        })
    }, [data])

    const handleUpdate = useCallback((update: Storage) => {
        onChange(data.map((currStorage) => {
            if (currStorage.id === update.id) {
                return update
            }
            return currStorage
        }))
    }, [
        data,
        onChange,
    ])

    const [
        tempStorage,
        setTempStorage,
    ] = useState({} as Storage)

    const handleModifyStorage = useCallback(() => {
        onChange([
            ...data,
            tempStorage,
        ])
        setTempStorage({})
        closeCreateForm()
    }, [
        data,
        tempStorage,
        onChange,
        setTempStorage,
        closeCreateForm,
    ])

    const handleRemoveStorage = useCallback((storageId) => {
        onChange(data.filter((storages) => {
            return storages.id !== storageId
        }))
    }, [
        data,
        onChange,
    ])

    const onCreateChange = useCallback((change) => {
        setTempStorage({
            ...tempStorage,
            ...change,
        })
    }, [tempStorage])

    const getUpdateForm = useCallback(({
        rowProps,
    }) => {
        return (
            <div className={classes.formContainer}>
                <StorageForm
                    value={rowProps}
                    onChange={handleUpdate}
                />
                <ButtonContainer>
                    <Button
                        label="Delete"
                        onClick={() => { return handleRemoveStorage(rowProps?.id) }}
                        secondary
                    />
                </ButtonContainer>

            </div>
        )
    }, [
        handleUpdate,
        handleRemoveStorage,
        classes.formContainer,
    ])

    return (
        <div
            className={classes.root}
        >
            <Card
                fullHeight
                contentClass={classes.cardContent}
            >
                <div className={classes.header}>
                    <Typography variant="h3">
                        Storages
                    </Typography>
                    <div className={classes.icon}>
                        {createFormOpened
                            ? (
                                <RemoveIcon
                                    className={classes.icon}
                                    onMouseDown={closeCreateForm}
                                />
                            )
                            : (
                                <AddIcon
                                    className={classes.icon}
                                    onMouseDown={openCreateForm}
                                />
                            )}
                    </div>
                </div>
                <Collapse
                    in={createFormOpened}
                    className={classes.formContainer}
                >
                    <StorageForm
                        value={tempStorage}
                        onChange={onCreateChange}
                    />
                    <ButtonContainer>
                        <Button
                            label="Add"
                            onClick={handleModifyStorage}
                            disabled={!(tempStorage.storageType && tempStorage.preconTimeInHours)}
                        />
                        <Button
                            secondary
                            label="Cancel"
                            onClick={closeCreateForm}
                        />

                    </ButtonContainer>
                </Collapse>
                <RichTable
                    configName={domainName}
                    name={domainName}
                    columns={columns}
                    load={loadData}
                    uniqField="id"
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                        headerWrapper: classes.tableHeaderWrapper,
                    }}
                    detailPanel={getUpdateForm}
                />
            </Card>
        </div>
    )
}

StorageTable.defaultProps = defaultProps

export default StorageTable
