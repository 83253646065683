const parsePrintQuotesSelectedRows = (selectedRows) => {
    return selectedRows.map((row) => {
        if (row.includes('_')) {
            return row.split('_')[0]
        }
        return row
    })
}

export default parsePrintQuotesSelectedRows
