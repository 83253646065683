import React, {
    useMemo,
    useCallback,
    useState,
} from 'react'
import pick from 'lodash/pick'

import FormWithControls from 'app/shared-components/FormWithControls'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    LaneStatus,
} from 'app/types/enums'

import useStyles from './LaneImplementation.styles'
import getVisibleFields from './utils/getVisibleFields'
import getFields from './fields/laneImplementation.fields'
import {
    CollServiceType,
    Lane,
} from '../lanes.types'

type Props = {
    data: Lane,
    name: string,
    onSuccess: () => void,
}

const LaneImplementation = ({
    data,
    name,
    onSuccess,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const [originalStatus] = useState<LaneStatus>(data.laneStatus)
    const [
        value,
        setValue,
    ] = useState<Lane>(data)

    const {
        reqCustomsCoord,
        deliveryServiceType,
        collectionServiceType,
        id,
    } = value

    const fields = useMemo(() => {
        return getFields({
            deliveryServiceType,
            originalStatus,
            reqCustomsCoord,
            isDropOffSkyCell: collectionServiceType === CollServiceType.DROPOFF_SKYCELL,
            getAllowedValues,
        })
    }, [
        getAllowedValues,
        deliveryServiceType,
        originalStatus,
        reqCustomsCoord,
        collectionServiceType,
    ])

    const fieldsWithClassNames = useFieldsWithClassName(fields, classes)

    const modifyDataBeforeSend = useCallback(() => {
        const visibleFields = getVisibleFields(fields)

        return pick(value, visibleFields)
    }, [
        fields,
        value,
    ])

    return (
        <FormWithControls
            name={name}
            requestParams={orderManagementRequest.Lane.Update({
                id,
            })}
            value={value}
            setExternalValue={setValue}
            fields={fieldsWithClassNames}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            onSuccess={onSuccess}
            modifyDataBeforeSend={modifyDataBeforeSend}
            exists
        />
    )
}

export default LaneImplementation
