import {
    RequestConfig,
} from 'app/types/request.types'

type OpenBookingsRequests = {
    filterAll: () => RequestConfig
}

const openBookingRequests: OpenBookingsRequests = {
    filterAll: () => {
        return {
            url: 'order/filter-request/open-bookings',
        }
    },
}

export default openBookingRequests
