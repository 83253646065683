import React, {
    useCallback,
    useMemo,
} from 'react'
import moment from 'moment'
import {
    dateMask,
} from 'app/utils/date'
import {
    monthYearShortMask,
} from 'app/utils/date/monthYear'
import StatusHandler from 'app/shared-components/StatusHandler'

import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import dateTimeMask from 'app/utils/date/dateTimeMask'
import useGetForecast from './hooks/useGetForecast'
import ForecastSpreadsheet from '../ForecastSpreadsheet'
import useBulkUpdateForecast from './hooks/useBulkUpdateForecast'
import {
    countFurtherMonths, countPreviousMonths, parseForecastData,
    createDefaultValues, dataToSpreadsheetColumnsMap,
} from '../Forecast.utils'
import {
    ForecastDataSpreadsheetColumnsConfig,
} from '../Forecast.types'

const ForecastSubmission = () => {
    const {
        isDataReady,
        data,
        isError,
        isFetching,
        error,
        refetch,
    } = useGetForecast()

    const forecastFields = useMemo(() => {
        return countFurtherMonths()
    }, [])

    const actualDataFields = useMemo(() => {
        return countPreviousMonths()
    }, [])

    const spreadsheetData = useMemo(() => {
        return (data || []).reduce((acc, item) => {
            return [
                ...acc,
                {
                    ...item,
                    changedOn: moment(item.changedOn, dateTimeMask).format(dateMask),
                    ...(item.forecasts || []).reduce(
                        parseForecastData(), createDefaultValues(forecastFields),
                    ),
                    ...(item.actuallyShippedContainers || []).reduce(
                        parseForecastData(), createDefaultValues(actualDataFields),
                    ),
                },
            ]
        }, [])
    }, [
        actualDataFields,
        data,
        forecastFields,
    ])

    const save = useBulkUpdateForecast({
        onSuccess: refetch,
    })

    const onSave = useCallback((items) => {
        save(items.map((item) => {
            const {
                id, isConfirmed,
            } = item

            return {
                id,
                isConfirmed,
                forecasts: Array.from(forecastFields).map(([key]) => {
                    const momentDate = moment(key, monthYearShortMask)

                    return {
                        month: momentDate.month() + 1,
                        year: momentDate.year(),
                        units: item[key],
                    }
                }),
            }
        }, []))
    }, [
        save,
        forecastFields,
    ])

    const getFieldsMapper = useCallback(dataToSpreadsheetColumnsMap, [])

    const tmpFields: ForecastDataSpreadsheetColumnsConfig = useMemo(() => {
        return [
            ...Array.from(forecastFields).sort().reverse().map(getFieldsMapper({
                disabled: false,
            })),
            ...Array.from(actualDataFields).sort().reverse().map(getFieldsMapper({
                disabled: true,
            })),
        ]
    }, [
        actualDataFields,
        forecastFields,
        getFieldsMapper,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={isFetching}
            error={error}
            isSuccess={isDataReady}
        >
            <DataContentWrapper isEmpty={!data?.length}>
                <ForecastSpreadsheet
                    onSave={onSave}
                    onCancel={refetch}
                    data={spreadsheetData}
                    tmpFields={tmpFields}
                />
            </DataContentWrapper>
        </StatusHandler>

    )
}

export default ForecastSubmission
