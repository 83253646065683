import {
    useRef,
    useMemo,
    useState,
} from 'react'
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom'

import DETAILS_PAGE from 'app/Apps/DomainObject/detailsPage'
import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'

import {
    DomainObjectContextValue,
} from 'app/Apps/DomainObject/DomainObjectContext'

type Params = {
    defaultTab: string,
    name: string,
    uniqField: string,
    domainRequestKey: RequestKeys,
}

const useCreateDomainObjectContextValue = ({
    defaultTab,
    name,
    uniqField,
    domainRequestKey,
}: Params) => {
    const history = useHistory()
    const richtableRef = useRef(null)

    const [
        selectedTab,
        setSelectedTab,
    ] = useState<string>(defaultTab)

    const {
        url,
    } = useRouteMatch()

    const contextValue = useMemo((): DomainObjectContextValue => {
        return {
            name,
            updateTableElement: (updatedObj) => {
                richtableRef.current.update(updatedObj)
            },
            addTableElement: (updatedObj) => {
                richtableRef.current.add(updatedObj)
            },
            uniqField,
            selectedTab,
            setSelectedTab,
            selectDomainObject: (id) => { // TODO: rename to 'selectEntityDetailsPage'
                history.push(`${url}/all/${DETAILS_PAGE}/${id}`)
            },
            requestKey: domainRequestKey,
        }
    }, [
        url,
        name,
        history,
        uniqField,
        selectedTab,
        domainRequestKey,
    ])

    return {
        contextValue,
        richtableRef,
    }
}

export default useCreateDomainObjectContextValue
