import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import {
    ResponseMutation,
} from 'app/types/request.types'
import DTOSpec from 'app/services/dtoSpec'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import request from 'app/Apps/ContactManagement/contactManagement.request'

type Params = {
    onSuccess: () => void,
}

const DOMAIN_NAME = DTOSpec.BillingCompany.Info.name

const useArchiveBillingCompany = ({
    onSuccess,
}: Params): ResponseMutation<{id: number}> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessArchive,
        onError,
    } = useHandleStatusRequest({
        id: DOMAIN_NAME,
        title: 'Billing Company Archived',
        onSuccess,
    })

    return useMutation(({
        id,
    }: {id: number}) => {
        return request.Archive.mutationFn({
            id,
            token,
        })
    }, {
        onSuccess: onSuccessArchive,
        onError,
    })
}

export default useArchiveBillingCompany
