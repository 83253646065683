import {
    makeStyles,
} from 'app/tss'

const general = 'general'
const contacts = 'contacts'
const generalWithComment = 'generalWithComment'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
            paddingTop: theme.spacing(2),
        },
        cardContent: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${general} ${general} ${general}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        general: {
            gridArea: general,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapperWithComment: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${generalWithComment} ${generalWithComment} ${generalWithComment} ${generalWithComment}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        generalWithComment: {
            gridArea: generalWithComment,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        container: {
            display: 'flex',
        },
        button: {
            flex: 1,
            alignSelf: 'center',
            margin: `0 ${theme.spacing(4)}`,
        },
        fields: {
            flex: 6,
        },
        checkBoxOnTop: {
            padding: `${theme.spacing(1)} 0 0 0`,
        },
        contactsGridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1),
            gridTemplateAreas: `
            "${contacts} ${contacts} ${contacts} ${contacts}"`,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        contacts: {
            gridArea: contacts,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    }
})

export default useStyles
