import React, {
    useState,
    useCallback,
    useEffect,
} from 'react'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import simpleApi from 'app/services/simpleApi'

import AttachmentType from './Attachment.types'
import Attachment from './Attachment'

type Props = {
    title?: string,
    name?: string,
    value?: AttachmentType[] | number[],
    dataType?: string,
    onChange: (...args: any) => void,
    className?: string,
    upload?: boolean,
    disabled?: boolean,
    required?: boolean,
}

// DON'T USE THIS COMPONENT as componentName: 'Attachment',
// USE componentName: 'AttachmentsObjectSelector' instead

// TODO-JM: remove dataType property when we don't use Attachment directly as component
const defaultProps: Partial<Props> = {
    title: 'Attachments',
    name: '',
    value: [],
    dataType: '',
    className: '',
    upload: true,
    disabled: false,
    required: false,
}

const AttachmentContainer = (props: Props) => {
    const token = useJWTToken()
    const {
        title,
        name,
        value,
        dataType,
        onChange,
        className,
        upload,
        disabled,
        required,
    } = props

    const [
        state,
        setState,
    ] = useState({
        files: [],
        editable: true,
    })

    const getFiles = useCallback((): AttachmentType => {
        if (!value || value.length === 0) {
            setState({
                files: [],
                editable: true,
            })
            return
        }
        simpleApi({
            url: 'attachment/filter-request/all',
            token,
            method: 'POST',
            data: {
                includeFilters: {
                    id: value,
                },
            },
        }).then((attachments) => {
            setState({
                files: attachments?.items,
                editable: true,
            })
        })
    }, [
        token,
        value,
    ])

    const handleOnChange = useCallback((newDocumentId) => {
        const fileIds = [
            ...value,
            ...newDocumentId,
        ]

        onChange(fileIds, {
            target: {
                name,
                value: fileIds,
            },
        })
    }, [
        name,
        onChange,
        value,
    ])

    const onDelete = useCallback((fileToDeleteId) => {
        const files = state.files.filter(({
            id,
        }) => {
            return id !== fileToDeleteId
        })
        const ids = files.map(({
            id,
        }) => {
            return id
        })
        const agregation = dataType === '[]&Attachment'
            ? {
                [name.replace('Ids', '')]: files,
            } : {}

        onChange(ids, {
            target: name,
            value: ids,
        }, agregation)
    }, [
        name,
        onChange,
        state.files,
        dataType,
    ])

    useEffect(() => {
        if (dataType === '[]Attachment') {
            setState({
                files: value,
                editable: false,
            })
            return
        }
        getFiles()
    }, [
        dataType,
        getFiles,
        value,
    ])
    return (
        <Attachment
            title={title}
            onChange={handleOnChange}
            value={state.files}
            editable={state.editable && !disabled}
            onDelete={onDelete}
            name={name}
            className={className}
            upload={upload}
            disabled={disabled}
            required={required}
        />
    )
}

AttachmentContainer.defaultProps = defaultProps

export default AttachmentContainer
