import getUniqueId from 'app/utils/getUniqueId'

const bolAwbColumns = [
    {
        id: getUniqueId(),
        fieldName: 'airportSeaport',
        label: 'Airport / Seaport',
    },
    {
        id: getUniqueId(),
        fieldName: 'mAddress',
        label: 'Master Address',
    },
    {
        id: getUniqueId(),
        fieldName: 'mNotifyParty',
        label: 'Master Notify Party',
    },
    {
        id: getUniqueId(),
        fieldName: 'hAddress',
        label: 'House Address',
    },
    {
        id: getUniqueId(),
        fieldName: 'hNotifyParty',
        label: 'Notify Party',
    },
    {
        id: getUniqueId(),
        fieldName: 'instructions',
        label: 'Instructions',
    },
]

export default bolAwbColumns
