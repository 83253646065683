import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import {
    ResponseMutation,
} from 'app/types/request.types'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import simpleApi from 'app/services/simpleApi'

const useSimpleApiRequest = ({
    onSuccess,
    onError,
    name,
    requestParams,
    title,
}: {
    onSuccess: (resp) => void,
    onError: (err) => void,
    name: string,
    requestParams: {url: string, method: string},
    title?: string,
}): ResponseMutation<Record<string, any>> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessHandler,
        onError: onErrorHandler,
    } = useHandleStatusRequest({
        id: name,
        title,
        onSuccess,
        onError,
    })

    return useMutation((data) => {
        return simpleApi({
            data,
            token,
            ...requestParams,
        })
    }, {
        onSuccess: onSuccessHandler,
        onError: onErrorHandler,
    })
}

export default useSimpleApiRequest
