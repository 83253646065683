import React, {
    useCallback, useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import useHistoryNavigation from 'app/hooks/useHistoryNavigation'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import Button from 'app/shared-components/Button'
import LaneForm from '../LaneForm'
import LaneRequests from '../Lane.requests'

import useGetLane from '../hooks/useGetLane'
import useLaneGroupContext from '../../hooks/useLaneGroupContext'

const LaneUpdate = ({
    id,
    refetch,
}: { id: number, refetch: (...args: any[]) => void }) => {
    const {
        laneGroupId,
    } = useLaneGroupContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLane(id)

    const {
        updatePathWithParams,
    } = useHistoryNavigation()

    const onSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        refetch,
        invalidate,
    ])

    const archive = useArchive({
        requestParamFn: data?.archived ? LaneRequests.restore
            : LaneRequests.archive,
        notificationId: 'LaneUpdateDto',
        title: 'Lane',
        data,
        redirectionAfterSuccess: `/apps/pricing/lane-groups/all/edit/${laneGroupId}/lanes`,
        onSuccess,
    })

    const customButtons = useMemo(() => {
        return [
            <Button
                label="Copy"
                key="copy"
                onClick={() => {
                    updatePathWithParams({
                        action: 'copy',
                        id: data?.id,
                    })
                }}
            />,
            <ArchiveButton
                onClick={archive.handleArchiveRestore}
                archived={data?.archived}
            />,
        ]
    }, [
        updatePathWithParams,
        data,
        archive.handleArchiveRestore,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <LaneForm
                    data={data}
                    onSuccess={onSuccess}
                    onCancel={invalidate}
                    requestParam={LaneRequests.update.request({
                        laneId: id,
                    })}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Lane"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>

        </StatusHandler>
    )
}

export default LaneUpdate
