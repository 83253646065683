import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderStatuses,
} from 'app/types/enums'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const toBeConfirmedConfig: SkyNetTableConfig<DailyOpsFields> = {
    name: 'DailyOps.ToBeConfirmed',
    tabs: [{
        name: 'To Be Confirmed',
        url: 'all',
    }],
    fields: [
        {
            name: 'orderNumber',
            filterField: 'orderNumber',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'orderStatus',
            filterField: 'orderStatus',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: OrderStatuses,
        },
        {
            name: 'customerLocationName',
            filterField: 'customerLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'accountCompanyCompanyName',
            filterField: 'accountCompanyCompanyName',
            labelKey: 'AccountQuotes.accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'forwarderLocationName',
            filterField: 'forwarderLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'originLocationLocationName',
            filterField: 'originLocationLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'handoverPointLocationName',
            filterField: 'handoverPointLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'requestedHandover',
            componentName: 'ContainerSelectionUntil',
            headerProps: {
                children: 'Requested Handover In',
            },
        },
    ],
}

export default toBeConfirmedConfig
