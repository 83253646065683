import React from 'react'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'

type Props = {
    configName?: string,
    mapCellProps: string,
    value?: string,
}

const defaultProps = {
    value: '',
    configName: null,
}

const EnumValue = ({
    configName,
    mapCellProps,
    value,
}: Props) => {
    const fieldConfig = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })

    const allowedValues = useEnumValues()(fieldConfig?.allowedValuesEnum)

    if (!fieldConfig?.allowedValuesEnum) {
        // eslint-disable-next-line no-console
        console.error(`No allowed values config found for "${mapCellProps}" field in "${configName}"`)
    }

    return (
        <div>{allowedValues[value] || value}</div>
    )
}

EnumValue.defaultProps = defaultProps

export default EnumValue
