import {
    makeStyles,
} from 'app/tss'

const general = 'general'
const other = 'other'
const handover = 'handover'
const collection = 'collection'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${general} ${other}"
                "${handover} ${collection}"
                `,
            gridTemplateColumns: 'repeat(2, 2fr)',
            padding: `${theme.spacing(1)}`,
        },
        general: {
            gridArea: general,
            height: 'fit-content',
        },
        handover: {
            gridArea: handover,
        },
        collection: {
            gridArea: collection,
        },
    }
})

export default useStyles
