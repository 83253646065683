import React, {
    useState,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    RegionalGroup,
} from '../regionalGroups.types'
import request from '../regionalGroups.request'
import useGetLinkedOpportunity from '../hooks/useGetLinkedOpportunity'
import getFields from './regionalGroupForm.fields'

const defaultProps = {
    data: {},
    disabled: false,
    onSuccess: null,
}

const RegionalGroupForm = ({
    data,
    onSuccess,
    disabled,
}: {
    data?: RegionalGroup,
    onSuccess?: () => void,
    disabled?: boolean,
}) => {
    const [
        value,
        setValue,
    ] = useState<Partial<RegionalGroup>>({
        regionalGroupNumber: data.regionalGroupNumber,
        tempRange: data.tempRange,
        account: data.account,
        annualPotentialVolume: data.annualPotentialVolume,
        originRegion: data.originRegion,
        opportunityIndex: data.opportunityIndex,
        destinationRegion: data.destinationRegion,
        supportingDocuments: data.supportingDocuments,
        shortTermForecastOwner: data.shortTermForecastOwner,
        additionalInformation: data.additionalInformation,
    })
    const getAllowedValues = useEnumValues()
    const {
        data: linkedOpportunity,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLinkedOpportunity(data?.id, disabled)

    const fields = getFields({
        getAllowedValues,
        hasLinkedOpportunities: linkedOpportunity?.hasLinkedOpportunities ?? true,
        disabled,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady || disabled}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                onSuccess={onSuccess}
                value={value}
                setExternalValue={setValue}
                name={request.domainName}
                fields={fields}
                disabled={disabled}
                requestParams={request.UpdateRegionalGroup({
                    regionalGroupId: data.id,
                })}
                exists
            />

        </StatusHandler>
    )
}

RegionalGroupForm.defaultProps = defaultProps

export default RegionalGroupForm
