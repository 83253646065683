import {
    useCallback,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from 'react-query'
import CountriesRequest from '../Countries.request'
import {
    Country,
} from '../Countries.types'

const useGetCountry = (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getCountry])
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Country>({
        key: RequestKeys.getCountry,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(CountriesRequest.get),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetCountry
