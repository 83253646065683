import React, {
    useCallback, useMemo,
} from 'react'
import {
    Icon,
} from '@mui/material'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import omit from 'lodash/omit'
import InputBase from 'app/shared-components/InputBase'
import LocationSelector from 'app/shared-components/LocationSelector'

import {
    LocationType,
} from 'app/types/enums'
import useStyles from './Inputs.styles'

type Props = {
    value: {
        id: number,
        airportSeaport?: {
            id: number,
            iataCode: string,
            locationName: string,
        },
        airportSeaportId?: number,
        mAddress?: string,
        mNotifyParty?: string,
        hAddress?: string,
        hNotifyParty?: string,
        instructions?: string,
    },
    onChange: (...args: any[]) => void,
    onDelete: (...args: any[]) => void,
    columns: Record<string, any>[]
}

const Inputs = ({
    value,
    onChange,
    onDelete,
    columns,
} : Props) => {
    const {
        classes,
    } = useStyles()

    const parsedInputs: {
        name: string,
        val: any,
        type: string
    }[] = useMemo(() => {
        return Object.entries(omit(value, 'id')).map(([
            name,
            val,
        ]) => {
            return {
                name,
                val,
                type: ['airportSeaport'].includes(name) ? 'number' : 'text',
            }
        })
    }, [value])

    const handleChange = useCallback((change, {
        target: {
            name,
        },
    }) => {
        onChange({
            ...value,
            [name]: change,
        })
    }, [
        value,
        onChange,
    ])

    const handleOnDelete = useCallback(() => {
        onDelete(value.id)
    }, [
        value,
        onDelete,
    ])

    const onLocationChange = useCallback((locationId) => {
        const newValue = {
            ...value,
            airportSeaport: {
                id: Number(locationId),
            },
        }

        onChange(newValue)
    }, [
        value,
        onChange,
    ])

    return (
        <TableRow>
            {value && columns.map(({
                fieldName,
            }) => {
                const currentInput = parsedInputs.find(({
                    name,
                }) => { return name === fieldName })

                if (fieldName === 'airportSeaport') {
                    return (
                        <LocationSelector
                            key="new"
                            value={currentInput?.val?.id}
                            onChange={onLocationChange}
                            allowedLocationTypes={[
                                LocationType.AIRPORT,
                                LocationType.SEAPORT,
                            ]}
                        />
                    )
                }
                return (
                    <TableCell
                        classes={{
                            root: classes.tableCell,
                        }}
                        key={currentInput?.name}
                    >
                        <InputBase
                            value={currentInput?.val}
                            name={currentInput?.name}
                            onChange={handleChange}
                            type={currentInput?.type}
                            autoComplete="off"
                            data-testid={`inputBase-${currentInput?.name}`}
                        />
                    </TableCell>
                )
            })}
            <TableCell
                classes={{
                    root: classes.tableCell,
                }}
            >
                <Icon
                    className={classes.deleteIcon}
                    onClick={handleOnDelete}
                >
                    delete
                </Icon>
            </TableCell>
        </TableRow>
    )
}

export default Inputs
