/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import {
    DateTimePicker,
} from '@mui/x-date-pickers'

import {
    dateTimeMask,
    strToDate,
} from 'app/utils/date'
import Label from 'app/shared-components/Label'
import TextSingleline from 'app/shared-components/TextSingleline'

import {
    TextField,
} from '@mui/material'
import useStyles from './DateTimeSelect.styles'

const propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    className: PropTypes.string,
}

const defaultProps = {
    title: '',
    name: '',
    onChange: noop,
    disabled: false,
    required: false,
    value: '',
    className: '',
}

const DateTimeSelect = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        title,
        name,
        onChange,
        disabled,
        required,
        value,
        className,
    } = props

    if (disabled) {
        return (
            <div
                className={clsx(classes.calendar, className)}
                data-testid={`dateTime-${name}`}
            >
                <TextSingleline
                    {...props}
                    value={value}
                />
            </div>
        )
    }

    return (
        <div className={clsx(classes.calendar, className)}>
            <Label
                title={title}
            />
            <div
                className={clsx(
                    {
                        [classes.isRequired]: required,
                    },
                )}
            >
                <div className={classes.requiredMark} />
                <DateTimePicker
                    ampm={false}
                    value={strToDate(value, dateTimeMask)}
                    onChange={onChange}
                    format={dateTimeMask}
                    renderInput={(params) => {
                        return (
                            <TextField
                                fullWidth
                                variant="standard"
                                {...params} // eslint-disable-line react/jsx-props-no-spreading, max-len
                            />
                        )
                    }}
                />
            </div>
        </div>
    )
}

DateTimeSelect.propTypes = propTypes
DateTimeSelect.defaultProps = defaultProps

export default DateTimeSelect
