import React, {
    useEffect, useRef,
} from 'react'
import {
    Compatible,
} from '@silevis/reactgrid'
import useOutsideClick from 'app/hooks/useOutsideClick'
import useClickHandler from 'app/hooks/useClickHandler'
import Dropdown from '../../Dropdown'
import {
    SelectCell,
} from './SelectCellTemplate'
import useSkyNetSpreadSheetContext from '../../SkyNetSpreadSheetContext'
import SelectableElement from '../../SelectableElement'
import useStyles from './SelectCellWrapper.styles'
import {
    SpreadsheetCellOption,
} from '../../SkyNetSpreadSheet.types'

const SelectCellWrapper = ({
    cell,
    options,
    onChange,
}: {
    cell: Compatible<SelectCell>,
    onChange: (...args: any[]) => void,
    options: SpreadsheetCellOption[]
}) => {
    const wrapperRef = useRef(null)
    const {
        classes,
    } = useStyles()

    const {
        forceEditQuit,
        setFocusChanging,
        rowHeight,
    } = useSkyNetSpreadSheetContext()

    useEffect(() => {
        setFocusChanging(false)

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setFocusChanging(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useOutsideClick(wrapperRef, forceEditQuit)
    useClickHandler(['Esc'], forceEditQuit)

    return (
        <div
            ref={wrapperRef}
            className={classes.wrapper}
        >
            <SelectableElement
                text={cell.text}
                id={cell.id}
                name={cell.name}
                editMode
                onClick={forceEditQuit}
            />
            <Dropdown
                rowHeight={rowHeight}
                onChange={onChange}
                options={options}
            />
        </div>
    )
}

export default SelectCellWrapper
