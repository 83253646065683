import {
    SkyNetTableConfig, RangeType,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import LoggerType, {
    LoggerTable,
} from './Loggers.types'

type LoggerTypeValue =
    Exclude<LoggerType, LoggerType.SKYCELL | LoggerType.CARTASENSE_M | LoggerType.MR_813>
type LoggerTypeConfig = { name: string, url: string }
type LoggerTypeTab = Record<LoggerTypeValue, LoggerTypeConfig>

const loggerTypeTabMapper: LoggerTypeTab = {
    [LoggerType.SKYCELL_SAVY_BLE]: {
        name: 'SkyCell Savy Logger',
        url: 'skycellsavylogger',
    },
    [LoggerType.CARTASENSE]: {
        name: 'CartaSense Logger',
        url: 'cartasenselogger',
    },
    [LoggerType.MR_810T]: {
        name: 'MR 810 T',
        url: 'mr810t',
    },
    [LoggerType.MR_812P]: {
        name: 'MR 812 P',
        url: 'mr812p',
    },
    [LoggerType.CL_TT_7100]: {
        name: 'CL TT-7100',
        url: 'cltt7100',
    },
    [LoggerType.CL_BUTTERFLY]: {
        name: 'CL Butterfly',
        url: 'clbutterfly',
    },
    [LoggerType.MR_814]: {
        name: 'MR 814',
        url: 'mr814',
    },
    [LoggerType.MR_815]: {
        name: 'MR 815',
        url: 'mr815',
    },
}

const config: SkyNetTableConfig<keyof LoggerTable> = {
    name: 'LoggersTable',
    tabs: [
        {
            name: 'Loggers',
            url: 'all',
        },
        ...Object.entries(loggerTypeTabMapper).map(([
            logger,
            {
                name, url,
            },
        ]: [
            LoggerTypeValue,
            LoggerTypeConfig
        ]) => {
            return {
                name,
                url,
                requestUrl: 'all',
                includeFilters: {
                    loggerType: [logger],
                },
            }
        }),
    ],
    fields: [
        {
            name: 'loggerNumber',
            reduced: true,
        },
        {
            name: 'locationName',
            filterField: 'locationName',
            labelKey: 'Container.location',
            filterType: FilterType.Array,
        },
        {
            name: 'gatewayImeiMac',
            labelKey: 'Gateway.gatewayImeiMac',
            sorting: false,
        },
        {
            name: 'loggerType',
            componentName: 'EnumValue',
            allowedValuesEnum: LoggerType,
            filterField: 'loggerType',
            filterType: FilterType.Array,
        },
        {
            name: 'loggerQrCode',
            width: '370px',
        },
        {
            name: 'containerSerialNumber',
            sorting: false,
        },
        {
            name: 'lastMeasuredOnUtc',
            componentName: 'TemperatureAge',
            rangeType: RangeType.DayRange,
            sorting: false,
        },
        {
            name: 'batteryVoltage',
        },
        {
            name: 'rentingCompany',
            labelKey: 'Logger.rentingCompany',
            sorting: false,
        },
        {
            name: 'loggerAge',
            componentName: 'LoggerAgeFromDate',
            labelKey: 'ContainerView.loggerExchangedOnUtcAmbient',
            mapCellProps: 'createdOn',
            sorting: false,
        },
    ],
}

export default config
