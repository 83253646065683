import {
    useCallback,
} from 'react'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import simpleApi from 'app/services/simpleApi'
import {
    useDispatch,
} from 'react-redux'
import {
    startToSaveForm,
    failedToLoadSaveForm,
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'

import {
    RequestData,
} from '../containerSelection.types'

const NAME = 'containersFreightModification'

const useSaveContainerSelection = (
    value: RequestData,
    orderNumber: string,
    isGeneralFreight: boolean,
    apiPath: string,
    onSuccess: (response: any) => void,
) => {
    const dispatch = useDispatch()
    const token = useJWTToken()
    const save = useCallback((): void => {
        dispatch(
            startToSaveForm({
                id: NAME,
                title: 'We are saving your updates in Order. This might take a big.',
            }),
        )

        simpleApi({
            data: value,
            method: isGeneralFreight ? 'PUT' : 'PATCH',
            url: `order/${orderNumber}/${apiPath}?modificationType=INTERNAL`,
            token,
        }).then((response) => {
            dispatch(
                successedToSaveForm({
                    id: NAME,
                    title: 'We have saved your updates in Order',
                }),
            )
            onSuccess(response)
        }).catch(({
            response: {
                data: dataResponse,
                status: statusCode,
            },
        }) => {
            const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(dataResponse)))

            dispatch(
                failedToLoadSaveForm({
                    id: NAME,
                    errors: text ? JSON.parse(text) : 'There was an unexpected error while saving. Please try again or contact IT if the error persists.',
                    statusCode,
                }),
            )
        })
    }, [
        dispatch,
        value,
        isGeneralFreight,
        orderNumber,
        apiPath,
        token,
        onSuccess,
    ])

    return save
}

export default useSaveContainerSelection
