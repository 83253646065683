import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/AccountManagement/Opportunities/opportunities.request'
import {
    OpportunityTableType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    LeaseType,
} from 'app/types/enums'

export default (accountId, {
    filters,
}: {
    filters?: {
        accountLaneGroupLeaseType?: LeaseType
    }
} = {}) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<{data: OpportunityTableType[]}>({
        key: RequestKeys.getOpportunitiesByAccount,
        params: {},
        defaultValue: {
            data: {
                filters,
            },
            filter: {
                accountId: [accountId],
            },
            sort: {
                field: 'opportunityNumber',
                direction: 'asc',
            },
        },
        config: requests.FilterRequest,
        keepPreviousData: true,
        enabled: Boolean(accountId),
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data: data?.data,
        loadData,
    }
}
