import {
    useCallback, useMemo,
    useState,
} from 'react'
import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    PropsTabs,
    ModificationType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import request from '../../customerTransport.request'

import {
    ModificationData,
    ModificationCustomerOrder,
} from '../dataUpdate.types'

const useModifyOrder = ({
    data,
    onSuccess,
}: PropsTabs): ModificationData => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessModification,
        onError,
    } = useHandleStatusRequest({
        id: 'OrderByNumberView',
        title: 'Order Modified',
        onSuccess,
    })

    const modificationCustomerOrderData = useMemo(() => {
        return {
            requiresManualBillingReviewOps: data?.billing?.requiresManualBillingReviewOps,
            commentForBillingOps: data?.commentForBillingOps,
            personInCharge: data?.personInCharge,
            orderReceived: data?.orderReceived,
            customerReference: data?.customerReference,
            createdBy: data?.createdBy,
            orderNumber: data?.orderNumber,
            temperatureRange: data?.temperatureRange,
            freightForwarder: data?.freightForwarder,
            leaseStartTimestamp: data?.billing?.leaseStartTimestamp,
        }
    }, [data])

    const [defaultValue] = useState<ModificationCustomerOrder>(modificationCustomerOrderData)

    const [
        value,
        setValue,
    ] = useState<ModificationCustomerOrder>(modificationCustomerOrderData)

    const resetData = useCallback((): void => {
        setValue(defaultValue)
    }, [
        defaultValue,
        setValue,
    ])

    const {
        mutate,
        isLoading,
    } = useMutation((modificationType: ModificationType) => {
        return request.ModifyOrder.mutationFn({
            modificationType,
            orderNumber: data?.orderNumber,
            token,
            data: {
                customerReference: value.customerReference,
                orderReceived: value.orderReceived,
                leaseStartTimestamp: value.leaseStartTimestamp,
                requiresManualBillingReviewOps: value.requiresManualBillingReviewOps,
                commentForBillingOps: value.commentForBillingOps,
                freightForwarder: value?.freightForwarder,
            },
        })
    }, {
        onSuccess: onSuccessModification,
        onError,
    })

    return {
        value,
        setValue,
        resetData,
        updateOrder: mutate,
        isSaveDisabled: defaultValue === value,
        isLoading,
    }
}

export default useModifyOrder
