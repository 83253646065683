import React, {
    useCallback, useEffect, useReducer, useState,
} from 'react'
import {
    Skeleton,
} from '@mui/material'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import Button from 'app/shared-components/Button'

import dashboardRequest from '../KamDashboard.requests'
import toBeAssignedLanesConfig from './toBeAssignedLanes.config'
import useStyles from './ToBeAssignedLanes.style'
import {
    UnassignedToOpportunity, UnassignedToOpportunityError,
} from '../KamDashboard.types'
import checkIsMatch from './checkIsMatch'
import SelectOpportunityDialog from './SelectOpportunityDialog'
import reducer from './store/reducer'
import {
    setError,
    setValue,
    setAssignedLanes,
    setDefaultLane,
    setOpen,
} from './store/actions'

const MAX_SELECTED = 10

const ERROR_MSG = {
    maxLength: '10 lanes maximum',
    match: 'Please select lanes with the same account and temperature range',
    maxLengthAndMatch: 'Please select lanes with the same account and temperature range. 10 lanes maximum',
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ToBeAssignedLanes = () => {
    const {
        classes,
    } = useStyles()
    const [
        {
            value,
            error,
            assignedLanes,
            defaultLane,
            open,
        },
        dispatch,
    ]: [
        {
            value: number[],
            error: UnassignedToOpportunityError,
            assignedLanes: UnassignedToOpportunity[],
            open: boolean,
            defaultLane: UnassignedToOpportunity,
        },
        (args: {
            type: string,
            error?: UnassignedToOpportunityError,
            assignedLanes?: UnassignedToOpportunity[],
            defaultLane?: UnassignedToOpportunity,
            newValue?: number[],
            open?: boolean,
        }) => void,
    ] = useReducer(reducer, {
        value: [],
        open: false,
    })

    const [
        isAssigned,
        setIsAssigned,
    ] = useState(false)

    const onSelectRow = (newVal: number[]) => {
        dispatch(setValue(newVal))
    }

    useEffect(() => {
        if (!error && value.length < 2) {
            return
        }

        if (value.length < 2 && error) {
            dispatch(setError(null))

            return
        }

        const isMatch = checkIsMatch(value, assignedLanes)

        if (value.length > MAX_SELECTED && !isMatch) {
            dispatch(setError(UnassignedToOpportunityError.MAX_LENGTH_AND_MATCH))

            return
        }

        if (value.length > MAX_SELECTED) {
            dispatch(setError(UnassignedToOpportunityError.MAX_LENGTH))
            return
        }

        if (!isMatch) {
            dispatch(setError(UnassignedToOpportunityError.MATCH))

            return
        }

        if (error) {
            dispatch(setError(null))
        }
    }, [
        assignedLanes,
        error,
        value,
    ])

    const onSelectOpportunity = () => {
        const selectedLane = assignedLanes.find(({
            id,
        }) => {
            return id === value[0]
        })

        dispatch(setDefaultLane(selectedLane))
        dispatch(setOpen(true))
    }

    const onLoad = useCallback(({
        data,
    }) => {
        dispatch(setAssignedLanes(data))
    }, [])

    const setOpenDialog = (isOpen) => {
        dispatch(setOpen(isOpen))
    }

    const onSuccessAssigned = () => {
        onSelectRow([])
        setIsAssigned(false)
    }

    return (
        <div className={classes.root}>
            {isAssigned ? (
                <div className={classes.skeletonRoot}>
                    {Array(45).fill(
                        <Skeleton
                            animation="wave"
                            height={50}
                            width="10%"
                            style={{
                                marginRight: 16,
                            }}
                            key="skeleton-item"
                        />,
                    )}
                </div>
            ) : (
                <SkyNetTable
                    name="account/lane"
                    customUrl={dashboardRequest.url.toBeAssigned}
                    tableConfig={toBeAssignedLanesConfig}
                    tableControls={tableControls}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                    selectedRows={value}
                    onSelectRow={onSelectRow}
                    onLoad={onLoad}
                    showSelectAll
                />
            )}
            <div className={classes.buttonContainer}>
                <Button
                    disabled={Boolean(error) || !value.length}
                    label="Select Opportunity"
                    onClick={onSelectOpportunity}
                />
                <div
                    data-testid="errorMsg"
                    className={classes.errorMsg}
                >
                    {ERROR_MSG[error]}
                </div>
            </div>
            <SelectOpportunityDialog
                open={open}
                setOpen={setOpenDialog}
                onSuccess={onSuccessAssigned}
                accountId={defaultLane?.accountId}
                temperatureRange={defaultLane?.temperatureRange}
                selectedLanes={value}
                setIsAssigned={setIsAssigned}
            />
        </div>
    )
}

export default ToBeAssignedLanes
