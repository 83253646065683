import {
    UnassignedToOpportunity,
} from '../KamDashboard.types'

const checkIsMatch = (value: number[], assignedLanes: UnassignedToOpportunity[]): boolean => {
    const selectedLanes = assignedLanes.filter((lane) => {
        return value.some((id) => {
            return id === lane.id
        })
    })
    const [defaultLane] = selectedLanes

    return selectedLanes.every(({
        temperatureRange,
        accountCompanyCompanyName,
    }) => {
        return temperatureRange === defaultLane.temperatureRange
            && accountCompanyCompanyName === defaultLane.accountCompanyCompanyName
    })
}

export default checkIsMatch
