import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import FullScreen from './FullScreen'
import CloseButton from './CloseButton'

import useSidebarContainer from './useSidebarContainer'

import useStyles from './Sidebar.styles'

const propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const defaultProps = {
    children: null,
}

const CONTROLS = [
    {
        component: FullScreen,
    },
    {
        component: CloseButton,
        mapper: (props) => {
            return {
                onClose: props.onClose,
            }
        },
    },
]

const SideBar = (props) => {
    const {
        children,
    } = props
    const {
        classes,
    } = useStyles()

    const container = useSidebarContainer()

    if (!container) {
        return null
    }

    return ReactDOM.createPortal(
        <div
            data-testid="sidebar"
            className={classes.root}
        >
            <div className={classes.controlsWrapper}>
                <div className={classes.controls}>
                    {CONTROLS.map(({
                        component: Component,
                        mapper,
                    }) => {
                        const controlProps = mapper ? mapper(props) : null

                        return (
                            <Component
                                key={Component.name}
                                {...controlProps} // eslint-disable-line react/jsx-props-no-spreading, max-len
                            />
                        )
                    })}
                </div>
            </div>
            <div
                id="contentWrapper"
                className={classes.contentWrapper}
            >
                {children}
            </div>
        </div>,
        container,
    )
}

SideBar.propTypes = propTypes
SideBar.defaultProps = defaultProps

export default React.memo(SideBar)
