import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    kamUpcoming,
} from '../KamDashboard.types'

const kamUpcomingConfig: SkyNetTableConfig<keyof kamUpcoming> = {
    name: 'kamUpcoming',
    tabs: [{
        name: 'Upcoming Quotes',
        url: 'all',
    }],
    fields: [
        {
            name: 'quoteNumber',
            labelKey: 'AccountQuotes.quoteNumber',
        },
        {
            name: 'quotedForCompany',
            labelKey: 'AccountQuotes.quotedForCompany',
        },
        {
            name: 'accountName',
            labelKey: 'AccountLane.accountCompanyName',
            filterField: 'accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'leaseType',
            labelKey: 'AccountLane.leaseType',
            filterField: 'leaseType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: LeaseType,
        },
        {
            name: 'pricingOwner',
            labelKey: 'KamDashboard.pricingOwner',
        },
        {
            name: 'createdBy',
            labelKey: 'KamDashboard.requestor',
            sorting: false,
        },
        {
            name: 'lanesToBePriced',
            labelKey: 'KamDashboard.totalLanes',
            sorting: false,
        },
        {
            name: 'lastUpdated',
            labelKey: 'KamDashboard.lastUpdated',
            sorting: false,
        },
    ],
}

export default kamUpcomingConfig
