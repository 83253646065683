import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    Containers,
} from 'app/Apps/Containers/containers.types'
import tableConfig from 'app/Apps/Containers/Container.config'
import {
    TemperatureRangePreconditioned,
} from 'app/types/enums'

const availableTableConfig: SkyNetTableConfig<keyof Containers | 'jypNumber' | 'locationLocationName' | 'currentOrderOrderNumber'> = {
    ...tableConfig,
    name: 'AvailableContainersTable',
    fields: [
        {
            name: 'serialNumber',
            width: '160px',
        },
        {
            name: 'jypNumber',
            labelKey: 'Container.jypNumber',
            width: '150px',
        },
        {
            name: 'containerStatusEnriched',
            componentName: 'ContainerStatusEnriched',
            filterField: 'containerStatusEnriched',
            filterType: FilterType.Array,
            width: '220px',
        },
        {
            name: 'tempRange',
            componentName: 'EnumValue',
            allowedValuesEnum: TemperatureRangePreconditioned,
            filterField: 'tempRange',
            filterType: FilterType.Array,
            width: '150px',
        },
        {
            name: 'containerTypeContainerModel',
            filterField: 'containerTypeContainerModel',
            filterType: FilterType.Array,
            width: '320px',
        },
        {
            name: 'locationLocationName',
            width: '380px',
        },
        {
            name: 'currentOrderOrderNumber',
            width: '150px',
        },
    ],
    tabs: [],
}

export default availableTableConfig
