import {
    FieldConfig,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    ForecastDataSpreadsheetColumnsConfig,
    ForecastSpreadsheetDataType,
} from '../../Forecast.types'

const getSingleForecastConfig = ({
    tmpFields,
    isDisabled,
}: {
    isDisabled: (a: Record<string, any>) => boolean,
    tmpFields: ForecastDataSpreadsheetColumnsConfig
}): SkyNetSpreadSheetConfigType<keyof ForecastSpreadsheetDataType> => {
    const styleConfig = {
        background: '#FFF',
    }

    return {
        id: 'SingleForecast',
        maxHeight: window.screen.height - 120,
        headerRow: true,
        rowsCounter: false,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'account',
            rowId: 0,
        },
        fields: [
            {
                name: 'dataType',
                title: '',
                width: 120,
                disabled: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.TEXT,
                    style: styleConfig,
                },
            },
            ...tmpFields.map((fieldProps) => {
                return {
                    width: 120,
                    required: true,
                    defaultValue: 0,
                    ...fieldProps,
                    cellConfig: {
                        type: SkyNetSpreadSheetCellType.NUMBER,
                        style: styleConfig,
                        ...fieldProps?.cellConfig || {},
                    },
                    disabled: fieldProps.disabled || isDisabled,
                }
            }),
            {
                name: 'id',
                width: 1,
                hidden: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.ID,
                },
            },
        ] as FieldConfig<keyof ForecastSpreadsheetDataType>[],
    }
}

export default getSingleForecastConfig
