import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    ContactMainType,
} from 'app/types/enums'
import {
    Contact,
} from './Contacts.types'

type ContactTable = Contact & {
    locationLocationName: string,
    locationCompanyCompanyName: string
}

const config: SkyNetTableConfig<keyof ContactTable> = {
    name: 'ContactsTable',
    tabs: [{
        name: 'All Contacts',
        url: 'all',
    }],
    fields: [
        {
            name: 'contactName',
            filterField: 'contactName',
            reduced: true,
        },
        {
            name: 'email',
            filterField: 'email',
        },
        {
            name: 'phone',
            filterField: 'phone',
        },
        {
            name: 'isMainContact',
            componentName: 'ChipCheckMark',
        },
        {
            name: 'locationLocationName',
            filterField: 'locationLocationName',
            labelKey: 'location',
        },
        {
            name: 'locationCompanyCompanyName',
            filterField: 'locationCompanyCompanyName',
            labelKey: 'Location.company',
        },
        {
            name: 'mainType',
            componentName: 'EnumList',
            allowedValuesEnum: ContactMainType,
        },
        {
            name: 'changedBy',
            filterField: 'changedBy',
            labelKey: 'ContactView.changedBy',
        },
    ],
}

export default config
