import React from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import useLoadDtoForSkyNetTable from 'app/shared-components/SkyNetTable/hooks/useLoadDtoForSkyNetTable'

import {
    Modification,
} from './LocationChangeSummary.types'
import columns from './locationChangeSummary.columns'
import useStyles from './LocationChangeSummary.style'

const OrderChangeSummary = ({
    locationNumber,
}: {locationNumber: number}) => {
    const {
        classes,
    } = useStyles()

    const load: (params: any) => Promise<{
        items: Modification,
        matches: number
    }> = useLoadDtoForSkyNetTable(
        orderManagementRequest.LocationChangeSummary.request(locationNumber),
    )

    return (
        <RichTable
            configName="LocationChangeSummaryTable"
            name={orderManagementRequest.OrderChangeSummary.name}
            load={load}
            columns={columns}
            uniqField="id"
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
        />
    )
}

export default OrderChangeSummary
