import {
    Column,
} from 'app/types/simpletable.types'
import getUniqueId from 'app/utils/getUniqueId'
import InputSingleline from 'app/shared-components/InputSingleline'
import Checkbox from 'app/shared-components/Checkbox'

export type QuoteLane = {
    changedOn: string,
    destAirportIataCode: string,
    id: number,
    laneComment: string,
    laneNumber: string,
    originAirportIataCode: string,
    selected: boolean,
}

const getColumns = ({
    classes,
    handleChange,
    l,
}: {
    classes: Record<string, any>,
    l: (l:string) => string,
    handleChange: (...a: any[]) => void,
}): Column <keyof QuoteLane>[] => {
    return [
        {
            label: '',
            component: Checkbox,
            fieldName: 'selected',
            getProps: ({
                id,
                selected,
            }) => {
                return {
                    className: classes.checkbox,
                    value: selected,
                    onChange: (checked) => {
                        handleChange({
                            id,
                            selected: checked,
                        })
                    },
                }
            },
            width: 50,
            id: getUniqueId(),
        },
        {
            label: l('AccountLane.laneNumber'),
            component: InputSingleline,
            fieldName: 'laneNumber',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountLane.originAirport'),
            component: InputSingleline,
            fieldName: 'originAirportIataCode',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountLane.destinationAirport'),
            component: InputSingleline,
            fieldName: 'destAirportIataCode',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountLane.quoteLaneComment'),
            component: InputSingleline,
            fieldName: 'laneComment',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
    ]
}

export default getColumns
