import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            margin: `${theme.spacing(2)} 0`,
        },
        checkbox: {
            padding: '0px !important',
        },
        input: {
            backgroundColor: theme.palette.secondary[50],
            paddingLeft: theme.spacing(0.25),
        },
        inputLabel: {
            display: 'none',
        },
        tableCellHead: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    }
})

export default useStyles
