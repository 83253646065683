import React, {
    useState,
    useEffect,
} from 'react'

import Card from 'app/shared-components/Card'
import Checkbox from 'app/shared-components/Checkbox'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import StatusHandler from 'app/shared-components/StatusHandler'
import useLabel from 'app/hooks/useLabel'

import useGetLaneById from '../../hooks/useGetLaneById'
import useActivateNotifications from './hooks/useActivateNotifications'

const Notifications = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetLaneById(id)
    const [
        value,
        setValue,
    ] = useState<boolean>()

    useEffect(() => {
        setValue(data?.activateNotifications)
    }, [data])

    const l = useLabel()

    const {
        mutate,
    } = useActivateNotifications(invalidate)

    const onClickSave = () => {
        mutate({
            activateNotifications: value,
            laneId: data?.id,
        })
    }

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <Card title="Notifications">
                    <Checkbox
                        title={l('Lane.activateNotifications')}
                        value={value}
                        onChange={setValue}
                    />
                </Card>
                <ButtonContainer>
                    <Button
                        label="Save"
                        onClick={onClickSave}
                        disabled={data?.activateNotifications === value}
                    />
                </ButtonContainer>
            </>
        </StatusHandler>
    )
}

export default Notifications
