import React from 'react'
import PropTypes from 'prop-types'

import Button from 'app/shared-components/Button'

import useStyles from './SelectLane.style'

const propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
}

const SelectLane = (props) => {
    const {
        classes,
    } = useStyles()
    const {
        onChange,
        id,
    } = props

    return (
        <div className={classes.wrapper}>
            <Button
                label="select"
                onClick={() => { onChange(id) }}
            />
        </div>
    )
}

SelectLane.propTypes = propTypes

export default SelectLane
