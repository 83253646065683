import {
    ProductType,
    OrderTemperatureRange,
    LeaseType,
} from 'app/types/enums'
import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    DeliveryServiceType,
} from '../../../Lanes/lanes.types'

export enum Selection {
    DEFAULT = 'DEFAULT',
    BACKUP = 'BACKUP'
}

export type CustomerTransportRequestData = {
    lane?: {
        id: number,
    },
    freightForwarder?: {
        id: number,
    },
    orderReceived: string,
    orderEntered: string,
    requestedPickup?: string,
    requestedDelivery?: string,
    requiredContainers: {
        id: number,
        productType: ProductType,
        quantity: number,
    }[],
    commentForBillingOps: string,
    requiredCargoComment: string,
    estimatedPickup?: string,
    numberOfTrucks?: number,
    numberOfVans?: number,
    transportModeRoad: TransportModeRoad,
    transportModeForwarder?: TransportModeRoad,
    originScSelection?: Selection,
    fwdPosSelection?: Selection,
    customerReference: string,
    personInCharge: string,
    leaseStartTimestamp: string,
    mawbNumber: string,
    hawbNumber: string,
    isPerformanceQualification: boolean,
}

export type CustomerTransportCreateData = {
    selectedLaneId?: number,
    originServiceCenter?: {id: number}
    forwarder?: {id: number},
    consignee?: {id: number},
    customerExecution?: {id: number},
    destinationAirport?: {id: number},
    handoverPoint?: {id: number},
    originAirport?: {id: number},
    collectionDropoffPoint?: {id: number},
    account?: { companyName: string },
    temperatureRange?: OrderTemperatureRange,
    laneCommentOps?: string,
    freightForwarderId?: number,
    customerExecutionContactsOps?: {id: number}[],
    leaseType?: LeaseType,
    deliveryServiceType?: DeliveryServiceType,
    minOrder?: number,
    isPerformanceQualification?: boolean,
} & Partial<CustomerTransportRequestData>
