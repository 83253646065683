import React, {
    useMemo, useCallback, useState, useEffect,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useGetContractBasis from 'app/Apps/Pricing/ContractBasis/hooks/useGetContractBasis'
import Button from 'app/shared-components/Button'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import QuoteInformationForm from '../QuoteInformationForm'
import requests from '../AccountQuotes.requests'
import {
    AccountQuote,
    QuoteStatus,
} from '../AccountQuotes.types'
import useAssignToContractBasis from './hooks/useAssignToContractBasis'

const AssignQuote = ({
    data,
    invalidate,
}: {
    data: AccountQuote,
    invalidate: () => void
}) => {
    const [
        value,
        setValue,
    ] = useState(data)

    const [
        defaultContractBasis,
        setDefaultContractBasis,
    ] = useState(value?.contractBasis)

    const {
        data: contractBasis,
        isDataReady: isDataReadyContractBasis,
    } = useGetContractBasis(
        value?.quoteStatus === QuoteStatus.CREATED ? value?.contractBasis?.id : null,
    )

    useEffect(() => {
        if (
            contractBasis && value?.contractBasis?.id !== defaultContractBasis?.id
            && contractBasis?.id === value?.contractBasis?.id
        ) {
            setDefaultContractBasis(value?.contractBasis)
            setValue({
                ...value,
                currency: contractBasis?.currency,
            })
        }
    }, [
        contractBasis,
        defaultContractBasis?.id,
        value,
        isDataReadyContractBasis,
    ])

    const {
        updateTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback(() => {
        invalidate()
        updateTableElement({
            ...value,
        })
    }, [
        updateTableElement,
        value,
        invalidate,
    ])

    const assignToContractBasis = useAssignToContractBasis({
        id: data.id,
        onSuccess,
    })

    const customButtons = useMemo(() => {
        return data?.quoteStatus === QuoteStatus.CREATED && data.contractBasis ? [<Button
            key="assing-to-contract-basis"
            label="Assign To Contract Basis"
            onClick={assignToContractBasis}
        />] : []
    }, [
        data?.quoteStatus,
        data.contractBasis,
        assignToContractBasis,
    ])

    return (
        <QuoteInformationForm
            value={value}
            setValue={setValue}
            render={({
                fields, formData, onChange,
            }) => {
                return (
                    <FormWithControls
                        disabled={data?.quoteStatus !== QuoteStatus.CREATED}
                        setExternalValue={onChange}
                        value={formData}
                        name="AccountQuote"
                        requestParams={requests.update({
                            id: data.id,
                        })}
                        fields={fields}
                        onSuccess={invalidate}
                        onCancel={invalidate}
                        exists
                        customButtons={customButtons}
                    />
                )
            }}
        />
    )
}

export default AssignQuote
