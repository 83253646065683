import {
    useCallback,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from 'react-query'
import requests from '../Contacts.request'
import {
    Contact,
} from '../Contacts.types'

const useGetContact = (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getContact])
    }, [queryClient])

    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useRequest<Contact>({
        key: RequestKeys.getContact,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.get),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetContact
