import {
    LocationType,
} from 'app/types/enums'
import {
    Validate,
} from 'app/types/form.types'

export type Location = {
    latitude?: number,
    longitude?: number,
    id: number,
    locationName: string,
}

export type LocationSelectorProps = {
    value: number | string,
    title: string,
    name: string,
    onChange: (value: any, ...params: any[]) => void,
    disabled?: boolean,
    isRequired?: boolean,
    className?: string,
    classNames?: {
        options?: string,
        label?: string,
        addresses? : string,
    },
    filterByCompanyId?: string,
    filterLocationType: string,
    filterAddressCategory?: string[],
    filterAddressMainCategory?: string,
    isApprovedSubContractor?: boolean,
    isShippingLocation?: boolean,
    allowedLocationTypes?: LocationType[],
    'data-testid'?: string,
    required?: boolean,
    validate?: Validate,
    includeFilters?: Record<keyof Location, any[]>,
    setErrors?: (e: [string, any[]]) => void
}

export const LocationSelectorDefaultProps: Partial<LocationSelectorProps> = {
    value: undefined,
    filterLocationType: undefined,
    isShippingLocation: false,
    filterAddressCategory: undefined,
    filterAddressMainCategory: undefined,
    isApprovedSubContractor: undefined,
    name: '',
    title: '',
    disabled: false,
    isRequired: false,
    className: '',
    classNames: {},
    'data-testid': 'LocationSelector',
    allowedLocationTypes: [],
    required: undefined,
    validate: undefined,
    includeFilters: undefined,
    setErrors: undefined,
}

export type LocationLabelIconType = {
    label: string,
    iconPath: string
}
export type LocationLabel = {
    label: string,
}

export type LocationObjectSelectorProps = Omit<LocationSelectorProps, 'value'> & {
    value: {
        id: string | number,
    }
}
