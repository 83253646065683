import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'
import {
    LeaseType,
} from 'app/types/enums'
import {
    AccountLanesTable,
    LaneStatus,
} from '../../AccountLanes/AccountLanes.types'

export default ({
    opportunities, leaseType, laneStatus, quoteId,
}: {
    opportunities: string[],
    leaseType?: LeaseType,
    laneStatus?: LaneStatus,
    quoteId?: number,
}) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<{data: AccountLanesTable[]}>({
        key: RequestKeys.getAccountLanesByOpportunities,
        params: {},
        defaultValue: {
            filter: {
                opportunity: opportunities,
                laneStatus: laneStatus && [laneStatus],
                leaseType: leaseType && [leaseType],
            },
            sort: {
                field: 'laneNumber',
                direction: 'asc',
            },
        },
        config: requests.filterRequest,
        keepPreviousData: true,
        enabled: !quoteId && !!opportunities?.length,
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data: data?.data,
        loadData,
    }
}
