import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react'
import clsx from 'clsx'

import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import validateForm from 'app/utils/validateForm'
import useGetLaneById from 'app/Apps/OrderManagement/hooks/useGetLaneById'
import {
    LaneStatus,
} from 'app/types/enums'

import useStyles from './BookingDetailsStep.style'
import {
    AdditionalLaneStepType,
    BookingDetailsStepType,
} from '../../createCustomerTransportForm.types'
import getFields from './bookingDetailsStep.fields'
import useGetProductTypes from './hooks/useGetProductTypes'
import useCreateAutomaticQuote from './hooks/useCreateAutomaticQuote'

const getMsgText = ({
    laneNumber,
    isCreatedLaneError,
}) => {
    return isCreatedLaneError
        ? `A lane was created ${laneNumber}, but we could not automatically price it. Please contact the pricing team in order to get it priced.`
        : `A new lane ${laneNumber} has been created and priced successfully. Please proceed below with the implementation.`
}

const forbiddenLaneStatuses = [
    LaneStatus.IN_PROGRESS,
    LaneStatus.PRICED,
]

const defaultProps = {
    selectedLaneId: undefined,
}

const BookingDetailsStep = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
    selectedLaneId,
    onReset,
    onEdit,
    laneData,
}: {
    value: BookingDetailsStepType,
    onChange: (newVal: BookingDetailsStepType) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
    selectedLaneId?: number,
    onReset: () => void,
    onEdit: () => void,
    laneData: AdditionalLaneStepType,
}) => {
    const {
        classes,
    } = useStyles()
    const {
        leaseStartTimestamp,
        requiredContainers,
    } = value
    const [
        createdLaneId,
        setCreatedLaneId,
    ] = useState<number>()

    const allowedProductTypes = useGetProductTypes({
        leaseStartTimestamp,
        selectedLaneId,
        tempRange: laneData.tempRange,
    })

    const isDeliveryRoad = laneData.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    const dependentFieldLeaseStart = isDeliveryRoad ? 'requestedDelivery' : 'requestedPickup'

    const [
        dependentData,
        setDependentData,
    ] = useState<string>()

    useEffect(() => {
        if (leaseStartTimestamp) {
            return
        }
        if (value[dependentFieldLeaseStart] !== dependentData) {
            setDependentData(value[dependentFieldLeaseStart])
            onChange({
                ...value,
                leaseStartTimestamp: value[dependentFieldLeaseStart],
            })
        }
    }, [
        dependentData,
        dependentFieldLeaseStart,
        leaseStartTimestamp,
        onChange,
        value,
    ])

    const onSuccessGetLane = useCallback((resp) => {
        if (!forbiddenLaneStatuses.includes(resp?.laneStatus)) {
            onChange({
                ...value,
                createdLane: resp,
            })
            setCreatedLaneId(null)
            setActiveStep(index + 1)
        }
    }, [
        index,
        onChange,
        setActiveStep,
        value,
    ])

    const {
        data: createdLane,
    } = useGetLaneById(createdLaneId, onSuccessGetLane)

    const isCreatedLaneError = forbiddenLaneStatuses.includes(createdLane?.laneStatus)

    const onSuccessCreateAutomaticQuote = ({
        id,
    }) => {
        setCreatedLaneId(id)
    }

    const {
        isLoading,
        mutate,
    } = useCreateAutomaticQuote({
        onSuccess: onSuccessCreateAutomaticQuote,
    })

    const onClickContinue = () => {
        if (selectedLaneId) {
            setActiveStep(index + 1)

            return
        }

        if (createdLaneId) {
            setCreatedLaneId(null)
        }

        mutate({
            ...laneData,
            leaseStartTimestamp,
            requiredContainers,
        })
    }

    const onClickBack = () => {
        setActiveStep(
            selectedLaneId ? index - 2 : index - 1,
        )
        onReset()
    }

    const onClickEdit = () => {
        setActiveStep(index)
        onEdit()
    }

    const fields = getFields({
        isDeliveryRoad,
        allowedProductTypes,
    })

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value,
        })
    }, [
        fields,
        value,
    ])

    return (
        <div>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                name="OrderByNumberView"
                disabled={activeStep > index}
            />
            <div className={classes.actions}>
                {activeStep === index && (
                    <>
                        <Button
                            label="Continue"
                            onClick={onClickContinue}
                            className={classes.button}
                            disabled={!isFormValid}
                            saving={!selectedLaneId && isLoading}
                            data-testid="btn-continue"
                        />
                        <Button
                            label="Back"
                            secondary
                            onClick={onClickBack}
                            className={classes.button}
                            data-testid="btn-back"
                        />
                    </>
                )}
                {activeStep > index && (
                    <Button
                        label="Edit"
                        onClick={onClickEdit}
                        className={classes.button}
                        data-testid="btn-edit"
                    />
                )}
            </div>
            {createdLane && (
                <div className={clsx(
                    classes.msg,
                    isCreatedLaneError ? classes.errorMsg : classes.successMsg,
                )}
                >
                    {isCreatedLaneError ? (
                        <img
                            src="assets/images/warning.svg"
                            alt="warning"
                            className={classes.icon}
                        />
                    ) : (
                        <img
                            src="assets/images/info.svg"
                            alt="info"
                            className={classes.icon}
                        />
                    )}
                    <div>
                        {getMsgText({
                            laneNumber: createdLane?.laneNumber,
                            isCreatedLaneError,
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

BookingDetailsStep.defaultProps = defaultProps

export default BookingDetailsStep
