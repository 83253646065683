import React, {
    useMemo,
} from 'react'

import {
    useArchivedTableControl,
} from 'app/shared-components/TableControls/ShowArchived'
import Card from 'app/shared-components/Card'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'

import requests from '../Lane.requests'
import columns from './LanesTable.columns'
import useLaneGroupContext from '../../hooks/useLaneGroupContext'
import useGetLaneByLaneGroup from '../hooks/useGetLaneByLaneGroup'
import LaneUpdate from '../LaneUpdate'
import LaneCopy from '../LaneCopy'
import LaneCreate from '../LaneCreate'
import useStyles from './LaneTable.styles'

const LanesTable = () => {
    const {
        classes,
    } = useStyles()
    const {
        laneGroupId,
    } = useLaneGroupContext()

    const showArchived = useArchivedTableControl(requests.domainName)

    const requestsHandlers = useGetLaneByLaneGroup(laneGroupId, showArchived)

    const customUrl = useMemo(() => {
        return requests.getLaneByLaneGroup({
            laneGroupId,
        })?.url
    }, [laneGroupId])

    const actionComponents = useMemo(() => {
        return {
            Copy: LaneCopy,
            Update: LaneUpdate,
            Create: LaneCreate,
        }
    }, [])

    return (
        <div
            data-testid="LanesTable"
            className={classes.root}
        >
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="LanesTable-card"
            >
                <CollapsibleTable
                    title="Lanes"
                    domainName={requests.domainName}
                    columns={columns}
                    customUrl={customUrl}
                    requestHandlers={requestsHandlers}
                    actionComponents={actionComponents}
                />
            </Card>
        </div>
    )
}

export default LanesTable
