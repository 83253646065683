import {
    useUserRoles,
} from '@skycell-ag/auth'

import hasPermission, {
    rolesTypes,
} from 'app/utils/hasPermission'

/**
* Use this to check if the user has any of the passed permissions
* @param    {Array}  permissions array to check
* @return   {Boolean}
*/

const usePermission = (perm: rolesTypes[]) => {
    const roles = useUserRoles()

    return hasPermission(perm, roles)
}

export default usePermission
