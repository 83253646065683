import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from 'react-query'
import {
    useCallback,
} from 'react'

import config from '../FxRate.request'
import FxRate from '../FxRate.types'

export default (
    id: number | string,
    onSuccess?: (fxRate: any) => void,
) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getFxRateById])
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<FxRate>({
        key: RequestKeys.getFxRateById,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.FxRate.requestFn),
        onSuccess,
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}
