import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    NotificationTableViewFields, RecipientTypes,
} from './utils/OrderNotifications.types'

const columns: RichTableColumns<NotificationTableViewFields>[] = [
    {
        id: 'notificationName',
        headerProps: {
            children: 'Notification / Follow-up',
        },
        mapCellProps: 'notificationName',
    },
    {
        id: 'notificationType',
        headerProps: {
            children: 'Type',
        },
        mapCellProps: 'notificationType',
        componentName: 'NotificationType',
    },
    {
        id: 'recipientTypes',
        headerProps: {
            children: 'Recipient Type',
        },
        mapCellProps: 'recipientTypes',
        componentName: 'EnumList',
        allowedValuesEnum: RecipientTypes,
    },
    {
        id: 'recipients',
        headerProps: {
            children: 'Recipients',
        },
        mapCellProps: 'recipients',
        componentName: 'Recipients',
    },
    {
        id: 'sendFirstTime',
        width: '50px',
        headerProps: {
            children: '1st',
        },
        mapCellProps: 'sendFirstTime',
        componentName: 'SendTimeCell',
    },
    {
        id: 'sendSecondTime',
        width: '50px',
        headerProps: {
            children: '2nd',
        },
        mapCellProps: 'sendSecondTime',
        componentName: 'SendTimeCell',
    },
    {
        id: 'sendThirdTime',
        width: '50px',
        headerProps: {
            children: '3rd',
        },
        mapCellProps: 'sendThirdTime',
        componentName: 'SendTimeCell',
    },
    {
        id: 'lastSentOn',
        headerProps: {
            children: 'Sent On',
        },
        mapCellProps: 'lastSentOn',
        componentName: 'LastSentOn',
    },
]

export default columns
