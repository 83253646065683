import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ResponseMutation,
} from 'app/types/request.types'
import DTOSpec from 'app/services/dtoSpec'
import request from 'app/Apps/ContactManagement/contactManagement.request'

type Params = {
    onSuccess: () => void,
}

const DOMAIN_NAME = DTOSpec.BillingCompany.Info.name

const useRestoreBillingCompany = ({
    onSuccess,
}: Params): ResponseMutation<{id: number}> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRestore,
        onError,
    } = useHandleStatusRequest({
        id: DOMAIN_NAME,
        title: 'Billing Company Restored',
        onSuccess,
    })

    return useMutation(({
        id,
    }: {id: number}) => {
        return request.Restore.mutationFn({
            id,
            token,
        })
    }, {
        onSuccess: onSuccessRestore,
        onError,
    })
}

export default useRestoreBillingCompany
