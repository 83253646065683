import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AccountLane,
} from '../../AccountLanes.types'

import requests from '../../AccountLanes.requests'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessBulkCreate,
        onError,
    } = useHandleStatusRequest({
        title: 'New lanes were created',
        id: 'AccountLanesBulkCreate',
    })

    const {
        mutateAsync: createAccountLanes,
    } = useMutation((lanes: AccountLane[]) => {
        return simpleApi({
            ...requests.bulkCreate({
                data: {
                    lanes,
                },
            }),
            token,
        })
    }, {
        onError,
    })

    return useCallback(async (lanes: AccountLane[], {
        onSuccess,
    }) => {
        return createAccountLanes(lanes, {
            onSuccess,
        }).then((data) => {
            onSuccessBulkCreate(data)
        })
    }, [
        createAccountLanes,
        onSuccessBulkCreate,
    ])
}
