import React, {
    useState, useCallback, useMemo,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import useGetRegionalGroupById from 'app/Apps/AccountManagement/RegionalGroups/hooks/useGetRegionalGroupById'

import getFields, {
    OpportunityFields,
} from './OpportunitiesForm.fields'
import useStyles from './OpportunitiesForm.style'

import {
    OpportunityType,
} from '../opportunities.types'
import request from '../opportunities.request'

const defaultProps = {
    data: {},
    disabled: false,
    onSuccess: null,
    exists: false,
    customButtons: [],
}

const OpportunitiesForm = ({
    data,
    onSuccess,
    disabled,
    exists,
    customButtons,
}: {
    data?: OpportunityType,
    onSuccess?: (opportunity?: OpportunityType) => void,
    disabled?: boolean,
    exists?: boolean,
    customButtons?: JSX.Element[],
}) => {
    const {
        state,
    } = useLocation()
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const [
        value,
        setValue,
    ] = useState<OpportunityFields>(data as OpportunityFields)

    const {
        data: regionalGroup,
    } = useGetRegionalGroupById(value?.regionalGroup?.id || state?.autofill?.regionalGroup?.id)

    const onChange = useCallback((next) => {
        setValue((prev) => {
            let result = {
                ...prev,
                ...next,
            }

            if (!isEqual(next.regionalGroup?.id, prev?.regionalGroup?.id)) {
                result = {
                    ...result,
                    reprOriginAirport: undefined,
                    reprDestinationAirport: undefined,
                }
            }

            return result
        })
    }, [])

    const formValues = useMemo(() => {
        return {
            ...value,
            originRegion: regionalGroup?.originRegion,
            destinationRegion: regionalGroup?.destinationRegion,
            accountName: regionalGroup?.account?.companyName,
            temperatureRange: regionalGroup?.tempRange,
            account: regionalGroup?.account,
        }
    }, [
        regionalGroup,
        value,
    ])

    const fields = getFields({
        getAllowedValues,
        originRegion: formValues?.originRegion,
        destinationRegion: formValues?.destinationRegion,
        disableRegionalGroup: Boolean(state?.autofill?.regionalGroup?.id
            || value?.existNonCanceledLanes),
        disabled,
    })
    const fieldsWithClasses = useFieldsWithClassName(fields, classes)

    return (
        <FormWithControls
            onSuccess={onSuccess}
            value={formValues}
            setExternalValue={onChange}
            name={request.domainName}
            fields={fieldsWithClasses}
            disabled={disabled}
            customButtons={customButtons}
            requestParams={exists ? request.UpdateOpportunity({
                opportunityId: data.id,
            }) : request.CreateOpportunity()}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            exists={exists}
        />
    )
}

OpportunitiesForm.defaultProps = defaultProps

export default OpportunitiesForm
