/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useState,
    useMemo,
    useEffect,
    useCallback,
} from 'react'

import containerTemperatureRanges from 'app/utils/containerTemperatureRanges'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    dayPassedToRange,
} from 'app/utils/date'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    LoggerType,
} from 'app/types/enums'
import useGetLoggerContainerInformationBySerialNumber from '../../hooks/useGetLoggerContainerInformationBySerialNumber'

import defaultConfigLoggers from './utils'
import {
    Container,
} from '../../containers.types'
import SensorData from './SensorData'
import useGetContainerBySerialNumber from '../../hooks/useGetContainerBySerialNumber'

type Props = {
    id: string,
}
const SensorDataContainer = ({
    id: serialNumber,
}: Props) => {
    const {
        data: {
            id,
            tempRange,
            lastMeasuredTempInternal,
            lastMeasuredTempAmbient,
            lastMeasuredOnUtcInternal,
            lastMeasuredOnUtcAmbient,
            loggerExchangedOnUtcAmbient,
            loggerExchangedOnUtcInternal,
            productType,
        } = {} as Container,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContainerBySerialNumber(serialNumber)

    const loggerTypesEnums = useEnumValues()(LoggerType)

    const [
        configLoggers,
        setConfigLoggers,
    ] = useState(null)

    const getLoggerConfigs = useCallback(({
        data: loggerContainerInformation,
    }) => {
        const {
            loggerTypesOptions, loggerPositionsOptions,
        } = loggerContainerInformation.reduce((acc, currentLogger) => {
            acc.loggerTypesOptions.add(currentLogger.logger.loggerType)
            currentLogger.sensorPositions.forEach((p) => {
                return acc.loggerPositionsOptions.add(p)
            })

            return acc
        }, {
            loggerTypesOptions: new Set(),
            loggerPositionsOptions: new Set(),
        })

        return setConfigLoggers(
            defaultConfigLoggers(
                Array.from(loggerTypesOptions).map((currentOption: LoggerType) => {
                    return {
                        value: currentOption,
                        label: loggerTypesEnums[currentOption],
                    }
                }), Array.from(loggerPositionsOptions),
            ),
        )
    }, [loggerTypesEnums])

    const {
        data: loggerContainerInformation,
        isDataReady: loggerInformationIsDataReady,
        isFetching: loggerInformationIsFetching,
    } = useGetLoggerContainerInformationBySerialNumber(serialNumber, getLoggerConfigs)

    const [
        timeRange,
        setTimeRange,
    ] = useState(dayPassedToRange(7))

    const [
        showTempRange,
        onCheckShowTempRange,
    ] = useState(false)

    const [
        temperatureChartFullscreen,
        setTemperatureChartFullscreen,
    ] = useState(false)

    const [
        isChartPrinting,
        setChartPrinting,
    ] = useState(false)

    const [
        isDateRange,
        setDateRange,
    ] = useState(false)

    const beforePrintHandler = useCallback(() => {
        setChartPrinting(true)
    }, [])

    const afterPrintHandler = useCallback(() => {
        setChartPrinting(false)
    }, [])

    useEffect(() => {
        window.addEventListener('beforeprint', beforePrintHandler)
        window.addEventListener('afterprint', afterPrintHandler)

        return () => {
            window.removeEventListener('beforeprint', beforePrintHandler)
            window.removeEventListener('afterprint', afterPrintHandler)
        }
    }, [
        afterPrintHandler,
        beforePrintHandler,
    ])

    const printChart = useCallback(() => {
        window.print()
    }, [])

    const temperatureRange = useMemo(() => {
        return containerTemperatureRanges[tempRange]
    }, [tempRange])

    const [
        showDoorEvent,
        onCheckShowDoorEvent,
    ] = useState(false)

    const [
        showVirtualLogger,
        setShowVirtualLogger,
    ] = useState(false)

    return (
        <StatusHandler
            isSuccess={isDataReady && loggerInformationIsDataReady}
            isFetching={isFetching && loggerInformationIsFetching}
            isError={isError}
            error={error}
        >
            <>
                {
                    id && configLoggers ? (
                        <SensorData
                            productType={productType}
                            serialNumber={serialNumber}
                            lastMeasuredTempInternal={lastMeasuredTempInternal}
                            lastMeasuredTempAmbient={lastMeasuredTempAmbient}
                            lastMeasuredOnUtcInternal={lastMeasuredOnUtcInternal}
                            lastMeasuredOnUtcAmbient={lastMeasuredOnUtcAmbient}
                            loggerExchangedOnUtcAmbient={loggerExchangedOnUtcAmbient}
                            loggerExchangedOnUtcInternal={loggerExchangedOnUtcInternal}
                            isChartPrinting={isChartPrinting}
                            printChart={printChart}
                            isDateRange={isDateRange}
                            setDateRange={setDateRange}
                            showTempRange={showTempRange}
                            onCheckShowTempRange={onCheckShowTempRange}
                            timeRange={timeRange}
                            setTimeRange={setTimeRange}
                            temperatureChartFullscreen={temperatureChartFullscreen}
                            setTemperatureChartFullscreen={setTemperatureChartFullscreen}
                            configLoggers={configLoggers}
                            setConfigLoggers={setConfigLoggers}
                            temperatureRange={temperatureRange}
                            showDoorEvent={showDoorEvent}
                            onCheckShowDoorEvent={onCheckShowDoorEvent}
                            showVirtualLogger={showVirtualLogger}
                            setShowVirtualLogger={setShowVirtualLogger}
                            loggerContainerInformation={loggerContainerInformation}
                        />
                    ) : null
                }
            </>

        </StatusHandler>
    )
}

export default SensorDataContainer
