import React, {
    useCallback,
    useState,
} from 'react'

import downloadDocument from 'app/utils/downloadDocument'
import InputSingleline from 'app/shared-components/InputSingleline'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import config from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/utils/OrderNotifications.config'

import useStyles from './SentFirstTimeOn.style'

type Props = {
    notificationName: string,
    orderNumber: string,
    disabled?: boolean,
    value: string,
    title?: string,
    id: number,
}

const defaultProps: Partial<Props> = {
    disabled: false,
    title: '',
}

const SentFirstTimeOn = ({
    notificationName,
    orderNumber,
    disabled,
    title,
    value,
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const [
        isOnLoad,
        setIsOnLoad,
    ] = useState<boolean>(false)

    const onSuccess = useCallback((data): void => {
        downloadDocument({
            headers: {
                'content-type': 'text/html;charset=UTF-8',
            },
            data,
        }, `${notificationName}${value}`)
        setIsOnLoad(false)
    }, [
        notificationName,
        value,
    ])

    useRequest({
        key: RequestKeys.getNotificationEmail,
        params: {
            orderNumber,
            id,
        },
        requestFunc: RequestFn.getEntity(config.NotificationEmail.requestFn),
        enabled: isOnLoad,
        onSuccess,
    })

    const onLoad = useCallback((): void => {
        setIsOnLoad(true)
    }, [])

    return (
        <div className={classes.root}>
            <InputSingleline
                value={value}
                disabled={disabled}
                title={title}
            />
            {value && (
                <div
                    onMouseDown={onLoad}
                    className={classes.icon}
                    data-testid="download-icon"
                >
                    <img
                        src="/assets/images/download_button.svg"
                        alt="None"
                    />
                </div>
            )}
        </div>
    )
}

SentFirstTimeOn.defaultProps = defaultProps

export default SentFirstTimeOn
