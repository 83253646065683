import {
    FilterType,
} from 'app/types/common.enums'
import {
    RichTableColumns,
} from 'app/types/richtable.types'
import TimeLessDateRangeSelectorFilter from 'app/shared-components/DateRangeSelectorFilter/TimeLessDateRangeSelectorFilter'

import {
    LaneGroup,
} from 'app/Apps/Pricing/Pricing.types'
import {
    CollectionServiceType,
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LeaseType,
} from 'app/types/enums'

const columns: RichTableColumns<keyof LaneGroup>[] = [
    {
        id: 'laneGroupNumber',
        mapCellProps: 'laneGroupNumber',
        headerProps: {
            children: 'Lane Group',
        },
    },
    {
        id: 'deliveryServiceType',
        filterField: 'deliveryServiceType',
        filterType: FilterType.Array,
        headerProps: {
            children: 'Delivery Service Type',
        },
        mapCellProps: 'deliveryServiceType',
        componentName: 'EnumValue',
        allowedValuesEnum: DeliveryServiceType,
    },
    {
        id: 'handoverPointLocationName',
        filterField: 'handoverPointLocationName',
        filterType: FilterType.Array,
        headerProps: {
            children: 'Handover Point',
        },
        mapCellProps: 'handoverPointLocationName',
    },
    {
        id: 'collectionServiceType',
        filterField: 'collectionServiceType',
        filterType: FilterType.Array,
        headerProps: {
            children: 'Collection Service Type',
        },
        mapCellProps: 'collectionServiceType',
        componentName: 'EnumValue',
        allowedValuesEnum: CollectionServiceType,
    },
    {
        id: 'collectionDropoffPointLocationName',
        filterField: 'collectionDropoffPointLocationName',
        filterType: FilterType.Array,
        headerProps: {
            children: 'Collection Point',
        },
        mapCellProps: 'collectionDropoffPointLocationName',
    },
    {
        id: 'leaseType',
        filterField: 'leaseType',
        filterType: FilterType.Array,
        headerProps: {
            children: 'Lease Type',
        },
        mapCellProps: 'leaseType',
        width: '120px',
        componentName: 'EnumValue',
        allowedValuesEnum: LeaseType,
    },

    {
        id: 'originAirportsIataCode',
        filterField: 'originAirportsIataCode',
        filterType: FilterType.Array,
        headerProps: {
            children: 'From',
        },
        mapCellProps: 'originAirportsIataCode',
        width: '70px',
        componentName: 'List',
    },
    {
        id: 'destinationAirportsIataCode',
        filterField: 'destinationAirportsIataCode',
        filterType: FilterType.Array,
        headerProps: {
            children: 'To',
        },
        mapCellProps: 'destinationAirportsIataCode',
        width: '70px',
        componentName: 'List',
    },
    {
        id: 'tempRange',
        filterField: 'tempRange',
        filterType: FilterType.Array,
        headerProps: {
            children: 'Temp',
        },
        width: '70px',
        mapCellProps: 'tempRange',
    },
    {
        id: 'validPricingFrom',
        filterField: 'validPricingFrom',
        filterType: FilterType.DateRange,
        FilterComponent: TimeLessDateRangeSelectorFilter,
        headerProps: {
            children: 'Valid From',
        },
        width: '120px',
        mapCellProps: 'validPricingFrom',
    },
    {
        id: 'validPricingTo',
        filterField: 'validPricingTo',
        filterType: FilterType.DateRange,
        headerProps: {
            children: 'Valid To',
        },
        width: '110px',
        mapCellProps: 'validPricingTo',
        componentName: 'LaneDateEnriched',
    },
]

export default columns
