import React, {
    useEffect, useMemo,
    useState,
} from 'react'
import omit from 'lodash/omit'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    ModificationType, TransportModeRoad,
} from '../../orders.types'
import getFields from './positioningSetup.fields'
import useStyles from './PositioningSetup.style'
import {
    PositioningSetupType,
} from './PositioningSetup.types'
import getServiceCenterMap from './getServiceCenterMap'
import useUpdatePositioningSetup from './hooks/useUpdatePositioningSetup'

const PositioningSetup = ({
    data,
    lane,
    isDeliveryRoad,
    orderNumber,
    onSuccess,
}: {
    data: PositioningSetupType,
    lane: Lane,
    orderNumber: string,
    isDeliveryRoad: boolean,
    onSuccess: () => void
}) => {
    const getAllowedValues = useEnumValues()
    const serviceCenterMap = useMemo(() => {
        return getServiceCenterMap(lane)
    }, [lane])
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState(data)

    const {
        originServiceCenterSelection,
        forwarderPositioningSelection,
        originServiceCenter,
        transportModeForwarder,
        forwarderPositioning,
        leaseType,
    } = value

    const [defaultValue] = useState(data)

    const [
        modification,
        setModification,
    ] = useState<ModificationType>()

    const [
        switchOriginContacts,
        setSwitchOriginContacts,
    ] = useState<boolean>(false)

    const [
        switchForwarderContacts,
        setSwitchForwarderContacts,
    ] = useState<boolean>(false)

    useEffect(() => {
        setSwitchOriginContacts(
            serviceCenterMap.origin[originServiceCenterSelection].switchContacts,
        )
        setValue((prevValue) => {
            return {
                ...prevValue,
                ...serviceCenterMap.origin[originServiceCenterSelection].data,
            }
        })
    }, [
        originServiceCenterSelection,
        serviceCenterMap.origin,
    ])

    useEffect(() => {
        setSwitchForwarderContacts(
            serviceCenterMap.forwarder[forwarderPositioningSelection].switchContacts,
        )
        setValue((prevState) => {
            return {
                ...prevState,
                ...serviceCenterMap.forwarder[forwarderPositioningSelection].data,
            }
        })
    }, [
        forwarderPositioningSelection,
        serviceCenterMap.forwarder,
    ])

    const {
        mutate,
        isLoading,
    } = useUpdatePositioningSetup(onSuccess)

    const resetData = (): void => {
        setValue(defaultValue)
    }

    const fields = useMemo(() => {
        return getFields({
            transportModeForwarder,
            getAllowedValues,
            isDeliveryRoad,
            originServiceCenterSelection,
            forwarderPositioningSelection,
            leaseType,
            forwarderPositioningId: forwarderPositioning?.id,
            originServiceCenterId: originServiceCenter?.id,
            switchOriginContacts,
            switchForwarderContacts,
        })
    }, [
        forwarderPositioning?.id,
        forwarderPositioningSelection,
        getAllowedValues,
        isDeliveryRoad,
        leaseType,
        originServiceCenter?.id,
        switchOriginContacts,
        switchForwarderContacts,
        originServiceCenterSelection,
        transportModeForwarder,
    ])

    const fieldsWithClassName = useFieldsWithClassName(fields, classes)

    const isSaveDisabled = defaultValue === value

    const update = (modificationType: ModificationType) => {
        mutate({
            modificationType,
            orderNumber,
            data: transportModeForwarder === TransportModeRoad.FTL ? value : omit(value, [
                'numberOfTrucks',
                'numberOfVans',
            ]),
        })
        setModification(modificationType)
    }

    return (
        <>
            <DomainObjectForm
                value={value}
                onChange={setValue}
                fields={fieldsWithClassName}
                name="OrderByNumberView"
            />
            <ButtonContainer>
                <Button
                    label="Save (SkyCell)"
                    onClick={() => {
                        update(ModificationType.INTERNAL)
                    }}
                    data-testid="save-btn-internal"
                    disabled={
                        isSaveDisabled || isLoading
                    }
                    saving={isLoading && modification === ModificationType.INTERNAL}
                />
                <Button
                    label="Save (Customer)"
                    onClick={() => { update(ModificationType.CUSTOMER) }}
                    disabled={isSaveDisabled || isLoading}
                    data-testid="save-btn-customer"
                    saving={isLoading && modification === ModificationType.CUSTOMER}
                />
                <Button
                    secondary
                    label="Cancel"
                    onClick={resetData}
                    disabled={isLoading}
                    data-testid="cancel-btn"
                />
            </ButtonContainer>
        </>
    )
}

export default PositioningSetup
