import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ResponseMutation,
} from 'app/types/request.types'

import request from '../../toBeAssignedLanes.request'

type RequestParams = {
    lanes: {id: number}[],
    opportunity: {id: number},
}

const useAssignmentToOpportunity = (onAssigned: () => void): ResponseMutation<RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessAssignment,
        onError,
    } = useHandleStatusRequest({
        id: 'AssignmentToOpportunity',
        onSuccess: onAssigned,
        onError: onAssigned,
    })

    return useMutation((data: RequestParams) => {
        return request.AssignmentToOpportunity.mutationFn({
            data,
            token,
        })
    }, {
        onSuccess: onSuccessAssignment,
        onError,
    })
}

export default useAssignmentToOpportunity
