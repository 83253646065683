import {
    useCallback,
} from 'react'
import {
    CellChange,
} from '@silevis/reactgrid'
import {
    Domain, ExtendedCellTypes,
} from '../SkyNetSpreadSheet.types'
import useSetMappedResults from './useSetMappedResults'
import {
    getCellValue,
} from '../getRows'
import {
    SpreadsheetMappingResponse,
} from '../SkyNetSpreadSheet.requests'

export default (configId: string) => {
    const addMappedDataToDomainConfig = useSetMappedResults({
        configId,
    })

    return useCallback((
        newValues: CellChange<ExtendedCellTypes>[],
        mappedResult: Map<Domain, SpreadsheetMappingResponse>,
        updatedData: Record<string, any>[],
    ) => {
        const resultData = [...updatedData]

        newValues.forEach(({
            columnId,
            newCell,
            rowId,
        }) => {
            let cellResult = newCell

            if ('undeterminedValue' in newCell && 'domain' in newCell && newCell.undeterminedValue) {
                if (mappedResult?.size > 0) {
                    addMappedDataToDomainConfig(mappedResult, newCell.name)
                }

                const result = (mappedResult.get(newCell?.domain) || []).find(({
                    label,
                }) => {
                    return label === newCell.undeterminedValue
                })

                cellResult = {
                    ...newCell,
                    undeterminedValue: null,
                    text: result?.label,
                    id: result?.id,
                    selectedValue: result,
                    value: result?.id,
                }
            }

            resultData[rowId][columnId] = getCellValue(cellResult)
        })

        return resultData
    }, [addMappedDataToDomainConfig])
}
