import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import NewSimulation from './NewSimulation'
import SavedSimulation from './SavedSimulation'
import SavedSimulations from './SavedSimulations'
import Co2simulations from './Co2simulations'

const TransportPlanner = () => {
    const {
        path,
    } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/simulation/:id`}>
                <SavedSimulation transportPlannerPath={path} />
            </Route>
            <Route path={`${path}/simulation/`}>
                <NewSimulation />
            </Route>
            <Route path={`${path}/co2Calculation/`}>
                <Co2simulations />
            </Route>
            <Route path={`${path}/savedSimulations`}>
                <SavedSimulations transportPlannerPath={path} />
            </Route>

            <Redirect
                exact
                path={path}
                to={`${path}/simulation`}
            />

            <Route path="/">
                <div>
                    Not found
                </div>
            </Route>
        </Switch>
    )
}

export default TransportPlanner
