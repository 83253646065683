import React, {
    useCallback,
    useMemo,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import EnumSelector from 'app/shared-components/EnumSelector'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    ContainerCode,
} from 'app/types/enums'
import useGetContainerTypes from 'app/Apps/ContainerTypes/hooks/useGetContainerTypes'

type Props = {
    onChange: ({
        id: string,
    }, ...args) => void,
    title: string,
    name: string,
    value?: { id: string },
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    value: null,
    required: false,
}

const SelectContainerTypeByCode = ({
    onChange,
    title,
    name,
    value,
    required,
}: Props) => {
    const {
        containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContainerTypes()

    const handleOnChange = useCallback((id, ...args) => {
        onChange({
            id,
        }, ...args)
    }, [onChange])

    const getAllowedValues = useEnumValues()
    const containerCodeAllowedValues = getAllowedValues(ContainerCode)

    const containerTypeAllowedValues = useMemo(() => {
        return (containerTypes || []).reduce((acc, element) => {
            return {
                ...acc,
                [element.id]: containerCodeAllowedValues[element.containerCode],
            }
        }, {})
    }, [
        containerCodeAllowedValues,
        containerTypes,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <EnumSelector
                allowedValues={containerTypeAllowedValues}
                onChange={handleOnChange}
                title={title}
                name={name}
                value={value?.id}
                required={required}
            />
        </StatusHandler>
    )
}

SelectContainerTypeByCode.defaultProps = defaultProps

export default SelectContainerTypeByCode
