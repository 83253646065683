import {
    FieldInCard, FormPart,
} from 'app/types/form.types'

import {
    AddressCategory,
    LaneStatus,
    LocationType,
} from 'app/types/enums'

import {
    LaneDetails,
} from 'app/Apps/Pricing/Pricing.types'

const laneFormFields: FormPart<keyof LaneDetails>[] = [
    {
        title: 'Origin / Destination',
        className: 'originDestination',
        fields: [
            {
                name: 'originAirport',
                labelKey: 'LaneUpdateNewDto.originAirport',
                componentName: 'LocationObjectSelector',
                filterLocationType: LocationType.AIRPORT,
                required: true,
            },
            {
                name: 'destinationAirport',
                labelKey: 'LaneUpdateNewDto.destAirport',
                componentName: 'LocationObjectSelector',
                filterLocationType: LocationType.AIRPORT,
                required: true,
            },
            {
                name: 'expPalletsYear',
                labelKey: 'LaneUpdateNewDto.expPalletsYear',
                componentName: 'IntegerInput',
                value: 1,
            },
        ],
    },
    {
        title: 'Consignee',
        className: 'consignee',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'consigneeProvisional',
                    labelKey: 'LaneUpdateNewDto.consigneeProvisional',
                    componentName: 'InputSingleline',
                }],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'consignee',
                        labelKey: 'LaneUpdateNewDto.consignee',
                        componentName: 'LocationObjectSelector',
                        filterLocationType: LocationType.ADDRESS,
                    },
                    {
                        name: 'consigneeContacts',
                        labelKey: 'LaneUpdateNewDto.consigneeContacts',
                        componentName: 'MultipleContactObjectSelector',
                    },
                ],
            },
        ],
    },
    {
        title: 'Customer (Execution)',
        className: 'customerExecution',
        fields: [
            {
                name: 'customerExecution',
                labelKey: 'LaneUpdateNewDto.customerExecution',
                componentName: 'LocationObjectSelector',
                filterLocationType: LocationType.ADDRESS,
                filterAddressCategory: [
                    AddressCategory.CUSTOMER,
                    AddressCategory.CUSTOMER_QUOTATION,
                ],
            },
            {
                name: 'customerExecutionContactsQuote',
                labelKey: 'LaneUpdateNewDto.customerExecutionContactsQuote',
                componentName: 'MultipleContactObjectSelector',
            },
        ],
    },
    {
        title: 'Billing Information',
        className: 'billingInformation',
        fields: [{
            name: 'billingCompany',
            labelKey: 'LaneUpdateNewDto.billingCompany',
            componentName: 'BillingCompanyObjectSelector',
        }],
    },
    {
        title: 'Others',
        className: 'others',
        fields: [{
            name: 'laneCommentBilling',
            labelKey: 'LaneUpdateNewDto.laneCommentBilling',
            componentName: 'LongText',
        }],
    },
]

export const generalColumn = (originalStatus, getAllowedValues)
    : FieldInCard<keyof LaneDetails> => {
    return {
        title: 'General',
        className: 'general',
        fields: [
            {
                name: 'laneStatus',
                labelKey: 'LaneUpdateNewDto.laneStatus',
                originalStatus,
                moduleName: 'laneStatus',
                componentName: 'StatusTransitionSelector',
                allowedValues: getAllowedValues(LaneStatus, true),
                useDropdown: true,
                required: true,
            },
            {
                name: 'laneCommentOps',
                labelKey: 'LaneUpdateNewDto.laneCommentOps',
                componentName: 'LongText',
            },
        ],
    }
}

export default laneFormFields
