import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import camelCase from 'lodash/camelCase'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Typography from '@mui/material/Typography'

import Card from 'app/shared-components/Card'

import useStyles from './GroupWithCard.style'
import Form from '../../Form'

const fieldTypes = PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    titleLink: PropTypes.string,
    className: PropTypes.string,
    fields: PropTypes.any, // eslint-disable-line
}))

const propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        className: PropTypes.string,
        fields: fieldTypes,
    })).isRequired,
    classNames: PropTypes.shape({
        gridCardWrapper: PropTypes.string,
        title: PropTypes.string,
    }),
}

const defaultProps = {
    classNames: {},
}

const GroupWithCard = (props) => {
    const {
        fields,
        classNames,
    } = props

    const {
        classes, cx,
    } = useStyles()
    const history = useHistory()

    const navigateToTitleLink = useCallback((link) => {
        history.push(link)
    }, [history])

    return (
        <>
            {
                fields.map(({
                    name,
                    title = name,
                    titleLink,
                    titleIcon,
                    className,
                    classNames: classNamesField,
                    fields: groupFields,
                    id,
                    'data-testid': dataTestid = 'group',
                }) => {
                    return (
                        <Card
                            key={`${camelCase(title)}-${id}`}
                            fullHeight
                            className={cx(classes.root, className)}
                            contentClass={classNamesField?.wrapper || className}
                            data-testid={dataTestid}
                        >
                            <Typography
                                variant="h3"
                                className={classNamesField?.title || classNames.title}
                            >
                                {titleIcon && (
                                    <img
                                        className={classes.titleIcon}
                                        src={titleIcon}
                                        alt={title}
                                    />
                                )}
                                {title}
                                {titleLink && (
                                    <ArrowForwardIcon
                                        className={classes.link}
                                        onClick={() => {
                                            return navigateToTitleLink(titleLink)
                                        }}
                                    />
                                )}
                            </Typography>

                            <div
                                className={cx(
                                    classes.gridCardWrapper,
                                    classNames.gridCardWrapper,
                                    className,
                                )}
                            >
                                {
                                    groupFields[0].fields && groupFields.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                className={item.className}
                                            >
                                                <Form
                                                    {...props} /* eslint-disable-line */
                                                    fields={item.fields}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !groupFields[0].fields && (
                                        <Form
                                            {...props} /* eslint-disable-line */
                                            fields={groupFields}
                                        />
                                    )
                                }
                            </div>
                        </Card>
                    )
                })
            }
        </>
    )
}

GroupWithCard.propTypes = propTypes
GroupWithCard.defaultProps = defaultProps

export default GroupWithCard
