import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    Company,
} from '../Companies.types'
import requests from '../Companies.request'

export default ({
    onSuccess,
}: { onSuccess: (...args: any[]) => void }) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdateCompany,
        onError,
    } = useHandleStatusRequest({
        title: 'Company was updated',
        id: 'CompanyUpdate',
        onSuccess,
    })

    const {
        mutate: updateCompany,
    } = useMutation((company: Company) => {
        return simpleApi({
            ...requests.update({
                data: company,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessUpdateCompany,
    })

    return useCallback((company: Company) => {
        updateCompany(company)
    }, [updateCompany])
}
