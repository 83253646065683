import React from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    rolesTypes,
} from 'app/utils/hasPermission'

import BillingCompanyOverview from './BillingCompanyOverview'
import DataUpdate from './BillingCompanyUpdate'
import BillingCompanyCreate from './BillingCompanyCreate'
import tableConfig from './BillingCompanies.config'
import requests from './BillingCompanies.request'

const BILLING_RIGHTS_WITH_CREATE_PERMISSION:rolesTypes[] = [
    'location_bil',
    'location_bil_super',
    'location_sales',
    'location_sales_super',
    'location_airlines',
    'location_airlines_super',
]

const overviewTab = {
    url: 'overview',
    label: 'Overview',
    Component: BillingCompanyOverview,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const BillingCompanies = () => {
    const tabs = [
        overviewTab,
        {
            url: 'data-update',
            label: 'Data Update',
            Component: DataUpdate,
        },
    ]
    const hasBillingCreate = useHasPermission(BILLING_RIGHTS_WITH_CREATE_PERMISSION)

    return (
        <SkyNetTable
            createForm={hasBillingCreate
                ? BillingCompanyCreate
                : undefined}
            name={requests.domainName}
            tabs={tabs}
            tableControls={tableControls}
            tableConfig={tableConfig}
            customUrl={requests.filterRequest().url}
            showSearch
        />
    )
}

export default BillingCompanies
