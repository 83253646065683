import React, {
    useCallback,
} from 'react'
import {
    useDispatch,
} from 'react-redux'
import {
    v1 as uuidv1,
} from 'uuid'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import barcodeServiceApi from 'app/services/barcodeServiceApi'
import downloadDocument from 'app/utils/downloadDocument'
import {
    failedToLoadSaveForm,
    startToSaveForm,
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'

import {
    Container,
} from '../../../containers.types'
import PrintLabelOverview from './PrintLabelOverview'

type Props = {
    value: Pick<Container, 'serialNumber' | 'containerType'>
}

const PrintLabelOverviewContainer = ({
    value: {
        serialNumber,
        containerType: {
            containerCode,
        },
    },
}: Props) => {
    const token = useJWTToken()
    const dispatch = useDispatch()
    const containerSerialNumber = serialNumber.slice(4)

    const printLabel = useCallback(() => {
        const id = uuidv1()

        dispatch(
            startToSaveForm({
                id,
                title: 'Please stand by while we’re preparing the barcodes.',
            }),
        )
        barcodeServiceApi({
            method: 'GET',
            token,
            responseType: 'arraybuffer',
            params: {
                containerSerialNumberTo: containerSerialNumber,
                containerSerialNumberFrom: containerSerialNumber,
                containerCode,
            },
        }).then((response) => {
            downloadDocument(
                response,
                `labels_${containerCode}_${containerSerialNumber}-${containerSerialNumber}`,
            )
            dispatch(
                successedToSaveForm({
                    id,
                    title: 'The label(s) have been generated and downloaded successfully',
                }),
            )
        }).catch((err) => {
            const enc = new TextDecoder('utf-8')

            dispatch(
                failedToLoadSaveForm({
                    id,
                    errors: err.response ? JSON.parse(enc.decode(err.response.data)) : err,
                }),
            )
        })
    }, [
        containerCode,
        containerSerialNumber,
        dispatch,
        token,
    ])

    return (
        <PrintLabelOverview
            printLabel={printLabel}
        />
    )
}

export default PrintLabelOverviewContainer
