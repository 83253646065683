import {
    FieldInCard,
} from 'app/types/form.types'
import {
    LoggerFormData,
    LoggerValue,
} from './LoggerForm.types'

type LoggerFormField = FieldInCard<keyof LoggerFormData>

const fields = ({
    loadOptions,
    loadLabel,
    value: {
        positionConfiguration,
    },
    loggerLabels,
}: {
    loadOptions: any,
    loadLabel: any,
    value: LoggerValue,
    loggerLabels: any,
}): LoggerFormField[] => {
    const allowedLoggerPositionValues = positionConfiguration
        ? positionConfiguration.reduce((acc, name) => {
            return {
                ...acc,
                [name]: loggerLabels[name] || name,
            }
        }, {}) : {}

    return [{
        id: 'logger',
        fields: [
            {
                label: 'Logger',
                componentName: 'TypeaheadObjectContainer',
                labelKey: 'LoggerInformationDto.logger',
                name: 'logger',
                className: 'internalLogger',
                loadLabel,
                loadOptions,
                labelField: 'loggerNumber',
                required: true,
            },
            {
                name: 'isDefaultLogger',
                className: 'isDefaultLogger',
                labelKey: 'LoggerInformationDto.defaultLogger',
                componentName: 'Checkbox',
            },
            {
                name: 'exchangeDocumentations',
                labelKey: 'LoggerInformationDto.exchangeDocumentations',
                className: 'exchangeDocumentations',
                componentName: 'AttachmentsObjectSelector',
            },
            {
                name: 'exchangeTimestamp',
                className: 'exchangeTimestamp',
                componentName: 'DateTimeZonedSelect',
                required: true,
            },
            {
                name: 'removedOn',
                componentName: 'DateTimeZonedSelect',
                labelKey: 'LoggerContainerInformation.removedOn',
            },
            {
                name: 'loggerPositionConfiguration',
                required: true,
                useDropdown: true,
                componentName: 'EnumSelector',
                allowedValues: allowedLoggerPositionValues,
                labelKey: 'LoggerInformationDto.loggerPositionConfigurationName',
            },
            {
                name: 'exchangeComment',
                className: 'exchangeComment',
                componentName: 'LongText',
                rows: 5,
            },
            {
                noLabel: true,
                name: 'linkToCreate',
                componentName: 'LinkTo',
                link: '/apps/loggers/all/create',
                value: 'Logger Missing? Add Here',
            },
        ],
    }]
}

export default fields
