import React from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequiredContainersCustomer, RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'

import List from '../List'

type Props = {
    value?: (RequiredContainersInternal | RequiredContainersCustomer)[],
    configName: string,
    mapCellProps: string,
}

const defaultProps = {
    value: [],
}

const RequiredContainersList = (props: Props) => {
    const {
        value,
        configName,
        mapCellProps,
    } = props

    const fieldConfig = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })

    const allowedValues = useEnumValues()(fieldConfig?.allowedValuesEnum)

    const labels = value.map((item: RequiredContainersCustomer | RequiredContainersInternal) => {
        const label = allowedValues[(item as RequiredContainersCustomer).productType]
            || (item as RequiredContainersInternal).containerType?.containerModel
        const {
            quantity,
        } = item

        return `${quantity} x ${label}`
    })

    if (!value.length) {
        return null
    }

    return (
        <List value={labels} />
    )
}

RequiredContainersList.defaultProps = defaultProps

export default RequiredContainersList
