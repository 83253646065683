import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import request from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LaneStatus,
} from 'app/types/enums'

const useLaneUpdate = ({
    id,
    onSuccess,
}: {
    onSuccess: () => void,
    id: number,
}) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'LaneLogistics',
        title: 'Update Lane',
    })

    return useMutation((data: Lane) => {
        return request.Lane.mutationFn({
            id,
            data: {
                ...data,
                laneStatus: LaneStatus.IMPLEMENTED,
            },
            token,
        })
    }, {
        onSuccess,
        onError,
    })
}

export default useLaneUpdate
