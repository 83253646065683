import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from 'react-query'
import {
    useCallback,
} from 'react'

import {
    LaneDetails,
} from 'app/Apps/Pricing/Pricing.types'
import laneRequests from '../Lane.requests'

export default (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getContractBasisLaneGroupLaneDetails])
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<LaneDetails>({
        key: RequestKeys.getContractBasisLaneGroupLaneDetails,
        params: {
            laneId: id,
        },
        requestFunc: RequestFn.getEntity(laneRequests.getLane),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}
