import React, {
    useCallback,
    useMemo,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    ContainerCode,
    ContainerType,
} from 'app/types/enums'
import {
    Event,
} from 'app/types/common.types'
import useGetContainerTypes from 'app/Apps/ContainerTypes/hooks/useGetContainerTypes'
import StatusHandler from 'app/shared-components/StatusHandler'

import mapContainerTypes from './mapContainerTypes'
import useStyles from './ContainerTypeSelection.style'
import ContainerTypeSelectionItem from './ContainerTypeSelectionItem'
import {
    ContainerTypeItem,
    SelectedContainerType,
} from '../co2simulations.types'

type Props = {
    value?: SelectedContainerType,
    onChange: (newVal: SelectedContainerType, event: Event<SelectedContainerType>) => void,
    name: string,
}

const defaultProps = {
    value: undefined,
}

const ContainerTypeSelection = ({
    value,
    onChange,
    name,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContainerTypes()
    const allowedValues = getAllowedValues(ContainerType)

    const selectedContainerTypeCode: ContainerCode = useMemo(() => {
        return value?.id && containerTypes.find(({
            id,
        }) => {
            return id === value.id
        })?.containerCode
    }, [
        containerTypes,
        value,
    ])

    const handleChange = useCallback((
        item: ContainerTypeItem,
        isFormChange: boolean = false,
    ): void => {
        const selectedContainerType = containerTypes.find(({
            containerCode,
        }) => {
            return containerCode === item.containerCode
        })

        if (selectedContainerType.id === value?.id && !isFormChange) {
            onChange(null, {
                target: {
                    value: null,
                    name,
                },
            })
            return
        }

        onChange({
            id: selectedContainerType.id,
            containerAmount: item.containerAmount,
        }, {
            target: {
                value: {
                    id: selectedContainerType.id,
                    containerAmount: item.containerAmount,
                },
                name,
            },
        })
    }, [
        containerTypes,
        value,
        onChange,
        name,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ul
                className={classes.container}
            >
                {
                    mapContainerTypes.map((listContainerType) => {
                        const containerType = containerTypes?.find(({
                            containerCode,
                        }) => {
                            return containerCode === listContainerType.containerCode
                        })

                        if (!containerType) return null

                        return (
                            <ContainerTypeSelectionItem
                                key={`Container-selection-${listContainerType.containerCode}`}
                                value={{
                                    ...listContainerType,
                                    title: `SkyCell ${allowedValues[containerType.containerModelInternalDry]}`,
                                    containerAmount: value?.containerAmount,
                                }}
                                selectedContainerTypeCode={selectedContainerTypeCode}
                                onChange={handleChange}
                            />
                        )
                    })
                }
            </ul>
        </StatusHandler>
    )
}

ContainerTypeSelection.defaultProps = defaultProps

export default ContainerTypeSelection
