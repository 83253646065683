import {
    useEffect, useMemo,
} from 'react'
import useRequestTable from 'app/hooks/useRequestTable'
import isEmpty from 'lodash/isEmpty'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/AccountManagement/Opportunities/opportunities.request'
import {
    OpportunityBulkEditType, OpportunityType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    FilterOptions,
} from 'app/hooks/useFilter'
import useFirstLoading from 'app/hooks/useFirstLoading'

export default (filterOptions?: FilterOptions) => {
    const {
        isDataReady,
        refetch,
        data: response,
        isError,
        error,
        isFetching,
    } = useRequestTable<{data: OpportunityBulkEditType[]}>({
        key: RequestKeys.getOpportunities,
        params: {
            includeFilters: isEmpty(filterOptions?.filter) ? undefined : filterOptions?.filter,
        },
        config: requests.getAll,
        keepPreviousData: true,
        enabled: !isEmpty(filterOptions?.filter),
    })

    const data = useMemo(() => {
        return (response?.data || []).map((item) => {
            return {
                account: item.accountId ? {
                    id: item.accountId,
                    label: item.accountName,
                } : undefined,
                regionalGroup: item?.regionalGroupId ? {
                    id: item.regionalGroupId,
                    label: item.regionalGroupNumber,
                } : undefined,
                originRegion: item.regionalGroupOriginRegion,
                destinationRegion: item.regionalGroupDestinationRegion,
                temperatureRange: item.temperatureRange,
                frequency: item.frequency,
                airline: item.airlineId ? {
                    id: item.airlineId,
                    label: item.airlineName,
                } : undefined,
                businessStart: item.businessStart,
                reprOriginAirport: item.reprOriginAirportId ? {
                    id: item.reprOriginAirportId,
                    label: item.reprOriginAirport,
                } : undefined,
                reprDestinationAirport: item.reprDestinationAirportId ? {
                    id: item.reprDestinationAirportId,
                    label: item.reprDestinationAirport,
                } : undefined,
                annualPalletVolume: item.annualPalletVolume,
                competitorsPackaging: item.competitorsPackaging,
                currentPackagingSolution: item.currentPackagingSolution,
                palletType: item.palletType,
                validationNeeded: item.validationNeeded,
                id: item.id,
                existNonCanceledLanes: item.existNonCanceledLanes,
            }
        })
    }, [response?.data]) as unknown as OpportunityType[]

    useEffect(() => {
        if (isDataReady) {
            filterOptions?.setFilteredData(data)
        }
    }, [
        data,
        filterOptions,
        isDataReady,
    ])

    const loaded = useFirstLoading(isFetching)

    return useMemo(() => {
        return {
            isError,
            loaded,
            error,
            isDataReady,
            refetch,
            data,
        }
    }, [
        data,
        error,
        isDataReady,
        isError,
        loaded,
        refetch,
    ])
}
