import React, {
    useMemo,
} from 'react'
import Card from 'app/shared-components/Card'
import {
    AccountLane,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import SimpleTable, {
    RowComponent,
} from 'app/shared-components/SimpleTable'
import {
    Column,
} from 'app/types/simpletable.types'
import useLabel from 'app/hooks/useLabel'
import getColumns, {
    QuoteLane,
} from './AssignPricingLanes.columns'
import useStyles from './AssignPricingLanes.styles'

const AssignPricingLanes = ({
    lanes,
    onSelect,
}: {
    lanes: AccountLane[],
    onSelect: (...args: any[]) => void
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()

    const columns = useMemo((): Column<keyof QuoteLane>[] => {
        return getColumns({
            classes,
            l,
            handleChange: onSelect,
        })
    }, [
        onSelect,
        classes,
        l,
    ])

    return (
        <Card
            title="Lanes"
            className={classes.container}
        >
            <SimpleTable
                name="QuoteOpportunitiesTable"
                columns={columns}
                data={lanes}
                component={RowComponent}
                disabled
                classNames={{
                    tableCellHead: classes.tableCellHead,
                    tableCell: classes.tableCellHead,
                }}
            />
        </Card>
    )
}

export default AssignPricingLanes
