import React from 'react'

import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import Overview from './Overview'
import tableConfig from './regionalGroups.config'
import request from './regionalGroups.request'
import UpdateData from './UpdateData'
import Opportunities from './Opportunities'

const tabs: SidebarTab[] = [
    {
        label: 'Overview',
        url: 'overview',
        Component: Overview,
    },
    {
        label: 'Update Data',
        url: 'update',
        Component: UpdateData,
    },
    {
        label: 'Opportunities',
        url: 'opportunities',
        Component: Opportunities,
    },
]

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const RegionalGroups = () => {
    return (
        <SkyNetTable
            name={tableConfig.name}
            moduleName={request.domainName}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

export default RegionalGroups
