import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import config from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/utils/OrderNotifications.config'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import {
    ResponseMutation,
} from 'app/types/request.types'

export enum Count {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
}

type RequestParams = {
    orderNumber: string,
    notificationId: number,
    count: Count,
}

const useTriggerNotifications = (
    onSuccess: () => void,
    onError: () => void,
): ResponseMutation<RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessTrigger,
        onError: onErrorTrigger,
    } = useHandleStatusRequest({
        id: 'Notification',
        title: 'Notification updated',
        onSuccess,
        onError,
    })

    return useMutation(({
        orderNumber,
        notificationId,
        count,
    }: RequestParams) => {
        return config.TriggerNotifications.mutationFn({
            orderNumber,
            notificationId,
            count,
            token,
        })
    }, {
        onSuccess: onSuccessTrigger,
        onError: onErrorTrigger,
    })
}

export default useTriggerNotifications
