import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    Container,
} from 'app/Apps/Containers/containers.types'
import config from './containerSelector.config'

const useGetContainer = (
    id,
) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Container>({
        key: RequestKeys.getContainer,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetContainer
