import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AccountQuote,
} from '../../AccountQuotes.types'
import requests from '../../AccountQuotes.requests'

export default ({
    onSuccess,
    setIsEdited,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessCreateAccountQuote,
        onError,
    } = useHandleStatusRequest({
        title: 'New account quote was created',
        id: 'AccountQuoteCreated',
        onSuccess,
    })

    const {
        mutate: createAccountQuote,
    } = useMutation((accountQuote: AccountQuote) => {
        return simpleApi({
            ...requests.create({
                data: accountQuote,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessCreateAccountQuote,
    })

    return useCallback((accountQuote: AccountQuote) => {
        setIsEdited(false)
        createAccountQuote(accountQuote)
    }, [
        createAccountQuote,
        setIsEdited,
    ])
}
