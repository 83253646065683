import {
    useCallback,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from 'react-query'
import {
    TemperatureRange,
} from 'app/types/enums'
import AccountManagementRequests from '../AccountManagement.request'

const useGetAccountOptions = (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getAccountOptions])
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<{
        originRegion: string[],
        destinationRegion: string[],
        tempRange: TemperatureRange[]
    }>({
        key: RequestKeys.getAccountOptions,
        params: {
            accountId: id,
        },
        requestFunc: RequestFn.getEntity(AccountManagementRequests.getRegionalGroupsByAccount),
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetAccountOptions
