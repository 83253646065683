import {
    CheckboxCellTemplate, Compatible, CheckboxCell, UncertainCompatible,
} from '@silevis/reactgrid'

export default class CustomCheckboxCellTemplate extends CheckboxCellTemplate {
    update(cell: Compatible<CheckboxCell>, cellToMerge: UncertainCompatible<CheckboxCell>):
        Compatible<CheckboxCell> {
        return this.getCompatibleCell({
            ...cell, checked: !!cellToMerge.value,
        })
    }
}
