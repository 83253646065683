import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    App,
} from 'app/types/common.enums'

import useStyles from './Dashboard.style'
import newQuotesConfig from './config/newQuotes.config'
import beingPricedConfig from './config/beingPriced.config'
import pricingCompletedConfig from './config/completed.config'
import lanesConfig from './config/lanes.config'
import dashboardRequest from './Dashboard.requests'

const DOMAIN_NAME = 'Dashboard'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Dashboard = () => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()

    const onRowClick = useCallback(({
        id: idQuote,
    }: {id: number}) => {
        return history.push(`/apps/${App.PRICING}/quotes/all/edit/${idQuote}/overview`)
    }, [history])

    const onLaneRowClick = useCallback(({
        id: idLane,
    }: {id: number}) => {
        return history.push(`/apps/${App.ACCOUNT_MANAGEMENT}/lanes/all/edit/${idLane}/overview`)
    }, [history])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Quotes
                <div className={classes.actionWrapper} />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.newQuotes}
                    tableConfig={newQuotesConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.pricingBeingPriced}
                    tableConfig={beingPricedConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.pricingCompleted}
                    tableConfig={pricingCompletedConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>
                Lanes
                <div className={classes.actionWrapper} />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    moduleName="Lanes"
                    onRowClick={onLaneRowClick}
                    customUrl={dashboardRequest.url.lanes}
                    tableConfig={lanesConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
        </div>
    )
}

export default Dashboard
