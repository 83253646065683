import omit from 'lodash/omit'

import {
    OrderTypes,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    InternalOrderForm,
} from './createInternalTransportForm.types'

const modifyData = (requestData: InternalOrderForm) => {
    const data = {
        ...requestData,
        orderType: OrderTypes.INTERNAL_TRANSPORT,
        previousOrders: requestData.previousOrders?.map((id) => {
            return {
                id,
            }
        }),
    }

    if (data.generalFreight) {
        data.generalFreight = data.generalFreight.map((item) => {
            return omit(item, ['id'])
        })
    }

    return omit(data, [
        'location',
        'selectPreviousOrders',
    ])
}

export default modifyData
