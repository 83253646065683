import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'

export default ({
    onSuccess,
}: {
    onSuccess: (...args: any[]) => void,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessLaneStatusUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Lane was rejected',
        id: 'RejectLane',
        onSuccess,
    })

    const {
        mutate: reject,
    } = useMutation((id: number) => {
        return simpleApi({
            ...requests.reject({
                id,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessLaneStatusUpdate,
    })

    return useCallback((id: number) => {
        reject(id)
    }, [reject])
}
