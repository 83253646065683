import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import moment from 'moment'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    DeliveryServiceType,
    OrderTypes,
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    ProductType,
} from 'app/types/enums'

import getFields from './newCustomerTransport.fields'
import useStyles from './NewCustomerTransport.style'
import config from './newCustomerTransport.request'
import {
    CustomerTransportRequestData,
    CustomerTransportCreateData,
    Selection,
} from './newCustomerTransport.types'
import useOnSuccessCreateOrder from '../../hooks/useOnSuccessCreateOrder'
import useGetLaneOpsInformation from '../hooks/useGetLaneOpsInformation'

const NewCustomerTransport = ({
    name,
}: {name: string}) => {
    const [
        value,
        setValue,
    ] = useState<CustomerTransportCreateData>({})
    const [
        primaryLane,
        setPrimaryLane,
    ] = useState<Lane>()
    const [
        dependentData,
        setDependentData,
    ] = useState<Partial<CustomerTransportCreateData>>({})
    const getAllowedValues = useEnumValues()

    const {
        classes,
    } = useStyles()

    const {
        selectedLaneId,
        originScSelection,
        requestedPickup,
        requestedDelivery,
        leaseStartTimestamp,
        fwdPosSelection,
        transportModeForwarder,
        numberOfTrucks,
        numberOfVans,
        isPerformanceQualification,
    } = value

    const {
        lane: selectedLane,
    } = useGetLaneOpsInformation({
        selectedLaneId,
    })

    const {
        data: allowedProductTypes,
    } = useRequest<ProductType[]>({
        key: RequestKeys.getAllowedProductTypes,
        params: {
            selectedLaneId,
            leaseStartTimestamp,
            isPerformanceQualification,
        },
        requestFunc: RequestFn.getEntity(config.AllowedProductTypes.requestFn),
        enabled: Boolean(leaseStartTimestamp),
    })

    const defaultTimeZone: string = useMemo(() => {
        const chars = {
            PLUS_: '+',
            MINUS_: '-',
        }

        const timeZone = selectedLane?.handoverPoint?.timeZone

        return timeZone?.replace(/PLUS_|MINUS_/g, (x) => {
            return chars[x]
        })
    }, [selectedLane])

    const isDeliveryRoad: boolean = useMemo(() => {
        return selectedLane?.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    }, [selectedLane?.deliveryServiceType])

    const fields = useMemo(() => {
        return getFields({
            value,
            selectedLane,
            isDeliveryRoad,
            defaultTimeZone,
            allowedProductTypes,
            getAllowedValues,
        })
    }, [
        allowedProductTypes,
        defaultTimeZone,
        isDeliveryRoad,
        selectedLane,
        value,
        getAllowedValues,
    ])

    useEffect(() => {
        if (selectedLaneId && selectedLane && selectedLane !== primaryLane) {
            setValue({
                ...value,
                consignee: selectedLane.consignee,
                customerExecution: selectedLane.customerExecution,
                customerExecutionContactsOps: selectedLane.customerExecutionContactsOps,
                destinationAirport: selectedLane.destinationAirport,
                handoverPoint: selectedLane.handoverPoint,
                originAirport: selectedLane.originAirport,
                collectionDropoffPoint: selectedLane.collectionDropoffPoint,
                account: selectedLane.account,
                temperatureRange: selectedLane.tempRange,
                transportModeRoad: selectedLane.transportModeRoad === TransportModeRoad.LTL
                    ? TransportModeRoad.LTL
                    : TransportModeRoad.FTL,
                transportModeForwarder:
                    selectedLane.transportModeRoad === TransportModeRoad.LTL
                        ? TransportModeRoad.LTL
                        : TransportModeRoad.FTL,
                orderEntered: moment().format('DD.MM.YYYY HH:mm ZZ'),
                originScSelection: Selection.DEFAULT,
                fwdPosSelection: Selection.DEFAULT,
                originServiceCenter: selectedLane.originSc,
                forwarder: selectedLane.fwdPos,
                laneCommentOps: selectedLane.laneCommentOps,
            })

            setPrimaryLane(selectedLane)
            setDependentData({
                originScSelection: Selection.DEFAULT,
                fwdPosSelection: Selection.DEFAULT,
            })
        }
    }, [
        primaryLane,
        selectedLane,
        selectedLaneId,
        value,
    ])

    useEffect(() => {
        if (
            Boolean(isPerformanceQualification)
            !== Boolean(dependentData.isPerformanceQualification)
        ) {
            setValue({
                ...value,
                requiredContainers: [],
            })
            setDependentData({
                ...dependentData,
                isPerformanceQualification,
            })
        }
    }, [
        dependentData,
        isPerformanceQualification,
        value,
    ])

    useEffect(() => {
        if (!selectedLaneId || !selectedLane) {
            return
        }

        if (originScSelection !== dependentData.originScSelection) {
            const newValueOriginServiceCenter = originScSelection === Selection.DEFAULT
                ? selectedLane.originSc
                : selectedLane.originScBackup

            setValue({
                ...value,
                originServiceCenter: newValueOriginServiceCenter,
            })
            setDependentData({
                ...dependentData,
                originScSelection,
            })
        }

        if (fwdPosSelection !== dependentData.fwdPosSelection) {
            const newValueForwarder = fwdPosSelection === Selection.DEFAULT
                ? selectedLane.fwdPos
                : selectedLane.fwdPosBackup

            setValue({
                ...value,
                forwarder: newValueForwarder,
            })
            setDependentData({
                ...dependentData,
                fwdPosSelection,
            })
        }

        if (transportModeForwarder === TransportModeRoad.LTL && (numberOfTrucks || numberOfVans)) {
            setValue({
                ...value,
                numberOfTrucks: undefined,
                numberOfVans: undefined,
            })
        }
    }, [
        value,
        dependentData,
        selectedLane,
        originScSelection,
        selectedLane?.originSc,
        selectedLane?.originScBackup,
        fwdPosSelection,
        selectedLane?.fwdPos,
        selectedLane?.fwdPosBackup,
        selectedLaneId,
        transportModeForwarder,
        numberOfTrucks,
        numberOfVans,
    ])

    useEffect(() => {
        if (leaseStartTimestamp) {
            return
        }
        if (requestedDelivery !== dependentData.requestedDelivery) {
            setDependentData({
                ...dependentData,
                requestedDelivery,
            })
            setValue({
                ...value,
                leaseStartTimestamp: requestedDelivery,
            })
        }
        if (requestedPickup !== dependentData.requestedPickup) {
            setDependentData({
                ...dependentData,
                requestedPickup,
            })
            setValue({
                ...value,
                leaseStartTimestamp: requestedPickup,
            })
        }
    }, [
        dependentData,
        leaseStartTimestamp,
        requestedDelivery,
        requestedPickup,
        value,
    ])

    const fieldsWithClasses = useFieldsWithClassName(fields, classes)

    const modifyDataBeforeSend = useCallback((requestData: CustomerTransportCreateData)
        : CustomerTransportRequestData => {
        const commonData: CustomerTransportRequestData = {
            customerReference: requestData.customerReference,
            leaseStartTimestamp: requestData.leaseStartTimestamp,
            transportModeRoad: requestData.transportModeRoad,
            transportModeForwarder: requestData.transportModeForwarder,
            lane: {
                id: requestData.selectedLaneId,
            },
            commentForBillingOps: requestData.commentForBillingOps,
            orderEntered: requestData.orderEntered,
            requiredCargoComment: requestData.requiredCargoComment,
            orderReceived: requestData.orderReceived,
            mawbNumber: requestData.mawbNumber,
            hawbNumber: requestData.hawbNumber,
            personInCharge: requestData.personInCharge,
            requiredContainers: requestData.requiredContainers,
            freightForwarder: requestData.freightForwarder,
            isPerformanceQualification: requestData.isPerformanceQualification,
        }

        return isDeliveryRoad
            ? {
                ...commonData,
                estimatedPickup: requestData.estimatedPickup,
                fwdPosSelection: requestData.fwdPosSelection,
                numberOfTrucks: requestData.numberOfTrucks,
                numberOfVans: requestData.numberOfVans,
                originScSelection: requestData.originScSelection,
                requestedDelivery: requestData.requestedDelivery,
            }
            : {
                ...commonData,
                requestedPickup: requestData.requestedPickup,
            }
    }, [isDeliveryRoad])

    const onSuccess = useOnSuccessCreateOrder(OrderTypes.CUSTOMER_TRANSPORT)

    const onCancel = useCallback(() => {
        setValue({})
        setDependentData({})
        setPrimaryLane(null)
    }, [])

    return (
        <FormWithControls
            name={name}
            requestParams={{
                url: 'order/customer-transport',
            }}
            value={value}
            setExternalValue={setValue}
            fields={fieldsWithClasses}
            classNames={{
                gridWrapper: selectedLaneId ? classes.gridWrapperSelectedLane : classes.gridWrapper,
            }}
            modifyDataBeforeSend={modifyDataBeforeSend}
            onSuccess={onSuccess}
            onCancel={onCancel}
            createEnabled={Boolean(selectedLaneId)}
        />
    )
}

export default NewCustomerTransport
