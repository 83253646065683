import {
    useCallback,
} from 'react'
import {
    Domain, ExtendedCellTypes,
} from '../SkyNetSpreadSheet.types'

export default () => {
    return useCallback((unresolvedValues: Map<Domain, Set<string>>, cell: ExtendedCellTypes) => {
        if ('undeterminedValue' in cell && 'domain' in cell
            && cell.undeterminedValue && Object.values(Domain).includes(cell.domain)) {
            if (unresolvedValues.has(cell.domain)) {
                unresolvedValues.get(cell.domain).add(cell.undeterminedValue)
                return
            }
            unresolvedValues.set(cell.domain, new Set([cell.undeterminedValue]))
        }
    }, [])
}
