/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useState,
    useCallback,
} from 'react'
import omit from 'lodash/omit'
import noop from 'lodash/noop'

import {
    RequestConfig,
} from 'app/types/request.types'
import {
    dateToStr,
    strToDate,
    dateTimeMask,
} from 'app/utils/date'

import useEnumValues from 'app/hooks/useEnumValues'
import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import GatewayRequests from '../Gateways.request'

import {
    Gateway,
} from '../Gateways.types'
import useStyles from './GatewayForm.styles'
import getFields from '../Gateways.fields'

type Props = {
    data?: Gateway,
    onSuccess?: (props: any) => void,
    onCancel?: (...args: any[]) => void,
    requestParam: RequestConfig,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    data: {} as Gateway,
    onSuccess: noop,
    onCancel: noop,
    disabled: false,
}
const GatewayForm = ({
    data,
    onSuccess,
    onCancel,
    requestParam,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const exists = !!data?.id

    const [
        value,
        setValue,
    ] = useState(data)

    const fieldsWithClassName: [] = useFieldsWithClassName(
        getFields({
            getAllowedValues,
            locationId: value?.location?.id || data?.location?.id,
            showContactInformation: exists,
            showConnectionInfo: exists,
            showConnectedLogger: exists,
            disabled,
        }),
        classes,
    )

    const modifyDataBeforeSend = useCallback((newValue) => {
        const result = {
            ...newValue,
            installedOn: newValue.installedOn ? dateToStr(strToDate(newValue.installedOn), dateTimeMask) : '',
        }

        return omit(result, 'gatewayLocationCategories')
    }, [])

    const handleOnChange = useCallback((newValue: Gateway) => {
        if (String(newValue?.location?.id) !== String(data?.location?.id)) {
            if (!(value?.latitude ?? value?.longitude)) {
                setValue({
                    ...newValue,
                    latitude: newValue?.location?.latitude,
                    longitude: newValue?.location?.longitude,
                    area: value?.location?.id !== newValue?.location.id
                        ? undefined : newValue?.area,
                })
                return
            }
            setValue({
                ...newValue,
                area: value?.location?.id !== newValue?.location.id
                    ? undefined : newValue?.area,
            })
            return
        }

        if (newValue?.longitude !== data?.longitude) {
            setValue({
                ...newValue,
                longitude: newValue?.longitude || null,
            })
            return
        }

        if (newValue?.latitude !== data?.latitude) {
            setValue({
                ...newValue,
                latitude: newValue?.latitude || null,
            })

            return
        }

        setValue(newValue)
    }, [
        data,
        value,
    ])

    return (
        <FormWithControls
            setExternalValue={handleOnChange}
            name={GatewayRequests.domainName}
            requestParams={requestParam}
            value={value}
            fields={fieldsWithClassName}
            classNames={{
                gridWrapper: classes.gridWrapperWithLoggers,
            }}
            modifyDataBeforeSend={modifyDataBeforeSend}
            onSuccess={onSuccess}
            exists={exists}
            disabled={disabled}
            onCancel={onCancel}
        />

    )
}

GatewayForm.defaultProps = defaultProps

export default GatewayForm
