import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import config from '../NewCustomerTransport/newCustomerTransport.request'

const useGetLaneOpsInformation = ({
    selectedLaneId,
    enabled = true,
}: {
    selectedLaneId: number,
    enabled?: boolean,
}) => {
    const {
        data: lane,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Lane>({
        key: RequestKeys.getSelectedLaneForNewCustomerTransport,
        params: {
            selectedLaneId,
        },
        requestFunc: RequestFn.getEntity(config.SelectedLane.requestFn),
        enabled: Boolean(selectedLaneId) && enabled,
    })

    return {
        lane,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetLaneOpsInformation
