import React, {
    useCallback,
} from 'react'

import {
    useJWTToken,
} from '@skycell-ag/auth'

import simpleApi from 'app/services/simpleApi'
import DTOSpec from 'app/services/dtoSpec'
import ChipSelect from 'app/shared-components/ChipSelect'

type Props = {
    value?:{
        label: string,
        value: string,
    }[],
    onChange: (value: any[]) =>void,
}

const defaultProps: Partial<Props> = {
    value: [],
}

const OtherLoggersSelect = ({
    value,
    onChange,
}: Props) => {
    const token = useJWTToken()
    const loadOptions = useCallback(({
        phrase = '',
    } = {}) => {
        return simpleApi({
            url: DTOSpec.Logger.Selector,
            method: 'POST',
            token,
            data: {
                searchAll: phrase,
                start: 0,
                rows: 50,
            },
        }).then(({
            items,
        }) => {
            return items.map(({
                loggerNumber, loggerType, allowedSensorPositions,
            }) => {
                return {
                    label: loggerNumber,
                    loggerNumber,
                    loggerType,
                    allowedSensorPositions,
                    value: loggerNumber,
                }
            })
        })
    }, [token])

    return (
        <div data-testid="chip-select-logger">
            <ChipSelect
                title="Logger"
                value={value}
                onChange={onChange}
                loadOptions={loadOptions}
            />
        </div>
    )
}

OtherLoggersSelect.defaultProps = defaultProps

export default OtherLoggersSelect
