import React, {
    useCallback,
} from 'react'

import AccountLaneForm from 'app/Apps/AccountManagement/AccountLanes/AccountLaneForm'
import Button from 'app/shared-components/Button'
import useGetAccountLane from 'app/Apps/AccountManagement/AccountLanes/hooks/useGetAccountLane'
import request from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'
import {
    LaneStatus,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'

import StatusHandler from 'app/shared-components/StatusHandler'

import getLaneToBeGroupedFields from './LaneToBeGroupedUpdate.fields'
import useStyles from './LaneToBeGroupedUpdate.styles'
import useRejectPricingLane from './hooks/useRejectPricingLane'
import useAssignLaneToLaneGroup from './hooks/useAssignLaneToLaneGroup'

const LaneToBeGroupedUpdate = ({
    id,
    refetch,
}: {
    id: number,
    refetch: (...args: any[]) => void
}) => {
    const {
        classes,
    } = useStyles()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAccountLane(id)

    const onSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        invalidate,
        refetch,
    ])

    const reject = useRejectPricingLane({
        id,
        onSuccess,
    })

    const assignToLaneGroup = useAssignLaneToLaneGroup({
        id,
        onSuccess,
    })

    const getCustomButtons = useCallback(({
        isValid,
    }) => {
        return [
            <Button
                key="assing-to-lane-group"
                label="Assign To Group"
                onClick={assignToLaneGroup}
                disabled={!isValid}
            />,
            <Button
                key="reject"
                label="Reject"
                onClick={reject}
                disabled={data?.laneStatus !== LaneStatus.UNDER_REVIEW_PRICING}
            />,
        ]
    }, [
        assignToLaneGroup,
        reject,
        data?.laneStatus,
    ])

    return (
        <div className={classes.formContainer}>
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <AccountLaneForm
                    data={data}
                    requestParam={request.quote({
                        id,
                    })}
                    onSuccess={onSuccess}
                    onCancel={invalidate}
                    getCustomButtons={getCustomButtons}
                    getCustomFields={getLaneToBeGroupedFields}
                    exists
                />
            </StatusHandler>
        </div>
    )
}

export default LaneToBeGroupedUpdate
