import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/Forecast/Forecast.requests'
import {
    ForecastType,
} from '../../Forecast.types'

export default () => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
        isError,
        error,
        isFetching,
    } = useRequestTable<{data: ForecastType[]}>({
        key: RequestKeys.getForecast,
        params: {},
        config: requests.getAll,
        keepPreviousData: true,
        enabled: true,
    })

    return {
        isError,
        isFetching,
        error,
        onParamsChange,
        isDataReady,
        refetch,
        data: data?.data,
        loadData,
    }
}
