import simpleApi from 'app/services/simpleApi'

const getState = (token, countryId, stateName) => {
    if (!countryId || !stateName) {
        return Promise.resolve(null)
    }
    return simpleApi({
        method: 'GET',
        url: `country/${countryId}`,
        token,
    }).then((response) => {
        if (response && response?.states) {
            const state = response?.states.find((currCountry) => {
                const current = currCountry.stateName.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()
                const stateNameToSearch = stateName.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()

                return current.includes(stateNameToSearch)
            })

            return state?.id
        }
        return null
    })
        .catch(() => {
            return null
        })
}

export default getState
