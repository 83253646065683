import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Position,
    PositionType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useStyles from './PositionForm.style'
import getFields from './positionForm.fields'
import useLoadAutoPopulatePosition from './hooks/useLoadAutoPopulatePosition'
import useGetPositionTypeMapping from './hooks/useGetPositionTypeMapping'

type Props = {
    value: Position,
    name: string,
    onSuccess: () => void,
    closeOpenedRow: () => void,
    invoiceId: number,
    disabled?: boolean,
    exists?: boolean,
}

const defaultProps = {
    disabled: false,
    exists: false,
}

const PositionForm = ({
    closeOpenedRow,
    invoiceId,
    onSuccess,
    value,
    disabled,
    exists,
    name,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'autoPopulatePosition',
    })

    const [
        data,
        setData,
    ] = useState<Position>(value)

    const [
        selectedPositionCode,
        setPositionCode,
    ] = useState<string>(null)

    const [
        selectedPositionType,
        setPositionType,
    ] = useState<PositionType>(value?.positionType)

    const {
        positionTypeMapping,
    } = useGetPositionTypeMapping()

    const fields = useMemo(() => {
        const allowedCodesObject = positionTypeMapping[data.positionType]
            || positionTypeMapping.LEASE

        return getFields({
            positionCodeAllowedValues: getAllowedValues(allowedCodesObject),
            getAllowedValues,
        })
    }, [
        data.positionType,
        getAllowedValues,
        positionTypeMapping,
    ])

    const fieldsWithClassName = useFieldsWithClassName(fields, classes)

    const loadAutoPopulatePosition = useLoadAutoPopulatePosition(invoiceId, data)

    useEffect(() => {
        if (data.positionType !== selectedPositionType) {
            setData({
                ...data,
                positionCode: null,
            })
            setPositionType(data.positionType)
        }
    }, [
        data,
        selectedPositionType,
    ])

    useEffect(() => {
        if (selectedPositionCode !== data.positionCode && !exists) {
            loadAutoPopulatePosition()
                .then((res) => {
                    setData({
                        ...data,
                        ...res,
                    })
                })
                .catch(onError)

            setPositionCode(data.positionCode)
        }
    }, [
        data,
        exists,
        loadAutoPopulatePosition,
        onError,
        selectedPositionCode,
    ])

    const onSuccessUpdate = useCallback((): void => {
        onSuccess()
        closeOpenedRow()
    }, [
        closeOpenedRow,
        onSuccess,
    ])

    const requestParams = useMemo(() => {
        return exists ? orderManagementRequest.Position.UPDATE.request({
            invoiceId,
            positionId: value.id,
        }) : orderManagementRequest.Position.CREATE.request({
            invoiceId,
        })
    }, [
        invoiceId,
        exists,
        value.id,
    ])

    return (
        <FormWithControls
            value={data}
            name={name}
            className={classes.rowFormContainer}
            fields={fieldsWithClassName}
            setExternalValue={setData}
            onSuccess={onSuccessUpdate}
            onCancel={closeOpenedRow}
            disabled={disabled}
            requestParams={requestParams}
            exists={exists}
        />
    )
}

PositionForm.defaultProps = defaultProps

export default PositionForm
