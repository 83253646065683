import {
    ContainerCode, ContainerGeneration,
    ContainerVolume, DefaultTemperatureRange, TemperatureRange,
    TemperatureRangeAll,
} from 'app/types/enums'
import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    ChangeDataFields,
} from './ChangeData.types'

const getFields = (getAllowedValues): DTOCardFormField<ChangeDataFields>[] => {
    return [
        {
            id: 'summary',
            title: 'Summary',
            className: 'summary',
            hasColumns: true,
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'containerModel',
                            componentName: 'InputSingleline',
                            disabled: true,
                        },
                        {
                            name: 'containerVolume',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(ContainerVolume),
                            required: true,
                        },
                        {
                            name: 'tareWeight',
                            componentName: 'IntegerInput',
                            required: true,
                        },
                        {
                            name: 'maxGrossWeight',
                            componentName: 'IntegerInput',
                            required: true,
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'containerCode',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(ContainerCode),
                            disabled: true,
                        },
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(TemperatureRange),
                            disabled: true,
                        },
                        {
                            name: 'containerGeneration',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(ContainerGeneration),
                            required: true,
                        },
                        {
                            name: 'isPrototype',
                            componentName: 'Checkbox',
                        },

                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'applicableTempRange',
                            componentName: 'MultiEnumSelector',
                            allowedValues: getAllowedValues(TemperatureRangeAll),
                            disabled: true,
                        },
                        {
                            name: 'defaultTempRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(DefaultTemperatureRange),
                            disabled: true,
                        },
                        {
                            name: 'isInOperationalUse',
                            componentName: 'Checkbox',
                        },
                        {
                            name: 'toleranceRange',
                            componentName: 'IntegerInput',
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [
                        {
                            name: 'isQuotable',
                            componentName: 'Checkbox',
                        },
                        {
                            name: 'containerPicture',
                            componentName: 'Attachment',
                            labelKey: 'ContainerType.containerPicture',
                        },
                    ],
                },
            ],
        },
        {
            id: 'extDimensions',
            title: 'External Dimensions',
            className: 'extDimensions',
            fields: [
                {
                    name: 'extDimLength',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'extDimWidth',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'extDimHeight',
                    componentName: 'IntegerInput',
                    required: true,
                },
            ],
        },
        {
            id: 'intDimensions',
            title: 'Internal Dimensions',
            className: 'intDimensions',
            fields: [
                {
                    name: 'intDimLength',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'intDimWidth',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'intDimHeight',
                    componentName: 'IntegerInput',
                    required: true,
                },
            ],
        },
        {
            id: 'temperatureRange',
            title: 'Temperature Range',
            className: 'temperatureRange',
            fields: [
                {
                    name: 'intTempRangeStart',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'intTempRangeEnd',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'extTempRangeStart',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'extTempRangeEnd',
                    componentName: 'IntegerInput',
                    required: true,
                },
            ],
        },
        {
            id: 'storageRange',
            title: 'Storage Range',
            className: 'storageRange',
            fields: [
                {
                    name: 'extStorageRangeStart',
                    componentName: 'IntegerInput',
                    required: true,
                },
                {
                    name: 'extStorageRangeEnd',
                    componentName: 'IntegerInput',
                    required: true,
                },
            ],
        },
    ]
}

export default getFields
