import React, {
    useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    Storage,
} from '../Storage.types'
import getFields from './StorageForm.fields'
import useStyles from './StorageForm.style'

type StorageFormProps = {
    value?: Storage,
    onChange: (storage: Storage) => void,
}
const defaultProps: Partial<StorageFormProps> = {
    value: {},
}

const StorageForm = ({
    value,
    onChange,
}: StorageFormProps) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
        })
    }, [getAllowedValues])
    const fieldsWithClassName = useFieldsWithClassName(fields, classes)

    return (
        <div
            className={classes.root}
        >
            <DomainObjectForm
                name="ServiceCenter"
                value={value}
                fields={fieldsWithClassName}
                className={classes.gridWrapper}
                onChange={onChange}
            />
        </div>
    )
}

StorageForm.defaultProps = defaultProps

export default StorageForm
