import React from 'react'
import upperCase from 'lodash/upperCase'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'

type Props = {
    title: string,
    openConfirmDialog?: boolean,
    handleClose: (confirm: boolean) => void
    archived?: boolean,
}
const defaultProps: Partial<Props> = {
    openConfirmDialog: true,
    archived: false,
}
const ArchiveConfirmationDialog = ({
    title,
    openConfirmDialog,
    handleClose,
    archived,
}: Props) => {
    return (
        <ConfirmationDialog
            open={openConfirmDialog}
            handleClose={handleClose}
            dialogTitle={`${upperCase(title)} ${archived ? 'RESTORE' : 'ARCHVING'} CONFIRMATION`}
            dialogContent={`Do you want to ${archived ? 'restore' : 'archive'} this ${title}?`}
        />
    )
}

ArchiveConfirmationDialog.defaultProps = defaultProps

export default ArchiveConfirmationDialog
