import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    OpportunityType,
} from '../../opportunities.types'

import requests from '../../opportunities.request'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessBulkCreate,
        onError,
    } = useHandleStatusRequest({
        title: 'New opportunities were created',
        id: 'OpportunitiesBulkCreate',
    })

    const {
        mutateAsync: createOpportunities,
    } = useMutation((opportunities: OpportunityType[]) => {
        return simpleApi({
            ...requests.bulkCreate({
                data: opportunities,
            }),
            token,
        })
    }, {
        onError,
    })

    return useCallback(async (opportunities: OpportunityType[], {
        onSuccess,
    }) => {
        return createOpportunities(opportunities, {
            onSuccess,
        }).then((data) => {
            onSuccessBulkCreate(data)
        })
    }, [
        createOpportunities,
        onSuccessBulkCreate,
    ])
}
