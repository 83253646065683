import React from 'react'
import noop from 'lodash/noop'

import {
    TypeaheadObjectContainer,
} from 'app/shared-components/Typeahead'
import {
    useGetCompany,
} from 'app/Apps/ContactManagement/Companies'
import useGetCompanyRequests from './hooks/useGetCompanyRequests'

type Props = {
    title?: string,
    value?: Record<string, any>,
    disabled?: boolean,
    onChange?: (value: any, ...params: any[]) => void,
    isRequired?: boolean,
}

const defaultProps: Partial<Props> = {
    title: '',
    value: {},
    disabled: false,
    onChange: noop,
    isRequired: false,
}

// this components accepts company as object and
// uses TypeaheadObject and passes entire object in onChange method instead of id
// uses react-query instead of simpleApi
const CompanyDataSelector = ({
    title,
    value,
    onChange,
    disabled,
    isRequired,
}: Props) => {
    const {
        data,
    } = useGetCompany(value?.id)

    const {
        loadOptions,
    } = useGetCompanyRequests()

    return (
        <div>
            <TypeaheadObjectContainer
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={data}
                labelField="companyName"
                value={value?.id}
                variant="standard"
                disabled={disabled}
                title={title}
                isRequired={isRequired}
            />
        </div>
    )
}

CompanyDataSelector.defaultProps = defaultProps

export default CompanyDataSelector
