import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    InvoiceDesignation,
} from 'app/types/enums'
import {
    OutgoingInvoice,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const useCreateInvoice = ({
    orderNumber,
    onSuccess,
    onError,
}: {
    orderNumber: string,
    onSuccess: (resp: OutgoingInvoice) => void,
    onError: () => void,
}) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRequest,
        onError: onErrorRequest,
    } = useHandleStatusRequest({
        id: 'CreateInvoice',
        onSuccess,
        onError,
    })

    return useMutation((data: {
        invoiceDesignation: InvoiceDesignation
    }) => {
        return orderManagementRequest.Invoices.Create.mutationFn({
            orderNumber,
            token,
            data,
        })
    }, {
        onSuccess: onSuccessRequest,
        onError: onErrorRequest,
    })
}

export default useCreateInvoice
