import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from 'react-query'

import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    TableResponse,
} from 'app/types/request.types'

import {
    NotificationTableView, SentStatus,
} from '../utils/OrderNotifications.types'
import config from '../utils/OrderNotifications.config'

export default ({
    url, includeFilters,
}: {
    url: string,
    includeFilters: {
        orderId?: number[],
        triggerType?: string[],
        isSentOnContainerSelection?: boolean[]
    }
}) => {
    const queryClient = useQueryClient()

    const refetchInterval = useCallback((resp): number | false => {
        const isReFetch = resp?.data?.some(({
            sentFirstStatus,
        }) => {
            return sentFirstStatus === SentStatus.IN_PROGRESS
        })

        return isReFetch && 10000
    }, [])

    const {
        onParamsChange,
        refetch,
        data,
    } = useRequestTable<TableResponse<NotificationTableView>>({
        defaultValue: {
            filter: {
                ...includeFilters,
            },
            sort: {
                field: 'notificationName',
                direction: 'asc',
            },
        },
        config: config.OrderNotifications.requestFn,
        params: {
            url,
        },
        key: RequestKeys.getOrderNotifications,
        refetchInterval,
    })

    const load = useCallback((): Promise<TableResponse<NotificationTableView>> => {
        if (!data) {
            return Promise.resolve({
                data: [],
            })
        }
        return Promise.resolve(data)
    }, [data])

    const refresh = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getOrderNotifications])
    }, [queryClient])

    return {
        onParamsChange,
        load,
        refetch,
        refresh,
    }
}
