import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import requests from '../orderComment.request'

export default ({
    onSuccess,
}: { onSuccess?: () => void }) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdateCompany,
        onError,
    } = useHandleStatusRequest({
        title: 'Comment was successfully removed',
        id: 'CommentRemoved',
        onSuccess,
    })

    const {
        mutate: deleteComment,
    } = useMutation((id: number) => {
        return simpleApi({
            ...requests.DeleteComment({
                id,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessUpdateCompany,
    })

    return useCallback((id: number) => {
        deleteComment(id)
    }, [deleteComment])
}
