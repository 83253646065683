import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LocationType,
    LaneGroupTemperatureRange,
    OpportunityFrequency,
    OpportunityStatus,
    OpportunityPalletType,
} from 'app/types/enums'
import {
    RegionFirstLevel,
} from 'app/Apps/Masterdata/Countries/Countries.types'
import {
    OpportunityType,
    Packaging,
} from '../opportunities.types'

export type OpportunityFields = OpportunityType & {
    'reprDestinationAirport.destinationCity': string,
    'reprOriginAirport.originCity': string,
}

const getFields = (
    {
        getAllowedValues,
        originRegion,
        destinationRegion,
        disableRegionalGroup,
        disabled,
    }: {
        getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
        originRegion: RegionFirstLevel,
        destinationRegion: RegionFirstLevel,
        disableRegionalGroup: boolean,
        disabled: boolean,
    },
): DTOCardFormField<keyof OpportunityFields>[] => {
    return [
        {
            id: 'generalInformation',
            title: 'General Information',
            className: 'generalInformation',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'opportunityNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'Opportunity.opportunityNumber',
                            disabled: true,
                        },
                        {
                            name: 'regionalGroup',
                            componentName: 'RegionalGroupSelector',
                            labelKey: 'RegionalGroups.regionalGroupNumber',
                            disabled: disabled || disableRegionalGroup,
                            required: true,
                        },
                        {
                            name: 'accountName',
                            componentName: 'InputSingleline',
                            labelKey: 'Opportunities.accountName',
                            disabled: true,
                        },
                        {
                            name: 'opportunityStatus',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OpportunityStatus),
                            labelKey: 'AccountQuotes.laneStatus',
                            disabled: true,
                            useDropdown: true,
                        },
                        {
                            name: 'opportunityComment',
                            componentName: 'LongText',
                            labelKey: 'Opportunity.opportunityComment',
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                            useDropdown: true,
                            required: true,
                            disabled: true,
                            labelKey: 'Opportunities.temperatureRange',
                        },
                        {
                            name: 'frequency',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OpportunityFrequency),
                            useDropdown: true,
                            labelKey: 'Opportunity.frequency',
                        },
                        {
                            name: 'airline',
                            componentName: 'CompanyObjectSelector',
                            labelKey: 'Opportunity.airline',
                        },
                        {
                            name: 'businessStart',
                            componentName: 'DateTimeSelect',
                            labelKey: 'Opportunity.businessStart',
                        },
                    ],
                },
            ],
        },
        {
            id: 'origin',
            title: 'Origin / Destination',
            className: 'origin',
            fields: [{
                id: 'column1',
                fields: [
                    {
                        name: 'originRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.originRegion',
                        disabled: true,
                    },
                    {
                        name: 'reprOriginAirport',
                        componentName: 'LocationObjectSelector',
                        filterLocationType: LocationType.AIRPORT,
                        includeFilters: {
                            regionFirstLevel: originRegion ? [originRegion] : undefined,
                        },
                        labelKey: 'Opportunity.reprOriginAirport',
                        required: true,
                        disabled: disabled || !originRegion,
                    },
                    {
                        name: 'destinationRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.destinationRegion',
                        disabled: true,
                    },
                    {
                        name: 'reprDestinationAirport',
                        componentName: 'LocationObjectSelector',
                        includeFilters: {
                            regionFirstLevel: destinationRegion ? [destinationRegion] : undefined,
                        },
                        filterLocationType: LocationType.AIRPORT,
                        labelKey: 'Opportunity.reprDestinationAirport',
                        required: true,
                        disabled: disabled || !destinationRegion,
                    },
                ],
            }],
        },
        {
            id: 'containerInformation',
            title: 'Container Information',
            className: 'containerInformation',
            fields: [
                {
                    name: 'annualPalletVolume',
                    componentName: 'IntegerInput',
                    labelKey: 'Opportunity.annualPalletVolume',
                    required: true,
                },
                {
                    name: 'competitorsPackaging',
                    componentName: 'EnumChipSelector',
                    allowedValues: getAllowedValues(Packaging),
                    required: true,
                    labelKey: 'Opportunity.competitorsPackaging',
                },
                {
                    name: 'currentPackagingSolution',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(Packaging),
                    required: true,
                    labelKey: 'Opportunity.currentPackagingSolution',
                },
                {
                    name: 'palletType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(OpportunityPalletType),
                    useDropdown: true,
                    required: true,
                    labelKey: 'Opportunity.palletType',
                },
                {
                    name: 'validationNeeded',
                    componentName: 'Checkbox',
                    labelKey: 'Opportunity.validationNeeded',
                },
            ],
        },
    ]
}

export default getFields
