export enum ActivityRecordType {
    MEETING = 'Meeting',
    CALL = 'Call'
}

export type ActivityRecordTable = {
    id: number,
    changedOn: string,
    companyName: string,
    organizer: string,
    participants: string[],
    subject: string,
    type: ActivityRecordType,
    happenedOn: string,
    durationInMinutes: number
}
