import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    Currency,
} from 'app/types/enums'

import {
    QuoteStatus,
    AccountQuoteTable,
} from './AccountQuotes.types'

const config: SkyNetTableConfig<keyof AccountQuoteTable> = {
    name: 'AccountQuotes',
    tabs: [{
        name: 'All',
        url: 'all',
    }],
    fields: [
        {
            name: 'quoteNumber',
            labelKey: 'AccountQuotes.quoteNumber',
            filterField: 'quoteNumber',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'quotedForCompany',
            labelKey: 'AccountQuotes.quotedForCompany',
            filterField: 'quotedForCompany',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'accountName',
            labelKey: 'AccountQuotes.accountName',
            filterField: 'accountName',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'currency',
            labelKey: 'AccountQuotes.currency',
            componentName: 'EnumValue',
            allowedValuesEnum: Currency,
            filterField: 'currency',
            filterType: FilterType.Array,
        },
        {
            name: 'quoteStatus',
            labelKey: 'LaneBaseDto.laneStatus',
            filterField: 'quoteStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: QuoteStatus,
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'createdBy',
            labelKey: 'AccountQuotes.createdBy',
            filterField: 'createdBy',
            filterType: FilterType.Array,
        },
    ],
}

export default config
