import {
    useState, SetStateAction,
} from 'react'

import DTOSpec from 'app/services/dtoSpec'
import useDelayedLoadOptions, {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    Contact,
} from 'app/types/common.types'

import config from '../contactSelector.config'
import contactParser from '../utils/contactParser'

export type Value = {
    id: number,
}

type Args = {
    valueConverter: (value: number[] | {id: number}[]) => number[]
    includeFilters?: {type?: string[]},
    listIds: {id: number}[] | number[],
}

const useCommonMultiContactSelectorLogic = ({
    valueConverter,
    includeFilters = {},
    listIds,
}: Args): {
    contacts: Contact[],
    setContacts: React.Dispatch<SetStateAction<Contact[]>>,
    loadOptions: (options: DelayedLoadOptions) => Promise<any>,
    contactsByIds: Contact[],
    onError: (e: any) => void
} => {
    const [
        contacts,
        setContacts,
    ] = useState<Contact[]>()

    const {
        onError,
    } = useHandleStatusRequest({
        id: 'Contacts',
    })

    const loadOptions = useDelayedLoadOptions({
        url: DTOSpec.Contact.Selector,
        convertion: contactParser,
        includeFilters,
    })

    const {
        data: contactsByIds,
        error,
    } = useRequest<Contact[]>({
        key: RequestKeys.getContactByIds,
        params: {
            includeFilters: {
                id: valueConverter(listIds),
            },
        },
        requestFunc: RequestFn.getEntity(config.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
        enabled: Boolean(listIds.length),
        keepPreviousData: true,
    })

    if (error) {
        onError(error)
    }

    return {
        contacts,
        setContacts,
        loadOptions,
        contactsByIds: listIds.length ? contactsByIds : [],
        onError,
    }
}

export default useCommonMultiContactSelectorLogic
