import React from 'react'
import {
    Grid,
} from '@mui/material'

import StatusHandler from 'app/shared-components/StatusHandler'
import Card from 'app/shared-components/Card'

import ContactForm from '../ContactForm/ContactForm'
import ContactChangeSummary from '../ContactChangeSummary'
import useGetContact from '../hooks/useGetContact'

const ContactOverview = ({
    id,
}: { id: number }) => {
    const {
        data,
        isFetching,
        isDataReady,
        isError,
        error,
    } = useGetContact(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <Grid
                    item
                    xs={12}
                >
                    <ContactForm
                        data={data}
                        disabled
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Card
                        title="Change History"
                    >
                        <ContactChangeSummary id={data?.id} />
                    </Card>
                </Grid>
            </>
        </StatusHandler>
    )
}

export default ContactOverview
