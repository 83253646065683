import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import config from 'app/Apps/OrderManagement/Orders/CustomerTransport/Invoices/invoce.request'
import {
    ResponseMutation,
} from 'app/types/request.types'
import {
    OutgoingInvoice,
} from 'app/Apps/OrderManagement/Orders/orders.types'

type RequestParams = {
    data: {id: number},
}

const useCreateCreditNote = (
    onSuccess: (params: OutgoingInvoice) => void,
): ResponseMutation<RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessCreateCreditNote,
        onError,
    } = useHandleStatusRequest({
        id: 'createCreditNote',
        title: 'Create Credit Note',
        onSuccess,
    })

    return useMutation(({
        data,
    }: RequestParams) => {
        return config.CreateCreditNote.mutationFn({
            data,
            token,
        })
    }, {
        onSuccess: onSuccessCreateCreditNote,
        onError,
    })
}

export default useCreateCreditNote
