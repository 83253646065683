import React from 'react'

import SingleObjectSelectorConverter from 'app/shared-components/SingleObjectSelectorConverter'

import LocationSelector from './LocationSelector'
import {
    LocationSelectorDefaultProps,
    LocationObjectSelectorProps,
} from './types/LocationSelector.types'

const LocationObjectSelector = ({
    value,
    onChange,
    name,
    title,
    disabled,
    isRequired,
    className,
    classNames,
    filterLocationType,
    isApprovedSubContractor,
    'data-testid': dataTestId,
    allowedLocationTypes,
    required,
    validate,
    setErrors,
    filterAddressMainCategory,
    filterAddressCategory,
    includeFilters,
}: LocationObjectSelectorProps) => {
    return (
        <SingleObjectSelectorConverter
            selector={LocationSelector}
            value={value}
            onChange={onChange}
            name={name}
            title={title}
            disabled={disabled}
            isRequired={isRequired}
            className={className}
            classNames={classNames}
            filterLocationType={filterLocationType}
            isApprovedSubContractor={isApprovedSubContractor}
            data-testid={dataTestId}
            allowedLocationTypes={allowedLocationTypes}
            required={required}
            filterAddressMainCategory={filterAddressMainCategory}
            filterAddressCategory={filterAddressCategory}
            includeFilters={includeFilters}
            validate={validate}
            setErrors={setErrors}
        />
    )
}

LocationObjectSelector.defaultProps = LocationSelectorDefaultProps

export default LocationObjectSelector
