import React, {
    useMemo, useState,
} from 'react'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import useEnumValues from 'app/hooks/useEnumValues'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    NOT_ALLOWED_POSTPONE_ORDER_STATUSES,
} from 'app/Apps/OrderManagement/Orders/utils/orderStatus'
import {
    OrderStatuses,
} from 'app/types/enums'
import {
    CancelOrderData, Order,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useCancelOrder from 'app/Apps/OrderManagement/Orders/hooks/useCancelOrder'

import useGetStatusOrderBtns from '../hooks/useGetStatusOrderBtns'
import CancellationOrderDialog from '../../Components/CancellationOrderDialog'
import useGetOrderByNumber from '../../hooks/useGetOrderByNumber'
import getFields from './customerTransportsOverview.fields'
import useStyles from './CustomerTransportsOverview.style'
import PostponeDialog from './PostponeDialog'
import OrderComment from '../../Components/OrderComment'

const DOMAIN_NAME = 'OrderByNumberView'

const CustomerTransportsOverview = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        data = {} as Order,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetOrderByNumber(orderNumber)

    const {
        orderStatus,
        leaseType,
        lane,
    } = data

    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const isShowPostpone: boolean = useMemo(() => {
        return !NOT_ALLOWED_POSTPONE_ORDER_STATUSES.includes(orderStatus)
    }, [orderStatus])

    const isCancelled: boolean = useMemo(() => {
        return orderStatus === OrderStatuses.CANCELLED
    }, [orderStatus])

    const {
        isShowCancel,
        openCancellation,
        setCancellationOpen,
    }: CancelOrderData = useCancelOrder({
        orderStatus,
    })

    const fields = useFieldsWithClassName(
        getFields(
            {
                orderStatus,
                isCancelled,
                leaseType,
                laneId: lane?.id,
                getAllowedValues,
            },
        ),
        classes,
    )

    const [
        openPostpone,
        setPostponeOpen,
    ] = useState<boolean>(false)

    const {
        allowedButtons,
        sendStatusOrder,
    } = useGetStatusOrderBtns({
        orderNumber,
        orderStatus,
        onSuccess: invalidate,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <DomainObjectForm
                    value={data}
                    className={isCancelled ? classes.gridWrapperCancellation : classes.gridWrapper}
                    fields={fields}
                    disabled
                    name={DOMAIN_NAME}
                />
                <ButtonContainer>
                    {
                        allowedButtons.map(({
                            dataToSend,
                            secondary,
                            label,
                            name,
                        }) => {
                            return (
                                <Button
                                    key={`btn-status-${name}`}
                                    label={label}
                                    secondary={secondary}
                                    onClick={() => { sendStatusOrder(dataToSend) }}
                                />
                            )
                        })
                    }
                    {isShowCancel && (
                        <Button
                            secondary
                            label="cancel order"
                            onClick={() => { setCancellationOpen(true) }}
                        />
                    )}
                    {isShowPostpone && (
                        <Button
                            data-testid="postpone-order-btn"
                            secondary
                            label="postpone order"
                            onClick={() => { setPostponeOpen(true) }}
                        />
                    )}
                </ButtonContainer>
                <OrderComment orderNumber={orderNumber} />
                <CancellationOrderDialog
                    orderNumber={orderNumber}
                    onSuccess={invalidate}
                    open={openCancellation}
                    setOpen={setCancellationOpen}
                />
                <PostponeDialog
                    open={openPostpone}
                    setOpen={setPostponeOpen}
                    onSuccess={invalidate}
                    order={data}
                />
            </>
        </StatusHandler>
    )
}

export default CustomerTransportsOverview
