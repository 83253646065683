import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    Currency, LeaseType,
} from 'app/types/enums'
import {
    Contact,
} from 'app/types/common.types'
import {
    QuoteStatus,
    AccountQuote,
} from '../AccountQuotes.types'

type QuoteInformationFields = AccountQuote & {
    accountContacts: Contact[],
    businessDevelopers: Contact[],
    keyAccountManager: Contact,
}

const getFields = ({
    getAllowedValues,
    exists,
    disabled,
    isStatusCreated,
}: {
    getAllowedValues: (e:Record<string, string>) => Record<string, string>,
    exists: boolean,
    disabled: boolean,
    isStatusCreated: boolean,
}): DTOCardFormField<keyof QuoteInformationFields>[] => {
    return [{
        id: 'quoteInformation',
        title: 'Quote Information',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'account',
                        componentName: 'AccountSelector',
                        labelKey: 'AccountQuotes.accountName',
                        required: true,
                        disabled: disabled || exists,
                    },
                    {
                        name: 'quoteStatus',
                        labelKey: 'AccountQuotes.quoteStatus',
                        allowedValues: getAllowedValues(QuoteStatus),
                        componentName: 'EnumSelector',
                        disabled: true,
                    },
                    {
                        name: 'contractBasis',
                        componentName: 'ContractBasisSelector',
                        disabled: !isStatusCreated || disabled,
                        required: true,
                        labelKey: 'AccountQuotes.contractBasis',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Currency),
                        labelKey: 'AccountQuotes.currency',
                        required: true,
                        useDropdown: true,
                        disabled: disabled || exists,
                    },
                    {
                        name: 'leaseType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LeaseType),
                        labelKey: 'AccountQuotes.leaseType',
                        required: true,
                        useDropdown: true,
                        disabled: disabled || exists,
                    },
                    {
                        name: 'quotedForCompany',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountQuotes.quotedForCompany',
                        required: true,
                        disabled: disabled || exists,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'isPerformanceQualification',
                        componentName: 'Checkbox',
                        labelKey: 'AccountQuotes.isPerformanceQualification',
                    },
                    {
                        name: 'comment',
                        componentName: 'LongText',
                        labelKey: 'AccountQuotes.comment',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'quotedFor',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountQuotes.quotedFor',
                    },
                    {
                        name: 'pricingOwner',
                        componentName: 'ContactObjectSelector',
                        labelKey: 'AccountQuotes.pricingOwner',
                        disabled: true,
                    },
                    {
                        name: 'createdBy',
                        componentName: 'InputSingleline',
                        labelKey: 'KamDashboard.requestor',
                        disabled: true,
                    },
                ],
            },
        ],
    }]
}

export default getFields
