import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import useFilter from 'app/hooks/useFilter'
import AccountLaneSpreadsheet from '../AccountLaneSpreadsheet'
import useAccountLaneBulkCreate from './hooks/useAccountLaneBulkCreate'
import {
    AccountLane,
} from '../AccountLanes.types'

const AccountLaneBulkCreate = ({
    backUrl,
}: {
    backUrl: string
}) => {
    const filterOptions = useFilter()
    const history = useHistory()

    const onSuccess = useCallback(() => {
        history.push(backUrl)
    }, [
        history,
        backUrl,
    ])

    const bulkCreate = useAccountLaneBulkCreate()

    const handleSave = useCallback((values: AccountLane[], options?: {
        onSuccess?: (...a: any[]) => void
    }) => {
        return bulkCreate(values, {
            onSuccess: options?.onSuccess || onSuccess,
        })
    }, [
        bulkCreate,
        onSuccess,
    ])

    return (
        <AccountLaneSpreadsheet
            filterOptions={filterOptions}
            onSave={handleSave}
            onCancel={onSuccess}
        />
    )
}

export default AccountLaneBulkCreate
