import {
    makeStyles,
} from 'app/tss'

const addressDetails = 'addressDetails'
const location = 'location'
const shippingLocation = 'shippingLocation'
const airportDetails = 'airportDetails'
const seaportDetails = 'seaportDetails'
const billingDetails = 'billingDetails'
const serviceCenter = 'serviceCenter'
const bolAwbDetails = 'bolAwbDetails'
const contactDetails = 'contactDetails'
const railportDetails = 'railportDetails'
const serviceCenterMetrics = 'serviceCenterMetrics'

const templateServiceAddress = `
" ${location} ${addressDetails} ${addressDetails} ${shippingLocation}"
" ${serviceCenter} ${addressDetails} ${addressDetails} ${shippingLocation}"
" ${bolAwbDetails} ${bolAwbDetails} ${bolAwbDetails} ${bolAwbDetails}"
" ${contactDetails} ${contactDetails} ${contactDetails} ${contactDetails}"
`
const templateAddress = `
" ${location} ${addressDetails} ${addressDetails} ${shippingLocation}"
" ${contactDetails} ${contactDetails} ${contactDetails} ${contactDetails}"
`

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        gridWrapperAddressService: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: templateServiceAddress,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapperAddress: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: templateAddress,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridWrapperAirport: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
            " ${location} ${airportDetails} ${shippingLocation}"
            " ${contactDetails} ${contactDetails} ${contactDetails}"
        `,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        gridWrapperSeaport: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
            " ${location} ${seaportDetails} ${shippingLocation}"
            " ${contactDetails} ${contactDetails} ${contactDetails}"
        `,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        gridWrapperRailport: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
            " ${location} ${railportDetails} ${shippingLocation}"
            " ${contactDetails} ${contactDetails} ${contactDetails}"
        `,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        gridWrapperBilling: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
            " ${location} ${addressDetails} ${addressDetails} ${billingDetails}"
            " ${contactDetails} ${contactDetails} ${contactDetails} ${contactDetails}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gridServiceCenterMetrics: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
            "${serviceCenterMetrics} ${serviceCenterMetrics} ${serviceCenterMetrics} ${serviceCenterMetrics}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        containerData: {
            gridArea: addressDetails,
        },
        location: {
            gridArea: location,
            display: 'flow-root',
        },
        shippingLocation: {
            gridArea: shippingLocation,
        },
        seaportDetails: {
            gridArea: seaportDetails,
        },
        railportDetails: {
            gridArea: railportDetails,
        },
        airportDetails: {
            gridArea: airportDetails,
        },
        locationType: {
            flexDirection: 'column',
        },
        addressDetails: {
            gridArea: addressDetails,
            minWidth: 290,
            overflow: 'auto !important',
        },
        billingDetails: {
            gridArea: billingDetails,
        },
        serviceCenter: {
            gridArea: serviceCenter,
        },
        bolAwbDetails: {
            gridArea: bolAwbDetails,
        },
        contactDetails: {
            gridArea: contactDetails,
            margin: 0,
            padding: 0,
            overflow: 'auto',
        },
        paymentTerms: {
            flexDirection: 'column',
        },
        serviceCenterMetrics: {
            gridArea: serviceCenterMetrics,
            padding: theme.spacing(1),
        },
    }
})

export default useStyles
