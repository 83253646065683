import React, {
    useMemo,
    useCallback,
} from 'react'
import {
    Link,
    useHistory,
} from 'react-router-dom'
import Typography from '@mui/material/Typography'

import AddIcon from '@mui/icons-material/Add'
import Card from 'app/shared-components/Card'
import SkyNetTable from 'app/shared-components/SkyNetTable'

import {
    LocationType,
} from 'app/types/enums'
import useStyles from '../CompanyOverview.styles'
import config from './Addresses.config'

const Addresses = ({
    data,
}: {
    data: { id: number }
}) => {
    const history = useHistory()
    const {
        classes,
    } = useStyles()

    const filter = useMemo(() => {
        return {
            locationType: [LocationType.ADDRESS],
            companyId: [data?.id],
        }
    }, [data?.id])

    const onRowClick = useCallback(({
        id: locationId,
    }) => {
        history.push(`/apps/contactmanagement/locations/address/edit/${locationId}/data-update`)
    }, [history])

    const createLocationLink = `/apps/contactmanagement/locations/all/create?companyId=${data?.id}&locationType=ADDRESS`

    return (
        <div
            className={classes.tableContainer}
        >
            <Card
                fullHeight
                className={classes.cardRoot}
                contentClass={classes.cardContent}
            >
                <div className={classes.header}>
                    <Typography
                        variant="h3"
                    >
                        Addresses
                    </Typography>
                    <div className={classes.icon}>
                        <Link
                            className={classes.link}
                            to={createLocationLink}
                        >
                            <AddIcon
                                data-testid="open-create-locationType-ADDRESS"
                                className={classes.icon}
                            />
                        </Link>
                    </div>
                </div>
                <SkyNetTable
                    name="Location"
                    simple
                    filter={filter}
                    tableConfig={config}
                    onRowClick={onRowClick}
                />
            </Card>
        </div>
    )
}

export default Addresses
