import React from 'react'
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom'

import Dashboard from 'app/Apps/Dashboard'
import Containers from 'app/Apps/Containers'
import ContainerSelection from 'app/Apps/ContainerSelection'
import Finance from 'app/Apps/Finance'
import Pricing from 'app/Apps/Pricing'
import Loggers from 'app/Apps/Loggers'
import Countries from 'app/Apps/Masterdata/Countries'
import TransportPlanner from 'app/Apps/TransportPlanner'
import ContainerTypes from 'app/Apps/ContainerTypes'
import Gateways from 'app/Apps/Sensors/Gateways'
import {
    App,
} from 'app/types/common.enums'

import ContactManagement from './ContactManagement'
import OrderManagement from './OrderManagement'
import AccountManagement from './AccountManagement'
import Forecast from './Forecast'
import DamageTracking from './DamageTracking'
import RelationshipsManagement from './RelationshipsManagement'

const Apps = () => {
    const {
        path,
    } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/dashboard`}>
                <Dashboard />
            </Route>
            <Route path={`${path}/${App.MASTERDATA}/countries`}>
                <Countries />
            </Route>
            <Route path={`${path}/${App.ORDER_MANAGEMENT}`}>
                <OrderManagement />
            </Route>
            <Route path={`${path}/${App.CONTAINERS}/containers`}>
                <Containers />
            </Route>
            <Route path={`${path}/${App.CONTAINERS}/containers-selection`}>
                <ContainerSelection />
            </Route>
            <Route path={`${path}/${App.CONTAINERS}/container-type`}>
                <ContainerTypes />
            </Route>
            <Route path={`${path}/${App.FINANCE}`}>
                <Finance />
            </Route>
            <Route path={`${path}/gateways`}>
                <Gateways />
            </Route>
            <Route path={`${path}/${App.ACCOUNT_MANAGEMENT}`}>
                <AccountManagement />
            </Route>
            <Route path={`${path}/${App.PRICING}`}>
                <Pricing />
            </Route>
            <Route path={`${path}/loggers`}>
                <Loggers />
            </Route>
            <Route path={`${path}/${App.CONTACT_MANAGEMENT}`}>
                <ContactManagement />
            </Route>
            <Route path={`${path}/${App.RELATIONSHIPS_MANAGEMENT}`}>
                <RelationshipsManagement />
            </Route>
            <Route path={`${path}/${App.TRANSPORT_PLANNER}`}>
                <TransportPlanner />
            </Route>
            <Route path={`${path}/${App.DAMAGE_TRACKING}`}>
                <DamageTracking />
            </Route>
            <Route path={`${path}/${App.FORECAST}`}>
                <Forecast />
            </Route>
            <Redirect
                exact
                path={`${path}`}
                to={`${path}/dashboard`}
            />
        </Switch>
    )
}

export default Apps
