/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import {
    useTheme,
} from '@mui/material/styles'

import useStyles from './ChipSelect.style'

const propTypes = {
    innerProps: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.string,
}

const defaultProps = {
    children: '',
    innerProps: {},
}

function NoOptionsMessage(props) {
    const {
        innerProps,
        children,
    } = props

    const theme = useTheme()
    const {
        classes,
    } = useStyles(theme)

    return (
        <Typography
            color="textSecondary"
            className={classes.noOptionsMessage}
            {...innerProps}
        >
            {children}
        </Typography>
    )
}

NoOptionsMessage.propTypes = propTypes
NoOptionsMessage.defaultProps = defaultProps

export default NoOptionsMessage
