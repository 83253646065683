import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import request from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateCustomerTransportForm/createCustomerTransportForm.request'
import {
    CustomerTransportRequestParams,
} from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateCustomerTransportForm/createCustomerTransportForm.types'

const useCreateCustomerTransportOrder = (onSuccess: ({
    orderNumber,
}: {orderNumber: string}) => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'CreateOrder',
    })

    return useMutation((data: CustomerTransportRequestParams) => {
        return request.CreateCTOrder.mutationFn({
            data,
            token,
        })
    }, {
        onSuccess,
        onError,
    })
}

export default useCreateCustomerTransportOrder
