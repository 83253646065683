/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import sortAscending from 'app/utils/sortAscending'

import MultipleSelectUnselectable from '../MultipleSelectUnselectable'

import useStyles from './MultiEnumSelector.styles'

const propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    allowedValues: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    allowOnly: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
    value: [],
    className: null,
    allowOnly: [],
}

const filteredAllowedValues = (allowedValues, allowOnly, value) => {
    if (allowOnly.length) {
        return Object.entries(allowedValues).filter(([item]) => {
            return allowOnly.includes(item) || value.includes(item)
        })
    }
    return Object.entries(allowedValues)
}

const MultiEnumSelector = (props) => {
    const {
        classes,
    } = useStyles()

    const {
        allowedValues,
        onChange,
        value,
        className,
        allowOnly,
    } = props

    const entries = sortAscending(filteredAllowedValues(allowedValues, allowOnly, value))

    return (
        <div className={clsx(classes.root, className)}>
            <MultipleSelectUnselectable
                {...props}
                options={entries}
                value={value}
                onChange={(options) => {
                    const val = options.map((option) => {
                        return option[0]
                    })

                    return onChange(val)
                }}
            />
        </div>
    )
}

MultiEnumSelector.propTypes = propTypes
MultiEnumSelector.defaultProps = defaultProps

export default MultiEnumSelector
