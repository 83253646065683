import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    ResponseMutation,
} from 'app/types/request.types'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import config from '../QuotePdf.request'

type RequestParams = {
    quoteId: number,
    fileId: string,
}

const useRemoveQuotePdf = (
    onSuccess: () => void,
): ResponseMutation<RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRemoveInvoice,
        onError,
    } = useHandleStatusRequest({
        id: 'QuotePdf',
        title: 'Document has been removed',
        onSuccess,
    })

    return useMutation(({
        quoteId,
        fileId,
    }: RequestParams) => {
        return config.RemovePdfQuote.mutationFn({
            quoteId,
            fileId,
            token,
        })
    }, {
        onSuccess: onSuccessRemoveInvoice,
        onError,
    })
}

export default useRemoveQuotePdf
