import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            background: theme.palette.red,
        },
        gridCardWrapper: {
            display: 'grid',
            gridGap: `0 ${theme.spacing(1.875)}`,
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            padding: 8,
            flexGrow: 0,
            width: `calc(100% + ${theme.spacing(2)})`,
            margin: theme.spacing(-1),
            maxWidth: '100%',
            flexBasis: '100%',
        },
        link: {
            cursor: 'pointer',
            verticalAlign: 'middle',
        },
        titleIcon: {
            paddingRight: theme.spacing(1),
            verticalAlign: 'middle',
        },
    }
})

export default useStyles
