import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    ResponseMutation,
} from 'app/types/request.types'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requestConfig from '../loggerDocumentDelete.request'

type RequestParams = {
    loggerId: number,
    documentId: number,
}

const useDeleteLoggerDocument = (
    onSuccess: () => void,
): ResponseMutation<RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessDelete,
        onError,
    } = useHandleStatusRequest({
        id: 'LoggerDocument',
        title: 'Document has been removed',
        onSuccess,
    })

    return useMutation(({
        loggerId,
        documentId,
    }: RequestParams) => {
        return requestConfig.RemoveLoggerDocument.mutationFn({
            loggerId,
            documentId,
            token,
        })
    }, {
        onSuccess: onSuccessDelete,
        onError,
    })
}

export default useDeleteLoggerDocument
