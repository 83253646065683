import {
    RequestConfig,
} from 'app/types/request.types'

type ContractBasisRequests = {
    domainName: string,
    create: () => RequestConfig,
    update: ({
        id: number,
    }) => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    restore: ({
        id: number,
    }) => RequestConfig,
    archive: ({
        id: number,
    }) => RequestConfig,
    filterRequest: ({
        params,
        includeFilters,
    }) => RequestConfig,
    fetchAllUrl: string,
}

const contractBasisRequests: ContractBasisRequests = {
    domainName: 'ContractBasis',
    create: () => {
        return {
            url: 'contractbasis',
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `contractbasis/${id}/data`,
            method: 'PATCH',
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `contractbasis/${id}`,
            method: 'GET',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `contractbasis/${id}/restore`,
            method: 'PATCH',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `contractbasis/${id}/archive`,
            method: 'PATCH',
        }
    },
    filterRequest: ({
        params,
        includeFilters,
    }) => {
        return {
            url: 'contractbasis/filter-request/all',
            method: 'POST',
            ...params,
            includeFilters,
        }
    },
    fetchAllUrl: '/apps/pricing/contract-basis/all',
}

export default contractBasisRequests
