import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ResponseMutation,
} from 'app/types/request.types'
import config from 'app/Apps/OrderManagement/Orders/orders.request'
import {
    CancellationInfo,
} from '../../../orders.types'

type RequestParams = {
    orderNumber: string,
    data: CancellationInfo,
}

const useCancelOrderRequest = (onSuccess: () => void): ResponseMutation<RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessPostpone,
        onError,
    } = useHandleStatusRequest({
        id: 'OrderByNumberView',
        title: 'Order Cancellation',
        onSuccess,
    })

    return useMutation(({
        orderNumber,
        data,
    }: RequestParams) => {
        return config.CancelOrder.mutationFn({
            orderNumber,
            data,
            token,
        })
    }, {
        onSuccess: onSuccessPostpone,
        onError,
    })
}

export default useCancelOrderRequest
