import React, {
    useCallback, useMemo, useState,
} from 'react'
import noop from 'lodash/noop'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    useHistory,
} from 'react-router-dom'
import {
    useQueryClient,
} from 'react-query'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    FeesType,
} from 'app/shared-components/Fees/Fee.types'
import FormWithControls from 'app/shared-components/FormWithControls'
import useScrollToForm from 'app/hooks/useScrollToForm'
import DTOSpec from 'app/Apps/Pricing/ContractBasis/dtoSpec'
import AdditionalFeesRequests from 'app/Apps/Pricing/ContractBasis/AdditionalFees/AdditionalFees.requests'
import currency from 'app/utils/currency'
import Button from 'app/shared-components/Button'
import simpleApi from 'app/services/simpleApi'
import SharedContextProvider from 'app/shared-components/SharedContext'
import {
    useChangeValidity,
} from 'app/shared-components/ChangeValidity'
import useRequestWithConfirmation from 'app/hooks/useRequestWithConfirmation'
import {
    AdditionalFeesStatus,
} from 'app/types/enums'
import {
    App,
} from 'app/types/common.enums'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'

import useEnumValues from 'app/hooks/useEnumValues'
import AdditionalFeeChangeValidity from './AdditionalFeeChangeValidity'
import additionalFeesFields from './additionalFee.fields'
import useStyles from './AdditionalFeeDetailPanel.styles'

import {
    AdditionalFeeValue,
} from './AdditionalFee.types'
import AdditionalFeeCancellation from './AdditionalFeeCancellation'

type Props = {
    rowProps: AdditionalFeeValue,
    onSuccess?: (props: any) => void,
    exists?: boolean,
    closeForm?: (refresh: boolean) => void,
    redirectToCopyForm: (id: number) => void,
    onDetailPanelCreateSuccess?: (params: any)=> void,
    isCopy?: boolean;
}

const defaultProps: Partial<Props> = {
    exists: false,
    closeForm: noop,
    onSuccess: noop,
    onDetailPanelCreateSuccess: noop,
    isCopy: false,
}

const AdditionalFeeDetailPanel = ({
    rowProps: value,
    onSuccess,
    exists,
    closeForm,
    redirectToCopyForm,
    onDetailPanelCreateSuccess,
    isCopy,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const token = useJWTToken()
    const getAllowedValues = useEnumValues()
    const hidefxRate = useMemo(() => {
        return value.currency === currency.default
    }, [value.currency])
    const scrollToForm = useScrollToForm(value.additionalFeesNumber)
    const [
        reason,
        setReason,
    ] = useState('')
    const [
        modifiedValue,
        setModifiedValue,
    ] = useState({
        ...value,
        edited: false,
    })
    const history = useHistory()

    const setExternalValue = useCallback((update) => {
        const editedValue = {
            ...update,
            edited: true,
        }

        if (!update.fxRate?.id) {
            return setModifiedValue(editedValue)
        }
        if (value.fxRate?.id === editedValue.fxRate?.id) {
            return setModifiedValue({
                ...editedValue,
                fxRate: value.fxRate,
            })
        }
        return simpleApi({
            url: DTOSpec.FxRate.Get(editedValue.fxRate.id),
            token,
        }).then((newFxRate) => {
            return setModifiedValue({
                ...editedValue,
                fxRate: newFxRate,
            })
        })
    }, [
        value,
        token,
    ])

    const fields = useFieldsWithClassName(
        additionalFeesFields(
            {
                ccy: value?.currency,
                fxRate: modifiedValue.fxRate,
                fees: value.fees,
                hidefxRate,
                exists,
                originalStatus: value.additionalFeesStatus,
                editingAllowed: value?.additionalFeesStatus
                    !== AdditionalFeesStatus.IN_PROGRESS,
                editingCommentAllowed: value.additionalFeesStatus
                    !== AdditionalFeesStatus.VALID,
                getAllowedValues,
            },
        ),
        classes,
    )

    const handleOnCancel = useCallback(() => {
        setModifiedValue({
            ...modifiedValue,
            edited: false,
        })
        closeForm(false)
    }, [
        modifiedValue,
        closeForm,
    ])

    const queryClient = useQueryClient()
    const redirectionAfterSuccess = `/apps/${App.PRICING}/contract-basis/all/edit/${value.contractId}/additional-fees/${value.additionalFeesNumber}`

    const onChangeValiditySuccess = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getContractBasisAdditionalFee])
        history.push(redirectionAfterSuccess)
    }, [
        queryClient,
        history,
        redirectionAfterSuccess,
    ])

    const changeValidity = useChangeValidity({
        requestParamFn: AdditionalFeesRequests.validity,
        notificationId: 'AdditionalFeesUpdateDto',
        title: 'Additional Fee',
        data: {
            id: value.id,
            validFrom: value.validFrom,
            validTo: value.validTo,
        },
        onSuccess: onChangeValiditySuccess,
    })

    const onCancelFeeSuccess = useCallback(() => {
        return history.push(redirectionAfterSuccess)
    }, [
        history,
        redirectionAfterSuccess,
    ])

    const cancelFee = useRequestWithConfirmation({
        requestParamFn: AdditionalFeesRequests.cancellation,
        notificationId: 'AdditionalFeesUpdateDto',
        title: 'Additional Fee',
        data: {
            ...value,
            commentForBilling: reason,
        },
        actionDone: 'Cancelled',
        onSuccess: onCancelFeeSuccess,
    })

    const archive = useArchive({
        requestParamFn: value?.archived ? AdditionalFeesRequests.restore
            : AdditionalFeesRequests.archive,
        notificationId: 'AdditionalFeeUpdateDto',
        title: 'Additional Fee',
        data: value,
        redirectionAfterSuccess,
        onSuccess,
    })

    const customButtons = useMemo(() => {
        return (
            [
                <Button
                    key="Copy"
                    label="Copy"
                    onClick={() => { redirectToCopyForm(value.id) }}
                />,
                <Button
                    key="Set to Canceled"
                    label="Set to Canceled"
                    onClick={cancelFee.askConfirmation}
                    disabled={value?.additionalFeesStatus === AdditionalFeesStatus.CANCELLED}
                />,
                <Button
                    key="Change Validity"
                    label="Change Validity"
                    onClick={changeValidity.handleChangeValidity}
                    disabled={value?.additionalFeesStatus !== AdditionalFeesStatus.VALID}
                />,
                <ArchiveButton
                    key="archive-button"
                    onClick={archive.handleArchiveRestore}
                    archived={value?.archived}
                />,
            ]
        )
    }, [
        value,
        redirectToCopyForm,
        changeValidity.handleChangeValidity,
        cancelFee.askConfirmation,
        archive.handleArchiveRestore,
    ])

    const requestParams = useMemo(() => {
        if (isCopy) {
            return AdditionalFeesRequests.duplicate({
                id: value.additionalFeeId,
            })
        }

        if (exists) {
            if (value.additionalFeesStatus !== AdditionalFeesStatus.IN_PROGRESS) {
                return AdditionalFeesRequests.editableData({
                    id: value.id,
                })
            }

            return AdditionalFeesRequests.update({
                id: value.id,
            })
        }

        return AdditionalFeesRequests.create({
            contractId: value.contractId,
        })
    }, [
        exists,
        isCopy,
        value,
    ])

    return (
        <div
            className={classes.root}
            ref={scrollToForm}
        >
            <SharedContextProvider
                value={{
                    feePricingName: FeesType.Additional,
                }}
            >
                <FormWithControls
                    name={AdditionalFeesRequests.create.name}
                    value={modifiedValue}
                    onSuccess={
                        !exists ? onDetailPanelCreateSuccess : onSuccess
                    }
                    fields={fields}
                    classNames={{
                        gridWrapper: hidefxRate
                            ? classes.gridWrapper : classes.gridWrapperWithFxRate,
                    }}
                    requestParams={requestParams}
                    exists={exists}
                    onCancel={handleOnCancel}
                    customButtons={value.id ? customButtons : undefined}
                    setExternalValue={setExternalValue}
                />
            </SharedContextProvider>
            <AdditionalFeeChangeValidity
                changeValidity={changeValidity}
                id={value?.id}
            />
            <AdditionalFeeCancellation
                id={value?.id}
                cancelFee={cancelFee}
                setReason={setReason}
                reason={reason}
            />
            <ArchiveConfirmationDialog
                title="Additional Fee"
                openConfirmDialog={archive.openConfirmDialog}
                handleClose={archive.handleArchiveRestoreConfirm}
                archived={value?.archived}
            />
        </div>
    )
}

AdditionalFeeDetailPanel.defaultProps = defaultProps

export default AdditionalFeeDetailPanel
