import {
    rolesTypes,
} from 'app/utils/hasPermission'

const nonDBRolesList: rolesTypes[] = [
    'location_cs',
    'location_bil',
    'location_ops',
    'location_ts',
    'location_prd',
    'location_rnd',
    'location_pmo',
    'location_cs_super',
    'location_bil_super',
    'location_ops_super',
    'location_ts_super',
    'location_pmo_super',
]

export default nonDBRolesList
