import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import simpleApi from 'app/services/simpleApi'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    QuotePreviewRequestParams,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'
import DTOSpec from 'app/Apps/Pricing/ContractBasis/dtoSpec'

const useQuotePreview = () => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'Notification',
    })

    return useMutation(({
        additionalFees,
        laneGroups,
        selectedAirports,
        selectedLaneFeesStatuses,
        selectedCustomerExecution,
        exactDates,
        laneFeeValidFrom,
        laneFeeValidTo,
        selectedLaneStatus,
        isCustomerQuotation,
        allowDuplication,
        skycellEmployeeContact,
        customerContact,
        comment,
        quoteId,
    }: QuotePreviewRequestParams) => {
        return simpleApi({
            ...DTOSpec.ContractBasis.QuotePrint.PreviewRequest,
            data: {
                additionalFees,
                laneGroups,
                selectedAirports,
                selectedLaneFeesStatuses,
                selectedCustomerExecution,
                exactDates,
                laneFeeValidFrom,
                laneFeeValidTo,
                selectedLaneStatuses: selectedLaneStatus,
                isCustomerQuotation,
                allowDuplication,
                skycellEmployeeContact,
                customerContact,
                comment,
                quoteId,
            },
            token,
        })
    }, {
        onError,
    })
}

export default useQuotePreview
