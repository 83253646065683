import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ResponseMutation,
} from 'app/types/request.types'

import config from '../../invoce.request'

type RequestParams = {
    orderNumber: string,
    data: {id: number},
}

const useRelinkLaneFee = (onSuccess: () => void): ResponseMutation<RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRelinkLaneFee,
        onError,
    } = useHandleStatusRequest({
        id: 'relinkLaneFee',
        title: 'Relink Lane Fee',
        onSuccess,
    })

    return useMutation(({
        orderNumber,
        data,
    }: RequestParams) => {
        return config.RelinkLaneFee.mutationFn({
            orderNumber,
            data,
            token,
        })
    }, {
        onSuccess: onSuccessRelinkLaneFee,
        onError,
    })
}

export default useRelinkLaneFee
