export enum StorageType {
    C = 'C',
    CRT = 'CRT',
    DRY = 'DRY',
}

export enum StorageSpaceRatio {
    _1_1 = '_1_1',
    _1_2 = '_1_2',
    _2_3 = '_2_3',
    _3_4 = '_3_4',
    _4_5 = '_4_5',
    _5_6 = '_5_6',
    _6_7 = '_6_7',
    _9_10 = '_9_10',
}

export type Storage = {
    id?: number,
    storageType?: StorageType,
    preconTimeInHours?: number,
    immediateUseOnly?: boolean,
    preconEndOnWorkdayOnly?: boolean,
    storageSpaceRatio?: StorageSpaceRatio,
    preconCapacity?: number,
    inStoragePrecon?: boolean,
    changedOn?: string,
    changedBy?: string,
}
