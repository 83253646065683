import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    StorageType,
    StorageSpaceRatio,
    Storage,
} from '../Storage.types'

const LocationFormServiceCenterMetricsFields = ({
    getAllowedValues,
}): DTOCardFormField<keyof Storage>[] => {
    return [{
        id: 'storage',
        title: 'General',
        className: 'storage',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'storageType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(StorageType),
                        labelKey: 'ServiceCenterStorage.storageType',
                        required: true,
                        useDropdown: true,
                    },
                    {
                        name: 'storageSpaceRatio',
                        labelKey: 'ServiceCenterStorage.storageSpaceRatio',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(StorageSpaceRatio),
                        useDropdown: true,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'preconTimeInHours',
                        componentName: 'IntegerInput',
                        labelKey: 'ServiceCenterStorage.preconTimeInHours',
                        required: true,
                    },
                    {
                        name: 'preconCapacity',
                        labelKey: 'ServiceCenterStorage.preconCapacity',
                        componentName: 'IntegerInput',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'immediateUseOnly',
                        componentName: 'Checkbox',
                        labelKey: 'ServiceCenterStorage.immediateUseOnly',
                    },
                    {
                        name: 'inStoragePrecon',
                        componentName: 'Checkbox',
                        labelKey: 'ServiceCenterStorage.inStoragePrecon',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'preconEndOnWorkdayOnly',
                    componentName: 'Checkbox',
                    labelKey: 'ServiceCenterStorage.preconEndOnWorkdayOnly',
                }],
            },
        ],
    }]
}

export default LocationFormServiceCenterMetricsFields
