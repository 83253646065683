const DTOSpec = {
    Attachment: {
        url: 'attachment/filter-request/all',
        domainName: 'Attachment',
    },
    Countries: {
        Selector: 'country/filter-request/selector',
        domainName: 'Country',
        Update: {
            refresh: 'Country',
        },
        Get: (countryId) => {
            return `country/${countryId}`
        },
    },
    Company: {
        Selector: 'company/filter-request/selector',
        domainName: 'Company',
        Get: (companyId) => {
            return `company/${companyId}`
        },
    },
    Contact: {
        Selector: 'contact/filter-request/selector',
        url: 'contact/filter-request/all',
        Get: (contactId) => {
            return `contact/${contactId}`
        },
        domainName: 'Contact',
    },
    ContainerType: {
        Selector: 'containertype/filter-request/selector',
        Get: (containerId) => {
            return `containertype/${containerId}`
        },
        domainName: 'ContainerType',
    },
    Gateways: {
        domainName: 'Gateway',
    },
    FxRate: {
        Update: {
            refresh: 'FxRate',
        },
        Get: (fxrateId) => {
            return `fxrate/${fxrateId}`
        },
    },
    LaneGroup: {
        Update: {
            name: 'LaneGroupUpdateDto',
            request: ({
                id,
            }) => {
                return {
                    method: 'PATCH',
                    domainName: 'LaneGroupUpdateDto',
                    url: `lanegroup/${id}/data`,
                }
            },
        },
    },
    Container: {
        domainName: 'Container',
        Update: {
            name: 'Container',
            request: ({
                serialNumber,
            }) => {
                return {
                    url: `Container/${serialNumber}/data`,
                    method: 'PATCH',
                    domainName: 'ContainerUpdateDto',
                }
            },
        },
        Status: {
            Update: {
                name: 'ContainerStatusChangeDto',
                request: ({
                    serialNumber,
                }) => {
                    return {
                        method: 'PATCH',
                        domainName: 'ContainerStatusChangeDto',
                        url: `container/${serialNumber}/status-change`,
                    }
                },
            },
        },
    },
    Logger: {
        domainName: 'Logger',
        Remove: {
            name: 'LoggerRemovalDto',
            request: ({
                serialNumber,
            }) => {
                return {
                    url: `container/${serialNumber}/logger-removal`,
                    method: 'PATCH',
                    domainName: 'LoggerRemovalDto',
                }
            },
        },
        Selector: 'logger/filter-request/selector',
    },
    RoadFreight: {
        Info: {
            name: 'RoadfreightTransport',
            url: 'roadfreighttransport/filter-request/all',
        },
        Create: {
            name: 'RoadfreightTransport',
            request: () => {
                return {
                    url: 'roadfreighttransport',
                    method: 'POST',
                    domainName: 'RoadfreightTransport',
                }
            },
        },
        Update: {
            name: 'LoggerUpdateDto',
            request: ({
                id,
            }) => {
                return {
                    url: `roadfreighttransport/${id}/data`,
                    method: 'PATCH',
                    domainName: 'RoadfreightTransport',
                }
            },
        },
    },
    Location: {
        Info: {
            name: 'Location',
            url: 'location/filter-request/all',
        },
        Get: (locationId) => {
            return `location/${locationId}`
        },
        Selector: 'location/filter-request/selector',
        Filter: {
            isProspect: [
                true,
                false,
            ],
        },
        Request: 'location/filter-request/all',
    },
    BillingCompany: {
        Info: {
            name: 'BillingCompanyDto',
            url: 'billingcompany/filter-request/all',
        },
        Create: {
            name: 'BillingCompanyCreate',
            request: () => {
                return {
                    url: 'billingcompany',
                    method: 'POST',
                    domainName: 'BillingCompanyDto',
                }
            },
        },
        Update: {
            name: 'BillingCompanyUpdate',
            request: ({
                id,
            }) => {
                return {
                    url: `billingcompany/${id}/data`,
                    method: 'PATCH',
                    domainName: 'BillingCompanyDto',
                }
            },
        },
        Selector: 'billingcompany/filter-request/all',
    },
    OrderInvoices: {
        name: 'OrderInvoicesView',
    },
    Position: {
        url: 'position/filter-request/all',
        domainName: 'Position',
    },
    Accounts: {
        Selector: 'account/filter-request/selector',
        Get: (accountId) => {
            return `account/${accountId}`
        },
    },
}

export default DTOSpec
