import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'

const useRemovePosition = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'removePosition',
    })

    return useMutation(({
        invoiceId,
        positionId,
    }: {
        invoiceId: number,
        positionId: number,
    }) => {
        return orderManagementRequest.Position.DELETE.mutationFn({
            token,
            invoiceId,
            positionId,
        })
    }, {
        onSuccess,
        onError,
    })
}

export default useRemovePosition
