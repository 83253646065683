import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from 'react-query'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'

import requestConfig from '../documents.request'

const useGetLoggerDocuments = (loggerId: number) => {
    const queryClient = useQueryClient()

    const refresh = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getLoggerDocuments])
    }, [queryClient])

    const {
        onParamsChange,
        loadData: load,
    } = useRequestTable({
        key: RequestKeys.getLoggerDocuments,
        params: {
            loggerId,
        },
        config: requestConfig.getLoggerDocuments,
        enabled: Boolean(loggerId),
    })

    return {
        onParamsChange,
        load,
        refresh,
    }
}

export default useGetLoggerDocuments
