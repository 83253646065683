const addSimpleUrl = (host) => {
    return (cb) => {
        return ({
            url,
            ...rest
        }) => {
            return cb({
                ...rest,
                url: `${host}/${url}`,
            })
        }
    }
}

export default addSimpleUrl
