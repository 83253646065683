import React, {
    useEffect,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useRequestTable from 'app/hooks/useRequestTable'
import request from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateCustomerTransportForm/createCustomerTransportForm.request'
import {
    AvailableLanesFilters,
} from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateCustomerTransportForm/createCustomerTransportForm.types'

import {
    AirlineLeaseAvailableLane,
} from './availableLanes.types'
import columns from './availableLanes.columns'
import useStyles from './AvailableLanes.style'

const AvailableLanes = ({
    value,
    onChange,
    includeFilters,
    availableLanes,
    setAvailableLanes,
    disabled,
}: {
    value: number[],
    onChange: (newVal: number[]) => void,
    includeFilters: AvailableLanesFilters,
    availableLanes: {data:AirlineLeaseAvailableLane[]},
    setAvailableLanes: (val: {data:AirlineLeaseAvailableLane[]}) => void,
    disabled: boolean,
}) => {
    const {
        classes,
    } = useStyles()

    const {
        onParamsChange,
        data,
        loadData,
    } = useRequestTable<{data:AirlineLeaseAvailableLane[]}>({
        config: request.AvailableLanes.requestFn,
        params: {},
        key: RequestKeys.getAvailableLanes,
    })

    const onSelectRow = (id) => {
        onChange(id)
    }

    useEffect(() => {
        if (availableLanes === data) {
            return
        }

        setAvailableLanes(data)

        if (value) {
            onChange(null)
        }
    }, [
        availableLanes,
        data,
        onChange,
        setAvailableLanes,
        value,
    ])

    return (
        <RichTable
            configName="AvailableLanes"
            name="AirlineLeaseAvailableLane"
            load={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            radioSelect
            onSelectRow={onSelectRow}
            selectedRows={value}
            filter={includeFilters}
            disabled={disabled}
        />
    )
}

export default AvailableLanes
