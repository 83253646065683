import {
    useMemo,
} from 'react'
import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import {
    ALLOWED_CONFIRM_ORDER_STATUSES,
    ALLOWED_ASK_FOR_POOLING,
} from 'app/Apps/OrderManagement/Orders/utils/orderStatus'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    OrderStatuses,
} from 'app/types/enums'

import request from '../customerTransport.request'

type Args = {
    orderNumber: string,
    orderStatus: OrderStatuses,
    onSuccess: () => void,
}

type DataButtonType = {
    name: string,
    label: string,
    dataToSend: OrderStatuses,
    secondary?: boolean,
}

type ConditionShowType = {
    confirmOrder: boolean,
    askForPrecon: boolean,
    askForPooling: boolean,
    reject: boolean,
    discussionRequired: boolean,
}

const dataButtons: DataButtonType[] = [
    {
        name: 'confirmOrder',
        label: 'confirm order',
        dataToSend: OrderStatuses.CONFIRMED,
    },
    {
        name: 'askForPrecon',
        label: 'ask for precon',
        secondary: true,
        dataToSend: OrderStatuses.CONFIRMING_PRECON_FEASIBILITY,
    },
    {
        name: 'askForPooling',
        label: 'ask for pooling',
        secondary: true,
        dataToSend: OrderStatuses.WAITING_FOR_POOLING,
    },
    {
        name: 'reject',
        label: 'reject',
        secondary: true,
        dataToSend: OrderStatuses.REJECTED,
    },
    {
        name: 'discussionRequired',
        label: 'discussion required',
        secondary: true,
        dataToSend: OrderStatuses.IN_DISCUSSION,
    },
]

const useGetStatusOrderBtns = ({
    orderNumber,
    orderStatus,
    onSuccess,
}: Args) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessModification,
        onError,
    } = useHandleStatusRequest({
        id: 'OrderByNumberView',
        title: 'Order Status Updated',
        onSuccess,
    })

    const {
        mutate,
    } = useMutation((orderStatusRequest: OrderStatuses) => {
        return request.UpdateStatusOrder.mutationFn({
            orderNumber,
            token,
            data: {
                orderStatus: orderStatusRequest,
            },
        })
    }, {
        onSuccess: onSuccessModification,
        onError,
    })

    const conditionShowMap: ConditionShowType = useMemo(() => {
        return {
            confirmOrder: ALLOWED_CONFIRM_ORDER_STATUSES.includes(orderStatus),
            askForPrecon: orderStatus === OrderStatuses.NOT_STARTED,
            askForPooling: ALLOWED_ASK_FOR_POOLING.includes(orderStatus),
            reject: orderStatus === OrderStatuses.IN_DISCUSSION,
            discussionRequired: orderStatus === OrderStatuses.WAITING_FOR_POOLING,
        }
    }, [orderStatus])

    const allowedButtons: DataButtonType[] = useMemo(() => {
        return dataButtons.filter(({
            name,
        }) => {
            return conditionShowMap[name]
        })
    }, [conditionShowMap])

    return {
        allowedButtons,
        sendStatusOrder: mutate,
    }
}

export default useGetStatusOrderBtns
