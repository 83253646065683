const stepsRules = {
    ROAD_INTERNAL: {},
    ROAD_INTERNAL_PICKUP: {
        AIR_INTERNAL: {
            ROAD_INTERNAL_DELIVERY: {},
        },
        AIR_ROAD_INTERNAL: {},
        SEA_INTERNAL: {
            ROAD_INTERNAL_DELIVERY: {},
        },
        SEA_ROAD_INTERNAL: {},
        EVENT_EVENT: {
            ROAD_INTERNAL_DELIVERY: {},
        },
    },
    SEA_ROAD_INTERNAL: {
        ROAD_INTERNAL_DELIVERY: {},
    },
    AIR_ROAD_INTERNAL: {
        ROAD_INTERNAL_DELIVERY: {},
    },
}

export default stepsRules
