import React from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import noop from 'lodash/noop'

import {
    QuoteStatus,
} from '../AccountQuotes.types'

import useGetAccountQuote from '../hooks/useGetAccountQuote'
import QuoteInformationForm from '../QuoteInformationForm'
import RequestedOpportunities from './RequestedOpportunities'

const AccountQuoteOverview = ({
    id,
}: {
    id: number
}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetAccountQuote(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <QuoteInformationForm
                    value={data}
                    setValue={noop}
                    disabled
                    omitField={[
                        QuoteStatus.CANCELLED,
                        QuoteStatus.CREATED,
                    ].includes(data?.quoteStatus) ? [
                            'customerQuotation',
                            'pricingOwner',
                        ] : undefined}
                    render={({
                        fields, formData,
                    }) => {
                        return (
                            <DomainObjectForm
                                name="AccountQuoteInformation"
                                value={formData}
                                fields={fields}
                                disabled
                            />
                        )
                    }}
                />
                { Boolean(data?.account?.id) && (
                    <RequestedOpportunities
                        data={data}
                    />
                ) }
            </>
        </StatusHandler>
    )
}

export default AccountQuoteOverview
