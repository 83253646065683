import {
    useCallback, useMemo, useState, useEffect,
} from 'react'
import isEqual from 'lodash/isEqual'

import useDelayedLoadOptions, {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import DTOSpec from 'app/services/dtoSpec'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    AddressCategory,
} from 'app/types/enums'
import {
    getIncludeLocationTypeFilters, getLabel,
} from '../utils'

export default ({
    allowedLocationTypes = [],
    filterByCompanyId,
    filterLocationType,
    filterAddressCategory,
    filterAddressMainCategory,
    isApprovedSubContractor,
    isShippingLocation,
    classes,
    includeFilters: includeFiltersConfig,
}): (options: DelayedLoadOptions) => Promise<any> => {
    const [
        filters,
        setFilters,
    ] = useState(includeFiltersConfig)

    const getAllowedValues = useEnumValues()
    const addressCategories = useMemo(() => {
        return getAllowedValues(AddressCategory)
    }, [getAllowedValues])

    const locationConverter = useCallback((items) => {
        return items.map((location) => {
            return {
                ...location,
                label: getLabel(location, classes, addressCategories),
            }
        })
    }, [
        addressCategories,
        classes,
    ])

    const filter = useMemo(() => {
        return {
            ...includeFiltersConfig,
            companyId: filterByCompanyId && [filterByCompanyId],
            locationType: filterLocationType && (Array.isArray(filterLocationType)
                ? filterLocationType : [filterLocationType]),
            addressCategory: filterAddressCategory,
            addressMainCategory: filterAddressMainCategory && [filterAddressMainCategory],
            isApprovedSubContractor:
                isApprovedSubContractor ? [isApprovedSubContractor] : undefined,
            isShippingLocation: isShippingLocation ? [isShippingLocation] : undefined,
            ...getIncludeLocationTypeFilters(allowedLocationTypes, filterLocationType),
        }
    }, [
        includeFiltersConfig,
        allowedLocationTypes,
        filterLocationType,
        filterByCompanyId,
        filterAddressCategory,
        filterAddressMainCategory,
        isApprovedSubContractor,
        isShippingLocation,
    ])

    useEffect(() => {
        if (!isEqual(filters, includeFiltersConfig)) {
            setFilters(includeFiltersConfig)
        }
    }, [
        filters,
        includeFiltersConfig,
    ])

    const loadOptions = useDelayedLoadOptions({
        url: DTOSpec.Location.Request,
        params: {
            rows: 25,
            start: 0,
        },
        includeFilters: filter,
        convertion: locationConverter,
    })

    return useCallback(({
        phrase,
    }) => {
        return loadOptions({
            phrase,
            filters,
        })
    }, [
        filters,
        loadOptions,
    ])
}
