import {
    NestedField,
} from 'app/types/form.types'
import {
    LocationFormFields,
} from '../LocationForm.types'
import {
    ServiceCenterStatus,
} from '../../Locations.types'

export const getServiceFieldDetails = ({
    getAllowedValues,
}): NestedField<LocationFormFields>[] => {
    return [
        {
            name: 'serviceCenterPlannedStartDate',
            className: 'serviceCenterPlannedStartDate',
            componentName: 'DateSelect',
            labelKey: 'Location.serviceCenterPlannedStartDate',
        },
        {
            name: 'serviceCenterStatus',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(ServiceCenterStatus),
            labelKey: 'Location.serviceCenterStatus',
        },
        {
            name: 'enhancedSkyCenterScanning',
            componentName: 'Checkbox',
            labelKey: 'Location.enhancedSkyCenterScanning',
        },
    ]
}

export default getServiceFieldDetails
