import {
    makeStyles,
} from 'app/tss'

const storage = 'storage'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary[50],
            cursor: 'default',
        },
        gridWrapper: {
            padding: theme.spacing(1),
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
        "${storage} ${storage} ${storage} ${storage}"
        `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        storage: {
            gridArea: storage,
        },
    }
})

export default useStyles
