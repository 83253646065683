import React from 'react'

import useStyles from './DataContentWrapper.styles'

const defaultProps = {
    emptyDataMessage: 'No data available',
}
const DataContentWrapper = ({
    children,
    emptyDataMessage,
    isEmpty,
}: {
    children: JSX.Element,
    emptyDataMessage?: string,
    isEmpty: boolean,
}) => {
    const {
        classes,
    } = useStyles()

    if (isEmpty) {
        return <div className={classes.content}>{emptyDataMessage}</div>
    }
    return children
}

DataContentWrapper.defaultProps = defaultProps

export default DataContentWrapper
