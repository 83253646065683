import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    useQueryClient,
} from 'react-query'
import {
    useCallback,
} from 'react'
import config from '../Containers.request'
import {
    Container,
} from '../containers.types'

const useGetContainerBySerialNumber = (
    serialNumber,
) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getContainerBySerialNumber])
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Container>({
        key: RequestKeys.getContainerBySerialNumber,
        params: {
            serialNumber,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(serialNumber),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetContainerBySerialNumber
