import React from 'react'
import {
    Chip,
} from '@mui/material'
import showExistingValues from 'app/utils/showExistingValues'

import {
    LocationType,
} from 'app/types/enums'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import {
    LocationLabelIconType,
} from './types/LocationSelector.types'

const getIncludeLocationTypeFilters = (allowedLocationTypes, filterLocationType) => {
    return allowedLocationTypes.length && !filterLocationType
        ? {
            locationType: allowedLocationTypes,
        }
        : undefined
}

const formatLocationByLocationType = ({
    locationType,
    locationName,
    iataCode,
    unloCode,
}: Location, customConfig?: string): string => {
    switch (locationType) {
    case LocationType.SEAPORT:
    case LocationType.RAILPORT:
        return `${unloCode} | ${locationName}`
    case LocationType.AIRPORT:
        return `${iataCode} | ${locationName}`
    case LocationType.ADDRESS:
    default:
        return customConfig || locationName
    }
}

const getAddressCategories = (location, classes, adressCategories) => {
    if (location?.addressCategory.length) {
        return location.addressCategory.map((item) => {
            return (
                <Chip
                    label={adressCategories[item]}
                    className={classes.chip}
                    key={`chip-${item}`}
                    size="small"
                />
            )
        })
    }
    return null
}

const getCompanyName = (location, classes) => {
    if (location.companyName) {
        return (
            <>
                <img
                    className={classes.companyIcon}
                    alt="companyIcon"
                    src="/assets/images/icons/company.svg"
                />
                {' '}
                {location.companyName}
            </>

        )
    }
    return null
}

const getLabel = (location: Location, classes, adressCategories): JSX.Element => {
    const {
        locationName,
        addressLine1,
        zip,
        city,
        country,
        countryName,
    } = location

    const result = formatLocationByLocationType(
        location,
        showExistingValues([
            locationName,
            addressLine1,
            zip,
            city,
            countryName || country?.countryName,
        ], ', '),
    )

    const addrCategories = getAddressCategories(location, classes, adressCategories)
    const companyName = getCompanyName(location, classes)

    return (
        <>
            {result}
            {(companyName || addrCategories)
                && (
                    <div className={classes?.secondRow}>
                        {companyName}
                        {' '}
                        {addrCategories}
                    </div>
                )}
        </>
    )
}

const getLocationData = (location: Location): string[] => {
    const firstLine = formatLocationByLocationType(location)

    const {
        addressLine1, addressLine2, addressLine3, city, zip, country,
    } = location

    return [
        firstLine,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        zip,
        country?.countryName,
    ].filter(Boolean)
}

const getExpandedInfoByLocation = (
    location: Location,
): LocationLabelIconType => {
    if (!location) {
        return null
    }

    const {
        locationType,
    } = location

    const label = getLocationData(location).join('\n')

    return getLocationLabelIcon(label, locationType)
}

const getBasicInfoByLocation = (
    location: Location,
): LocationLabelIconType => {
    if (!location) {
        return null
    }

    const {
        locationType,
        locationName,
        city,
    } = location

    const label = formatLocationByLocationType(location, `${locationName}${city ? `, ${city}` : ''}`)

    return getLocationLabelIcon(label, locationType)
}

const getLocationLabelIcon = (label: string, locationType: LocationType): LocationLabelIconType => {
    return {
        label,
        iconPath: `assets/images/locationSelector/${locationType.toLowerCase()}.svg`,
    }
}

const getTooltipData = (location: Location): JSX.Element => {
    if (!location) {
        return null
    }

    const {
        id,
    } = location

    return (
        <>
            {getLocationData(
                location,
            ).map((el) => {
                return <div key={`location${id}-${el}`}>{el}</div>
            })}
        </>
    )
}

const getConvertionFn = ({
    classes,
    addressCategories,
}: {
    classes: Record<string, any>,
    addressCategories: Record<string, string>,
}) => {
    return (locations) => {
        return locations.map((location) => {
            return {
                ...location,
                label: getLabel(location, classes, addressCategories),
                value: location.id,
                textLabel: formatLocationByLocationType(location),
            }
        })
    }
}

const airportsConvertionFn = (locations) => {
    return locations.map((location) => {
        const {
            iataCode, locationName,
        } = location

        return {
            ...location,
            label: showExistingValues([
                iataCode,
                locationName,
            ], ' | '),
            value: location.id,
            textLabel: location.iataCode,
        }
    })
}

export {
    getIncludeLocationTypeFilters,
    formatLocationByLocationType,
    getLabel,
    getExpandedInfoByLocation,
    getBasicInfoByLocation,
    getTooltipData,
    getConvertionFn,
    airportsConvertionFn,
}
