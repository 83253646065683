import React, {
    useCallback,
    useMemo,
} from 'react'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    OrderTemperatureRange,
} from 'app/types/enums'

import useGetAvailableLanesForCTCreation from './hooks/useGetAvailableLanesForCTCreation'
import useStyles from './AvailableLanes.style'
import columns from './AvailableLanes.columns'

type Value = {
    customerExecution: {id: number},
    account: {id: number},
    originAirport: {id: number},
    destinationAirport: {id: number},
    handoverPointId: number,
    collectionDropoffPointId: number,
    consignee: {id: number},
    temperatureRange: OrderTemperatureRange,
}

type Props = {
    value: Value,
    onChange: (...args: any[]) => void

}

const DOMAIN_NAME = 'LaneCustomerTransportCreationView'

const AvailableLanes = (props: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        value: {
            customerExecution,
            account,
            originAirport,
            destinationAirport,
            handoverPointId,
            collectionDropoffPointId,
            consignee,
            temperatureRange,
        },
        onChange,
    } = props

    const {
        onParamsChange,
        loadData,
    } = useGetAvailableLanesForCTCreation()

    const filter = useMemo(() => {
        return {
            customerExecutionId: customerExecution?.id && [customerExecution.id],
            consigneeId: consignee?.id && [consignee.id],
            originAirportId: originAirport?.id && [originAirport.id],
            destAirportId: destinationAirport?.id && [destinationAirport.id],
            accountId: account?.id && [account.id],
            laneGroupTempRange: temperatureRange && [temperatureRange],
            laneGroupHandoverPointId: handoverPointId && [handoverPointId],
            laneGroupCollectionDropoffPointId: collectionDropoffPointId
                && [collectionDropoffPointId],
        }
    }, [
        collectionDropoffPointId,
        consignee?.id,
        customerExecution?.id,
        destinationAirport?.id,
        handoverPointId,
        originAirport?.id,
        account?.id,
        temperatureRange,
    ])

    const onSelect = useCallback((id) => {
        onChange(id)
    }, [onChange])

    const fields = useMemo(() => {
        return columns(onSelect)
    }, [onSelect])

    return (
        <RichTable
            configName="AvailableLanes"
            name={DOMAIN_NAME}
            load={loadData}
            onParamsChange={onParamsChange}
            filter={filter}
            columns={fields}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
        />
    )
}

export default AvailableLanes
