import React, {
    useState, useMemo, useCallback,
} from 'react'

import useLabel from 'app/hooks/useLabel'
import useEnumValues from 'app/hooks/useEnumValues'
import SkyNetSpreadSheet from 'app/shared-components/SkyNetSpreadSheet'
import Card from 'app/shared-components/Card'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import getConfig from './ForecastSpreadsheet.config'
import {
    ForecastDataSpreadsheetColumnsConfig,
    ForecastSpreadsheetDataType,
} from '../Forecast.types'
import useStyles from './ForecastSpreadsheet.styles'

const defaultProps = {
    onSave: undefined,
    onCancel: undefined,
    data: [],
}

const ForecastSpreadsheet = ({
    data,
    onSave,
    onCancel,
    tmpFields,
}: {
    data?: ForecastSpreadsheetDataType[]
    onSave?: (...args: any[]) => void,
    onCancel?: (...args: any[]) => void,
    tmpFields: ForecastDataSpreadsheetColumnsConfig,
}) => {
    const [
        value,
        setValue,
    ] = useState(data)

    const {
        classes,
    } = useStyles()

    const getLabel = useLabel()
    const getAllowedValues = useEnumValues()

    const config = useMemo(() => {
        return getConfig({
            getLabel,
            getAllowedValues,
            tmpFields,
        })
    }, [
        getLabel,
        getAllowedValues,
        tmpFields,
    ])

    const handleSave = useCallback(() => {
        onSave(value)
    }, [
        value,
        onSave,
    ])

    return (
        <div className={classes.wrapper}>
            <Card>
                <SkyNetSpreadSheet
                    config={config}
                    value={value}
                    disabled={false}
                    onChange={setValue}
                />
            </Card>
            <ButtonContainer>
                <Button
                    label="Save"
                    onClick={handleSave}
                />
                <Button
                    secondary
                    label="Cancel"
                    onClick={onCancel}
                />
            </ButtonContainer>
        </div>
    )
}

ForecastSpreadsheet.defaultProps = defaultProps

export default ForecastSpreadsheet
