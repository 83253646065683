import React, {
    useCallback,
    useEffect,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'

import {
    Validate,
} from 'app/types/form.types'
import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'
import {
    valueConverterToArray,
    valueConverterToObject,
} from './utils/converters'
import getValueLabelByEmail from './utils/getValueLabelByEmail'
import useCommonMultiContactSelectorLogic, {
    Value,
} from './hooks/useCommonMultiContactSelectorLogic'
import useValidateItem from '../../hooks/useValidateItem'

type Props = {
    name?: string,
    value?: Value[],
    onChange: (newValue: Value[]) => void,
    includeFilters?: Record<string, string[]>,
    getValueLabel?: (val: Record<string, any>) => string,
    title?: string,
    className?: string,
    disabled?: boolean,
    required?: boolean,
    validate?: Validate<Contact[]>,
    setErrors?: (e: [string, any[]]) => void,
}

const defaultProps: Partial<Props> = {
    value: [],
    title: 'Contacts',
    name: undefined,
    disabled: false,
    required: false,
    className: '',
    includeFilters: undefined,
    getValueLabel: undefined,
    validate: undefined,
    setErrors: undefined,
}

const MultipleContactObjectSelector = ({
    name,
    value,
    onChange,
    title,
    disabled,
    required,
    className,
    includeFilters,
    getValueLabel,
    validate,
    setErrors,
}: Props) => {
    const {
        contacts,
        setContacts,
        loadOptions,
        contactsByIds,
    } = useCommonMultiContactSelectorLogic({
        valueConverter: valueConverterToArray,
        includeFilters,
        listIds: value,
    })

    useEffect(() => {
        if (contactsByIds && JSON.stringify(contactsByIds) !== JSON.stringify(contacts)) {
            setContacts(contactsByIds)
        }
    }, [
        contacts,
        contactsByIds,
        setContacts,
    ])

    const onChangeValue = useCallback((newValue: Value[], actionParams): void => {
        let updatedValues

        if (actionParams?.action === 'remove-value') {
            updatedValues = contacts.filter(({
                id,
            }) => { return id !== actionParams?.removedValue?.id })
        } else {
            updatedValues = newValue
        }

        onChange(updatedValues ? valueConverterToObject(updatedValues) : [])
    }, [
        contacts,
        onChange,
    ])

    const errors = useValidateItem({
        validate, value: contacts, setFormError: setErrors, key: name,
    })

    return (
        <div className={className}>
            <ChipSelect
                title={title}
                value={contacts}
                onChange={onChangeValue}
                loadOptions={loadOptions}
                getValueLabel={getValueLabel || getValueLabelByEmail}
                showTitleOptions
                disabled={disabled}
                required={required}
                errors={errors}
                validated={Boolean(validate)}
            />
        </div>
    )
}

MultipleContactObjectSelector.defaultProps = defaultProps

export default MultipleContactObjectSelector
