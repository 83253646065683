import {
    NestedField,
} from 'app/types/form.types'
import {
    LocationType,
} from 'app/types/enums'
import {
    LocationFormFields,
} from '../LocationForm.types'

const getLocationFieldDetails = ({
    isNew,
    hasEditPermission,
    isProspect,
    orignalIsProspect,
    isAebServiceProvider,
    isAddress,
}): NestedField<LocationFormFields>[] => {
    const prospect:NestedField<LocationFormFields> = {
        name: 'isProspect',
        componentName: 'Switch',
        labelKey: 'LocationRegular.isProspect',
        disabled: isNew
            ? !hasEditPermission
            : orignalIsProspect === false || !hasEditPermission,
    }
    const locationFields:NestedField<LocationFormFields> = {
        name: 'locationName',
        componentName: 'InputSingleline',
        labelKey: 'Location.locationName',
        required: true,
    }
    const locationRules:NestedField<LocationFormFields> = {
        name: 'locationNameRule',
        componentName: 'InputRule',
        rule: 'Name + City + (Country code)',
        example: 'Kuehne & Nagel Mechelen (BE)',
        labelKey: 'Location.locationNameRule',
    }

    const aebServiceProvider:NestedField<LocationFormFields> = {
        name: 'aebServiceProviderId',
        componentName: 'InputSingleline',
        labelKey: 'Location.isAebServiceProvider',
    }
    const isShippingLocation:NestedField<LocationFormFields> = {
        name: 'isShippingLocation',
        componentName: 'Switch',
        labelKey: 'Location.isShippingLocation',
    }
    const portOfReference:NestedField<LocationFormFields> = {
        name: 'portOfReference',
        componentName: 'LocationObjectSelector',
        labelKey: 'LocationView.portOfReference',
        filterLocationType: [
            LocationType.AIRPORT,
            LocationType.SEAPORT,
        ],
        isShippingLocation: true,
    }
    const attachmentsFields:NestedField<LocationFormFields> = {
        name: 'attachments',
        labelKey: 'Location.attachments',
        componentName: 'AttachmentsObjectSelector',
    }

    return [
        locationFields,
        !isProspect ? locationRules : undefined,
        isAddress ? prospect : undefined,
        !isProspect ? isShippingLocation : undefined,
        isAebServiceProvider ? aebServiceProvider : undefined,
        isAddress ? portOfReference : undefined,
        attachmentsFields,
    ].filter(Boolean)
}

export default getLocationFieldDetails
