import {
    useMemo, useCallback,
} from 'react'
import noop from 'lodash/noop'
import {
    useQuery,
    QueryFunction,
    useQueryClient,
    UseQueryResult,
} from 'react-query'
import {
    useDispatch,
} from 'react-redux'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'

export type UseRequestParam = {
    key: RequestKeys,
    params?: Record<string, any>,
    requestFunc: (dispatch: (any) => void) => QueryFunction,
    onSuccess?: (any) => void,
    refetchInterval?: number | false | ((any) => number | false),
    refetchIntervalInBackground?: boolean,
    enabled?: boolean,
    keepPreviousData?: boolean,
    staleTime?: number,
}

export type UseRequestResponse<T> = {
    data: T,
    isDataReady: boolean,
    isFetching: boolean,
    isError: boolean,
    error: any,
    refetch: (options?: {
        throwOnError: boolean,
        cancelRefetch: boolean
    }) => Promise<UseQueryResult>,
    invalidate: () => void,
}

const useRequest = <Type = TableResponse>({
    key,
    params,
    requestFunc,
    enabled = true,
    onSuccess = noop,
    refetchInterval = false,
    refetchIntervalInBackground = false,
    keepPreviousData = false,
    staleTime = 1000,
}: UseRequestParam): UseRequestResponse<Type> => {
    const token = useJWTToken()
    const dispatch = useDispatch()

    const {
        data,
        isSuccess,
        isFetching,
        isError,
        error,
        refetch,
    } = useQuery({
        queryKey: [
            key,
            {
                ...params,
                token,
            },
        ],
        queryFn: requestFunc(dispatch),
        enabled,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime,
        refetchInterval,
        refetchIntervalInBackground,
        keepPreviousData,
        onSuccess,
    })

    const isDataReady = useMemo((): boolean => {
        return isSuccess && !isFetching
    }, [
        isSuccess,
        isFetching,
    ])

    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([key])
    }, [
        queryClient,
        key,
    ])

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        invalidate,
    }
}

export default useRequest
