import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CollServiceType, TransportModeRoad, Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LaneGroupTemperatureRange,
    ProductType,
} from 'app/types/enums'
import {
    Location,
} from 'app/shared-components/LocationSelector/types/LocationSelector.types'

export enum Selection {
    DEFAULT = 'DEFAULT',
    BACKUP = 'BACKUP'
}

export type SelectLaneStepType = {
    deliveryServiceType: DeliveryServiceType,
    handoverPoint: {id: number},
    collectionServiceType: CollServiceType,
    collectionDropoffPoint: {id: number},
    originAirport: {id: number},
    destinationAirport: {id: number},
    tempRange: LaneGroupTemperatureRange,
    customerExecution: {id: number},
    account: {id: number},
    selectedLaneId: number,
    consignee: Location,
}

export type AdditionalLaneStepType = {
    deliveryServiceType: DeliveryServiceType,
    handoverPoint: {id: number},
    collectionServiceType: CollServiceType,
    collectionDropoffPoint: {id: number},
    originAirport: {id: number},
    destinationAirport: {id: number},
    tempRange: LaneGroupTemperatureRange,
    customerExecution: {id: number},
    account: {id: number},
    consignee: {id: number},
    baseLeaseDays?: number,
    transportModeRoad: TransportModeRoad,
    isLtlPositioningTemperatureControlled: boolean,
    isFtlPositioningTemperatureControlled: boolean,
    handoverPointContacts: { id: number}[],
    collectionDropoffPointContacts: { id: number}[],
}

export type BookingDetailsStepType = {
    orderReceived: string,
    requestedPickup: string,
    requestedDelivery: string,
    customerReference: string,
    leaseStartTimestamp: string,
    requiredContainers: {
        id: number,
        productType: ProductType,
        quantity: number,
    }[],
    createdLane?: Lane,
}

export type FinalizeBookingStepType = {
    transportModeRoad?: TransportModeRoad,
    transportModeForwarder?: TransportModeRoad,
    numberOfTrucks: number,
    numberOfVans: number,
    orderEntered: string,
    mawbNumber: string,
    originScSelection: Selection,
    originSc?: {id: number},
    originScBackup?: {id: number},
    fwdPosBackup?: {id: number},
    hawbNumber: string,
    estimatedPickup: string,
    fwdPosSelection: Selection,
    commentForBillingOps: string,
    personInCharge: string,
    freightForwarder: {id: number},
    minOrder: number,
    requiredCargoComment: string,
}

export type CreateFormType = {
    firstStep: SelectLaneStepType,
    secondStep: SelectLaneStepType | Lane,
    thirdStep: BookingDetailsStepType,
    fourthStep: Lane,
    fifthStep: FinalizeBookingStepType,
}

export type AvailableLanesFilters = {
    accountId: [number],
    deliveryServiceType: [DeliveryServiceType],
    collectionServiceType: [CollServiceType],
    tempRange: [LaneGroupTemperatureRange],
    handoverPointId: [number],
    collectionDropoffPointId: [number],
    originAirportId: [number],
    destAirportId: [number],
    customerExecutionId: [number],
}

export type AutomaticQuoteRequestParams = {
    account: {id: number},
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    tempRange: LaneGroupTemperatureRange,
    handoverPoint: {id: number},
    collectionDropoffPoint: {id: number},
    originAirport: {id: number},
    destinationAirport: {id: number},
    customerExecution: {id: number},
    transportModeRoad: TransportModeRoad,
    isLtlPositioningTemperatureControlled: boolean,
    isFtlPositioningTemperatureControlled: boolean,
    consignee: {id: number},
    baseLeaseDays?: number,
    leaseStartTimestamp: string,
    requiredContainers: {
        id: number,
        productType: ProductType,
        quantity: number,
    }[]
}

export type CustomerTransportRequestParams = {
    lane?: {
        id: number,
    },
    freightForwarder?: {
        id: number,
    },
    orderReceived: string,
    orderEntered: string,
    requestedPickup?: string,
    requestedDelivery?: string,
    requiredContainers: {
        id: number,
        productType: ProductType,
        quantity: number,
    }[],
    commentForBillingOps: string,
    requiredCargoComment: string,
    estimatedPickup?: string,
    numberOfTrucks: number,
    numberOfVans: number,
    transportModeRoad?: TransportModeRoad,
    transportModeForwarder?: TransportModeRoad,
    originScSelection: Selection,
    fwdPosSelection: Selection,
    customerReference: string,
    personInCharge: string,
    leaseStartTimestamp: string,
    mawbNumber: string,
    hawbNumber: string,
}
