import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'

import {
    UnassignedToOpportunity,
} from '../KamDashboard.types'
import {
    DeliveryServiceType,
} from '../../../OrderManagement/Lanes/lanes.types'
import {
    CollectionServiceType,
} from '../../../OrderManagement/Orders/orders.types'
import {
    LaneStatus,
} from '../../AccountLanes/AccountLanes.types'

const toBeAssignedLanesConfig: SkyNetTableConfig<keyof UnassignedToOpportunity> = {
    name: 'toBeAssigned',
    tabs: [{
        name: 'To Be Assigned',
        url: 'all',
    }],
    fields: [
        {
            name: 'laneNumber',
            labelKey: 'AccountLane.laneNumber',
        },
        {
            name: 'laneGroupNumber',
            labelKey: 'Lane.laneGroup',
        },
        {
            name: 'accountCompanyCompanyName',
            filterField: 'account',
            filterType: FilterType.Array,
            labelKey: 'AccountLane.accountCompanyName',
        },
        {
            name: 'temperatureRange',
            componentName: 'EnumValue',
            allowedValuesEnum: LaneGroupTemperatureRange,
            filterField: 'temperatureRange',
            filterType: FilterType.Array,
            labelKey: 'AccountLane.temperatureRange',
        },
        {
            name: 'customerQuotation',
            filterField: 'customerQuotation',
            filterType: FilterType.Array,
            labelKey: 'AccountQuotes.customerQuotation',
        },
        {
            name: 'deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
            labelKey: 'LaneGroupView.deliveryService',
        },
        {
            name: 'collectionServiceType',
            labelKey: 'LaneGroupView.collService',
            componentName: 'EnumValue',
            allowedValuesEnum: CollectionServiceType,
        },
        {
            name: 'originAirport',
            labelKey: 'OrderView.from',
        },
        {
            name: 'destinationAirport',
            labelKey: 'OrderView.to',
        },
        {
            name: 'laneStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: LaneStatus,
            labelKey: 'LaneQuoteRequest.selectedLaneStatus',
        },
        {
            name: 'changedOn',
            labelKey: 'KamDashboard.lastUpdated',
        },
    ],
}

export default toBeAssignedLanesConfig
