import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import useFilter from 'app/hooks/useFilter'
import OpportunitiesSpreadSheet from '../OpportunitiesSpreadSheet'
import useOpportunitiesBulkCreate from './hooks/useOpportunitiesBulkCreate'

const OpportunitiesBulkCreate = ({
    backUrl,
}: {
    backUrl: string
}) => {
    const history = useHistory()
    const filterOptions = useFilter()

    const onSuccess = useCallback(() => {
        history.push(backUrl)
    }, [
        history,
        backUrl,
    ])

    const opportunitiesCreate = useOpportunitiesBulkCreate()

    const onSave = useCallback((opportunities, options?: {
        onSuccess?: (...a: any[]) => void
    }) => {
        opportunitiesCreate(opportunities, {
            onSuccess: options?.onSuccess || onSuccess,
        })
    }, [
        onSuccess,
        opportunitiesCreate,
    ])

    return (
        <OpportunitiesSpreadSheet
            filterOptions={filterOptions}
            onSave={onSave}
            onCancel={onSuccess}
            enableAddNewRows
        />
    )
}

export default OpportunitiesBulkCreate
