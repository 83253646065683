import React, {
    useCallback,
    useState,
} from 'react'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'

import useStyles from './RelinkAdditionalFeesDialog.style'
import AdditionalFeesSelector from './AdditionalFeesSelector'
import useRelinkAdditionalFee from './hooks/useRelinkAdditionalFee'

const DIALOG_TITLE = 'RELINK ADDITIONAL FEES'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    orderNumber: string,
    onSuccess: () => void
}

const RelinkAdditionalFeesDialog = ({
    open,
    setOpen,
    onSuccess,
    orderNumber,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const [
        additionalFee,
        setAdditionalFee,
    ] = useState<number>()

    const onSuccessRelink = (): void => {
        setOpen(false)
        onSuccess()
    }

    const {
        isLoading,
        mutate,
    } = useRelinkAdditionalFee(onSuccessRelink)

    const relinkAdditionalFees = useCallback((): void => {
        mutate({
            orderNumber,
            data: {
                id: additionalFee,
            },
        })
    }, [
        additionalFee,
        mutate,
        orderNumber,
    ])

    const handleClose = useCallback((action: boolean): void => {
        if (!action) {
            setOpen(false)
            setAdditionalFee(undefined)

            return
        }

        relinkAdditionalFees()
    }, [
        relinkAdditionalFees,
        setOpen,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="relink"
            negativeLabel="cancel"
            isLoading={isLoading}
            classNames={{
                paper: classes.paper,
            }}
        >
            <div className={classes.gridWrapper}>
                <AdditionalFeesSelector
                    value={additionalFee}
                    onChange={setAdditionalFee}
                    orderNumber={orderNumber}
                />
            </div>
        </ConfirmationDialog>
    )
}

export default RelinkAdditionalFeesDialog
