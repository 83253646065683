import {
    useCallback,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    useQueryClient,
} from 'react-query'

import useRequestTable from 'app/hooks/useRequestTable'
import {
    Position,
    Position as PositionType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    RichTableData,
} from 'app/types/richtable.types'

import request from '../../invoce.request'

const useGetPositionsByInvoiceId = (invoiceId) => {
    const queryClient = useQueryClient()
    const refresh = () => {
        queryClient.invalidateQueries([RequestKeys.getPositionsByInvoiceId])
    }

    const {
        data,
    } = useRequestTable<RichTableData<PositionType[]>>({
        key: RequestKeys.getPositionsByInvoiceId,
        params: {
            invoiceId,
        },
        config: request.GetPositionsByInvoiceId.requestFn,
        enabled: Boolean(invoiceId),
    })

    const load = useCallback((): Promise<RichTableData<Position[]>> => {
        if (!data) {
            return Promise.resolve({
                data: [],
            })
        }
        return Promise.resolve(data)
    }, [data])

    return {
        refresh,
        load,
    }
}

export default useGetPositionsByInvoiceId
