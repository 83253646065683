import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import {
    FreightTypes, OrderTemperatureRange,
} from 'app/types/enums'

import {
    InternalOrderForm,
} from './createInternalTransportForm.types'
import OrderInformation from './Steps/OrderInformation'
import SelectionOrderStep from './Steps/SelectionOrderStep'

const getSteps = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    setIsEdited,
}) => {
    return [
        {
            key: '0',
            label: 'Fill in Order Information',
            expanded: true,
            renderContent: ({
                index,
            }: {
            index: number,
        }) => {
                return (
                    <OrderInformation
                        value={value}
                        onChange={onChange}
                        index={index}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                )
            },
        },
        {
            key: '1',
            label: 'Delivery Options',
            expanded: (currentStep) => {
                return currentStep > 1
            },
            renderContent: ({
                index,
            }: {
                index: number,
            }) => {
                return (
                    <SelectionOrderStep
                        value={value}
                        onChange={onChange}
                        index={index}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        setIsEdited={setIsEdited}
                    />
                )
            },
        },
        {
            key: '2',
            label: 'Delivery Options',
            expanded: (currentStep) => {
                return currentStep > 2
            },
            renderContent: ({
                index,
            }: {
                index: number,
            }) => {
                return (
                    <SelectionOrderStep
                        value={value}
                        onChange={onChange}
                        index={index}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        setIsEdited={setIsEdited}
                    />
                )
            },
        },
        {
            key: '3',
            label: 'Delivery Options',
            expanded: (currentStep) => {
                return currentStep > 3
            },
            renderContent: ({
                index,
            }: {
                index: number,
            }) => {
                return (
                    <SelectionOrderStep
                        value={value}
                        onChange={onChange}
                        index={index}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        setIsEdited={setIsEdited}
                    />
                )
            },
        },
    ]
}

const CreateInternalTransportForm = () => {
    const {
        state = {},
    } = useLocation()

    const {
        collectionDropoffPointId,
        selectedPreviousOrderId,
    } = state

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const [
        value,
        setValue,
    ] = useState<InternalOrderForm>({
        freightType: FreightTypes.CONTAINERS,
        temperatureRange: OrderTemperatureRange.ANY_TEMP_RANGE,
        location: collectionDropoffPointId && {
            id: collectionDropoffPointId,
        },
        selectPreviousOrders: Boolean(collectionDropoffPointId),
        previousOrders: selectedPreviousOrderId && [selectedPreviousOrderId],
        orderSteps: [],
    })

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    const onChange = useCallback((newVal: InternalOrderForm) => {
        setValue(newVal)
        setIsEdited(true)
    }, [])

    useBeforeUnloadDialog(isEdited)

    const stepperSteps = useMemo(() => {
        return getSteps({
            value,
            onChange,
            activeStep,
            setActiveStep,
            setIsEdited,
        }).slice(0, activeStep + 1)
    }, [
        activeStep,
        onChange,
        value,
    ])

    return (
        <>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </>
    )
}

export default CreateInternalTransportForm
