import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    LeaseType, LocationRegion,
    ProductType, TemperatureRange,
} from 'app/types/enums'
import {
    ForecastOngoingOpportunitiesTable,
} from '../Forecast.types'

const config = ({
    tmpFields,
}: {
    tmpFields: Record<string, any>[]
}): SkyNetTableConfig<keyof ForecastOngoingOpportunitiesTable> => {
    return {
        name: 'ForecastOngoingOpportunities',
        tabs: [{
            name: 'Ongoing Opportunities',
            url: 'all',
        }],
        fields: [
            {
                name: 'accountName',
                labelKey: 'Forecasts.accountName',
                filterField: 'accountName',
                filterType: FilterType.Array,
                reduced: true,
            },
            {
                name: 'opportunityNumber',
                labelKey: 'Forecasts.opportunityNumber',
                reduced: true,
            },
            {
                name: 'temperatureRange',
                labelKey: 'AccountQuotes.opportunityTempRange',
                componentName: 'EnumValue',
                allowedValuesEnum: TemperatureRange,
                reduced: true,
            },
            {
                name: 'productType',
                labelKey: 'Forecasts.productType',
                componentName: 'EnumValue',
                allowedValuesEnum: ProductType,
            },
            {
                name: 'leaseType',
                labelKey: 'Forecasts.leaseType',
                componentName: 'EnumValue',
                allowedValuesEnum: LeaseType,
            },
            {
                name: 'originAirportRegion',
                labelKey: 'RegionalGroups.originRegion',
                componentName: 'EnumValue',
                allowedValuesEnum: LocationRegion,
            },
            {
                name: 'originAirportIataCode',
                labelKey: 'Forecasts.originAirport',
            },
            {
                name: 'destinationAirportRegion',
                labelKey: 'RegionalGroups.destinationRegion',
                componentName: 'EnumValue',
                allowedValuesEnum: LocationRegion,
            },
            {
                name: 'destinationAirportIataCode',
                labelKey: 'Forecasts.destinationAirport',
            },
            {
                name: 'changedOn',
                labelKey: 'Forecasts.validFrom',
            },
            {
                name: 'responsible',
                labelKey: 'Forecasts.responsible',
                filterField: 'responsible',
                filterType: FilterType.Array,
            },
            ...tmpFields.map((fieldProps) => {
                return fieldProps
            }),
        ],
    }
}

export default config
