import {
    makeStyles,
} from 'app/tss'

const selection = 'selection'
const requiredContainers = 'requiredContainers'
const positioningSetup = 'positioningSetup'
const availableLanes = 'availableLanes'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridRowGap: theme.spacing(2),
            gridTemplateAreas: `
                "${selection}"
                "${availableLanes}"
            `,
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        gridWrapperSelectedLane: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${selection} ${selection} ${selection} ${selection}"
                "${positioningSetup} ${positioningSetup} ${positioningSetup} ${requiredContainers}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        availableLanes: {
            gridArea: availableLanes,
            height: '100%',
            minHeight: 500,
        },
        selection: {
            gridArea: selection,
        },
        requiredContainers: {
            gridArea: requiredContainers,
        },
        positioningSetup: {
            gridArea: positioningSetup,
        },
        numberOfTrucks: {
            '&&': {
                width: '50%',
                paddingRight: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        numberOfVans: {
            '&&': {
                width: '50%',
                paddingLeft: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        wrapper: {
            '&&': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        title: {
            '&&': {
                paddingLeft: theme.spacing(2),
            },
        },
    }
})

export default useStyles
