import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'

export default ({
    id, onSuccess,
}: {
    id: number,
    onSuccess: (...args: any[]) => void
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessAssigning,
        onError,
    } = useHandleStatusRequest({
        title: 'Lane was rejected',
        id: 'LaneRejected',
        onSuccess,
    })

    const {
        mutate: reject,
    } = useMutation((laneId: number) => {
        return simpleApi({
            ...requests.rejectPricing({
                id: laneId,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessAssigning,
    })

    return useCallback(() => {
        reject(id)
    }, [
        reject,
        id,
    ])
}
