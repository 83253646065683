import {
    useMemo,
} from 'react'

import {
    tss,
} from 'app/tss'

import {
    useTheme,
} from '@mui/material/styles'

const useStyles = () => {
    const theme = useTheme()

    return useMemo(() => {
        const styles = {
            cell: {
                ...theme.typography.body1,
                color: theme.palette.secondary[500],
                paddingLeft: '2px',
                border: {
                    left: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                    right: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                    bottom: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                    top: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                },
            },
            header: {
                ...theme.typography.h4,
                background: theme.palette.common.white,
                fontWeight: 500,
                textWrap: 'balance',
            },
            scrolledGridContainer: {
                'overflow-y': 'auto',
                'overflow-x': 'auto',
                width: '100%',
                paddingBottom: '20px',
            },
            recalculatedContainer: {
                'overflow-x': 'hidden',
            },
            required: {
                background: theme.palette.primary[30],
            },
            disabled: {
                background: theme.palette.secondary[100],
            },
            hidden: {
                display: 'none',
            },
        }

        return {
            getClasses: tss.create({
                scrolledGridContainer: styles.scrolledGridContainer,
                recalculatedContainer: styles.recalculatedContainer,
            } as Record<string, any>),
            styles,
        }
    }, [theme])
}

export default useStyles
