import {
    Currency,
    paymentSchedule,
    operationalDays,
} from 'app/types/enums'
import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    ServiceCenterMetrics,
} from '../Locations.types'

const LocationFormServiceCenterMetricsFields = ({
    getAllowedValues,
}): DTOCardFormField<keyof ServiceCenterMetrics>[] => {
    return [{
        id: 'serviceCenterMetrics',
        title: 'Service Center Metrics',
        className: 'serviceCenterMetrics',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Currency),
                        labelKey: 'AccountQuotes.currency',
                        required: true,
                        useDropdown: true,
                    },
                    {
                        name: 'paymentSchedule',
                        labelKey: 'ServiceCenter.paymentSchedule',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(paymentSchedule),
                        required: true,
                        useDropdown: true,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'fixedCosts',
                        componentName: 'IntegerInput',
                        labelKey: 'ServiceCenter.fixedCosts',
                        required: true,
                    },
                    {
                        name: 'operationalDays',
                        labelKey: 'ServiceCenter.operationalDays',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(operationalDays),
                        required: true,
                        useDropdown: true,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'variableCosts',
                        componentName: 'IntegerInput',
                        labelKey: 'ServiceCenterMetrics.variableCosts',
                        required: true,
                    },
                    {
                        name: 'pcmCapacity',
                        componentName: 'IntegerInput',
                        labelKey: 'ServiceCenterMetrics.pcmCapacity',
                        required: true,
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'dryIceCapacity',
                        componentName: 'IntegerInput',
                        labelKey: 'ServiceCenterMetrics.dryIceCapacity',
                        required: true,
                    },
                    {
                        name: 'workforce',
                        componentName: 'IntegerInput',
                        labelKey: 'ServiceCenterMetrics.workforce',
                        required: true,
                    },
                ],
            },
        ],
    }]
}

export default LocationFormServiceCenterMetricsFields
