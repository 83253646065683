/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useState,
    useCallback,
    useMemo,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'

import {
    dayPassedToRange,
} from 'app/utils/date'
import useTabsContext from 'app/shared-components/TabNavigation/hooks/useTabsContext'
import useGetSensorDataForContainer from 'app/hooks/useGetSensorData/useGetSensorDataForContainer'
import {
    loggerTypeOptions,
    getLoggerPositionsConfig,
} from 'app/utils/optionsLoggers'
import {
    LoggerType,
} from 'app/types/enums'
import {
    ConfigLogger, LoggerPosition,
} from 'app/Apps/Loggers/Loggers.types'
import ContainerTemperatureChart from './ContainerTemperatureChart'

type Props = {
    defaultAmbientLoggerType: LoggerType,
    defaultInternalLoggerType: LoggerType,
    serialNumber: string,
}

const ContainerTemperatureChartContainer = ({
    serialNumber,
    defaultAmbientLoggerType,
    defaultInternalLoggerType,
}: Props) => {
    const {
        baseUrl,
    } = useTabsContext()

    const history = useHistory()

    const [
        timeRange,
        setRange,
    ] = useState(dayPassedToRange(7))

    const [
        isDateRange,
        setDateRange,
    ] = useState(false)

    const containerLoggerTypes: ConfigLogger = useMemo(() => {
        return {
            title: 'Logger Types',
            name: 'loggerTypes',
            options: Array.from(new Set([
                defaultInternalLoggerType,
                defaultAmbientLoggerType,
            ])).map((type) => { return loggerTypeOptions[type] }),
        }
    }, [
        defaultAmbientLoggerType,
        defaultInternalLoggerType,
    ])

    const containerLoggerPositions = useMemo(() => {
        return getLoggerPositionsConfig({
            positions: [
                LoggerPosition.INTERNAL,
                LoggerPosition.AMBIENT,
            ],
        })
    }, [])

    const {
        sensorData,
    } = useGetSensorDataForContainer({
        serialNumber,
        from: timeRange.from,
        to: timeRange.to,
        configLoggers: [
            containerLoggerTypes,
            containerLoggerPositions,
        ],
        isDateRange,
    })

    const onFullScreen = useCallback((e) => {
        e.preventDefault()
        history.push(`${baseUrl}/sensordata`)
    }, [
        baseUrl,
        history,
    ])

    return (
        <ContainerTemperatureChart
            {...sensorData}
            setRange={setRange}
            timeRange={timeRange}
            onFullScreen={onFullScreen}
            isDateRange={isDateRange}
            setDateRange={setDateRange}
        />
    )
}

export default ContainerTemperatureChartContainer
