import {
    FieldInCard,
    FormPart,
    NestedField,
} from 'app/types/form.types'
import {
    TypeWithId,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'

import {
    LaneStatus, LaneFeesStatus,
} from 'app/types/enums'
import {
    PrintFilterValues, QUOTATION, EXECUTION,
} from './PrintForm.types'

const allowedCustomerTypes = {
    Quotation: QUOTATION,
    Execution: EXECUTION,
}

type PrintFilterFields = keyof PrintFilterValues

type Params = {
    state: Partial<PrintFilterValues>,
    contractId: number,
    laneGroups: TypeWithId[],
    disabledAll: boolean,
    getAllowedValues: (props: Record<string, string>) => Record<string, string>,
    showComment: boolean,
}

const printFormFields = ({
    state,
    contractId,
    laneGroups,
    disabledAll,
    getAllowedValues,
    showComment,
}: Params): FormPart<PrintFilterFields>[] => {
    const restrictAirport: NestedField<PrintFilterFields> = {
        name: 'restrictAirports',
        componentName: 'Checkbox',
        labelKey: 'LaneQuoteRequest.restrictAirports',
        className: 'checkBoxOnTop',
    }
    const allowDuplication: NestedField<PrintFilterFields> = {
        name: 'allowDuplication',
        componentName: 'Checkbox',
        labelKey: 'ContractBasisUpdateDto.allowDuplication',
    }

    const descriptionColumn: FieldInCard<PrintFilterFields>[] = [{
        id: 'column4',
        fields: [{
            name: 'comment',
            labelKey: 'ContractBasisUpdateDto.comment',
            componentName: 'LongText',
        }],
    }]

    const defaultColumns: FieldInCard<PrintFilterFields>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'selectedLaneFeesStatuses',
                    labelKey: 'LaneQuoteRequest.selectedLaneFeesStatuses',
                    componentName: 'MultiEnumSelector',
                    allowedValues: getAllowedValues(LaneFeesStatus),
                },
                {
                    name: 'selectedLaneStatus',
                    labelKey: 'LaneQuoteRequest.selectedLaneStatus',
                    componentName: 'MultiEnumSelector',
                    allowedValues: getAllowedValues(LaneStatus),
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'exactDates',
                    componentName: 'Checkbox',
                    labelKey: 'LaneQuoteRequest.exactDate',
                    className: 'checkBoxOnTop',
                },
                {
                    name: 'laneFeeValidFrom',
                    labelKey: 'LaneQuoteRequest.laneFeeValidFrom',
                    componentName: 'DateSelect',
                },
                {
                    name: 'laneFeeValidTo',
                    labelKey: 'LaneQuoteRequest.laneFeeValidTo',
                    componentName: 'DateSelect',
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'customerType',
                    labelKey: 'LaneQuoteRequest.customerType',
                    componentName: 'EnumSelector',
                    showInColumns: true,
                    noSort: true,
                    allowedValues: allowedCustomerTypes,
                },
                {
                    name: 'selectedCustomerExecution',
                    labelKey: 'LaneQuoteRequest.selectedCustomerExecution',
                    contractId,
                    componentName: 'CustomerExecutionSelector',
                    required: disabledAll || state.customerType === EXECUTION,
                },
            ],
        },
        {
            id: 'column4',
            fields: !state.restrictAirports ? [
                restrictAirport,
                allowDuplication,
            ] : [
                restrictAirport,
                {
                    name: 'selectedAirports',
                    labelKey: 'LaneQuoteRequest.selectedAirports',
                    componentName: 'AirportLaneGroupSelector',
                    title: 'Airports',
                    laneGroups,
                    disabled: disabledAll || !state.restrictAirports,
                },
                allowDuplication,
            ],
        },
    ]

    return [{
        title: 'Filters',
        className: showComment ? 'generalWithComment' : 'general',
        fields: showComment ? [
            ...defaultColumns,
            ...descriptionColumn,
        ] : defaultColumns,
    }]
}

export default printFormFields
