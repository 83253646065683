import React from 'react'
import {
    BrowserRouter,
} from 'react-router-dom'
import {
    Provider,
} from 'react-redux'
import {
    DndProvider,
} from 'react-dnd'
import {
    HTML5Backend,
} from 'react-dnd-html5-backend'
import 'moment/locale/en-gb'
import {
    AdapterMoment,
} from '@mui/x-date-pickers/AdapterMoment'

import {
    QueryClientProvider,
    QueryClient,
} from 'react-query'
import Auth from '@skycell-ag/auth'
import {
    LocalizationProvider,
} from '@mui/x-date-pickers/LocalizationProvider'

import ThemeProvider from './ThemeProvider'
import store from './store'
import Root from './Root'

const queryClient = new QueryClient()

const App = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    locale="en-gb"
                >
                    <QueryClientProvider client={queryClient}>
                        <Auth>
                            <BrowserRouter>
                                <ThemeProvider>
                                    <React.StrictMode>
                                        <Root />
                                    </React.StrictMode>
                                </ThemeProvider>
                            </BrowserRouter>
                        </Auth>
                    </QueryClientProvider>
                </LocalizationProvider>
            </Provider>
        </DndProvider>
    )
}

export default App
