import React from 'react'
import {
    Grid,
} from '@mui/material'

import Card from 'app/shared-components/Card'
import TextSingleline from 'app/shared-components/TextSingleline'
import Switch from 'app/shared-components/Switch'
import ContactsContainer from 'app/Apps/ContactManagement/ContactsLinked/ContactsContainer'
import useGetCountry from 'app/Apps/Masterdata/Countries/hooks/useGetCountry'
import useLabel from 'app/hooks/useLabel'
import {
    RegionZeroLevel,
    RegionFirstLevel,
    RegionSecondLevel,
} from 'app/Apps/Masterdata/Countries/Countries.types'

import {
    LocationType,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import StatusHandler from 'app/shared-components/StatusHandler'
import LocationChangeSummary from './LocationChangeSummary'

import useStyles from './LocationOverview.styles'
import {
    useGetLocation,
} from '../hooks'

type Props = {
    id: string | number,
}

const LocationOverview = ({
    id,
}: Props) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLocation(id)

    const {
        data: countryData,
        isDataReady: isCountryDataReady,
    } = useGetCountry(data?.country?.id || undefined)

    const {
        classes,
    } = useStyles()
    const l = useLabel()

    const allowedLocationTypes = useEnumValues()(LocationType)
    const regionZeroLabels = useEnumValues()(RegionZeroLevel)
    const regionFirstLabels = useEnumValues()(RegionFirstLevel)
    const regionSecondLabels = useEnumValues()(RegionSecondLevel)

    return (
        <StatusHandler
            isSuccess={isDataReady && isCountryDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <div className={classes.container}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Card
                            title="Location"
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                >
                                    <TextSingleline
                                        title={l('PreConUnit.locationLocationName')}
                                        value={data?.locationName}
                                    />
                                    <TextSingleline
                                        title={l('OrderLocationView.locationType')}
                                        value={allowedLocationTypes?.[data?.locationType]}
                                    />
                                    <Switch
                                        title="Is Shipping Location"
                                        value={data?.isShippingLocation}
                                        disabled
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                >
                                    <TextSingleline
                                        title={l('Country.regionZeroLevel')}
                                        value={regionZeroLabels[
                                            countryData?.regionZeroLevel
                                        ]}
                                    />
                                    <TextSingleline
                                        title={l('Country.regionFirstLevel')}
                                        value={regionFirstLabels[
                                            countryData?.regionFirstLevel
                                        ]}
                                    />
                                    <TextSingleline
                                        title={l('Country.regionSecondLevel')}
                                        value={regionSecondLabels[
                                            countryData?.regionSecondLevel
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <ContactsContainer
                        isWrapped
                        value={data?.id}
                    />
                    <Grid
                        item
                        xs={12}
                    >
                        <Card
                            title="Change History"
                        >
                            <LocationChangeSummary locationNumber={data?.id} />
                        </Card>
                    </Grid>
                </Grid>
            </div>

        </StatusHandler>
    )
}

export default LocationOverview
