import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import {
    RequestDataUpdate,
} from '../../AccountQuotes.types'
import requests from '../../AccountQuotes.requests'

const useAccountQuoteUpdate = (onSuccess: () => void) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdateAccountQuote,
        onError,
    } = useHandleStatusRequest({
        title: 'Account quote was updated',
        id: 'AccountQuoteUpdated',
        onSuccess,
    })

    return useMutation(({
        data, id,
    }: {data: RequestDataUpdate, id: number}) => {
        return simpleApi({
            ...requests.updateRequestedLane({
                data,
                id,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessUpdateAccountQuote,
    })
}

export default useAccountQuoteUpdate
