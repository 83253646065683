import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme, params, classes) => {
    return {
        icon: {
            width: '80%',
            height: 'auto',
        },
        item: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            padding: `${theme.spacing(0.25)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
            marginTop: theme.spacing(2),
            '&:hover': {
                backgroundColor: theme.palette.primary[200],
                paddingRight: theme.spacing(2.2),
            },
            [`&:hover .${classes.linksContainer}`]: {
                display: 'flex',
            },
            width: 50,
            height: 50,
        },
        iconLarge: {
            width: '100%',
        },
        iconMedium: {
            width: '75%',
        },
        iconSmall: {
            width: '60%',
        },
        linksContainer: {
            minWidth: 210,
            display: 'none',
            minHeight: '100%',
            color: theme.palette.common.white,
            zIndex: 1,
            position: 'absolute',
            left: 65,
            top: 0,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            '& :last-child div': {
                display: 'none',
            },
            '& :first-of-type': {
                borderTopRightRadius: 3,
            },
            '& :last-child': {
                borderBottomRightRadius: 3,
                borderBottomLeftRadius: 3,
            },
        },
        activeLink: {
            display: 'none',
        },
        linkContainer: {
            marginLeft: 2,
            position: 'relative',
            background: theme.palette.primary[200],
            '&:hover': {
                backgroundColor: theme.palette.primary[100],
            },
        },
        linkLabel: {
            color: `${theme.palette.common.white} !important`,
        },
        link: {
            textDecoration: 'none',
            fontWeight: 400,
            padding: theme.spacing(1.25),
            display: 'block',
            whiteSpace: 'nowrap',
        },
        underline: {
            width: '90%',
            position: 'absolute',
            bottom: 0,
            borderBottom: `1px solid ${theme.palette.common.white}`,
        },
        logoContainer: {
            position: 'absolute',
            bottom: theme.spacing(1),
            textAlign: 'center',
            width: 65,
        },
        logo: {
            width: 50,
        },
    }
})

export default useStyles
