import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            display: 'flex',
            marginBottom: theme.spacing(3),
        },
        card: {
            minHeight: 455,
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
