import flow from 'lodash/flow'
import Axios from 'axios'

import DOMAIN_SUBDIRECTORY from 'app/utils/api/constants'
import bearerToken from 'app/utils/api/bearerToken'
import addSimpleUrl from './addSimpleUrl'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const documentServiceApi = (params) => {
    const modifiedQuery = flow([
        bearerToken,
        addSimpleUrl(`${apiUrl}/${DOMAIN_SUBDIRECTORY}/v1/document/generate`),
    ])(Axios)

    return modifiedQuery(params)
}

export default documentServiceApi
