import {
    useCallback,
    useRef,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

export type DelayedLoadOptions = {
    phrase?: string,
    filters?: Record<string, any>
}

const DELAY_TIMEOUT = 600
/* This hook returns promise provider as props to Typeahead component.
*  Need to be refactored after Typeahead will be refactoring
*  to not work with promises.
*  UseMutation is used here to utilize react-query MutationCache
* to reduce number of the same request on component re-rendering */
const useDelayedLoadOptions = ({
    url,
    convertion = undefined,
    includeFilters = undefined,
    params,
}:{
    url: string,
    convertion?: (response) => any,
    includeFilters?: Record<string, any>,
    params?: Record<string, any>,
}) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'Load options',
    })

    const typingTimer = useRef(null)
    const {
        mutateAsync: load,
    } = useMutation(({
        phrase, filters,
    }: DelayedLoadOptions) => {
        const allFilters = {
            ...includeFilters,
            ...filters,
        }

        return simpleApi({
            url,
            method: 'POST',
            token,
            data: {
                searchAll: phrase,
                includeFilters: !isEmpty(allFilters) ? allFilters : undefined,
                ...(params || {}),
            },
        }).then(({
            items,
        }) => {
            return convertion ? convertion(items) : items
        })
    }, {
        onError,
    })

    return useCallback(({
        phrase,
        filters,
    }: DelayedLoadOptions) => {
        clearTimeout(typingTimer.current)
        return new Promise((resolve) => {
            typingTimer.current = setTimeout(() => {
                resolve(load({
                    phrase, filters,
                }))
            }, DELAY_TIMEOUT)
        })
    }, [load])
}

export default useDelayedLoadOptions
