/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Button,
} from '@mui/material'
import VpnLock from '@mui/icons-material/VpnLock'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    useTheme,
} from '@mui/material/styles'

import Label from 'app/shared-components/Label'

import useStyles from './DateTimeZonedSelect.style'
import Zone from './Zone'

const propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    handleSetLocalTime: PropTypes.func.isRequired,
    isLocalTime: PropTypes.bool.isRequired,
    state: PropTypes.shape({
        open: PropTypes.bool,
    }).isRequired,
    showDate: PropTypes.func.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    disableFuture: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
}

const defaultProps = {
    name: '',
    title: 'Calendar',
    value: '',
    disableFuture: false,
    disabled: false,
    required: false,
    className: '',
}

const DateTimeZonedSelect = (props) => {
    const {
        name,
        title,
        value,
        handleSetLocalTime,
        isLocalTime,
        state: {
            open,
        },
        showDate,
        setOpenDialog,
        disabled,
        required,
        className,
    } = props

    const theme = useTheme()
    const {
        classes,
        cx,
    } = useStyles(theme)

    return (
        <div
            className={clsx({
                [classes.pointer]: !disabled,
            }, classes.calendar, className)}
            data-testid={`dateTimeZoned-${name}`}
        >

            <Label
                title={title}
                className={classes.label}
                data-testid="datetime-select-label"
            />
            <div
                className={clsx(
                    {
                        [classes.field]: !disabled,
                        [classes.disabled]: disabled,
                        [classes.isRequired]: required,
                    },
                )}
            >
                <div className={classes.requiredMark} />
                {disabled ? (
                    <div
                        className={cx(classes.button, classes.disabledInput)}
                    >
                        <div
                            className={classes.calendarInput}
                        >
                            {showDate(value)}
                        </div>
                    </div>
                ) : (
                    <Button
                        className={classes.button}
                        onClick={setOpenDialog}
                        data-testid={`dateTimeZonedSelect-${name}`}
                    >
                        <div
                            className={classes.calendarInput}
                        >
                            {showDate(value)}
                        </div>
                    </Button>
                )}
            </div>
            <div className={clsx({
                [classes.icon]: true,
                [classes.iconActive]: isLocalTime,
            })}
            >
                <VpnLock
                    size="small"
                    className={classes.pointer}
                    onClick={handleSetLocalTime}
                />
                {open && (
                    <Zone
                        {...props}
                    />
                )}
            </div>
        </div>
    )
}

DateTimeZonedSelect.propTypes = propTypes
DateTimeZonedSelect.defaultProps = defaultProps

export default DateTimeZonedSelect
