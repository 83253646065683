import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom'

import SideBar from 'app/shared-components/SideBar'
import TabNavigation from 'app/shared-components/TabNavigation'
import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'

import useGetDomainObjectById from '../hooks/useGetDomainObjectById'

type Props = {
    getDomainObject?: (params: Record<string, any>) => RequestConfig,
    backUrl: string,
    onSelectRow: (id: string | number) => void,
    tabs: SidebarTab[],
}

const defaultProps = {
    getDomainObject: undefined,
}

const DomainObjectDetails = (props: Props) => {
    const {
        getDomainObject,
        onSelectRow,
        backUrl,
        tabs,
    } = props

    const {
        params: {
            id,
        },
    } = useRouteMatch()
    const history = useHistory()

    const {
        setSelectedTab,
        selectedTab,
        requestKey,
    } = useDomainObjectContext()

    const {
        data,
    } = useGetDomainObjectById({
        id,
        requestKey,
        requestFn: getDomainObject,
    })

    useEffect(() => {
        onSelectRow(id)

        return () => {
            onSelectRow(null)
        }
    }, [
        id,
        onSelectRow,
    ])

    const sidebarTabs = useMemo(() => {
        if (!getDomainObject) {
            return tabs
        }

        if (!data) {
            return undefined
        }

        return tabs.filter(({
            condition,
        }) => {
            if (condition) {
                return condition(data)
            }

            return true
        })
    }, [
        data,
        getDomainObject,
        tabs,
    ])

    const goBack = useCallback(() => {
        history.push(`${backUrl}/all`)
    }, [
        backUrl,
        history,
    ])

    return (
        <SideBar
            onClose={goBack}
        >
            {sidebarTabs?.length > 0 ? (
                <TabNavigation
                    onTabChange={setSelectedTab}
                    tab={selectedTab}
                    tabs={sidebarTabs}
                />
            ) : null}
        </SideBar>
    )
}

DomainObjectDetails.defaultProps = defaultProps

export default DomainObjectDetails
