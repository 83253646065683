import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    LaneGroupTemperatureRange,
    ProductType,
} from 'app/types/enums'
import request from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateCustomerTransportForm/createCustomerTransportForm.request'

const useGetProductTypes = ({
    leaseStartTimestamp,
    selectedLaneId,
    tempRange: selectedTempRange,
}: {
    selectedLaneId: number,
    leaseStartTimestamp: string,
    tempRange: LaneGroupTemperatureRange,
}): ProductType[] => {
    const {
        data: allowedProductTypes,
    } = useRequest<ProductType[]>({
        key: RequestKeys.getAllowedProductTypes,
        params: {
            selectedLaneId,
            leaseStartTimestamp,
            isPerformanceQualification: false,
        },
        requestFunc: RequestFn.getEntity(request.AllowedProductTypes.requestFn),
        enabled: Boolean(selectedLaneId && leaseStartTimestamp),
    })

    const {
        data: allProductTypes,
    } = useRequest<ProductType[]>({
        key: RequestKeys.getProductTypesContractBasis,
        params: {
            selectedTempRange,
        },
        requestFunc: RequestFn.getEntity(request.AllProductType.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp.data.reduce((acc, {
                    productType,
                    temperatureRange,
                }) => {
                    if (selectedTempRange === temperatureRange) {
                        return [
                            ...acc,
                            productType,
                        ]
                    }

                    return acc
                }, [])
            })
        }),
        enabled: Boolean(!selectedLaneId),
    })

    return selectedLaneId ? allowedProductTypes : allProductTypes
}

export default useGetProductTypes
