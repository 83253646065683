import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    OpportunityType,
} from '../../opportunities.types'

import requests from '../../opportunities.request'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessUpdateOpportunity,
        onError,
    } = useHandleStatusRequest({
        title: 'Opportunities were updated',
        id: 'OpportunitiesBulkUpdate',
    })

    const {
        mutateAsync: updateOpportunities,
    } = useMutation((opportunities: OpportunityType[]) => {
        return simpleApi({
            ...requests.bulkEdit({
                data: opportunities,
            }),
            token,
        })
    }, {
        onError,
    })

    return useCallback(async (opportunities: OpportunityType[], {
        onSuccess,
    }) => {
        if (!opportunities.length) {
            onError({
                response: {
                    data: ['Nothing was changed'],
                    statusCode: 500,
                },
            })
            return Promise.resolve()
        }

        return updateOpportunities(opportunities, {
            onSuccess,
        }).then((data) => {
            onSuccessUpdateOpportunity(data)
        })
    }, [
        updateOpportunities,
        onError,
        onSuccessUpdateOpportunity,
    ])
}
