import simpleApi from 'app/services/simpleApi'

const getTimeZoneByLatLng = (token, latitude, longitude) => {
    if (!latitude || !longitude) {
        return Promise.resolve(null)
    }
    return simpleApi({
        method: 'GET',
        url: `timezone/timezonebyposition?lat=${latitude}&lng=${longitude}`,
        token,
    }).then((response) => {
        return response?.timezone
    })
        .catch(() => {
            return null
        })
}

export default getTimeZoneByLatLng
