import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        wrapper: {
            width: '100%',
            display: 'block',
        },
        card: {
            overflow: 'auto !important',
        },

    }
})

export default useStyles
