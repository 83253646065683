import {
    AddressCategory,
    LocationType,
} from 'app/types/enums'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import LocationSelector from '../LocationSelector'
import LocationObjectSelector from '../LocationObjectSelector'
import LocationObjectDataSelector from '../LocationObjectDataSelector'
import LocationExpandedViewSelector from '../LocationExpandedViewSelector'
import LocationExpandedViewObjectSelector from '../LocationExpandedViewObjectSelector'

export const LocationSelectorComponents = {
    LocationExpandedViewSelector,
    LocationExpandedViewObjectSelector,
    LocationObjectSelector,
    LocationObjectDataSelector,
    LocationSelector,
}

type LocationSelectorConfig = {
    componentName: keyof typeof LocationSelectorComponents,
    filterLocationType?: LocationType | LocationType[],
    filterAddressCategory?: AddressCategory[],
    filterAddressMainCategory?: AddressMainCategory,
    filterByCompanyId?: string,
    isApprovedSubContractor?: boolean,
    isShippingLocation?: boolean,
    includeFilters?: Record<string, any>
}

export default LocationSelectorConfig
