import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from 'react-query'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    RegionalGroup,
} from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.types'
import request from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.request'

const useGetRegionalGroupsByAccount = () => {
    const queryClient = useQueryClient()
    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getRegionalGroupsByAccount])
    }, [queryClient])

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<TableResponse<RegionalGroup>>({
        config: request.RegionalGroups.requestFn,
        params: {},
        key: RequestKeys.getRegionalGroupsByAccount,
    })

    return {
        onParamsChange,
        loadData,
        invalidate,
    }
}

export default useGetRegionalGroupsByAccount
