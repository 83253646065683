import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import {
    ModificationType,
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import config from 'app/Apps/OrderManagement/Orders/orders.request'

type RequestParams = {
    modificationType: ModificationType,
    orderNumber: string,
    data: OrderStep & {version: number},
    id: number,
}

const useUpdateOrderStep = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessUpdate,
        onError,
    } = useHandleStatusRequest({
        id: 'ModifyOrderStep',
        onSuccess,
    })

    return useMutation(({
        modificationType,
        orderNumber,
        data,
        id,
    }: RequestParams) => {
        return modificationType
            ? config.ModifyOrderStep.mutationFn({
                modificationType,
                orderNumber,
                data,
                token,
                id,
            }) : config.UpdateOrderStep.mutationFn({
                orderNumber,
                data,
                token,
                id,
            })
    }, {
        onSuccess: onSuccessUpdate,
        onError,
    })
}

export default useUpdateOrderStep
