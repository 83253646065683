/**
 * Authorization Roles
 */
const authRoles = {
    /* master */
    master_read: ['SNE_MASTER_DATA_READ'],
    /* company */
    company_update: ['SNE_COMPANY_UPDATE'],
    company_update_limited: ['SNE_COMPANY_UPDATE_RESTRICTED'],
    company_create: ['SNE_COMPANY_CREATE'],
    company_skymind_update: ['SNE_COMPANY_SKYMIND_UPDATE'],
    /* containers */
    container_read: ['SNE_CONTAINER_READ'],
    container_update: ['SNE_CONTAINER_UPDATE'],
    container_create: ['SNE_CONTAINER_CREATE'],
    /* contract basis */
    contract_basis_read: ['SNE_CONTRACT_BASIS_READ'],
    contract_basis_admin: ['SNE_CONTRACT_BASIS_ADMIN'],
    contract_basis_automatic_quote: ['SNE_CONTRACT_BASIS_AUTOMATIC_QUOTE'],
    /* order */
    order_read: ['SNE_ORDER_READ'],
    order_update_transport: ['SNE_ORDER_UPDATE_TRANSPORT'],
    order_lane_update: ['SNE_ORDER_LANE_UPDATE'],
    /* invoice */
    outgoing_invoice_read: ['SNE_OUTGOING_INVOICE_READ'],
    outgoing_invoice_update: ['SNE_OUTGOING_INVOICE_UPDATE'],
    outgoing_invoice_create: ['SNE_OUTGOING_INVOICE_CREATE'],
    /* fx-rates */
    fxrate_read: ['SNE_FX_RATE_READ'],
    fxrate_create: ['SNE_FX_RATE_CREATE'],
    fxrate_update: ['SNE_FX_RATE_UPDATE'],
    /* location */
    location_read: ['SNE_LOCATION_READ'],
    location_sales: ['SNE_LOCATION_SALES'],
    location_sales_super: ['SNE_LOCATION_SALES_SUPER'],
    location_airlines: ['SNE_LOCATION_AIRLINES'],
    location_airlines_super: ['SNE_LOCATION_AIRLINES_SUPER'],
    location_cs: ['SNE_LOCATION_CS'],
    location_cs_super: ['SNE_LOCATION_CS_SUPER'],
    location_bil: ['SNE_LOCATION_BIL'],
    location_bil_super: ['SNE_LOCATION_BIL_SUPER'],
    location_ops: ['SNE_LOCATION_OPS'],
    location_ops_super: ['SNE_LOCATION_OPS_SUPER'],
    location_pmo: ['SNE_LOCATION_PMO'],
    location_pmo_super: ['SNE_LOCATION_PMO_SUPER'],
    location_ts: ['SNE_LOCATION_TS'],
    location_ts_super: ['SNE_LOCATION_TS_SUPER'],
    location_prd: ['SNE_LOCATION_PRD'],
    location_rnd: ['SNE_LOCATION_RND'],

    /* gateway */
    gateway_read: ['SNE_GATEWAY_READ'],
    gateway_update: ['SNE_GATEWAY_UPDATE'],
    gateway_create: ['SNE_GATEWAY_CREATE'],
    /* account */
    account_read_all: ['SNE_ACCOUNT_READ_ALL'],
    account_read_restricted: ['SNE_ACCOUNT_READ_RESTRICTED'],
    account_create_all: ['SNE_ACCOUNT_CREATE_ALL'],
    account_create_restricted: ['SNE_ACCOUNT_CREATE_RESTRICTED'],
    account_update_all: ['SNE_ACCOUNT_UPDATE_ALL'],
    account_update_restricted: ['SNE_ACCOUNT_UPDATE_RESTRICTED'],
    account_quote_lane_award_reject: ['SNE_QUOTE_LANE_AWARD_REJECT'],
    account_quote_lane_group: ['SNE_QUOTE_LANE_GROUP'],
    account_quote_pdf_create: ['SNE_QUOTE_PDF_CREATE'],
    account_quote_pdf_delete: ['SNE_QUOTE_PDF_DELETE'],
    /* logger */
    logger_read: ['SNE_LOGGER_READ'],
    logger_update: ['SNE_LOGGER_UPDATE'],
    logger_create: ['SNE_LOGGER_CREATE'],
    logger_delete: ['SNE_LOGGER_DELETE'],
    /* note */
    note_read: ['SNE_NOTE_READ'],
    note_create: ['SNE_NOTE_CREATE'],
    /* transport planner */
    transport_planner_read: ['SNE_TRANSPORT_PLANNER_READ'],
    transport_planner_create: ['SNE_TRANSPORT_PLANNER_CREATE'],
    transport_planner_available_update: ['SNE_TRANSPORT_PLANNER_AVAILABLE_UPDATE'],
    transport_planner_planed_update: ['SNE_TRANSPORT_PLANNER_PLANNED_UPDATE'],
    transport_planner_prime_update: ['SNE_TRANSPORT_PLANNER_PRIME_UPDATE'],

    /* damage tracking */
    damage_tracking_create: ['SNE_DAMAGE_CREATE'],
    damage_tracking_update: ['SNE_DAMAGE_UPDATE'],
    damage_tracking_read: ['SNE_DAMAGE_READ'],

    /* forecast */
    forecast_read: ['SNE_FORECAST_READ'],
    forecast_super: ['SNE_FORECAST_SUPER'],
    forecast_update: ['SNE_FORECAST_UPDATE'],

    /* relationships management */
    relationships_ops: ['SNE_RELATIONSHIPS_OPS'],
    relationships_sales: ['SNE_RELATIONSHIPS_SALES'],

    /* alerts */
    alerts_read: ['SNE_ALERTS_READ'],

    /* users */
    user_read: ['SNE_USER_READ'],
    user_create: ['SNE_USER_CREATE'],

    /* only dev team */
    dev_team: ['SMI_DEV_TEAM'],
}

export default authRoles
