import React, {
    useMemo,
} from 'react'
import useHasPermission from 'app/hooks/useHasPermission'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton,
    ArchiveConfirmationDialog,
    useArchive,
} from 'app/shared-components/Archive'
import ContactForm from '../ContactForm'
import useGetContact from '../hooks/useGetContact'
import request from '../Contacts.request'

const ContactUpdate = ({
    id,
}: { id: number }) => {
    const updateAllowed = useHasPermission([
        'company_update',
        'company_update_limited',
    ])

    const {
        data,
        isDataReady,
        error,
        isError,
        invalidate,
        isFetching,
    } = useGetContact(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? request.restore
            : request.archive,
        notificationId: 'Contact',
        title: 'Contact',
        data,
        redirectionAfterSuccess: '/apps/contactmanagement/contacts/all',
    })

    const customButtons = useMemo(() => {
        return updateAllowed ? [<ArchiveButton
            key="archive-button"
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />] : []
    }, [
        updateAllowed,
        archive,
        data,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <ContactForm
                    data={data}
                    disabled={!updateAllowed}
                    request={request.update()}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Contact"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

export default ContactUpdate
