import React, {
    useCallback, useMemo, useReducer, useState,
} from 'react'
import {
    useJsApiLoader, Libraries,
} from '@react-google-maps/api'
import clsx from 'clsx'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useQuery from 'app/hooks/useQuery'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'
import useGetMainCategory from 'app/hooks/useGetMainCategory'
import useEnumValues from 'app/hooks/useEnumValues'
import getAllowedCategoriesTypes from 'app/Apps/ContactManagement/utils/getAllowedCategoriesTypes'
import useGetAllowedCategoryType from 'app/Apps/ContactManagement/utils/useGetAllowedCategoryType'
import reducer from 'app/Apps/ContactManagement/store/reducer'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import {
    setValue,
} from 'app/Apps/ContactManagement/store/actions'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import useHasPermission from 'app/hooks/useHasPermission'

import {
    Library,
} from 'app/types/googlemaps.types'
import {
    AddressCategory, LocationType,
} from 'app/types/enums'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import isEqual from 'lodash/isEqual'

import useStyles from './LocationForm.styles'
import getLocationFormFields from './LocationForm.fields'
import getLocationFormServiceCenterMetricsFields from './LocationFormServiceCenterMetrics.fields'
import {
    ServiceCenterMetrics,
    Location,
} from '../Locations.types'
import locationRequests from '../Locations.request'
import LocationDuplicationCheckDialog from '../LocationDuplicationCheckDialog'
import useLocationDuplicationCheck from '../hooks/useLocationDuplicationCheck'
import Storage from './Storage'

type LocationFormProps = {
    data?: Location,
    action: (...args: any[]) => void,
    onCancel: (...args: any[]) => void,
}

const defaultProps: Partial<LocationFormProps> = {
    data: {
        locationType: LocationType.ADDRESS,
        enhancedSkyCenterScanning: true,
    },
}

const libraries: Libraries = [Library.PLACES]

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env

const getGridWrapperClass = (value, isServiceCenter) => {
    switch (value.locationType) {
    case LocationType.ADDRESS:
        return (isServiceCenter ? 'gridWrapperAddressService' : 'gridWrapperAddress')
    case LocationType.AIRPORT:
        return 'gridWrapperAirport'
    case LocationType.SEAPORT:
        return 'gridWrapperSeaport'
    case LocationType.RAILPORT:
        return 'gridWrapperRailport'
    default:
        return 'gridWrapperAddress'
    }
}

const removedAebServiceProvider = (isAebServiceProvider, val) => {
    if (!isAebServiceProvider && !!val.aebServiceProviderId) {
        return {
            ...val,
            aebServiceProviderId: undefined,
        }
    }
    return val
}

const LocationForm = ({
    data,
    action,
    onCancel,
}: LocationFormProps) => {
    const {
        isLoaded: isGoogleMapsServiceLoaded,
    } = useJsApiLoader({
        googleMapsApiKey: googleMapKey,
        libraries,
    })

    const isNew = !data?.id

    const [
        duplicationData,
        setDuplicationData,
    ] = useState(null)

    const getAllowedValues = useEnumValues()

    const queryParams = useQuery([
        'companyId',
        'locationType',
    ])

    const hasEditPermission = useHasPermission([
        'location_cs',
        'location_cs_super',
    ])

    const initialData = useMemo(() => {
        const companyId = queryParams?.companyId
        const locationType = queryParams?.locationType
        const valuesCompanyId = companyId ? {
            ...data,
            companyId,
        } : data
        const valuesLocationType = locationType ? {
            ...valuesCompanyId, locationType,
        } : valuesCompanyId

        if (isNew && hasEditPermission) {
            return {
                ...valuesLocationType,
                isProspect: true,
            }
        }

        return valuesLocationType
    }, [
        queryParams?.companyId,
        queryParams?.locationType,
        data,
        isNew,
        hasEditPermission,
    ])

    const [
        {
            value,
            timeZones,
        },
        dispatch,
    ]: [
        { value: Location, timeZones: [] },
        (args: { type: string, value: Location }) => void,
    ] = useReducer(reducer, {
        value: initialData,
        edited: false,
        timeZones: [],
    })

    const mainCategories = useGetMainCategory()

    const addressMainCategories = useGetAllowedCategoryType('LocationAccessRights', isNew ? 'create' : 'edit')

    const allowedCategories = useMemo(() => {
        if (mainCategories) {
            return getAllowedCategoriesTypes(
                mainCategories?.addressMainCategory,
                addressMainCategories,
                true,
                getAllowedValues,
            )
        }
        return []
    }, [
        mainCategories,
        addressMainCategories,
        getAllowedValues,
    ])

    const {
        timeZone,
        currentPlaceId,
    } = value
    const {
        classes,
    } = useStyles()

    const isServiceCenter = value.addressCategory?.includes(AddressCategory.SERVICECENTER)
        && value.locationType === LocationType.ADDRESS
    const isAebServiceProvider = value.addressCategory
        ?.includes(AddressCategory.AEB_FREIGHT_BILL_AND_AUDIT)

    const setAutocomplete = useCallback((val: Location, isAddressLine1 = false) => {
        const updatedValue = isAddressLine1
            ? {
                ...value,
                addressLine1: val,
            }
            : {
                ...value,
                ...val,
            }

        if (!isEqual(updatedValue, value)) {
            dispatch(setValue(updatedValue))
        }
    }, [value])

    const fields = useMemo(() => {
        return getLocationFormFields(
            {
                country: value?.country,
                locationType: value.locationType,
                isShippingLocation: value.isShippingLocation,
                timeZones,
                isServiceCenter,
                categories: value.addressCategory,
                isNew,
                allowedCategories,
                setAutocomplete,
                timeZone,
                currentPlaceId,
                value,
                isGoogleMapsServiceLoaded,
                getAllowedValues,
                hasEditPermission,
                isProspect: value?.isProspect,
                orignalIsProspect: data?.isProspect,
                isAebServiceProvider,
            },
        )
    }, [
        timeZones,
        isServiceCenter,
        isNew,
        allowedCategories,
        setAutocomplete,
        timeZone,
        currentPlaceId,
        value,
        isGoogleMapsServiceLoaded,
        getAllowedValues,
        hasEditPermission,
        data?.isProspect,
        isAebServiceProvider,
    ])
    const scmFields = useMemo(() => {
        return getLocationFormServiceCenterMetricsFields({
            getAllowedValues,
        })
    }, [getAllowedValues])

    const fieldsWithClassName = useFieldsWithClassName(fields, classes)
    const scmFieldsWithClassName = useFieldsWithClassName(scmFields, classes)

    const hasReadPermissions = useHasPermissionType(value.addressMainCategory, 'LocationAccessRights')
    const hasCategoriesEditPermissions = useHasPermissionType(value.addressMainCategory, 'LocationAccessRights', 'edit')

    const isNotEditable = useMemo(() => {
        return !(hasEditPermission
            || isNew
            || Boolean(
                hasCategoriesEditPermissions
                && value.addressMainCategory
                && value.addressMainCategory.length,
            ))
            || value.locationType === LocationType.AIRPORT
    }, [
        hasEditPermission,
        hasCategoriesEditPermissions,
        value.addressMainCategory,
        value.locationType,
        isNew,
    ])
    const onChangeValue = useCallback((val: Location) => {
        if (!isEqual(val, value)) {
            dispatch(setValue(val))
        }
    }, [value])

    const onChangeSCMValue = useCallback((val: ServiceCenterMetrics) => {
        if (!isEqual(val, value.serviceCenterMetrics)) {
            dispatch(setValue({
                ...value,
                serviceCenterMetrics: val,
            }))
        }
    }, [value])

    const onChangeStorageValue = useCallback((storages: Storage[]) => {
        if (!isEqual(storages, value.serviceCenterMetrics?.storages)) {
            dispatch(setValue({
                ...value,
                serviceCenterMetrics: {
                    ...value.serviceCenterMetrics,
                    storages,
                },
            }))
        }
    }, [value])

    const isNotReadable = useMemo(() => {
        return !hasReadPermissions && !isNew
            && value.addressMainCategory
            && value.addressMainCategory.length
    }, [
        hasReadPermissions,
        value.addressMainCategory,
        isNew,
    ])

    const archive = useArchive({
        requestParamFn: data?.archived ? locationRequests.restore
            : locationRequests.archive,
        notificationId: 'LocationUpdateDto',
        title: 'Location',
        data,
        redirectionAfterSuccess: '/apps/contactmanagement/locations/all',
    })

    const onCheckDuplicateSuccess = useCallback((duplicates) => {
        if (!duplicates?.length) {
            action(value)
            return
        }

        setDuplicationData(duplicates)
    }, [
        setDuplicationData,
        action,
        value,
    ])

    const checkDuplicate = useLocationDuplicationCheck(onCheckDuplicateSuccess)

    const callAction = useCallback(() => {
        if (value?.isShippingLocation && value?.locationType === LocationType.ADDRESS) {
            checkDuplicate({
                longitude: value?.longitude,
                latitude: value?.latitude,
                editedLocation: data.id ? {
                    id: data.id,
                } : undefined,
            })

            return
        }

        let result = value

        if (value.isProspect) {
            result = {
                ...value,
                isShippingLocation: false,
                longitude: undefined,
                latitude: undefined,
            }
        }

        action(removedAebServiceProvider(isAebServiceProvider, result))
    }, [
        checkDuplicate,
        action,
        value,
        isAebServiceProvider,
        data.id,
    ])

    const onCancelClick = useCallback(() => {
        dispatch(setValue(initialData))
    }, [initialData])

    const closeConfirmationDialog = useCallback(() => {
        setDuplicationData(null)
    }, [setDuplicationData])

    const classNames = useMemo(() => {
        return {
            gridWrapper: classes[getGridWrapperClass(
                value,
                isServiceCenter,
            )],
        }
    }, [
        classes,
        value,
        isServiceCenter,
    ])

    if (isNotReadable) {
        return null
    }
    return (
        <div
            className={classes.root}
        >
            <DomainObjectForm
                name="Location"
                value={value}
                fields={fieldsWithClassName}
                className={clsx(classNames.gridWrapper)}
                disabled={isNotEditable}
                exists={!isNew}
                onChange={onChangeValue}
            />
            {isServiceCenter
                && (
                    <>
                        <DomainObjectForm
                            name="ServiceCenter"
                            value={value.serviceCenterMetrics}
                            fields={scmFieldsWithClassName}
                            className={classes.gridServiceCenterMetrics}
                            disabled={isNotEditable}
                            exists={!isNew}
                            onChange={onChangeSCMValue}
                        />
                        <Storage
                            data={value?.serviceCenterMetrics?.storages}
                            onChange={onChangeStorageValue}
                        />
                    </>

                )}
            {value.locationType !== LocationType.AIRPORT && (
                <ButtonContainer>
                    <Button
                        label={isNew ? 'Create' : 'Save'}
                        onClick={callAction}
                        disabled={Boolean(duplicationData?.length) || isNotEditable}
                    />
                    { isNew ? null : (
                        <ArchiveButton
                            onClick={archive.handleArchiveRestore}
                            archived={data?.archived}
                            disabled={isNotEditable}
                        />
                    ) }
                    <Button
                        disabled={Boolean(duplicationData?.length)}
                        secondary
                        label="Cancel"
                        onClick={onCancel || onCancelClick}
                    />
                </ButtonContainer>
            )}
            <ArchiveConfirmationDialog
                title="Location"
                openConfirmDialog={archive.openConfirmDialog}
                handleClose={archive.handleArchiveRestoreConfirm}
                archived={data?.archived}
            />
            <LocationDuplicationCheckDialog
                createLocation={() => { return action(value) }}
                data={duplicationData}
                close={closeConfirmationDialog}
                title={!isNew ? 'Update Confirmation' : undefined}
                positiveLabel={!isNew ? 'Save anyway' : undefined}
            />
        </div>
    )
}

LocationForm.defaultProps = defaultProps

export default LocationForm
