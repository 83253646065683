import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../AccountQuotes.requests'

const useAddQuotePdf = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'Add PDF',
    })

    return useMutation(({
        quoteId,
        data,
    }: {
        quoteId: number,
        data: {
            id: number,
        }
    }) => {
        return request.addQuotePdf.mutationFn({
            quoteId,
            data,
            token,
        })
    }, {
        onSuccess,
        onError,
    })
}

export default useAddQuotePdf
