import React from 'react'
import FullscreenIcon from '@mui/icons-material/Fullscreen'

const propTypes = {}

const defaultProps = {}

const FullScreen = () => {
    return (
        <FullscreenIcon
            role="button"
        />
    )
}

FullScreen.propTypes = propTypes
FullScreen.defaultProps = defaultProps

export default FullScreen
