import React from 'react'
import moment from 'moment'

import StatusHandler from 'app/shared-components/StatusHandler'
import useGetLaneById from 'app/Apps/OrderManagement/hooks/useGetLaneById'
import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import FinalizeBookingStep from './FinalizeBookingStep'
import {
    BookingDetailsStepType,
    FinalizeBookingStepType, Selection,
} from '../../createCustomerTransportForm.types'

const FinalizeBookingStepContainer = ({
    setActiveStep,
    onChange,
    value,
    index,
    laneId,
    bookingDetails,
    onReset,
    setIsEdited,
}: {
    value: FinalizeBookingStepType,
    onChange: (val: Partial<FinalizeBookingStepType>) => void,
    setActiveStep: (val: number) => void,
    onReset: () => void,
    index: number,
    laneId: number,
    bookingDetails: BookingDetailsStepType,
    setIsEdited: (isEdited: boolean) => void,
}) => {
    const onSuccessGetLane = (resp) => {
        const {
            transportModeRoad,
        } = resp

        onChange({
            orderEntered: moment().format('DD.MM.YYYY HH:mm ZZ'),
            originScSelection: Selection.DEFAULT,
            fwdPosSelection: Selection.DEFAULT,
            transportModeRoad: transportModeRoad === TransportModeRoad.FTL_OR_LTL
                ? TransportModeRoad.FTL
                : transportModeRoad,
            originSc: resp.originSc,
            originScBackup: resp.originScBackup,
            fwdPosBackup: resp.fwdPosBackup,
        })
    }

    const {
        data: laneData,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLaneById(laneId, onSuccessGetLane)

    return (
        <StatusHandler
            isSuccess={isDataReady && !!value}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FinalizeBookingStep
                setActiveStep={setActiveStep}
                onChange={onChange}
                value={value}
                index={index}
                laneData={laneData}
                bookingDetails={bookingDetails}
                onReset={onReset}
                setIsEdited={setIsEdited}
            />
        </StatusHandler>
    )
}

export default FinalizeBookingStepContainer
