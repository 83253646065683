import React, {
    useMemo,
} from 'react'
import {
    useAuth,
    useUserAvatar,
} from '@skycell-ag/auth'

import Panel from './Panel'

const PanelContainer = () => {
    const {
        user,
    } = useAuth()

    const userName = useMemo(() => {
        if (!user) {
            return ''
        }
        const {
            firstName,
            lastName,
        } = user

        return `${firstName} ${lastName}`
    }, [user])

    const {
        data,
    } = useUserAvatar()

    return (
        <Panel
            userAvatar={data}
            userName={userName}
        />
    )
}

export default PanelContainer
