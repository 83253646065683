import simpleApi from 'app/services/simpleApi'

const getCountry = (token, countryName) => {
    if (!countryName) {
        return Promise.resolve(null)
    }

    return simpleApi({
        method: 'POST',
        url: 'country/filter-request/all',
        token,
        data: {
            start: 0,
            rows: 400,
        },
    }).then((response) => {
        return response?.items.find((currCountry) => {
            return currCountry.countryName.includes(countryName)
        })
    })
        .catch(() => {
            return null
        })
}

export default getCountry
