import {
    createContext,
} from 'react'
import noop from 'lodash/noop'
import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'

export type DomainObjectContextValue = {
    name: string,
    updateTableElement: (updatedObj: any) => void,
    addTableElement: (updatedObj: any) => void,
    uniqField: string,
    selectedTab: string,
    setSelectedTab: (value: string) => void,
    selectDomainObject: (id: string | number) => void,
    requestKey: RequestKeys,
}

const DomainObject = createContext<DomainObjectContextValue>({
    name: '',
    uniqField: '',
    selectedTab: undefined,
    setSelectedTab: noop,
    selectDomainObject: noop,
    addTableElement: noop,
    updateTableElement: noop,
    requestKey: undefined,
})

export default DomainObject
