import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import SelectLane from './SelectLane'
import {
    AvailableLanes,
} from './AvailableLanes.types'

const columns = (onChange): RichTableColumns<Partial<keyof AvailableLanes>>[] => {
    return [
        {
            id: 'laneNumber',
            headerProps: {
                children: 'Lane',
            },
            mapCellProps: 'laneNumber',
        },
        {
            id: 'customerExecutionLocationName',
            headerProps: {
                children: 'Customer (Execution)',
            },
            mapCellProps: 'customerExecutionLocationName',
        },
        {
            id: 'accountCompanyCompanyName',
            headerProps: {
                children: 'Account',
            },
            mapCellProps: 'accountCompanyCompanyName',
        },
        {
            id: 'consigneeLocationName',
            headerProps: {
                children: 'Consignee',
            },
            mapCellProps: 'consigneeLocationName',
        },
        {
            id: 'laneGroupLeaseType',
            headerProps: {
                children: 'Lease Type',
            },
            mapCellProps: 'laneGroupLeaseType',
            componentName: 'EnumValue',
            allowedValuesEnum: OrderSubType,
        },
        {
            id: 'laneGroupHandoverPointLocationName',
            headerProps: {
                children: 'Handover Point',
            },
            mapCellProps: 'laneGroupHandoverPointLocationName',
        },
        {
            id: 'laneGroupCollectionDropoffPointLocationName',
            headerProps: {
                children: 'Collection/Drop-off Point',
            },
            mapCellProps: 'laneGroupCollectionDropoffPointLocationName',
        },
        {
            id: 'originAirportIataCode',
            headerProps: {
                children: 'From',
            },
            mapCellProps: 'originAirportIataCode',
        },
        {
            id: 'destAirportIataCode',
            headerProps: {
                children: 'To',
            },
            mapCellProps: 'destAirportIataCode',
        },
        {
            id: 'laneGroupTempRange',
            headerProps: {
                children: 'Temperature Range',
            },
            mapCellProps: 'laneGroupTempRange',
        },
        {
            id: 'id',
            headerProps: {
                children: '',
            },
            mapCellProps: () => {
                return {
                    onChange,
                }
            },
            Cell: SelectLane,
        },
    ]
}

export default columns
