import simpleApi from 'app/services/simpleApi'

import {
    UseRequestConfig,
} from 'app/types/request.types'

const request: Record<string, UseRequestConfig> = {
    ActivateNotifications: {
        mutationFn: ({
            token,
            laneId,
            activateNotifications,
        }) => {
            return simpleApi({
                url: `lane/${laneId}/activate-notifications`,
                method: 'PATCH',
                data: {
                    activateNotifications,
                },
                token,
            })
        },
    },
}

export default request
