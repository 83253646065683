import React, {
    useCallback, useMemo,
    useState,
} from 'react'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    OpportunityTableType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import request from 'app/Apps/AccountManagement/Opportunities/opportunities.request'
import useLabel from 'app/hooks/useLabel'

import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'

import useAssignmentToOpportunity from './hooks/useAssignmentToOpportunity'
import useStyles from './SelectOpportunityDialog.style'
import getColumns from './opportunityTable.columns'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    onSuccess: () => void,
    setIsAssigned: (isAssigned: boolean) => void,
    accountId: number,
    temperatureRange: LaneGroupTemperatureRange,
    selectedLanes: number[],
}

const DIALOG_TITLE = 'SELECT OPPORTUNITY'

const SelectOpportunityDialog = ({
    open,
    setOpen,
    onSuccess,
    accountId,
    temperatureRange,
    selectedLanes,
    setIsAssigned,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()

    const [
        value,
        setValue,
    ] = useState<number[]>([])

    const {
        loadData,
        onParamsChange,
    } = useRequestTable<TableResponse<OpportunityTableType>>({
        config: request.FilterRequestAll.requestFn,
        params: {},
        key: RequestKeys.getOpportunitiesAll,
    })

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const onAssigned = useCallback((): void => {
        setOpen(false)
        setValue(undefined)
        onSuccess()
    }, [
        onSuccess,
        setOpen,
    ])

    const {
        isLoading,
        mutate,
    } = useAssignmentToOpportunity(onAssigned)

    const handleClose = useCallback((action: boolean) => {
        if (!action) {
            setOpen(false)
            setValue(undefined)

            return
        }

        setIsAssigned(true)
        mutate({
            lanes: selectedLanes.map((el) => {
                return {
                    id: el,
                }
            }),
            opportunity: {
                id: value[0],
            },
        })
    }, [
        mutate,
        selectedLanes,
        setIsAssigned,
        setOpen,
        value,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="save"
            negativeLabel="cancel"
            classNames={{
                paper: classes.paper,
            }}
            isLoading={isLoading}
            positiveLabelDisabled={!value?.length}
        >
            <RichTable
                configName="OpportunityTable"
                name="Opportunities"
                load={loadData}
                columns={columns}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
                filter={{
                    temperatureRange: [temperatureRange],
                    accountId: [accountId],
                }}
                onParamsChange={onParamsChange}
                onSelectRow={setValue}
                selectedRows={value}
                radioSelect
            />
        </ConfirmationDialog>
    )
}

export default SelectOpportunityDialog
