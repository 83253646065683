import {
    useCallback,
    useMemo,
} from 'react'
import {
    useQueryClient,
} from 'react-query'

import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import request from '../components/LoggerExchange/LoggerExchange.requests'
import {
    LoggerContainerInfo,
} from '../containers.types'

const useGetLoggerContainerInformationBySerialNumber = (
    serialNumber: string,
    onSuccess?: (any) => void,
) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getLoggerContainerInformation])
    }, [queryClient])

    const {
        data,
        onParamsChange,
        loadData,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useRequestTable<{data: LoggerContainerInfo[]}>({
        config: request.filterRequest,
        params: {
            serialNumber,
        },
        key: RequestKeys.getLoggerContainerInformation,
        onSuccess,
    })

    const dataFormated = useMemo(() => {
        return (data?.data || []) as LoggerContainerInfo[]
    }, [data])

    return {
        data: dataFormated,
        onParamsChange,
        loadData,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    }
}

export default useGetLoggerContainerInformationBySerialNumber
