import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requestConfig from '../documents.request'

const useAddLoggerDocument = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'Add Document',
    })

    return useMutation(({
        loggerId,
        data,
    }: {
        loggerId: number,
        data: {
            id: number,
        }
    }) => {
        return requestConfig.addDocument.mutationFn({
            loggerId,
            data,
            token,
        })
    }, {
        onSuccess,
        onError,
    })
}

export default useAddLoggerDocument
