const DashboardRequest = {
    name: 'Dashboard',
    domainName: 'LaneGroup',
    uniqField: 'quoteNumber',
    url: {
        upcoming: 'quote/filter-request/kamdashboard-upcoming-quotes',
        toBeCompleted: 'quote/filter-request/kamdashboard-to-be-completed',
        completed: 'quote/filter-request/kamdashboard-completed',
        toBeAssigned: 'account/lane/filter-request/unassigned-to-opportunity',
    },
}

export default DashboardRequest
