import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import tableConfig from './activityRecords.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ActivityRecords = () => {
    return (
        <SkyNetTable
            name="ActivityRecord"
            tableControls={tableControls}
            tableConfig={tableConfig}
        />
    )
}

export default ActivityRecords
