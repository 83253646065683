import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Switch,
    Redirect,
    Route,
    useRouteMatch,
    useHistory,
} from 'react-router-dom'
import {
    useTheme,
} from '@mui/material/styles'
import RichTable from '@skycell-ag/richtable'

import useNextUrlParam from 'app/hooks/useNextUrlParam'
import useWindowSize from 'app/hooks/useWindowSize'
import objectNames from 'app/utils/objectNames'

import DomainHeader from './DomainHeader'
import DomainObjectDetails from './DomainObjectDetails'
import DomainObjectCreate from './DomainObjectCreate'
import DomainObjectPrint from './DomainObjectPrint'
import DETAILS_PAGE from './detailsPage'

import useStyles from './DomainObject.styles'

const propTypes = {
    name: PropTypes.oneOf(objectNames).isRequired,
    load: PropTypes.func.isRequired,
    uniqField: PropTypes.string.isRequired,
    tabs: PropTypes.any, // eslint-disable-line
    columns: PropTypes.arrayOf(PropTypes.shape({
        isReduced: PropTypes.bool,
    })),
    visibleByDefaultColumn: PropTypes.arrayOf(PropTypes.string),
    tableControls: PropTypes.arrayOf(PropTypes.string),
    createForm: PropTypes.func,
    printForm: PropTypes.func,
    sidebarTabs: PropTypes.any, // eslint-disable-line
    onRowClick: PropTypes.func,
    simple: PropTypes.bool,
    selectedRows: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])),
    onSelectRow: PropTypes.func,
    filter: PropTypes.object, // eslint-disable-line
    excludeFilters: PropTypes.object, // eslint-disable-line
    richtableRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    getDomainObject: PropTypes.func,
    noDetails: PropTypes.bool,
    counter: PropTypes.number,
    setSearchValue: PropTypes.func.isRequired,
    configName: PropTypes.string,
    showSelectAll: PropTypes.bool,
    disabled: PropTypes.bool,
}

const defaultProps = {
    columns: [],
    visibleByDefaultColumn: [],
    createForm: undefined,
    printForm: undefined,
    tableControls: undefined,
    sidebarTabs: undefined,
    onRowClick: undefined,
    selectedRows: undefined,
    onSelectRow: undefined,
    simple: false,
    filter: {},
    excludeFilters: {},
    className: null,
    getDomainObject: undefined,
    noDetails: false,
    counter: undefined,
    configName: undefined,
    showSelectAll: false,
    disabled: false,
}

const DomainObject = (props) => {
    const {
        name,
        configName,
        load,
        uniqField,
        tableControls,
        createForm,
        printForm,
        tabs,
        columns,
        sidebarTabs,
        onRowClick,
        selectedRows,
        onSelectRow,
        filter,
        excludeFilters,
        simple,
        richtableRef,
        className,
        getDomainObject,
        noDetails,
        counter,
        setSearchValue,
        visibleByDefaultColumn,
        showSelectAll,
        disabled,
    } = props

    const {
        breakpoints,
    } = useTheme()

    const {
        classes,
    } = useStyles()

    const history = useHistory()

    const [
        selectedTab,
        pageName,
        selectedRowIdFromParams,
    ] = useNextUrlParam()

    const [
        selectedRowId,
        setSelectedRowId,
    ] = useState(selectedRowIdFromParams)

    const {
        url,
    } = useRouteMatch()

    const reducedMode = useMemo(() => {
        return Boolean(selectedRowId) || pageName?.includes('create') || pageName?.includes('print')
    }, [
        pageName,
        selectedRowId,
    ])

    const handleOnRowClick = useCallback((row) => {
        if (selectedRows) {
            return
        }

        if (String(row[uniqField]) === String(selectedRowId)) {
            history.push({
                pathname: `${url}`,
            })
        } else {
            history.push({
                pathname: `${url}/${selectedTab}/${DETAILS_PAGE}/${row[uniqField]}`,
            })
        }
    }, [
        selectedRowId,
        selectedRows,
        selectedTab,
        uniqField,
        history,
        url,
    ])

    const applySearch = useCallback((searchText) => {
        setSearchValue(searchText)
    }, [setSearchValue])

    const {
        width,
    } = useWindowSize()

    const visibleColumns = useMemo(() => {
        if (width < breakpoints.values.xl && pageName !== undefined) {
            return [columns[0]]
        }
        return columns.filter(({
            reduced: visibleWhenReduced,
        }) => {
            return !reducedMode || visibleWhenReduced
        })
    }, [
        width,
        pageName,
        columns,
        reducedMode,
        breakpoints.values.xl,
    ])

    const selectedRowIdParsed = useMemo(() => {
        // TODO: should be removed ID-5148
        if (!/^[1-9]+[0-9]*$/.test(selectedRowId)) {
            return selectedRowId
        }

        return parseInt(selectedRowId, 10)
    }, [selectedRowId])

    const isNeedSwitch = useMemo(() => {
        return !simple || createForm || printForm || !noDetails
    }, [
        createForm,
        printForm,
        noDetails,
        simple,
    ])

    return (
        <div
            className={clsx(classes.root, className, {
                [classes.rootResponsive]: pageName,
            })}
        >
            <RichTable
                ref={richtableRef}
                classNames={{
                    contentWrapper: simple ? classes.tableContentWrapper : classes.contentWrapper,
                    headerWrapper: simple ? classes.tableHeaderWrapper : {},
                }}
                noBorders={simple}
                fullWidth={simple}
                name={name}
                configName={configName}
                load={load}
                className={clsx({
                    [classes?.wrapper]: width < breakpoints.values.xl && pageName,
                    [classes?.tableContent]: simple,
                })}
                uniqField={uniqField}
                onRowClick={onRowClick || handleOnRowClick}
                selectedRowId={selectedRowIdParsed}
                columns={visibleColumns}
                visible={visibleByDefaultColumn}
                renderControlPanel={(controlPanelProps) => {
                    return (
                        <DomainHeader
                            reducedMode={false}
                            simple={simple}
                            tableControls={tableControls}
                            createForm={createForm}
                            tabs={tabs}
                            counter={counter}
                            url={url}
                            selectedTab={selectedTab}
                            printForm={printForm}
                            applySearch={applySearch}
                            controlPanelProps={controlPanelProps}
                        />
                    )
                }}
                selectedRows={selectedRows}
                onSelectRow={onSelectRow}
                filter={filter}
                excludeFilters={excludeFilters}
                showSelectAll={showSelectAll}
                relative={!simple}
                disabled={disabled}
            />
            { isNeedSwitch && (
                <Switch>
                    <Route path={`${url}/:tab`}>
                        {createForm && (
                            <Route path={`${url}/:tab/create`}>
                                <DomainObjectCreate
                                    backUrl={url}
                                    form={createForm}
                                    name={name}
                                />
                            </Route>
                        )}
                        {printForm && (
                            <Route path={`${url}/:tab/print`}>
                                <DomainObjectPrint
                                    backUrl={url}
                                    form={printForm}
                                    name={name}
                                />
                            </Route>
                        )}
                        {!noDetails && (
                            <Route path={`${url}/:tab/${DETAILS_PAGE}/:id`}>
                                <DomainObjectDetails
                                    backUrl={url}
                                    onSelectRow={setSelectedRowId}
                                    tabs={sidebarTabs}
                                    getDomainObject={getDomainObject}
                                />
                            </Route>
                        )}
                    </Route>
                    {!simple
                    && <Redirect to={`${url}/all`} />}
                </Switch>
            )}
        </div>
    )
}

DomainObject.propTypes = propTypes
DomainObject.defaultProps = defaultProps

export default DomainObject
