import {
    NotificationTypes,
} from 'app/types/enums'

export enum SentStatus {
    FAIL = 'FAIL',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
}

export enum RecipientTypes {
    CONSIGNEE = 'CONSIGNEE',
    CUSTOMER = 'CUSTOMER',
    DESTINATION_LOCATION = 'DESTINATION_LOCATION',
    DROPOFFPOINT = 'DROPOFFPOINT',
    EVENT_CONTACT = 'EVENT_CONTACT',
    EVENT_BOOKER = 'EVENT_BOOKER',
    FORWARDER_AIR_INTERNAL = 'FORWARDER_AIR_INTERNAL',
    FORWARDER_AIR_ROAD_INTERNAL = 'FORWARDER_AIR_ROAD_INTERNAL',
    FORWARDER_CUSTOMS = 'FORWARDER_CUSTOMS',
    FORWARDER_EXPORT = 'FORWARDER_EXPORT',
    FORWARDER_IMPORT = 'FORWARDER_IMPORT',
    FORWARDER_POSITIONING = 'FORWARDER_POSITIONING',
    FORWARDER_ROAD_INTERNAL = 'FORWARDER_ROAD_INTERNAL',
    FORWARDER_ROAD_INTERNAL_DELIVERY = 'FORWARDER_ROAD_INTERNAL_DELIVERY',
    FORWARDER_ROAD_INTERNAL_PICKUP = 'FORWARDER_ROAD_INTERNAL_PICKUP',
    FORWARDER_SEA_INTERNAL = 'FORWARDER_SEA_INTERNAL',
    FORWARDER_SEA_ROAD_INTERNAL = 'FORWARDER_SEA_ROAD_INTERNAL',
    HANDOVER_POINT = 'HANDOVER_POINT',
    ORIGIN_LOCATION = 'ORIGIN_LOCATION',
    ORIGIN_LOCATION_PROVISION = 'ORIGIN_LOCATION_PROVISION',
    SERVICE_CENTER_SKYCENTER_REPORT = 'SERVICE_CENTER_SKYCENTER_REPORT',
    SKYCELL_BILLING = 'SKYCELL_BILLING',
    SKYCELL_OPS = 'SKYCELL_OPS',
    SKYCELL_TECHNICALSERVICES = 'SKYCELL_TECHNICALSERVICES',
}

export type DefaultAttachment = {
    id: number,
    fileName: string,
}

export type NotificationTableView = {
    id: number,
    sentFirstTimeOn: string,
    sentThirdTimeOn: string,
    sentSecondTimeOn: string,
    customAttachments: {
        id: number
    }[],
    notificationName: string,
    notificationType: NotificationTypes,
    recipientTypes: RecipientTypes,
    recipients: string[],
    sendFirstTime: boolean,
    sendSecondTime: boolean,
    sendThirdTime: boolean,
    triggerDescription: string,
    lastSentOn: string,
    optional: boolean,
    sentFirstStatus: SentStatus,
    sentSecondStatus: SentStatus,
    sentThirdStatus: SentStatus,
    defaultAttachments: DefaultAttachment[]
}

export type NotificationTableViewFields = keyof NotificationTableView
