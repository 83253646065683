import React, {
    useCallback,
    useState,
    useMemo, useEffect,
} from 'react'

import Tooltip from 'app/shared-components/Tooltip'
import Button from 'app/shared-components/Button'
import {
    TypeWithId,
    PrintPreview,
    PrintPreviewUniq,
    LanesTableRow,
    LanesTableRowUniq,
    AdditionalFeesTableRow,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import PrintFiltersForm from 'app/Apps/Pricing/ContractBasis/components/PrintFiltersForm'
import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import {
    QUOTATION,
    PrintFilterValues,
    ContactsSelection,
} from 'app/Apps/Pricing/ContractBasis/components/PrintFiltersForm/PrintForm.types'
import idConvertions from 'app/Apps/Pricing/ContractBasis/utils/idConvertions'

import PrintPreviewDataForm from './PrintPreviewDataForm'
import AddFeesLaneGroupsForm from './AddFeesLaneGroupsForm'
import useQuotePreview from '../hooks/useQuotePreview'

import useStyles from './ContractBasisCreateQuote.style'

type Props = {
    id: number,
    quoteId?: number,
    showContactsSelections?: boolean,
    renderActions: (params: any) => JSX.Element,
}

const defaultProps : Partial<Props> = {
    quoteId: undefined,
    showContactsSelections: true,
}

const ContractBasisCreateQuote = ({
    id,
    quoteId,
    showContactsSelections,
    renderActions,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)
    const [
        filtersState,
        setFiltersState,
    ] = useState<Partial<PrintFilterValues>>({
        customerType: QUOTATION,
        isCustomerQuotation: true,
    })
    const [
        previewData,
        setPreviewData,
    ] = useState<PrintPreviewUniq>({
        additionalFeesTableRows: [],
        lanesTableRows: [],
    })
    const [
        additionalFees,
        setAdditionalFees,
    ] = useState<TypeWithId>()
    const [
        laneGroups,
        setLaneGroups,
    ] = useState<TypeWithId[]>()

    const {
        mutate: preview,
    } = useQuotePreview()

    const [
        laneFeesSelectedRows,
        setLaneFeesSelectedRows,
    ] = useState<string[]>([])

    const [
        additionFeesSelectedRows,
        setAdditionFeesSelectedRows,
    ] = useState<number[]>([])

    const [
        contactsSelectionsState,
        setContactsSelectionsState,
    ] = useState<ContactsSelection>({})

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    useEffect(() => {
        if (additionalFees?.id || laneGroups?.length) {
            setIsEdited(true)
        }
    }, [
        additionalFees,
        laneGroups,
    ])

    useBeforeUnloadDialog(isEdited)

    const isAddFeeAndLaneGroupsSelected = useMemo((): boolean => {
        return additionalFees?.id && laneGroups?.length > 0
    }, [
        additionalFees,
        laneGroups,
    ])

    const firstStepComponent = useMemo(() => {
        return (
            <div>
                <AddFeesLaneGroupsForm
                    disabled={activeStep !== 0}
                    id={id}
                    setAdditionalFees={setAdditionalFees}
                    setLaneGroups={setLaneGroups}
                    quoteId={quoteId}
                />
                <div>
                    {activeStep === 0 && (
                        <Tooltip
                            title="Please select an Additional Fee and Lane Group"
                            disabled={isAddFeeAndLaneGroupsSelected}
                        >
                            <div className={classes.buttonWrapper}>
                                <Button
                                    label="Continue"
                                    onClick={() => { setActiveStep(1) }}
                                    className={classes.button}
                                    disabled={!isAddFeeAndLaneGroupsSelected}
                                />
                            </div>
                        </Tooltip>
                    )}
                    {activeStep !== 0 && (
                        <Button
                            label="Edit"
                            onClick={() => { setActiveStep(0) }}
                            className={classes.button}
                        />
                    )}
                </div>
            </div>
        )
    }, [
        activeStep,
        classes,
        id,
        isAddFeeAndLaneGroupsSelected,
        quoteId,
    ])

    const paramsForPreview = useMemo(() => {
        const {
            selectedAirports,
            selectedLaneFeesStatuses,
            selectedCustomerExecution,
            exactDates,
            laneFeeValidFrom,
            laneFeeValidTo,
            selectedLaneStatus,
            isCustomerQuotation,
            allowDuplication,
            comment,
        } = filtersState

        return {
            additionalFees,
            laneGroups,
            selectedAirports: selectedAirports?.map(idConvertions),
            selectedLaneFeesStatuses,
            selectedCustomerExecution: selectedCustomerExecution && {
                id: selectedCustomerExecution,
            },
            exactDates,
            laneFeeValidFrom,
            laneFeeValidTo,
            selectedLaneStatus,
            isCustomerQuotation,
            allowDuplication,
            skycellEmployeeContact: contactsSelectionsState.keyAccountManager
                || contactsSelectionsState.businessDeveloper,
            customerContact: contactsSelectionsState.customerExecutionContact
                || contactsSelectionsState.customerQuotationContact,
            comment,
            quoteId,
        }
    }, [
        additionalFees,
        laneGroups,
        filtersState,
        contactsSelectionsState,
        quoteId,
    ])

    const onPreviewSuccess = useCallback((response: PrintPreview) => {
        const modifyedResponse: PrintPreviewUniq = {
            ...response,
            lanesTableRows: response.lanesTableRows.map((row: LanesTableRow) => {
                return {
                    ...row,
                    tempId: `${row.laneNumber}.${row.laneFees.laneFeesNumber}`,
                }
            }),
        }

        setPreviewData(modifyedResponse)
        const allFeesIds = modifyedResponse.additionalFeesTableRows
            .map((item: AdditionalFeesTableRow) => {
                return item.additionalFeesFeeId
            })
        const allLaneIds = modifyedResponse.lanesTableRows.map((item: LanesTableRowUniq) => {
            return item.tempId
        })

        setAdditionFeesSelectedRows(allFeesIds)
        setLaneFeesSelectedRows(allLaneIds)
        setActiveStep(2)
    }, [])

    const previewCall = useCallback(() => {
        preview({
            ...paramsForPreview,
        }, {
            onSuccess: onPreviewSuccess,
        })
    }, [
        preview,
        onPreviewSuccess,
        paramsForPreview,
    ])

    const isContactsSelectionsSelected = useMemo((): boolean => {
        return !showContactsSelections
            || ((Boolean(contactsSelectionsState.keyAccountManager)
                    || Boolean(contactsSelectionsState.businessDeveloper)))
    }, [
        contactsSelectionsState,
        showContactsSelections,
    ])

    const secondStepComponent = useMemo(() => {
        return (
            <div>
                <PrintFiltersForm
                    contractId={id}
                    state={filtersState}
                    setState={setFiltersState}
                    laneGroups={laneGroups}
                    disabled={activeStep !== 1}
                    contactsSelections={contactsSelectionsState}
                    onContactsSelectionsChange={setContactsSelectionsState}
                    showContactsSelections={showContactsSelections}
                    showComment={!!quoteId}
                />
                <div>
                    {activeStep === 1 && (
                        <div className={classes.doubleButtons}>
                            <Button
                                label="Continue"
                                onClick={previewCall}
                                className={classes.button}
                                disabled={!isContactsSelectionsSelected}
                            />
                            <Button
                                label="Back"
                                secondary
                                onClick={() => { setActiveStep(0) }}
                                className={classes.button}
                            />
                        </div>
                    )}
                    {activeStep !== 1 && (
                        <Button
                            label="Edit"
                            onClick={() => { setActiveStep(1) }}
                            className={classes.button}
                        />
                    )}
                </div>
            </div>
        )
    }, [
        id,
        filtersState,
        laneGroups,
        activeStep,
        contactsSelectionsState,
        showContactsSelections,
        classes.doubleButtons,
        classes.button,
        previewCall,
        isContactsSelectionsSelected,
        quoteId,
    ])

    const paramsForLastActions = useMemo(() => {
        return {
            ...paramsForPreview,
            selectedLaneLaneFeesPairs: laneFeesSelectedRows.map((tempId: string) => {
                const [
                    laneNumber,
                    laneFeesNumber,
                ] = tempId.split('.')

                return {
                    laneNumber,
                    laneFeesNumber,
                }
            }),
            selectedAdditionalFeesFees: additionFeesSelectedRows,
        }
    }, [
        additionFeesSelectedRows,
        laneFeesSelectedRows,
        paramsForPreview,
    ])

    const thirdStepComponent = useMemo(() => {
        return (
            <div>
                <PrintPreviewDataForm
                    data={previewData}
                    laneFeesSelectedRows={laneFeesSelectedRows}
                    laneFeesOnSelectedRow={setLaneFeesSelectedRows}
                    additionFeesSelectedRows={additionFeesSelectedRows}
                    additonalFeesOnSelectedRow={setAdditionFeesSelectedRows}
                />
                <div>
                    <div className={classes.doubleButtons}>
                        {renderActions(paramsForLastActions)}
                        <Button
                            label="Back"
                            secondary
                            onClick={() => { setActiveStep(1) }}
                            className={classes.lastButton}
                        />
                    </div>
                </div>
            </div>
        )
    }, [
        previewData,
        laneFeesSelectedRows,
        additionFeesSelectedRows,
        classes.doubleButtons,
        classes.lastButton,
        renderActions,
        paramsForLastActions,
    ])

    const stepperSteps = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Select Relevant Additional Fees And Lane Groups',
                expanded: true,
                content: firstStepComponent,
            },
            {
                key: '2',
                label: 'Filter Lanes and Lane Fees',
                expanded: (currentStep) => {
                    return currentStep > 1
                },
                content: secondStepComponent,
            },
            {
                key: '3',
                label: 'Confirm Final Selection',
                expanded: false,
                content: thirdStepComponent,
            },
        ]
    }, [
        firstStepComponent,
        secondStepComponent,
        thirdStepComponent,
    ])

    return (
        <>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </>
    )
}

ContractBasisCreateQuote.defaultProps = defaultProps

export default ContractBasisCreateQuote
