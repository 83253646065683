import React from 'react'
import {
    Tooltip as MaterialTooltip,
} from '@mui/material'

import useStyles from './Tooltip.style'

type Props = {
    title: string | JSX.Element,
    enterDelay?: number,
    disabled?: boolean,
    placement?: 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top',
    children: JSX.Element,
}

const defaultProps: Partial<Props> = {
    enterDelay: 0,
    disabled: false,
    placement: 'bottom',
}

const Tooltip = ({
    title,
    disabled,
    children,
    placement,
    enterDelay,
}: Props) => {
    const {
        classes,
    } = useStyles()

    return (
        <MaterialTooltip
            arrow
            enterDelay={enterDelay}
            placement={placement}
            title={title}
            disableHoverListener={disabled}
            disableFocusListener={disabled}
            disableTouchListener={disabled}
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
            }}
        >
            {children}
        </MaterialTooltip>
    )
}

Tooltip.defaultProps = defaultProps

export default Tooltip
