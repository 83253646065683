import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import locationRequests from '../Locations.request'

type LocationParams = {
    latitude: number,
    longitude: number,
    editedLocation: {
        id: number,
    }
}

export default (onSuccessAction) => {
    const token = useJWTToken()

    const {
        onStartSaving,
        onError: onFailedRequest,
        removePendingStatus,
    } = useHandleStatusRequest({
        id: 'LocationDuplicationCheck',
    })

    const onSuccess = useCallback((data) => {
        removePendingStatus()
        onSuccessAction(data)
    }, [
        removePendingStatus,
        onSuccessAction,
    ])

    const onError = useCallback((err) => {
        onFailedRequest(err)
    }, [onFailedRequest])

    const {
        mutate: checkDuplication,
    } = useMutation((data: LocationParams) => {
        return simpleApi({
            ...locationRequests.locationDuplicateCheck({
                data,
            }),
            token,
        })
    }, {
        onSuccess,
        onError,
    })

    return useCallback((locationParams: LocationParams) => {
        onStartSaving('Checking Database for similar locations')
        checkDuplication(locationParams)
    }, [
        checkDuplication,
        onStartSaving,
    ])
}
