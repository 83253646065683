import {
    RequestConfig,
} from 'app/types/request.types'

type BillingCompaniesRequest = {
    domainName: string,
    get: ({
        id: number,
    }) => RequestConfig,
    filterRequest: () => RequestConfig,
    create: () => RequestConfig,
    update: ({
        id: number,
    }) => RequestConfig,
}

const requests: BillingCompaniesRequest = {
    domainName: 'BillingCompanyDto',
    get: ({
        id,
    }) => {
        return {
            url: `billingcompany/${id}`,
            method: 'GET',
        }
    },
    filterRequest: () => {
        return {
            url: 'billingcompany/filter-request/all',
            method: 'GET',
        }
    },
    create: () => {
        return {
            url: 'billingcompany',
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `billingcompany/${id}/data`,
            method: 'PATCH',
        }
    },
}

export default requests
