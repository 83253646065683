/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Tooltip from './Tooltip'

type Props = {
    value: string | JSX.Element,
    ownTooltipProps: Record<string, any>,
    children: JSX.Element
}

const TooltipWrapper = ({
    value,
    ownTooltipProps,
    children,
}: Props) => {
    return value ? (
        <Tooltip
            title={value}
            {...ownTooltipProps}
        >
            {children}
        </Tooltip>
    ) : children
}

export default TooltipWrapper
