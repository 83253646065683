import {
    useCallback,
} from 'react'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    v1 as uuidv1,
} from 'uuid'

import simpleApi from 'app/services/simpleApi'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    Position,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const useLoadAutoPopulatePosition = (invoiceId: number, data: Position): () => Promise<any> => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: uuidv1(),
    })

    const autoPopulatePositionApi = useCallback((positionCode) => {
        return simpleApi(
            {
                ...orderManagementRequest.AutoPopulatePosition.request(invoiceId),
                token,
                params: {
                    positionCode,
                },
            },
        )
            .catch(onError)
    }, [
        invoiceId,
        token,
        onError,
    ])

    return useCallback(() => {
        if (!data.positionCode) {
            return Promise.resolve({})
        }

        return autoPopulatePositionApi(data.positionCode)
    }, [
        autoPopulatePositionApi,
        data.positionCode,
    ])
}

export default useLoadAutoPopulatePosition
