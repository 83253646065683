import {
    FieldInCard,
} from 'app/types/form.types'
import {
    OrderStepFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getInternalEventFormFields = (): FieldInCard<OrderStepFields>[] => {
    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'eventName',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.eventName',
                },
                {
                    name: 'eventContactPerson',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.eventContactPerson',
                },
                {
                    name: 'eventOrganizer',
                    componentName: 'CompanyObjectSelector',
                    required: true,
                    labelKey: 'OrderStep.eventOrganizerCompany',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'eventLocation',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.eventLocation',
                },
                {
                    name: 'locationContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'OrderStep.locationContacts',
                },
            ],
        },
        {
            id: 'column3',
            fields: [{
                name: 'comment',
                componentName: 'LongText',
                labelKey: 'OrderStep.comment',
            }],
        },
        {
            id: 'column4',
            fields: [{
                name: 'eventBooker',
                componentName: 'ContactObjectSelector',
                labelKey: 'OrderStep.eventBooker',
            }],
        },
    ]
}

export default getInternalEventFormFields
