import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    kamCompleted,
} from '../KamDashboard.types'

const kamDashboardConfig: SkyNetTableConfig<keyof kamCompleted> = {
    name: 'kamCompleted',
    tabs: [{
        name: 'Completed',
        url: 'all',
    }],
    fields: [
        {
            name: 'quoteNumber',
            labelKey: 'AccountQuotes.quoteNumber',
        },
        {
            name: 'customerQuotation',
            labelKey: 'AccountQuotes.customerQuotation',
        },
        {
            name: 'accountName',
            labelKey: 'AccountLane.accountCompanyName',
            filterField: 'accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'leaseType',
            labelKey: 'AccountLane.leaseType',
            filterField: 'leaseType',
            componentName: 'EnumValue',
            filterType: FilterType.Array,
            allowedValuesEnum: LeaseType,
        },
        {
            name: 'pricingOwner',
            labelKey: 'KamDashboard.pricingOwner',
        },
        {
            name: 'createdBy',
            labelKey: 'KamDashboard.requestor',
            sorting: false,
        },
        {
            name: 'totalLanes',
            labelKey: 'KamDashboard.totalLanes',
            sorting: false,
        },
        {
            name: 'lastUpdated',
            labelKey: 'KamDashboard.lastUpdated',
            sorting: false,
        },
    ],
}

export default kamDashboardConfig
