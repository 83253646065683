import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    RegionFirstLevel,
} from 'app/Apps/Masterdata/Countries/Countries.types'
import {
    RegionalGroup,
} from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.types'

const getFields = (
    {
        getAllowedValues,
    }: {
        getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    },
): DTOCardFormField<keyof RegionalGroup>[] => {
    return [{
        id: 'generalInformation',
        title: 'General Information',
        className: 'generalInformation',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'account',
                        componentName: 'AccountSelector',
                        labelKey: 'RegionalGroups.accountName',
                        required: true,
                        disabled: true,
                    },
                    {
                        name: 'tempRange',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                        labelKey: 'RegionalGroups.tempRange',
                        required: true,
                        useDropdown: true,
                    },
                    {
                        name: 'shortTermForecastOwner',
                        componentName: 'UserObjectSelector',
                        labelKey: 'RegionalGroups.shortTermForecastOwner',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'originRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.originRegion',
                        required: true,
                    },
                    {
                        name: 'annualPotentialVolume',
                        componentName: 'IntegerInput',
                        labelKey: 'RegionalGroups.annualPotentialVolume',
                        required: true,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'destinationRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.destinationRegion',
                        required: true,
                    },
                    {
                        name: 'opportunityIndex',
                        componentName: 'IntegerInput',
                        labelKey: 'RegionalGroups.opportunityIndex',
                    },
                    {
                        name: 'additionalInformation',
                        labelKey: 'RegionalGroups.additionalInformation',
                        componentName: 'LongText',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'supportingDocuments',
                    componentName: 'AttachmentsObjectSelector',
                    labelKey: 'RegionalGroups.supportingDocuments',
                }],
            },
        ],
    }]
}

export default getFields
