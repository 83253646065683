import {
    useCallback,
} from 'react'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

export default () => {
    return useCallback((data: any[]) => {
        return data.filter((item) => {
            return !Object.values(item).every(
                (el: any) => {
                    if (isObject(el)) {
                        return !(isNumber(el?.id) || isString(el?.id))
                    }
                    return isEmpty(el)
                },
            )
        })
    }, [])
}
