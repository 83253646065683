/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import {
    FormHelperText,
    TextField,
} from '@mui/material'
import {
    DatePicker,
} from '@mui/x-date-pickers/DatePicker'
import clsx from 'clsx'

import Label from 'app/shared-components/Label'
import {
    strToDate,
} from 'app/utils/date'
import {
    Event,
} from 'app/types/common.types'

import useStyles from './DateSelect.style'

type Props = {
    title?: string,
    name?: string,
    error?: string,
    onChange: (newVal: Date, e: Event<number | Date>) => void,
    value?: string,
    disabled?: boolean,
    required?: boolean,
    className?: string,
}

const defaultProps = {
    title: '',
    name: '',
    error: '',
    disabled: false,
    required: false,
    value: null,
    className: '',
}

const DateSelect = ({
    title,
    name,
    error,
    onChange,
    value,
    disabled,
    required,
    className,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const handleOnChange = useCallback((date: string): void => {
        const newValue = date ? new Date(date) : null

        onChange(newValue, {
            target: {
                name,
                value: newValue,
            },
        })
    }, [
        name,
        onChange,
    ])

    return (
        <div className={className}>
            <Label title={title} />
            <div
                className={clsx(
                    classes.dateField,
                    {
                        [classes.disabled]: disabled,
                        [classes.isRequired]: required,
                    },
                )}
            >
                <div className={classes.requiredMark} />
                <DatePicker
                    className={classes.input}
                    value={strToDate(value)}
                    mask="DD.MM.YYYY"
                    onChange={handleOnChange}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="standard"
                            />
                        )
                    }}
                    disabled={disabled}
                    data-testid={`dateSelect-${name}`}
                />
                <FormHelperText
                    error={Boolean(error)}
                    className={classes.errorMessage}
                >
                    {error}
                </FormHelperText>
            </div>
        </div>
    )
}

DateSelect.defaultProps = defaultProps

export default DateSelect
