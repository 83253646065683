import React, {
    useMemo, useState,
} from 'react'
import FormWithControls from 'app/shared-components/FormWithControls'
import {
    AccountLane,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    isDeliveryRoad, showFTL, showLTL, isCollectonOrDropOffForwarder,
} from 'app/Apps/Pricing/utils/utils'
import useFieldsWithClassNames from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequestConfig,
} from 'app/types/request.types'
import useValidation from 'app/hooks/useValidation'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    addressCategoryValidation, isProspectAndNotShippingLocation,
    addressMainCategoryValidation,
} from 'app/Apps/ContactManagement/Locations'
import {
    isOneOfContactsProspect, isContactRequired,
} from 'app/Apps/ContactManagement/Contacts/Contacts.validation'

import useCheckAwardedLanes from './hooks/useCheckAwardedLanes'
import getFields from './AwardRejectLaneForm.fields'
import useStyles from './AwardRejectLaneForm.styles'

const AwardRejectLaneForm = ({
    data,
    disabled,
    onSuccess,
    onCancel,
    requestParam,
    getCustomButtons,
}: {
    data: AccountLane,
    requestParam: RequestConfig,
    disabled: boolean,
    onSuccess: (...args: any[]) => void,
    onCancel: (...args: any[]) => void,
    getCustomButtons: (...a: any[]) => JSX.Element[]
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const [
        value,
        setValue,
    ] = useState(data)

    const hasAwardedLanes = useCheckAwardedLanes(value?.laneGroupId)
    const contractBasisAdminPermission = useHasPermission(['contract_basis_admin'])
    const collectionDropoffPointValidation = useMemo(() => {
        if (value?.collectionServiceType === CollServiceType.DROPOFF_SKYCELL) {
            return addressCategoryValidation
        }
        if (value?.collectionServiceType === CollServiceType.COLLECTION) {
            return addressMainCategoryValidation
        }

        return isProspectAndNotShippingLocation
    }, [value?.collectionServiceType])

    const handoverPointContactsValidate = useMemo(() => {
        if (isDeliveryRoad(value?.deliveryServiceType)) {
            return [
                isContactRequired,
                isOneOfContactsProspect,
            ]
        }
        return undefined
    }, [value?.deliveryServiceType])

    const collectionPointContactValidate = useMemo(() => {
        if (isCollectonOrDropOffForwarder(value?.collectionServiceType)) {
            return [
                isContactRequired,
                isOneOfContactsProspect,
            ]
        }
        return undefined
    }, [value?.collectionServiceType])

    const fields = useFieldsWithClassNames(
        getFields({
            getAllowedValues,
            isDeliveryRoad: isDeliveryRoad(value?.deliveryServiceType),
            isCollectonOrDropOffForwarder:
                isCollectonOrDropOffForwarder(value?.collectionServiceType),
            handoverPointContactsValidate,
            collectionPointContactValidate,
            showFTL: showFTL(value?.transportModeRoad),
            showLTL: showLTL(value?.transportModeRoad),
            hasAwardedLanes,
            contractBasisAdminPermission,
            handoverPointValidation: value?.deliveryServiceType === DeliveryServiceType.PICKUP
                ? addressCategoryValidation
                : isProspectAndNotShippingLocation,
            collectionDropoffPointValidation,
        }), classes,
    )

    const formValues = useMemo(() => {
        return {
            ...data,
            ...value,
        }
    }, [
        data,
        value,
    ])

    const {
        isValid,
    } = useValidation({
        fields, values: formValues,
    })

    return (
        <FormWithControls
            disabled={disabled}
            value={formValues}
            name="AccountLane"
            requestParams={requestParam}
            setExternalValue={setValue}
            fields={fields}
            onSuccess={onSuccess}
            onCancel={onCancel}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            exists={Boolean(data?.id)}
            isValid={isValid}
            disableOnError
            getCustomButtons={getCustomButtons}
        />
    )
}

export default AwardRejectLaneForm
