import {
    StepType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    StepTypeDetailed,
} from 'app/types/enums'

import {
    StepData,
} from '../../createInternalTransportForm.types'

const mapOrderSteps: StepData[] = [
    {
        title: 'Road',
        stepType: StepType.ROAD,
        stepTypeDetailed: StepTypeDetailed.ROAD_INTERNAL,
        imgSrc: 'assets/images/orderSteps/road.svg',
    },
    {
        title: 'Road Pick-Up',
        stepType: StepType.ROAD,
        stepTypeDetailed: StepTypeDetailed.ROAD_INTERNAL_PICKUP,
        imgSrc: 'assets/images/orderSteps/road_internal_pickup.svg',
    },
    {
        title: 'Road Delivery',
        stepType: StepType.ROAD,
        stepTypeDetailed: StepTypeDetailed.ROAD_INTERNAL_DELIVERY,
        imgSrc: 'assets/images/orderSteps/road_internal_delivery.svg',
    },
    {
        title: 'Sea',
        stepType: StepType.SEA,
        stepTypeDetailed: StepTypeDetailed.SEA_INTERNAL,
        imgSrc: 'assets/images/orderSteps/sea.svg',
    },
    {
        title: 'Sea Road',
        stepType: StepType.SEA,
        stepTypeDetailed: StepTypeDetailed.SEA_ROAD_INTERNAL,
        imgSrc: 'assets/images/orderSteps/sea_road.svg',
    },
    {
        title: 'Air',
        stepType: StepType.AIR,
        stepTypeDetailed: StepTypeDetailed.AIR_INTERNAL,
        imgSrc: 'assets/images/orderSteps/air.svg',
    },
    {
        title: 'Air Road',
        stepType: StepType.AIR,
        stepTypeDetailed: StepTypeDetailed.AIR_ROAD_INTERNAL,
        imgSrc: 'assets/images/orderSteps/air_road.svg',
    },
    {
        title: 'Event',
        stepType: StepType.EVENT,
        stepTypeDetailed: StepTypeDetailed.EVENT_EVENT,
        imgSrc: 'assets/images/orderSteps/event.svg',
    },
]

export default mapOrderSteps
