/* eslint-disable class-methods-use-this */

import React from 'react'
import {
    Cell,
    CellTemplate,
    Compatible,
    getCellProperty,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid'
import getCellOptions from '../../getCellOptions'
import SelectCellWrapper from './SelectCellWrapper'
import {
    SkyNetSpreadSheetCellType,
} from '../../SkyNetSpreadSheet.types'
import SelectableElement from '../../SelectableElement'

export interface SelectCell extends Cell {
    type: SkyNetSpreadSheetCellType.SELECT,
    text?: string,
    id?: string | number,
    selectedValue: string
    inputValue?: string,
    getAllowedValues?: () => Record<string, string> | [string, string][]
    name?: string,
}

export default class SelectCellTemplate implements CellTemplate<SelectCell> {
    private configId: string

    private getCellOptions = getCellOptions

    constructor({
        configId,
    }: {
        configId: string
    }) {
        this.configId = configId
    }

    findOption(cell: Uncertain<SelectCell>, selectedValue: string) {
        return this.getCellOptions(cell, this.configId).find(({
            value: optionValue,
        }) => {
            return optionValue === selectedValue
        })
    }

    getLabel(cell: Uncertain<SelectCell> | Compatible<SelectCell>, selectedValue: string): string {
        return this.findOption(cell, selectedValue)?.label
    }

    getCompatibleCell(uncertainCell: Uncertain<SelectCell>): Compatible<SelectCell> {
        let selectedValue

        try {
            selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string')
        } catch {
            selectedValue = null
        }

        return {
            ...uncertainCell,
            selectedValue,
            text: this.getLabel(uncertainCell, selectedValue),
            value: 0,
            id: selectedValue,
        }
    }

    handleKeyDown(
        cell: Compatible<SelectCell>,
        keyCode: number,
    ): { cell: Compatible<SelectCell>, enableEditMode: boolean } {
        return {
            cell,
            enableEditMode:
                keyCode === keyCodes.POINTER
                || keyCode === keyCodes.ENTER || keyCode === keyCodes.SPACE,
        }
    }

    getValueByText(text, options) {
        return options.find(({
            value, label,
        }) => {
            return text === label || text === value
        })?.value
    }

    update(
        cell: Compatible<SelectCell>,
        cellToMerge: UncertainCompatible<SelectCell>,
    ): Compatible<SelectCell> {
        if (cellToMerge.type === SkyNetSpreadSheetCellType.SELECT) {
            return this.getCompatibleCell({
                ...cell,
                ...cellToMerge,
                selectedValue:
                    this.getValueByText(cellToMerge.text, this.getCellOptions(cell, this.configId)),
            })
        }

        return this.getCompatibleCell({
            ...cell,
            text: cellToMerge.text,
            selectedValue:
                this.getValueByText(
                    cellToMerge.text, this.getCellOptions(cell, this.configId),
                ) as string,
        })
    }

    render(
        cell: Compatible<SelectCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<SelectCell>, commit: boolean) => void,
    ): React.ReactNode {
        if (!isInEditMode) {
            return (
                <SelectableElement
                    disabled={cell.nonEditable}
                    text={cell.text}
                    id={cell.id}
                    name={cell.name}
                    editMode={false}
                />
            )
        }

        return (
            <SelectCellWrapper
                cell={cell}
                options={this.getCellOptions(cell, this.configId)}
                onChange={({
                    value, label,
                } = {}) => {
                    return onCellChanged(this.getCompatibleCell({
                        ...cell,
                        selectedValue: value,
                        text: label,
                        id: label,
                    }), true)
                }}
            />
        )
    }
}
