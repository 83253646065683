import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import LocationForm from '../LocationForm'

import {
    useCreateLocation,
} from '../hooks'

const LocationCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useDomainObjectContext()

    const onSuccess = useCallback((location) => {
        addTableElement(location)
        selectDomainObject(location.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    const createLocation = useCreateLocation({
        onSuccess,
    })

    return (
        <LocationForm
            action={createLocation}
        />
    )
}

export default LocationCreate
