import React, {
    useCallback,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    useHistory,
} from 'react-router-dom'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import tableConfig from './openBookings.config'
import requests from './openBookings.requests'
import orderManagementRequest from '../services/orderManagement.request'
import {
    OpenBookingOrder,
} from './openBookings.types'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]
const OpenBookings = () => {
    const history = useHistory()

    const onRowClick = useCallback(({
        orderNumber,
    }: OpenBookingOrder): void => {
        history.push(orderManagementRequest.OpenBookings.moveToUrl(orderNumber))
    }, [history])

    return (
        <SkyNetTable
            name={tableConfig.name}
            onRowClick={onRowClick}
            tableControls={tableControls}
            tableConfig={tableConfig}
            customUrl={requests.filterAll().url}
            showSearch
        />
    )
}

export default OpenBookings
