import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import Button from 'app/shared-components/Button'

import useStyles from './Pooling.style'
import directLeaseConfig from './configs/directLease.config'
import internalTransportsConfig from './configs/internalTransports.config'
import containerSelectionConfig from './configs/containerSelection.config'
import airlineLeaseConfig from './configs/airlineLease.config'
import pickupAddInfoConfig from './configs/pickupAddInfo.config'
import pickupAddPickUpConfig from './configs/pickupAddPickUp.config'
import pickupDeliveryConfirmationConfig from './configs/pickupDeliveryConfirmation.config'
import transportDepartureConfig from './configs/transportDeparture.config'
import transportArrivalConfig from './configs/transportArrival.config'
import deliveryAddInfoConfig from './configs/deliveryAddInfo.config'
import deliveryAddPickupConfig from './configs/deliveryAddPickup.config'
import deliveryConfirmationConfig from './configs/deliveryConfirmation.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Pooling = () => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()

    const openCreateForm = useCallback(({
        collectionDropoffPointId,
        selectedPreviousOrderId,
    }): void => {
        history.push({
            pathname: orderManagementRequest.Orders.internalCreateFormUrl.url,
            state: {
                collectionDropoffPointId,
                selectedPreviousOrderId,
            },
        })
    }, [history])

    const onRowClick = useCallback((name: string, {
        orderNumber,
    }: {
        orderNumber: string,
    }): void => {
        history.push(orderManagementRequest.Pooling.moveToUrl[name](orderNumber))
    }, [history])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>Container Selection</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingContainerSelection}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('containerSelection', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingContainerSelection}
                    tableConfig={containerSelectionConfig}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>Customer Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('customerTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingCustomerTransport}
                    tableConfig={directLeaseConfig(openCreateForm)}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('customerTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingCustomerTransport}
                    tableConfig={airlineLeaseConfig(openCreateForm)}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>
                Internal Transports
                <Button
                    label="Create Internal Order"
                    onClick={openCreateForm}
                    data-testid="openCreateFormBtn"
                    icon={<AddIcon />}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddInfo}
                    tableConfig={pickupAddInfoConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddPickup}
                    tableConfig={pickupAddPickUpConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.pickupDeliveryConfirmation}
                    tableConfig={pickupDeliveryConfirmationConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportDeparture}
                    tableConfig={transportDepartureConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportArrival}
                    tableConfig={transportArrivalConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddInfo}
                    tableConfig={deliveryAddInfoConfig}
                    noDetails
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddPickup}
                    tableConfig={deliveryAddPickupConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryConfirmation}
                    tableConfig={deliveryConfirmationConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>
                Open Transports
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => { onRowClick('internalTransports', args) }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingInternalTransports}
                    tableConfig={internalTransportsConfig}
                    noDetails
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
        </div>
    )
}

export default Pooling
