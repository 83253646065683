import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    OrderTemperatureRange,
} from 'app/types/enums'
import {
    FieldInCard, FormPart,
} from 'app/types/form.types'

import {
    ShipmentContainerFormType,
} from '../../../createInternalTransportForm.types'

type ShipmentContainerFormFields = keyof ShipmentContainerFormType

type Args = {
    location: {id: number},
    temperatureRange: OrderTemperatureRange,
    selectPreviousOrders: boolean,
    getAllowedValues: (enumType: any) => Record<string, string>,
    disabled: boolean,
}

const freightTypeContainer = ({
    location,
    temperatureRange,
    selectPreviousOrders,
    getAllowedValues,
    disabled,
}: Args): FormPart<ShipmentContainerFormFields>[] => {
    const previousOrders: FieldInCard<ShipmentContainerFormFields>[] = [{
        title: 'Previous Orders',
        id: 'previousOrders',
        className: 'previousOrders',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'previousOrders',
            componentName: 'PreviousOrdersITCreationForm',
            location: location?.id,
        }],
    }]

    return [
        {
            title: 'Order Information',
            className: 'orderInformation',
            id: 'orderInformation',
            fields: [
                {
                    id: 'column1',
                    fields: [{
                        name: 'orderComment',
                        componentName: 'LongText',
                        multiline: true,
                        labelKey: 'Order.orderComment',
                    }],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'location',
                            componentName: 'LocationObjectSelector',
                            isShippingLocation: true,
                            required: true,
                            labelKey: 'OrderStep.pickupLocation',
                        },
                        {
                            name: 'selectPreviousOrders',
                            componentName: 'Checkbox',
                            title: 'Select Previous Orders?',
                            disabled: !location?.id || disabled,
                            labelKey: 'Order.selectPreviousOrders',
                        },
                        {
                            name: 'incoTerm',
                            componentName: 'Select',
                            allowedValues: getAllowedValues(IncoTerm),
                            required: true,
                            labelKey: 'OrderByNumberView.incoTerm',
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            useDropdown: true,
                            allowedValues: getAllowedValues(OrderTemperatureRange),
                            labelKey: 'Order.temperatureRange',
                        },
                        {
                            name: 'requiredCargoComment',
                            componentName: 'LongText',
                            multiline: true,
                            labelKey: 'Order.requiredCargoComment',
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [{
                        name: 'requiredContainersInternal',
                        componentName: 'RequiredContainersByContainerType',
                        temperatureRange,
                        required: true,
                    }],
                },
            ],
        },
        ...location?.id && selectPreviousOrders ? previousOrders : [],
    ]
}

export default freightTypeContainer
