import {
    LeaseType,
} from 'app/types/enums'
import {
    DeliveryServiceType, TransportModeRoad, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'
import {
    Country,
} from 'app/Apps/Masterdata/Countries/Countries.types'
import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'
import {
    OpportunityType,
} from '../Opportunities/opportunities.types'

export enum LaneStatus {
    TO_BE_QUOTED = 'TO_BE_QUOTED',
    UNDER_REVIEW_PRICING = 'UNDER_REVIEW_PRICING',
    IN_PROGRESS = 'IN_PROGRESS',
    REJECTED_PRICING = 'REJECTED_PRICING',
    PRICED = 'PRICED',
    AWARDED = 'AWARDED',
    REJECTED_CUSTOMER = 'REJECTED_CUSTOMER',
    CANCELLED = 'CANCELLED',
    IMPLEMENTED = 'IMPLEMENTED',
    INACTIVE = 'INACTIVE'
}

export type AccountLanesTable = {
    id: number,
    changedOn: string,
    laneNumber: string,
    accountCompanyName: string,
    originAirport: string,
    destinationAirport: string,
    opportunity: string,
    customerExecution: string,
    customerExecutionProvisional: string,
    changedBy: string,
    handoverPointLocationName: string,
    handoverPoint: string,
    collectionDropoffPointLocationName: string,
    collectionDropoff: string,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    temperatureRange: string,
    laneStatus: LaneStatus,
    handoverPointZipCode: string,
    handoverPointCountryName: string,
    collectionDropoffPointZipCode: string,
    collectionDropoffPointCountryName: string,
}

export type AccountLane = AccountLanesTable & {
    opportunity: OpportunityType,
    leaseType: LeaseType,
    laneComment: string,
    originAirport: {
        id: number
    },
    destinationAirport: {
        id: number,
    },
    account: {
        id: number
    },
    contractBasisAccount: {
        id: number
    },
    deliveryServiceType: DeliveryServiceType,
    transportModeRoad: TransportModeRoad,
    isLtlPositioningTemperatureControlled: boolean,
    isFtlPositioningTemperatureControlled: boolean,
    handoverPointContacts: Contact[],
    handoverPointZipCode: string,
    handoverPointAddress: string,
    handoverPointCountry: Country,
    collectionServiceType: CollServiceType,
    collectionDropoffPointContacts: Contact[],
    collectionDropoffPointZipCode: string,
    collectionDropoffPointAddress: string,
    collectionDropoffPointCountry: Country,
    customerExecutionContacts: Contact[],
    customerExecutionProvisional: string,
    consigneeContacts: Contact[],
    consigneeProvisional: string,
    temperatureRange?: string,
    handoverPoint?: Location,
    collectionDropoffPoint?: Location,
    laneStatus: LaneStatus,
    consignee?: Location,
    billingCompany?: Company,
    customerSite?: Location,
    customerExecutionContactsQuote?: Contact[],
    laneGroupId?: boolean,
    laneGroupTag?: string,
    archived?: boolean,
}

export type AccountLaneForm = keyof(AccountLane & {
    linkToCreateAddress: string,
})
