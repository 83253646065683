import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
    LaneStatus,
} from 'app/types/enums'
import {
    CollectionServiceType, DeliveryServiceType,
} from '../../OrderManagement/Orders/orders.types'
import {
    LaneGroup,
} from '../Pricing.types'

type LaneGroupFields = LaneGroup & {
    customerQuotationLocationName: string,
    accountCompanyCompanyName: string,
}

const config: SkyNetTableConfig<keyof LaneGroupFields> = {
    name: 'LaneGroup',
    tabs: [
        {
            name: 'All',
            url: 'all',
        },
        {
            name: 'Lanes In Progress',
            requestUrl: 'all',
            url: 'lane-in-progress',
            filters: {
                atLeastOneLaneStatus: [LaneStatus.IN_PROGRESS],
            },
        },
        {
            name: 'Lanes Priced',
            requestUrl: 'all',
            url: 'lane-priced',
            filters: {
                atLeastOneLaneStatus: [LaneStatus.PRICED],
            },
        },
        {
            name: 'Lanes Awarded',
            requestUrl: 'all',
            url: 'lane-awarded',
            filters: {
                atLeastOneLaneStatus: [LaneStatus.AWARDED],
            },
        },
    ],
    fields: [
        {
            name: 'laneGroupNumber',
            labelKey: 'LaneGroupView.laneGroupNumber',
            reduced: true,
        },
        {
            name: 'customerQuotationLocationName',
            labelKey: 'LaneGroupView.customerQuotationLocationName',
            filterField: 'customerQuotationLocationName',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'accountCompanyCompanyName',
            labelKey: 'AccountQuotes.accountName',
            filterField: 'accountCompanyCompanyName',
            filterType: FilterType.Array,
            mapCellProps: ({
                hasNDA,
                accountCompanyCompanyName,
            }) => {
                return {
                    children: `${hasNDA ? 'NDA' : accountCompanyCompanyName}`,
                }
            },
            reduced: true,
        },
        {
            name: 'contractBasisCurrency',
            labelKey: 'LaneOpsInformationDto.currency',
            filterField: 'contractBasisCurrency',
            filterType: FilterType.Array,
        },
        {
            name: 'deliveryServiceType',
            labelKey: 'LaneGroupView.deliveryService',
            filterField: 'deliveryServiceType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
        },
        {
            name: 'collectionServiceType',
            labelKey: 'LaneGroupView.collService',
            filterField: 'collectionServiceType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: CollectionServiceType,
        },
        {
            name: 'leaseType',
            labelKey: 'LaneGroupView.leaseType',
            filterField: 'leaseType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: LeaseType,
        },
        {
            name: 'handoverPointLocationName',
            labelKey: 'LaneGroup.handoverPoint',
            filterField: 'handoverPointLocationName',
            filterType: FilterType.Array,
            isHidden: true,
        },
        {
            name: 'collectionDropoffPointLocationName',
            labelKey: 'LaneGroupView.collectionDropoffPointLocationName',
            filterField: 'collectionDropoffPointLocationName',
            filterType: FilterType.Array,
            isHidden: true,
        },
        {
            name: 'originAirportsIataCode',
            labelKey: 'LaneGroupView.from',
            filterField: 'originAirportsIataCode',
            filterType: FilterType.Array,
            componentName: 'List',
        },
        {
            name: 'destinationAirportsIataCode',
            labelKey: 'LaneGroupView.to',
            filterField: 'destinationAirportsIataCode',
            filterType: FilterType.Array,
            componentName: 'List',
        },
        {
            name: 'tempRange',
            labelKey: 'LaneGroupView.tempRange',
            filterField: 'tempRange',
            filterType: FilterType.Array,
        },
        {
            name: 'validPricingFrom',
            labelKey: 'LaneGroupView.validPricingFrom',
            filterField: 'validPricingFrom',
            filterType: FilterType.DateRange,
        },
        {
            name: 'validPricingTo',
            filterField: 'validPricingTo',
            labelKey: 'LaneGroupView.validPricingTo',
            filterType: FilterType.DateRange,
            componentName: 'LaneDateEnriched',
        },
    ],
}

export default config
