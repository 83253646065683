import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    newQuotes,
} from '../Dashboard.types'

const newQuotesConfig: SkyNetTableConfig<keyof newQuotes> = {
    name: 'newQuotes',
    tabs: [{
        name: 'New Quotes',
        url: 'all',
    }],
    fields: [
        {
            name: 'quoteNumber',
            labelKey: 'AccountQuotes.quoteNumber',
        },
        {
            name: 'quotedForCompany',
            labelKey: 'AccountQuotes.quotedForCompany',
        },
        {
            name: 'accountName',
            labelKey: 'AccountLane.accountCompanyName',
            filterField: 'accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'leaseType',
            labelKey: 'AccountLane.leaseType',
            filterField: 'leaseType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: LeaseType,
        },
        {
            name: 'createdBy',
            labelKey: 'KamDashboard.requestor',
            filterField: 'createdBy',
            filterType: FilterType.Array,
        },
        {
            name: 'lanesToBePriced',
            labelKey: 'PriceDashboard.lanesToBePriced',
            sorting: false,
        },
        {
            name: 'createdOn',
            labelKey: 'PriceDashboard.submittedOn',
        },
    ],
}

export default newQuotesConfig
