import {
    useQueryClient,
} from 'react-query'
import {
    useCallback,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../Lanes/lane.request'
import {
    Lane,
} from '../Lanes/lanes.types'

const useGetLaneById = (id: number, onSuccess?: (any) => void) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries([RequestKeys.getLaneOpsInformationById])
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Lane>({
        key: RequestKeys.getLaneOpsInformationById,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requestConfig.LaneById.requestFn),
        enabled: Boolean(id),
        keepPreviousData: true,
        onSuccess,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetLaneById
