import {
    useMemo,
} from 'react'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    DTOCardFormField,
} from 'app/types/form.types'
import useValidation from 'app/hooks/useValidation'
import getFields from './QuoteInformationForm.fields'
import {
    AccountQuote, QuoteStatus,
} from '../AccountQuotes.types'

const defaultProps = {
    customButtons: [],
    omitField: [],
    disabled: false,
    requestParam: undefined,
    companyName: undefined,
}

const QuoteInformationForm = ({
    value,
    setValue,
    render,
    omitField,
    disabled,
}: {
    value: AccountQuote,
    setValue: React.Dispatch<React.SetStateAction<AccountQuote>>,
    omitField?: string[],
    disabled?: boolean,
    render?: ({
        fields,
        onChange,
        formData,
        isValid,
    }: {
        fields: DTOCardFormField<any>[],
        onChange: (...args: any[]) => void,
        formData: AccountQuote,
        isValid: boolean
    }) => JSX.Element
}) => {
    const getAllowedValues = useEnumValues()

    const fields = useMemo(() => {
        const result = getFields({
            getAllowedValues,
            exists: Boolean(value?.id),
            disabled,
            isStatusCreated: value.quoteStatus === QuoteStatus.CREATED,
        })

        return omitField.length > 0 ? [{
            ...result[0],
            fields: result?.[0].fields.reduce((acc, config) => {
                return [
                    ...acc,
                    {
                        ...config,
                        fields: config?.fields.filter(({
                            name,
                        }) => { return !omitField.includes(name) }),
                    },
                ]
            }, []),
        }] : result
    }, [
        getAllowedValues,
        value,
        omitField,
        disabled,
    ])

    const {
        isValid,
    } = useValidation({
        fields, values: value,
    })

    return render({
        fields,
        onChange: setValue,
        formData: value,
        isValid,
    })
}

QuoteInformationForm.defaultProps = defaultProps

export default QuoteInformationForm
