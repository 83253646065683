import documentServiceApi from 'app/services/documentServiceApi'

// eslint-disable-next-line import/prefer-default-export
export const printLanePricingQuotes = ({
    laneDepPricingNumbers,
    fileFormat,
    token,
}) => {
    return documentServiceApi({
        method: 'POST',
        responseType: 'arraybuffer',
        url: 'DC-000001',
        data: {
            laneDepPricingNumbers,
            fileFormat,
        },
        token,
    })
}
