import {
    RequestConfig,
} from 'app/types/request.types'
import {
    ForecastType,
} from './Forecast.types'

type ForecastRequests = {
    get: ({
        id: number,
    }) => RequestConfig,
    getForecastOngoingOpportunities: () => RequestConfig,
    getAll: () => RequestConfig,
    bulkUpdate: ({
        data,
    }: {
        data: ForecastType[]
    }) => RequestConfig,
    forecastUpdate: ({
        id,
    }: {id: number}) => RequestConfig
}

const forecastRequests: ForecastRequests = {
    get: ({
        id,
    }) => {
        return {
            url: `opportunity/forecast/${id}`,
            method: 'GET',
        }
    },
    getForecastOngoingOpportunities: () => {
        return {
            url: 'opportunity/forecast/filter-request/ongoing-opportunities',
            method: 'POST',
        }
    },
    getAll: () => {
        return {
            url: 'opportunity/forecast/filter-request/submission',
            method: 'POST',
        }
    },
    bulkUpdate: ({
        data,
    }) => {
        return {
            url: 'opportunity/forecast/submission',
            method: 'POST',
            data,
        }
    },
    forecastUpdate: ({
        id,
    }) => {
        return {
            url: `opportunity/forecast/${id}`,
            method: 'PATCH',
        }
    },
}

export default forecastRequests
