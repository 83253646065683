import {
    getUniqFieldId,
} from 'app/Apps/DomainObject'
import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const pickupViaRoadConfig: SkyNetTableConfig<DailyOpsFields> = {
    tabs: [{
        name: 'Pick-up Via Road: Confirmation',
        url: 'all',
    }],
    fields: [
        {
            name: 'orderNumber',
            filterField: 'orderNumber',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'customerLocationName',
            filterField: 'customerLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'accountCompanyCompanyName',
            filterField: 'accountCompanyCompanyName',
            labelKey: 'AccountQuotes.accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'mawbNumber',
            filterField: 'mawbNumber',
            filterType: FilterType.Array,
        },
        {
            name: 'requestedHandover',
            filterField: 'requestedHandover',
            filterType: FilterType.DateRange,
        },
        {
            name: 'requestedHandover',
            id: getUniqFieldId('requestedHandover', 1),
            componentName: 'ContainerSelectionUntil',
            headerProps: {
                children: 'Requested Handover In',
            },
            sorting: false,
        },
    ],
}

export default pickupViaRoadConfig
