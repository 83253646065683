import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import useStyles from './QuickAddressCreationIcon.styles'

const QuickAddressCreationIcon = () => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.container}>
            <AddCircleIcon className={classes.icon} />
            <span>Quick Address Creation</span>
            <OpenInNewIcon className={classes.icon} />
        </div>
    )
}

export default QuickAddressCreationIcon
