import ContactSelector, {
    ContactObjectSelector, ContactSelectorConfig,
} from 'app/shared-components/ContactSelector'
import Checkbox from 'app/shared-components/Checkbox'
import InputSingleline, {
    InputSingleLineConfig,
} from 'app/shared-components/InputSingleline'
import DateTimeZonedSelect, {
    DateTimeZonedSelectConfig,
} from 'app/shared-components/DateTimeZonedSelect'
import CompanySelector, {
    CompanyObjectSelector,
} from 'app/shared-components/CompanySelector'
import CompanyDataSelector from 'app/shared-components/CompanySelector/CompanyDataSelector'
import IntegerInput, {
    IntegerInputConfig,
} from 'app/shared-components/IntegerInput'
import LongText, {
    LongTextConfig,
} from 'app/shared-components/InputMultiline'
import {
    LocationSelectorConfig,
    LocationSelectorComponents,
} from 'app/shared-components/LocationSelector'
import DateTimeZonedFromLocation, {
    DateTimeZonedFromLocationConfig,
} from 'app/Apps/OrderManagement/Orders/Components/OrderStepsForm/DateTimeZonedFromLocation'
import MultipleContactSelector, {
    MultipleContactObjectSelector,
    MultipleContactByLocationObjectSelector,
    MultipleContactSelectorConfig,
} from 'app/shared-components/MultipleContactSelector'
import LinkTo, {
    LinkToConfig,
} from 'app/shared-components/LinkTo'
import LinkToClick, {
    LinkToClickConfig,
} from 'app/shared-components/LinkToClick'
import Attachment, {
    AttachmentsObjectSelector,
} from 'app/shared-components/Attachment'
import AllowedContainerTypes from 'app/Apps/OrderManagement/Orders/CustomerTransport/CustomerTransportsOverview/AllowedContainerTypes'
import RequiredContainersByProductType, {
    RequiredContainersByProductTypeConfig,
} from 'app/shared-components/RequiredContainersByProductType'
import RequiredContainersByContainerType, {
    RequiredContainersByContainerTypeConfig,
} from 'app/shared-components/RequiredContainersByContainerType'
import GeneralFreight from 'app/shared-components/GeneralFreight'
import OrderStatus from 'app/Apps/OrderManagement/Orders/Components/OrderStatus'
import OrderStatusHistory, {
    OrderStatusHistoryConfig,
} from 'app/Apps/OrderManagement/Orders/Components/OrderStatusHistory'
import SelectedAssets from 'app/Apps/OrderManagement/Orders/Components/SelectedAssets'
import BookedContainers from 'app/Apps/OrderManagement/Orders/CustomerTransport/CustomerTransportsOverview/BookedContainers'
import SelectContainerTypeByCode from 'app/Apps/Containers/components/SelectContainerTypeByCode'
import AvailableLanes from 'app/Apps/OrderManagement/Orders/CustomerTransport/NewCustomerTransport/AvailableLanes'
import AirlineLeaseAvailableLane, {
    AirlineLeaseAvailableLaneConfig,
} from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateCustomerTransportForm/Steps/SelectLaneStep/AvailableLanes'
import SendTime, {
    SendTimeConfig,
} from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/OrderNotificationForm/SendTime'
import SentFirstTimeOn, {
    SentFirstTimeOnConfig,
} from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/OrderNotificationForm/SentFirstTimeOn'
import DefaultAttachments from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/OrderNotificationForm/DefaultAttachments'
import RecipientSelector from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/OrderNotificationForm/RecipientSelector'
import MultipleBusinessDevelopersSelect from 'app/shared-components/MultipleBusinessDevelopersSelect'
import DateTimeSelect from 'app/shared-components/DateTimeSelect'
import TruckCapacity, {
    TruckCapacityConfig,
} from 'app/shared-components/TruckingCapacity'
import CustomerExecutionSelector from 'app/Apps/Pricing/ContractBasis/components/PrintFiltersForm/CustomerExecutionSelector'
import {
    CustomerExecutionSelectorConfig,
} from 'app/Apps/Pricing/ContractBasis/components/PrintFiltersForm/CustomerExecutionSelector/CustomerExecutionSelector.types'
import TemperatureAge from 'app/Apps/DomainObject/ComponentMapper/TemperatureAge'
import StatusAge from 'app/Apps/DomainObject/ComponentMapper/StatusAge'
import ConnectedLoggers from 'app/Apps/Sensors/Gateways/ConnectedLoggers'
import GatewayContacts from 'app/Apps/Sensors/Gateways/GatewayContacts'
import LoggerTypeSelector from 'app/shared-components/LoggerTypeSelector'
import SharePositioningSelect, {
    SharePositioningSelectConfig,
} from 'app/shared-components/SharePositioningSelect'
import LocationChangeSelector, {
    LocationChangeSelectorConfig,
} from 'app/shared-components/LocationChangeSelector'
import LoggerSelector from 'app/shared-components/LoggerSelector'
import StatusSelector from 'app/shared-components/StatusSelector'
import ContainerSelector from 'app/shared-components/ContainerSelector'
import FileDownloadLink from 'app/shared-components/FileDownloadLink'
import {
    FxRateObjectWithSelectConfig, FxRateObjectWithSelect,
} from 'app/shared-components/FxRateWithSelect'
import Fees from 'app/shared-components/Fees'
import {
    FeesConfig,
} from 'app/shared-components/Fees/Fee.types'
import StatusTransitionSelector, {
    StatusTransitionSelectorConfig,
} from 'app/shared-components/StatusTransitionSelector'
import DateSelect from 'app/shared-components/DateSelect'
import AirportLaneGroupSelector, {
    AirportLaneGroupSelectorConfig,
} from 'app/Apps/Pricing/ContractBasis/components/PrintFiltersForm/AirportLaneGroupSelector'
import PreviousOrdersITCreationForm, {
    PreviousOrdersITCreationFormConfig,
} from 'app/Apps/OrderManagement/Orders/InternalTransport/CreateInternalTransportForm/PreviousOrdersITCreationForm'
import {
    BillingCompanyObjectSelector,
    BillingCompanyExpandedViewSelector,
} from 'app/shared-components/BillingCompanySelector'
import InputRule, {
    InputRuleConfig,
} from 'app/shared-components/InputRule'
import Switch from 'app/shared-components/Switch'
import BookedFreight from 'app/Apps/OrderManagement/Orders/InternalTransport/InternalTransportsOverview/BookedFreight'
import BillingInformationContainers from 'app/Apps/OrderManagement/Orders/CustomerTransport/BillingInformation/Containers'
import PreviousOrders, {
    PreviousOrdersConfig,
} from 'app/Apps/OrderManagement/Orders/InternalTransport/InternalTransportsOverview/PreviousOrders'
import ContactNameComment from 'app/Apps/ContactManagement/Contacts/ContactNameComment'
import ChipSelect from 'app/shared-components/ChipSelect'
import GoogleAddress, {
    GoogleAddressConfig,
} from 'app/shared-components/GoogleAddress'
import {
    CountryDataSelector,
} from 'app/shared-components/CountrySelector'
import CitySuggestions, {
    CitySuggestionsConfig,
} from 'app/Apps/ContactManagement/utils/CitySuggestions'
import TaxCodesSelector, {
    TaxCodesSelectorConfig,
} from 'app/shared-components/TaxCodesSelector'
import ContactsLinked from 'app/Apps/ContactManagement/ContactsLinked/Contacts'
import StateSelector, {
    StateSelectorConfig,
} from 'app/shared-components/StateSelector'
import BolAwbInstructions from 'app/Apps/ContactManagement/Locations/LocationForm/BolAwbInstructions/BolAwbInstructions'
import ContactsContainer from 'app/Apps/ContactManagement/ContactsLinked/ContactsContainer'
import SelectedContainersUpdateLocation from 'app/Apps/OrderManagement/Orders/Components/OrderSteps/SavedOrderStepsForm/UpdateLocationDialog/SelectedContainers'
import {
    TypeaheadObjectContainer,
    TypeaheadObjectContainerConfig,
} from 'app/shared-components/Typeahead'
import TableFees, {
    TableFeesConfig,
} from 'app/Apps/Pricing/TableFees'
import AccountSelector, {
    AccountSelectorConfig,
} from 'app/shared-components/AccountSelector'
import SkyNetSpreadSheet, {
    SkyNetSpreadSheetConfig,
} from 'app/shared-components/SkyNetSpreadSheet'
import AreaSelector, {
    AreaSelectorConfig,
} from 'app/shared-components/AreaSelector'
import CurrencyHeadingSelector, {
    CurrencyHeadingSelectorConfig,
} from 'app/shared-components/CurrencyHeadingSelector'
import BooleanReader from 'app/shared-components/BooleanReader'
import OpportunitiesSelector from 'app/shared-components/OpportunitiesSelector'
import ContractBasisSelector from 'app/shared-components/ContractBasisSelector'
import RegionalGroupSelector from 'app/shared-components/RegionalGroupSelector'
import RouteSelection from 'app/Apps/TransportPlanner/Simulation/RouteSelection'
import AnalyseTemperature from 'app/Apps/TransportPlanner/Simulation/AnalyseTemperature'
import RouteSteps from 'app/Apps/TransportPlanner/Simulation/RouteSteps'
import ContainerTypeSelection from 'app/Apps/TransportPlanner/Simulation/ContainerTypeSelection'
import LaneSelector from 'app/shared-components/LaneSelector'
import UserSelector, {
    UserObjectSelector,
} from 'app/shared-components/UserSelector'
import MultipleUserSelector from 'app/shared-components/MultipleUserSelector'
import MultipleAccountSelector from 'app/shared-components/MultipleAccountSelector'
import CustomerExecutionQuotationSelector from 'app/shared-components/CustomerExecutionSelector'
import RestrictedPermissionInputSingleline, {
    RestrictedPermissionInputSinglelineConfig,
} from 'app/shared-components/RestrictedPermissionInputSingleline'
import ZipCodeInput, {
    ZipCodeInputConfig,
} from 'app/shared-components/ZipCodeInput'
import SingleForecastSpreadsheet from 'app/Apps/Forecast/ShortTermForecasts/SingleForecastSpreadsheet'
import SingleForecastSpreadsheetConfig
    from 'app/Apps/Forecast/ShortTermForecasts/SingleForecastSpreadsheet/SingleForecastSpreadsheet.types'

import {
    EnumComponents, EnumComponentConfig,
} from './EnumComponents.config.types'

const componentsDefaultProps = {
    MultipleBusinessDevelopersSelect,
    SelectContainerTypeByCode,
    AttachmentsObjectSelector,
    FileDownloadLink,
    AllowedContainerTypes,
    CompanyObjectSelector,
    CompanyDataSelector,
    AccountSelector,
    CustomerExecutionQuotationSelector,
    DefaultAttachments,
    CompanySelector,
    DateTimeSelect,
    GeneralFreight,
    SelectedAssets,
    BookedContainers,
    AvailableLanes,
    RecipientSelector,
    OrderStatus,
    Attachment,
    Checkbox,
    MultipleContactObjectSelector,
    MultipleContactSelector,
    TemperatureAge,
    StatusAge,
    GatewayContacts,
    ConnectedLoggers,
    LoggerTypeSelector,
    LoggerSelector,
    StatusSelector,
    ContainerSelector,
    DateSelect,
    BillingCompanyObjectSelector,
    BillingCompanyExpandedViewSelector,
    BookedFreight,
    Switch,
    ContactNameComment,
    ChipSelect,
    CountryDataSelector,
    ContactsLinked,
    BolAwbInstructions,
    ContactsContainer,
    SelectedContainersUpdateLocation,
    BillingInformationContainers,
    BooleanReader,
    OpportunitiesSelector,
    ContractBasisSelector,
    RegionalGroupSelector,
    RouteSelection,
    AnalyseTemperature,
    RouteSteps,
    ContainerTypeSelection,
    LaneSelector,
    UserSelector,
    UserObjectSelector,
    MultipleUserSelector,
    MultipleAccountSelector,
    SingleForecastSpreadsheet,
}

type componentsDefaultPropsConfig = {
    componentName?: keyof typeof componentsDefaultProps,
}

export const ComponentsFormCollection = {
    ...componentsDefaultProps,
    MultipleContactByLocationObjectSelector,
    ...LocationSelectorComponents,
    ...EnumComponents,
    AccountSelector,
    DateTimeZonedFromLocation,
    CustomerExecutionSelector,
    InputSingleline,
    LinkTo,
    LinkToClick,
    SendTime,
    SentFirstTimeOn,
    OrderStatusHistory,
    SharePositioningSelect,
    RequiredContainersByProductType,
    RequiredContainersByContainerType,
    DateTimeZonedSelect,
    TruckCapacity,
    LocationChangeSelector,
    FxRateObjectWithSelect,
    Fees,
    StatusTransitionSelector,
    AirportLaneGroupSelector,
    PreviousOrdersITCreationForm,
    AirlineLeaseAvailableLane,
    PreviousOrders,
    InputRule,
    GoogleAddress,
    CitySuggestions,
    TaxCodesSelector,
    StateSelector,
    TypeaheadObjectContainer,
    LongText,
    SkyNetSpreadSheet,
    AreaSelector,
    IntegerInput,
    CurrencyHeadingSelector,
    TableFees,
    ContactSelector,
    ContactObjectSelector,
    RestrictedPermissionInputSingleline,
    ZipCodeInput,
}

export type ComponentsFormCollectionNames = keyof typeof ComponentsFormCollection

export type ComponentsFormCollectionProps
    = componentsDefaultPropsConfig
    | DateTimeZonedSelectConfig
    | InputSingleLineConfig
    | RequiredContainersByContainerTypeConfig
    | RequiredContainersByProductTypeConfig
    | MultipleContactSelectorConfig
    | LocationSelectorConfig
    | DateTimeZonedFromLocationConfig
    | OrderStatusHistoryConfig
    | SharePositioningSelectConfig
    | EnumComponentConfig
    | SendTimeConfig
    | TruckCapacityConfig
    | LinkToConfig
    | LinkToClickConfig
    | SentFirstTimeOnConfig
    | CustomerExecutionSelectorConfig
    | LocationChangeSelectorConfig
    | FxRateObjectWithSelectConfig
    | FeesConfig
    | StatusTransitionSelectorConfig
    | AirportLaneGroupSelectorConfig
    | PreviousOrdersITCreationFormConfig
    | PreviousOrdersConfig
    | InputRuleConfig
    | GoogleAddressConfig
    | CitySuggestionsConfig
    | TaxCodesSelectorConfig
    | StateSelectorConfig
    | TypeaheadObjectContainerConfig
    | AirlineLeaseAvailableLaneConfig
    | LongTextConfig
    | SkyNetSpreadSheetConfig<any>
    | IntegerInputConfig
    | CurrencyHeadingSelectorConfig
    | AreaSelectorConfig
    | TableFeesConfig
    | ContactSelectorConfig
    | AccountSelectorConfig
    | RestrictedPermissionInputSinglelineConfig
    | SingleForecastSpreadsheetConfig
    | ZipCodeInputConfig
