import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import AdditionalFeesRequests from 'app/Apps/Pricing/ContractBasis/AdditionalFees/AdditionalFees.requests'

import {
    AdditionalFeeValue,
} from './AdditionalFee.types'

import AdditionalFeeDetailPanel from './AdditionalFeeDetailPanel'

type Props = {
    rowProps: AdditionalFeeValue,
    exists?: boolean,
    onSuccess: (refresh: Record<string, any>) => any,
    onDetailPanelCreateSuccess: noop,
    closeForm?: (refresh: boolean) => void,
    redirectToCopyForm: (id: number) => void,
    isCopy?: boolean,
}

const defaultProps: Partial<Props> = {
    exists: false,
    closeForm: noop,
    isCopy: false,
}

const AdditionalFeeDetailPanelContainer = ({
    rowProps: value,
    exists,
    onSuccess,
    onDetailPanelCreateSuccess,
    closeForm,
    redirectToCopyForm,
    isCopy,
}: Props) => {
    const {
        id,
    } = value

    const {
        data: laneDetailData,
    } = useRequest<AdditionalFeeValue>({
        key: RequestKeys.getContractBasisAdditionalFeeDetails,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(AdditionalFeesRequests.get),
        enabled: Boolean(id),
    })

    const valueForControls = useMemo(() => {
        if (!exists) {
            return value
        }
        return {
            ...laneDetailData,
            ...value,
        }
    }, [
        laneDetailData,
        value,
        exists,
    ])

    if (exists && !laneDetailData) {
        return null
    }
    return (
        <AdditionalFeeDetailPanel
            onDetailPanelCreateSuccess={onDetailPanelCreateSuccess}
            onSuccess={onSuccess}
            closeForm={closeForm}
            rowProps={valueForControls}
            exists={exists}
            isCopy={isCopy}
            redirectToCopyForm={redirectToCopyForm}
        />
    )
}

AdditionalFeeDetailPanelContainer.defaultProps = defaultProps

export default AdditionalFeeDetailPanelContainer
