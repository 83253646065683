import React from 'react'
import Link from '@mui/material/Link'
import {
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import PrintIcon from '@mui/icons-material/Print'

import usePrintQuote from 'app/hooks/usePrintQuotes'
import parsePrintQuotesSelectedRows from 'app/utils/parsePrintQuotesSelectedRows'

import useStyles from './PrintQuotes.style'

const propTypes = {
    selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
}

const PrintQuotes = (props) => {
    const {
        selectedRows,
        name,
    } = props

    const {
        classes,
    } = useStyles()

    const printQuotes = usePrintQuote(name, parsePrintQuotesSelectedRows(selectedRows))

    return (
        <Link
            className={classes.link}
            component="button"
            color="textSecondary"
            variant="body1"
            onClick={printQuotes}
        >
            <PrintIcon className={classes.icon} />
            <Typography
                variant="h5"
            >
                Print Quote
            </Typography>
        </Link>
    )
}

PrintQuotes.propTypes = propTypes

export default PrintQuotes
