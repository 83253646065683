import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    ResponsibleDepartment,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    FormPart,
} from 'app/types/form.types'

import {
    ShipmentFreightFormType,
} from '../../../createInternalTransportForm.types'

const freightTypeGeneral = (
    location: {id: number},
    getAllowedValues: (enumType: any) => Record<string, string>,
): FormPart<keyof ShipmentFreightFormType>[] => {
    return [
        {
            title: 'Order Information',
            className: 'orderInformation',
            id: 'orderInformation',
            fields: [
                {
                    id: 'column1',
                    fields: [{
                        name: 'orderComment',
                        componentName: 'LongText',
                        multiline: true,
                        labelKey: 'Order.orderComment',
                    }],

                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'location',
                            componentName: 'LocationObjectSelector',
                            required: true,
                            labelKey: 'OrderStep.pickupLocation',
                        },
                        {
                            name: 'incoTerm',
                            componentName: 'Select',
                            allowedValues: getAllowedValues(IncoTerm),
                            required: true,
                            labelKey: 'OrderByNumberView.incoTerm',
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [{
                        name: 'requiredCargoComment',
                        componentName: 'LongText',
                        multiline: true,
                        labelKey: 'Order.requiredCargoComment',
                    }],

                },
                {
                    id: 'column4',
                    fields: [{
                        name: 'responsibleDepartment',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ResponsibleDepartment),
                        useDropdown: true,
                        required: true,
                        labelKey: 'Order.responsibleDepartment',
                    }],
                },
            ],
        },
        {
            title: 'General Freight',
            className: 'generalFreight',
            id: 'generalFreight',
            fields: [{
                name: 'generalFreight',
                componentName: 'GeneralFreight',
                required: true,
            }],
        },
    ]
}

export default freightTypeGeneral
