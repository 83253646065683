import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LoggerConfiguration,
    LoggerType,
} from 'app/types/enums'
import {
    LoggerFormData,
} from '../LoggerForm/LoggerForm.types'

type LoggerUpdateData = LoggerFormData & {
    loggerNumber: string,
    loggerType: LoggerType,
    isCurrentLogger: boolean
}

export default ({
    getEnumValues,
    removedOnDisabled,
    loggerNumber,
}: {
    getEnumValues: (value: Record<string, string>) => Record<string, string>,
    removedOnDisabled: boolean,
    loggerNumber: string,
}): DTOCardFormField<keyof LoggerUpdateData>[] => {
    return [
        {
            title: 'Logger Information',
            className: 'loggerInformation',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'loggerNumber',
                            componentName: 'LinkTo',
                            labelKey: 'LoggerInformationDto.logger',
                            link: `/apps/loggers/all/edit/${loggerNumber}`,
                            disabled: true,
                        },
                        {
                            name: 'isCurrentLogger',
                            labelKey: 'LoggerContainerInformation.isCurrentLogger',
                            componentName: 'BooleanReader',
                            disabled: true,
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'loggerType',
                            componentName: 'EnumSelector',
                            allowedValues: getEnumValues(LoggerType),
                            labelKey: 'LoggerInformationDto.logger',
                            disabled: true,
                        },
                        {
                            name: 'isDefaultLogger',
                            labelKey: 'LoggerInformationDto.defaultLogger',
                            componentName: 'BooleanReader',
                            disabled: true,
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [{
                        name: 'loggerPositionConfiguration',
                        disabled: true,
                        useDropdown: true,
                        componentName: 'EnumSelector',
                        allowedValues: getEnumValues(LoggerConfiguration),
                        labelKey: 'LoggerInformationDto.loggerPositionConfigurationName',
                    }],
                },
            ],
        },
        {
            title: 'Pairing Information',
            className: 'pairingInformation',
            fields: [
                {
                    name: 'exchangeTimestamp',
                    componentName: 'DateTimeZonedSelect',
                    disabled: true,
                },
                {
                    name: 'removedOn',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'LoggerContainerInformation.removedOn',
                    disabled: removedOnDisabled,
                },
            ],
        },
    ]
}
