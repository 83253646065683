import {
    RequestConfig,
} from 'app/types/request.types'

type LoggerRequests = {
    domainName: string,
    get: ({
        id,
    }) => RequestConfig,
    getByLoggerNumber: ({
        loggerNumber,
    }) => RequestConfig,
    create: () => RequestConfig,
    update: ({
        serialNumber,
    })=> RequestConfig,
}

const loggerRequest: LoggerRequests = {
    domainName: 'Logger',
    get: ({
        id,
    }) => {
        return {
            url: `logger/${id}`,
        }
    },
    getByLoggerNumber: ({
        loggerNumber,
    }) => {
        return {
            url: `logger/loggernumber/${loggerNumber}`,
            method: 'GET',
        }
    },
    create: () => {
        return {
            url: 'logger',
            method: 'POST',
        }
    },
    update: ({
        serialNumber,
    }) => {
        return {
            url: `logger/${serialNumber}/data`,
            method: 'PATCH',
        }
    },
}

export default loggerRequest
