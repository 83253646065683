import {
    DefaultTruckingComment,
    TransportModeRoad,
    OrderStepFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    LocationType, StepTypeDetailed,
} from 'app/types/enums'
import {
    FieldInCard,
    NestedField,
} from 'app/types/form.types'

type Args = {
    forwarderId: number,
    pickupLocationId: number,
    deliveryLocationId: number,
    filterLocationType: LocationType,
    transportModeRoad: TransportModeRoad,
    exists: boolean,
    isFirstStep: boolean,
    stepTypeDetailed: StepTypeDetailed,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
}

const getInternalAirFormFields = ({
    forwarderId,
    pickupLocationId,
    deliveryLocationId,
    filterLocationType,
    transportModeRoad,
    getAllowedValues,
    exists,
    isFirstStep,
    stepTypeDetailed,
}: Args): FieldInCard<OrderStepFields>[] => {
    const trucksAndVans: FieldInCard<OrderStepFields>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'OrderStep.numberOfTrucks',
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'OrderStep.numberOfVans',
        },
    ]

    const pickupLocationContactsByLocationField: NestedField<OrderStepFields>[] = [{
        name: 'pickupLocationContacts',
        componentName: 'MultipleContactByLocationObjectSelector',
        existedForm: exists,
        selectedLocation: pickupLocationId,
        labelKey: 'OrderStep.pickupLocationContacts',
    }]

    const pickupLocationContactsField: NestedField<OrderStepFields>[] = [{
        name: 'pickupLocationContacts',
        componentName: 'MultipleContactObjectSelector',
        labelKey: 'OrderStep.pickupLocationContacts',
    }]

    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'forwarder',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.ADDRESS,
                    isApprovedSubContractor: true,
                    labelKey: 'OrderStep.forwarder',
                },
                {
                    name: 'forwarderContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    labelKey: 'OrderStep.forwarderContacts',
                    existedForm: exists,
                    selectedLocation: forwarderId,
                },
                {
                    name: 'mawbReceived',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.mawbReceived',
                },
                {
                    name: 'mawbNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.mawbNumber',
                },
                {
                    name: 'mawbDocumentCopy',
                    componentName: 'AttachmentsObjectSelector',
                    labelKey: 'OrderStep.mawbDocumentCopy',
                },
                {
                    name: 'hawbReceived',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.hawbReceived',
                },
                {
                    name: 'hawbNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.hawbNumber',
                },
                {
                    name: 'hawbDocumentCopy',
                    componentName: 'AttachmentsObjectSelector',
                    labelKey: 'OrderStep.hawbDocumentCopy',
                },
                {
                    name: 'transportModeRoad',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'OrderStep.transportModeRoad',
                },
                ...transportModeRoad === TransportModeRoad.FTL ? trucksAndVans as [] : [],
                {
                    name: 'isTemperatureControlled',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.isTemperatureControlled',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'pickupLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.pickupLocation',
                },
                ...isFirstStep
                    ? pickupLocationContactsByLocationField
                    : pickupLocationContactsField,
                {
                    name: 'requestedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.requestedPickup',
                },
                {
                    name: 'estimatedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.estimatedPickup',
                },
                {
                    name: 'actualPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    labelKey: 'OrderStep.actualPickup',
                },
                {
                    name: 'pickupComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.pickupComment',
                },
                {
                    name: 'shipmentStart',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.shipmentStart',
                },
                {
                    name: 'defaultTruckingComment',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DefaultTruckingComment),
                    useDropdown: true,
                    labelKey: 'OrderStep.defaultTruckingComment',
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'deliveryLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.deliveryLocation',
                },
                {
                    name: 'deliveryLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: exists,
                    selectedLocation: deliveryLocationId,
                    labelKey: 'OrderStep.deliveryLocationContacts',
                },
                {
                    name: 'requestedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.requestedDelivery',
                },
                {
                    name: 'estimatedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.estimatedDelivery',
                },
                {
                    name: 'actualDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    labelKey: 'OrderStep.actualDelivery',
                },
                {
                    name: 'deliveryComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.deliveryComment',
                },
                {
                    name: 'shipmentEnd',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.shipmentEnd',
                },
                {
                    name: 'truckingComment',
                    componentName: 'LongText',
                    className: 'truckingComment',
                    labelKey: 'OrderStep.truckingComment',
                },
            ],
        },
        {
            id: 'column4',
            fields: [
                {
                    name: 'flightNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderStep.flightNumber',
                },
                {
                    name: 'airline',
                    componentName: 'CompanyObjectSelector',
                    labelKey: 'OrderStep.airline',
                },
                {
                    name: 'originAirport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType,
                    labelKey: 'OrderStep.originAirport',
                },
                {
                    name: 'destinationAirport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType,
                    labelKey: 'OrderStep.destinationAirport',
                },
                ...stepTypeDetailed !== StepTypeDetailed.AIR_INTERNAL
                && stepTypeDetailed !== StepTypeDetailed.AIR_ROAD_INTERNAL ? [{
                    name: 'estimatedFlightDeparture',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.estimatedFlightDeparture',
                }] as NestedField<OrderStepFields>[] : [],
                {
                    name: 'actualFlightDeparture',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.actualFlightDeparture',
                },
                ...stepTypeDetailed !== StepTypeDetailed.AIR_INTERNAL
                && stepTypeDetailed !== StepTypeDetailed.AIR_ROAD_INTERNAL ? [{
                    name: 'estimatedFlightArrival',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.estimatedFlightArrival',
                }] as NestedField<OrderStepFields>[] : [],
                {
                    name: 'actualFlightArrival',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'OrderStep.actualFlightArrival',
                },
                {
                    name: 'flightComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.flightComment',
                },
            ],
        },
    ]
}

export default getInternalAirFormFields
