import {
    useCallback,
} from 'react'
import {
    useDispatch,
} from 'react-redux'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import downloadDocument from 'app/utils/downloadDocument'
import {
    startToSaveForm,
    failedToLoadSaveForm,
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'
import {
    printLanePricingQuotes,
} from './printLanePricingQuotes'

const usePrintQuotes = (name, laneDepPricingNumbers) => {
    const dispatch = useDispatch()
    const token = useJWTToken()

    const printQuotes = useCallback(() => {
        dispatch(
            startToSaveForm({
                id: name,
                title: 'Please stand by while we’re preparing the quotes.',
            }),
        )

        printLanePricingQuotes({
            laneDepPricingNumbers,
            fileFormat: 'PDF',
            token,
        }).then((response) => {
            downloadDocument(response, `Quote ${laneDepPricingNumbers.join(', ')}`)
            dispatch(
                successedToSaveForm({
                    id: name,
                    title: 'The quotes have been successfully downloaded.',
                }),
            )
        }).catch(({
            response: {
                data,
                status: statusCode,
            },
        }) => {
            const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(data)))

            dispatch(
                failedToLoadSaveForm({
                    id: name,
                    errors: text ? JSON.parse(text) : 'There was an error while downloading the quotes',
                    statusCode,
                }),
            )
        })
    }, [
        dispatch,
        name,
        laneDepPricingNumbers,
        token,
    ])

    return printQuotes
}

export default usePrintQuotes
