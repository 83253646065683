import {
    getUniqFieldId,
} from 'app/Apps/DomainObject'
import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    DailyOpsFields,
} from '../dailyOps.types'

const confirmationConfig: SkyNetTableConfig<DailyOpsFields> = {
    tabs: [{
        name: 'Confirmation Of Drop-off: Info',
        url: 'all',
    }],
    fields: [
        {
            name: 'orderNumber',
            filterField: 'orderNumber',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            width: '100px',
        },
        {
            name: 'customerLocationName',
            filterField: 'customerLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'accountCompanyCompanyName',
            filterField: 'accountCompanyCompanyName',
            labelKey: 'AccountQuotes.accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'mawbNumber',
            filterField: 'mawbNumber',
            filterType: FilterType.Array,
        },
        {
            name: 'baseLeaseUntilTimestamp',
            filterField: 'baseLeaseUntilTimestamp',
            filterType: FilterType.DateRange,
        },
        {
            name: 'baseLeaseUntilTimestamp',
            componentName: 'ContainerSelectionUntil',
            id: getUniqFieldId('baseLeaseUntilTimestamp', 1),
            headerProps: {
                children: 'Collection / Drop-off Point',
            },
            sorting: false,
        },
    ],
}

export default confirmationConfig
