import {
    useCallback,
} from 'react'
import {
    CellChange, Row, Id,
} from '@silevis/reactgrid'
import getRows, {
    getCellValue,
} from '../getRows'
import {
    Domain, ExtendedCellTypes, SkyNetSpreadSheetConfigType,
} from '../SkyNetSpreadSheet.types'
import {
    SpreadsheetMappingResponse,
} from '../SkyNetSpreadSheet.requests'

// called every time we made changes to one or several cells
export default ({
    findUnresolvedValues,
    onCellChange,
    data,
    getSpreadsheetMappings,
    applyResultsToValues,
    convertRowsToData,
    config,
    styles,
    disabled,
    locked,
    setData,
}: {
    applyResultsToValues: (
        newValues: CellChange<ExtendedCellTypes>[],
        mappedResult: Map<Domain, SpreadsheetMappingResponse>,
        updatedData: Record<string, any>[]
    ) => Record<string, any>[],
    onCellChange: ({
        newData, cell, newValue, currentRowId,
    }: {
        newData: Record<string, any>[],
        cell: ExtendedCellTypes,
        newValue: any,
        currentRowId: Id
    }) => Promise<Record<string, any>[]>,
    config: SkyNetSpreadSheetConfigType<any>,
    convertRowsToData: (r: Row[]) => Record<string, any>[],
    data: Record<string, any>[],
    disabled: boolean,
    findUnresolvedValues: (
        unresolvedValues: Map<Domain, Set<string>>,
        cell: ExtendedCellTypes
    ) => void,
    getSpreadsheetMappings: (
        unresolvedValues: Map<Domain, Set<string>>
    ) => Promise<Map<Domain, SpreadsheetMappingResponse>>,
    locked: boolean,
    setData: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
    styles: Record<string, any>,
}) => {
    return useCallback(async (newValues: CellChange[]) => {
        let newData = JSON.parse(JSON.stringify(data))
        const unresolvedValues = new Map()

        try {
            await Promise.all(newValues.map(async ({
                columnId,
                newCell,
                rowId: currentRowId,
            }) => {
                findUnresolvedValues(unresolvedValues, newCell)
                const newValue = getCellValue(newCell)

                newData[currentRowId][columnId] = newValue
                newData = await onCellChange({
                    newData, cell: newCell, newValue, currentRowId,
                })
            }))

            if (unresolvedValues.size > 0) {
                const mappedResult = await getSpreadsheetMappings(unresolvedValues)

                newData = applyResultsToValues(newValues, mappedResult, newData)
            }
        } catch (err) {
            // eslint-disable-next-line
            console.error('some error happened', err)
        } finally {
            setData(
                convertRowsToData(
                    getRows(newData, config, styles, disabled || locked),
                ),
            )
        }
    }, [
        data,
        findUnresolvedValues,
        onCellChange,
        getSpreadsheetMappings,
        applyResultsToValues,
        convertRowsToData,
        config,
        styles,
        disabled,
        locked,
        setData,
    ])
}
