import React, {
    useRef,
} from 'react'
import clsx from 'clsx'
import Loading from 'app/shared-components/Loading'
import useScrollOptions from 'app/hooks/useScrollOptions'

import {
    SpreadsheetCellOption,
} from '../SkyNetSpreadSheet.types'
import useStyles from './MultipleSelectDropdown.styles'
import OptionCheckboxDropdown from '../OptionCheckboxDropdown'

const MultipleSelectDropdown = ({
    rowHeight,
    options,
    selected,
    setSelected,
}: {
    rowHeight: number,
    options: SpreadsheetCellOption[],
    selected: string[],
    setSelected: React.Dispatch<string[]>
}) => {
    const {
        classes,
    } = useStyles()
    const ref = useRef(null)

    useScrollOptions(ref)

    return (
        (options || []).length > 0 ? (
            <div
                ref={ref}
                data-testid="typeahead-options-wrapper"
                className={clsx(classes.options)}
                style={{
                    top: rowHeight,
                }}
            >
                {(options || []).map(({
                    value, label,
                }, i) => {
                    const checked = selected.includes(value as string)
                    const setAsSelected = (e) => {
                        e.stopPropagation()
                        if (checked) {
                            return setSelected(selected.filter((el) => {
                                return el !== value as string
                            }))
                        }
                        return setSelected([
                            ...selected,
                            value as string,
                        ])
                    }

                    return (
                        <OptionCheckboxDropdown
                            key={`option-${value}`}
                            onClick={setAsSelected}
                            value={value}
                            label={label}
                            checked={checked}
                            index={i}
                        />
                    )
                })}
            </div>
        ) : (
            <div
                data-testid="loadingWrapper"
                className={classes.loadingWrapper}
                style={{
                    top: rowHeight,
                }}
            >
                <Loading />
            </div>
        )
    )
}

export default MultipleSelectDropdown
