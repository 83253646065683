import {
    useCallback,
} from 'react'

import {
    useMutation,
} from 'react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../../AccountQuotes.requests'

export default ({
    id, onSuccess,
}: {
    id: number,
    onSuccess: (...args: any[]) => void
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessAssigning,
        onError,
    } = useHandleStatusRequest({
        title: 'Quote was successfully assigned to Contract Basis',
        id: 'QuoteAssignedToContractBasis',
        onSuccess,
    })

    const {
        mutate: assignToContractBasis,
    } = useMutation((quoteId: number) => {
        return simpleApi({
            ...requests.assignToContractBasis({
                id: quoteId,
            }),
            token,
        })
    }, {
        onError,
        onSuccess: onSuccessAssigning,
    })

    return useCallback(() => {
        assignToContractBasis(id)
    }, [
        assignToContractBasis,
        id,
    ])
}
