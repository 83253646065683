import {
    useMutation,
} from 'react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../Notifications.request'

const useActivateNotifications = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessActivation,
        onError,
    } = useHandleStatusRequest({
        id: 'ActivateNotifications',
        onSuccess,
    })

    return useMutation(({
        laneId,
        activateNotifications,
    }: {
        laneId: number,
        activateNotifications: boolean,
    }) => {
        return request.ActivateNotifications.mutationFn({
            token,
            laneId,
            activateNotifications,
        })
    }, {
        onError,
        onSuccess: onSuccessActivation,
    })
}

export default useActivateNotifications
