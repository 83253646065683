import React, {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import Alert from '@mui/material/Alert'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'

import useLabel from 'app/hooks/useLabel'

import getStatusText from './getStatusText'

import useStyles from './FailureMessage.styles'

type Error = {
    fields: string[],
    message: string,
}

type Props = {
    id: string,
    close: () => void,
    errors?: Error[] | string,
    statusCode: number,
    isDetailsOpen: boolean,
    setDetailsOpen: (val: boolean) => void,
}

const defaultProps = {
    errors: [],
}

const FailureMessage = ({
    id,
    close,
    errors,
    statusCode,
    isDetailsOpen,
    setDetailsOpen,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()

    const statusText = useMemo(() => {
        return getStatusText(statusCode)
    }, [statusCode])

    const replaceErrorFields = (template, values) => {
        return values.reduce((acc, value) => {
            return acc.replace(/%s/, value)
        }, template)
    }

    const action = useMemo(() => {
        return isDetailsOpen ? (
            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={close}
            >
                <CloseIcon
                    fontSize="inherit"
                    className={classes.icon}
                />
            </IconButton>
        ) : (
            <>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => { setDetailsOpen(true) }}
                >
                    <ExpandMoreIcon
                        fontSize="inherit"
                        className={classes.icon}
                    />
                </IconButton>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={close}
                >
                    <CloseIcon
                        fontSize="inherit"
                        className={classes.icon}
                    />
                </IconButton>

            </>
        )
    }, [
        classes.icon,
        close,
        isDetailsOpen,
        setDetailsOpen,
    ])

    return (
        <Alert
            key={`failure-${id}`}
            variant="filled"
            severity="error"
            data-testid={`failure-message-${id}`}
            icon={(
                <div className={classes.iconWrapper}>
                    <CancelIcon
                        fontSize="inherit"
                    />
                </div>
            )}
            action={action}
            classes={{
                message: classes.message,
            }}
        >
            {!isEmpty(errors) && Array.isArray(errors) ? errors.map((error) => {
                const labels = error.fields?.map((field) => {
                    return l(`${id}.${field}`) || field
                })

                if (!labels || labels.length === 0) {
                    return (
                        <span className={classes.spanWrapper}>
                            {error.message || error}
                        </span>
                    )
                }

                return (
                    labels.map((label, i) => {
                        return (
                            <span
                                key={label || i}
                                className={classes.spanWrapper}
                            >
                                {`${label}: `}
                                {error.message.includes('%') ? replaceErrorFields(error.message, labels) : error.message}
                            </span>
                        )
                    })
                )
            }) : (
                <span
                    className={classes.spanWrapper}
                >
                    {errors as string}
                </span>
            )}
            <Collapse in={isDetailsOpen}>
                <Alert
                    severity="error"
                    icon={false}
                    className={classes.details}
                >
                    {statusText}
                </Alert>
            </Collapse>
        </Alert>
    )
}

FailureMessage.defaultProps = defaultProps

export default FailureMessage
