import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    Storage,
} from './Storage.types'

const columns: RichTableColumns<keyof Storage>[] = [
    {
        id: 'storageType',
        headerProps: {
            children: 'Storage Type',
        },
        mapCellProps: 'storageType',
    },
    {
        id: 'preconTimeInHours',
        headerProps: {
            children: 'PreCon Time',
        },
        mapCellProps: 'preconTimeInHours',
    },
    {
        id: 'preconCapacity',
        headerProps: {
            children: 'PreCon Capacity',
        },
        mapCellProps: 'preconCapacity',
    },
]

export default columns
