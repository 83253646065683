import {
    useMutation,
} from 'react-query'
import {
    useDispatch,
} from 'react-redux'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import {
    BEErrorResponse, RequestConfig,
} from 'app/types/request.types'
import {
    QuoteGenerateAdditionalData,
    QuotePreviewRequestParams,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'
import simpleFullResponseApi from 'app/services/simpleApi/simpleFullResponseApi'
import {
    failedToLoadSaveForm,
    startToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'

type QuoteGenerateRequestParams = QuotePreviewRequestParams & QuoteGenerateAdditionalData

const NotificatioType = 'Notification'

const useQuoteGenerate = ({
    locationName,
    companyName,
    request,
    onSuccess,

}:{
    locationName: string,
    companyName: string,
    request: RequestConfig,
    onSuccess: (data, locationName, companyName) => void,
}) => {
    const token = useJWTToken()
    const dispatch = useDispatch()

    return useMutation((params: QuoteGenerateRequestParams) => {
        const {
            additionalFees,
            laneGroups,
            selectedAirports,
            selectedLaneFeesStatuses,
            selectedCustomerExecution,
            laneFeeValidFrom,
            laneFeeValidTo,
            selectedLaneStatus,
            selectedLaneLaneFeesPairs,
            selectedAdditionalFeesFees,
            isCustomerQuotation,
            allowDuplication,
            skycellEmployeeContact,
            customerContact,
            comment,
            quoteId,
        } = params

        dispatch(
            startToSaveForm({
                id: NotificatioType,
                title: 'Please stand by while we’re preparing the Lane Quote.',
            }),
        )
        return simpleFullResponseApi({
            ...request,
            responseType: 'arraybuffer',
            data: {
                additionalFees,
                laneGroups,
                selectedAirports,
                selectedLaneFeesStatuses,
                selectedCustomerExecution,
                laneFeeValidFrom,
                laneFeeValidTo,
                selectedLaneStatuses: selectedLaneStatus,
                selectedLaneLaneFeesPairs,
                selectedAdditionalFeesFees,
                isCustomerQuotation,
                allowDuplication,
                skycellEmployeeContact,
                customerContact,
                comment,
                quoteId,
            },
            token,
        })
    }, {
        onSuccess: (data: unknown) => {
            onSuccess(data, locationName, companyName)
        },
        onError: (err: BEErrorResponse) => {
            const enc = new TextDecoder('utf-8')

            const {
                response: {
                    data: errors,
                    status: statusCode,
                },
            } = err

            dispatch(
                failedToLoadSaveForm({
                    id: NotificatioType,
                    errors: JSON.parse(enc.decode(errors)),
                    statusCode,
                }),
            )
        },
    })
}

export default useQuoteGenerate
