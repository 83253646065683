/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
    useMemo, useRef,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import noop from 'lodash/noop'
import omit from 'lodash/omit'
import delay from 'lodash/delay'

import InputSingleline from 'app/shared-components/InputSingleline'
import useOutsideClick from 'app/hooks/useOutsideClick'

import useStyles from './Typeahead.styles'
import InputMultiline from '../InputMultiline'

const propTypes = {
    searchPhrase: PropTypes.string.isRequired,
    name: PropTypes.string,
    labelData: PropTypes.shape({
        value: PropTypes.string,
        iconPath: PropTypes.string,
    }),
    onChange: PropTypes.func,
    title: PropTypes.string,
    setSearchPhrase: PropTypes.func.isRequired,
    options: (props, propName, componentName) => {
        const {
            options,
            valueField,
        } = props

        if (!Array.isArray(options)) {
            return new Error(`Invalid prop ${propName} in Component ${componentName} need to be an array`)
        }

        const notValidElementIndex = options.findIndex((option) => {
            return !(option.label && option[valueField])
        })

        if (notValidElementIndex !== -1) {
            return new Error(`Invalid element with index ${notValidElementIndex} in prop ${propName} in Component ${componentName}.`)
        }

        return null
    },
    optionsVisible: PropTypes.bool.isRequired,
    labelField: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]).isRequired,
    valueField: PropTypes.string.isRequired,
    openOptions: PropTypes.func.isRequired,
    closeOptions: PropTypes.func.isRequired,
    variant: PropTypes.oneOf([
        'standard',
        'outlined',
        'filled',
    ]),
    classNames: PropTypes.shape({
        options: PropTypes.string,
    }),
    className: PropTypes.string,
    isRequired: PropTypes.bool,
    multiline: PropTypes.bool,
    getLabelValue: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string),
    validated: PropTypes.bool,
    noneOption: PropTypes.bool,
}

const defaultProps = {
    labelData: {},
    name: '',
    onChange: noop,
    options: [],
    errors: undefined,
    title: '',
    variant: 'outlined',
    classNames: {},
    className: '',
    isRequired: false,
    validated: undefined,
    multiline: false,
    getLabelValue: noop,
    noneOption: true,
}

const removeFields = (obj) => {
    return omit(obj, [
        'value',
        'Comp',
        'valueField',
        'labelField',
        'loadLabel',
        'loadOptions',
        'options',
        'loadAirports',
        'className',
    ])
}

const Typeahead = (props) => {
    const {
        onChange,
        options,
        openOptions,
        optionsVisible,
        setSearchPhrase,
        closeOptions,
        searchPhrase,
        valueField,
        variant,
        labelData,
        name,
        classNames,
        className,
        isRequired,
        multiline,
        getLabelValue,
        labelField,
        noneOption,
    } = props

    const {
        value,
        iconPath,
    } = labelData

    const wrapperRef = useRef()
    const {
        classes,
    } = useStyles()

    useOutsideClick(wrapperRef, closeOptions)

    const onOptionClick = useCallback((event) => {
        const target = event.currentTarget || event.target
        const {
            dataset: {
                value: newValue,
            },
        } = target

        const optionValue = options.find(({
            id,
        }) => { return String(id) === String(newValue) }) || {
            id: newValue,
        }

        onChange(optionValue, {
            target: {
                name,
                value: optionValue,
            },
        })
        closeOptions()
    }, [
        closeOptions,
        name,
        onChange,
        options,
    ])

    const onEnterPress = useCallback((event) => {
        const {
            key,
        } = event

        if (optionsVisible && key === 'Enter') {
            onChange(options[0], {
                target: {
                    name,
                    value: options[0],
                },
            })
            closeOptions()
        }
    }, [
        closeOptions,
        name,
        onChange,
        options,
        optionsVisible,
    ])

    const onBlur = useMemo(() => {
        return () => {
            return delay(closeOptions, 300)
        }
    }, [closeOptions])
    const InputComponent = multiline ? InputMultiline : InputSingleline

    return (
        <div
            data-testid={`typeahead-${name}`}
            className={clsx(classes.root, className)}
            ref={wrapperRef}
        >
            <InputComponent
                {...removeFields(props)}
                required={isRequired}
                onChange={setSearchPhrase}
                classNames={classNames}
                value={optionsVisible ? searchPhrase : value}
                onKeyPress={onEnterPress}
                onFocus={openOptions}
                onBlur={onBlur}
                variant={variant}
                iconPath={iconPath}
                autoComplete="off"
                name={name.replace('Id', '')} // needed to remove 'Id',strangely lastpass flagged it as user/pass field
            />
            <div
                className={
                    clsx(
                        classes.optionsWrapper,
                        optionsVisible && 'active',
                    )
                }
                data-testid="typeahead-options-wrapper"
            >
                {options.length > 0 && (
                    <div
                        className={clsx(classes.options, classNames.options)}
                    >
                        {noneOption ? (
                            <button
                                type="button"
                                key="option-none"
                                onClick={onOptionClick}
                                className={clsx(classes.option, classes.optionNone)}
                                data-value={undefined}
                                title="None"
                            >
                                None
                            </button>
                        ) : null}
                        {options.map((option) => {
                            return (
                                <button
                                    type="button"
                                    key={`option-${option[valueField]}`}
                                    onClick={onOptionClick}
                                    className={classes.option}
                                    data-value={option[valueField]}
                                >
                                    {option.label || getLabelValue(option, labelField)}
                                </button>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

Typeahead.propTypes = propTypes
Typeahead.defaultProps = defaultProps

export default Typeahead
