import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useTableControlsByPermissions from 'app/hooks/useTableControlsByPermissions'
import useRouteStateFilter from 'app/hooks/useRouteStateFilter'
import tableConfig from './opportunities.config'
import Overview from './Overview'
import DataUpdate from './DataUpdate'
import Lanes from './Lanes'
import CreateOpportunity from './CreateOpportunity'
import OpportunitiesChangeHistory from './OpportunitiesChangeHistory'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.BulkCreate,
    TableControlTypes.BulkEdit,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const tabOverview: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: Overview,
}

const tabUpdate: SidebarTab = {
    label: 'Data Update',
    url: 'update',
    Component: DataUpdate,
}

const tabLane: SidebarTab = {
    label: 'Lanes',
    url: 'lanes',
    Component: Lanes,
}

const tabHistory: SidebarTab = {
    label: 'Change History',
    url: 'change-history',
    Component: OpportunitiesChangeHistory,
}

const Opportunities = () => {
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const filter = useRouteStateFilter()

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: tabOverview,
            permission: true,
        },
        {
            tab: tabUpdate,
            permission: accountUpdate,
        },
        {
            tab: tabLane,
            permission: accountUpdate,
        },
        {
            tab: tabHistory,
            permission: true,
        },
    ])

    const restrictedTableControls = useTableControlsByPermissions(tableControls, {
        [TableControlTypes.BulkEdit]: accountUpdate,
        [TableControlTypes.BulkCreate]: accountCreate,
    })

    return (
        <SkyNetTable
            name={tableConfig.name}
            createForm={accountCreate
                ? CreateOpportunity
                : undefined}
            defaultTab="overview"
            tableControls={restrictedTableControls}
            tableConfig={tableConfig}
            tabs={tabs}
            showSearch
            filter={filter}
        />
    )
}

export default Opportunities
