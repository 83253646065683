import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import requests from '../AccountQuotes.requests'
import {
    OpportunityTableType,
} from '../../Opportunities/opportunities.types'

const useGetOpportunitiesByQuote = (quoteId: number) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<{data: OpportunityTableType[]}>({
        key: RequestKeys.getOpportunitiesByQuote,
        params: {},
        defaultValue: {
            data: {
                filters: {
                    quoteId,
                },
            },
        },
        config: requests.getOpportunitiesByQuote,
        keepPreviousData: true,
        enabled: Boolean(quoteId),
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data: data?.data,
        loadData,
    }
}

export default useGetOpportunitiesByQuote
