import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'

import useLabel from 'app/hooks/useLabel'
import Button from 'app/shared-components/Button'
import SimpleTable from 'app/shared-components/SimpleTable'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RejectionReason,
} from 'app/types/enums'
import {
    SelectedContainer,
    RequiredContainersCustomer,
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    Column,
} from 'app/types/simpletable.types'
import TableRowComponent from './TableRowComponent'
import useStyles from './BookedContainers.style'
import getColumns from './getColumns'
import {
    BookedContainer,
    RejectedContainer,
} from './bookedContainers.types'
import getSelectedContainersToReject from './utils/getSelectedContainersToReject'
import getSelectedContainersToRebook from './utils/getSelectedContainersToRebook'
import ValidationBookedContainers from './ValidationBookedContainers'
import useRejectContainers from './hooks/useRejectContainers'
import useRebookContainers from './hooks/useRebookContainers'

type Props = {
    value: SelectedContainer[],
    disabled: boolean,
    onSuccess: () => void,
    orderNumber: string,
    orderVersion: number,
    isInternalType: boolean,
    requiredContainers: RequiredContainersCustomer[] | RequiredContainersInternal[],
}

const BookedContainers = ({
    value,
    disabled,
    onSuccess,
    orderNumber,
    orderVersion,
    isInternalType,
    requiredContainers = [],
}: Props) => {
    const l = useLabel()
    const [
        data,
        setData,
    ] = useState<BookedContainer[]>(value)
    const {
        classes,
    } = useStyles()
    const rejectionReasons = useEnumValues()(RejectionReason)

    const selectedContainersToReject = useMemo((): RejectedContainer[] => {
        return getSelectedContainersToReject(data)
    }, [data])

    const selectedContainersToRebook = useMemo((): {id: number}[] => {
        return getSelectedContainersToRebook(data)
    }, [data])

    const {
        mutate: mutateRejectContainers,
        isLoading: isLoadingRejectContainers,
    } = useRejectContainers(onSuccess)
    const {
        mutate: mutateRebookContainers,
        isLoading: isLoadingRebookContainers,
    } = useRebookContainers(onSuccess)

    const onChange = useCallback((newValue: BookedContainer): void => {
        const updatedValue = data.map((item) => {
            if (item.id === newValue.id) {
                return newValue
            }

            return item
        })

        setData(updatedValue)
    }, [data])

    const columns = useMemo((): Column<keyof BookedContainer>[] => {
        return getColumns({
            classes,
            disabled,
            l,
            rejectionReasons,
        })
    }, [
        classes,
        disabled,
        l,
        rejectionReasons,
    ])

    return (
        <>
            <ValidationBookedContainers
                selectedContainers={data}
                requiredContainers={requiredContainers}
                isInternalType={isInternalType}
                disabled={disabled}
            />
            <SimpleTable
                name="BookedContainers"
                columns={columns}
                data={data}
                component={TableRowComponent}
                onChange={onChange}
                disabled
            />
            <ButtonContainer>
                <Button
                    label="reject containers"
                    disabled={disabled || !selectedContainersToReject.length}
                    saving={isLoadingRejectContainers}
                    onClick={() => {
                        mutateRejectContainers({
                            orderNumber,
                            orderVersion,
                            selectedContainers: selectedContainersToReject,
                        })
                    }}
                    data-testid="btn-reject-containers"
                />
                <Button
                    label="rebook containers"
                    disabled={disabled || !selectedContainersToRebook.length}
                    saving={isLoadingRebookContainers}
                    onClick={() => {
                        mutateRebookContainers({
                            orderNumber,
                            orderVersion,
                            selectedContainers: selectedContainersToRebook,
                        })
                    }}
                    data-testid="btn-rebook-containers"
                />
            </ButtonContainer>
        </>
    )
}

export default BookedContainers
