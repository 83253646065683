import React, {
    useCallback, useMemo,
} from 'react'

import SimpleTable from 'app/shared-components/SimpleTable'
import bolAwbColumns from './BolAwbInstructions.columns'
import Inputs from './Inputs'

type Props = {
    name?: string,
    onChange: (...args: any[]) => void,
    value?: {
        id: number,
        airportSeaport: {
            id: number,
            iataCode: string,
            locationName: string,
        },
        mAddress?: string,
        mNotifyParty?: string,
        hAddress?: string,
        hNotifyParty?: string,
        instructions?: string
    }[]
}

const defaultProps = {
    name: null,
    value: null,
}

const BolAwbInstructions = ({
    name,
    value,
    onChange,
}: Props) => {
    const bolAwbFields = useMemo(() => {
        return (value || []).map((val) => {
            return {
                ...val,
                airportSeaport: {
                    id: val.airportSeaport?.id,
                },
                airportSeaportId: val.airportSeaport?.id,
                mAddress: val.mAddress,
                mNotifyParty: val.mNotifyParty,
                hAddress: val.hAddress,
                hNotifyParty: val.hNotifyParty,
                instructions: val.instructions,
            }
        })
    }, [value])

    const handleChange = useCallback((
        changedValue,
    ) => {
        const newValue = value.map((val) => {
            if (val.id === changedValue.id) {
                return {
                    ...changedValue,
                    airportSeaport: {
                        id: Number(changedValue.airportSeaport.id),
                    },
                }
            }
            return val
        })

        onChange(newValue)
    }, [
        value,
        onChange,
    ])

    const handleAddEntry = useCallback(() => {
        const newInstance = {
            airportSeaport: null,
            mAddress: '',
            mNotifyParty: '',
            hAddress: '',
            hNotifyParty: '',
            instructions: '',
        }

        const newEntry = value ? [
            ...value,
            newInstance,
        ] : [newInstance]

        onChange(
            newEntry,
            {
                target: {
                    name,
                    value: newEntry,
                },
            },
        )
    }, [
        name,
        value,
        onChange,
    ])

    const handleDelete = useCallback((
        id,
    ) => {
        const newValue = value.map((val) => {
            if (val.id === id) {
                return undefined
            }
            return val
        }).filter(Boolean)

        onChange(newValue)
    }, [
        value,
        onChange,
    ])

    return (
        <SimpleTable
            name={name}
            columns={bolAwbColumns}
            data={bolAwbFields}
            component={Inputs}
            addEntry={handleAddEntry}
            onChange={handleChange}
            onDelete={handleDelete}
            disabled={false}
        />
    )
}

BolAwbInstructions.defaultProps = defaultProps

export default BolAwbInstructions
