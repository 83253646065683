import React, {
    useMemo,
    useCallback,
} from 'react'

import {
    useHistory,
} from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import useLabel from 'app/hooks/useLabel'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useRequestTable from 'app/hooks/useRequestTable'
import Card from 'app/shared-components/Card'
import {
    App,
} from 'app/types/common.enums'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'

import useStyles from './Lanes.styles'
import useGetOpportunityById from '../hooks/useGetOpportunityById'
import laneRequests from './Lanes.requests'
import columns from './Lanes.columns'

type Props = {
    id: number,
}

const Lanes = ({
    id,
}: Props) => {
    const history = useHistory()
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const {
        data,
    } = useGetOpportunityById(id)

    const {
        onParamsChange,
        loadData: load,
    } = useRequestTable<Props>({
        key: RequestKeys.getLanesFromOpportunity,
        params: {
            id,
        },
        config: laneRequests.filterLanesRequest,
        enabled: Boolean(id),
    })

    const injectLoadFilters = useLoadFilters(
        laneRequests.filterCountLanesRequest({
            id,
        }),
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns({
            l,
        }))
    }, [
        injectLoadFilters,
        l,
    ])

    const handleAddLane = useCallback(() => {
        history.push(`/apps/${App.ACCOUNT_MANAGEMENT}/lanes/all/create`, {
            autofill: {
                opportunity: {
                    id: data?.id,
                },
            },
        })
    }, [
        data,
        history,
    ])

    const onRowClick = useCallback(({
        id: idLane,
    }: {id: number}) => {
        history.push(`/apps/${App.ACCOUNT_MANAGEMENT}/lanes/all/edit/${idLane}/overview`, {
            filter: {
                accountId: [data?.account?.id],
            },
        })
    }, [
        data,
        history,
    ])

    return (
        <Card
            fullHeight
            contentClass={classes.cardContent}
        >
            <div className={classes.header}>
                <Typography variant="h3">
                    Lanes
                </Typography>
                <div className={classes.icon}>
                    <AddIcon
                        className={classes.icon}
                        onMouseDown={handleAddLane}
                    />
                </div>
            </div>
            <div
                className={classes.gridCardWrapper}
            >
                <RichTable
                    configName={laneRequests.domainName}
                    onParamsChange={onParamsChange}
                    name={laneRequests.domainName}
                    load={load}
                    columns={columnsWithFilters}
                    uniqField="id"
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                    onRowClick={onRowClick}
                />
            </div>
        </Card>
    )
}

export default Lanes
